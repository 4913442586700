import { FC } from "react";

import { Typography } from "antd";

interface IProps {
  message: string;
  error: boolean;
}

export const StatusMessage: FC<IProps> = ({ error, message }) => {
  return (
    <p>
      <Typography.Text
        type={ error ? "danger" : "success" }
      >
        { message }
      </Typography.Text>
    </p>
  );
};
