export const mobileNonFundColumns = [
  {
    title: "Asset Company & Asset Type",
    dataIndex: "asset",
    key: "asset",
    className: "ben-w-70",
  }, {
    title: "",
    key: "action",
    dataIndex: "action",
    align: "right",
    className: "ben-w-30",
  } as const,
];

export const desktopNonFundColumns = [
  {
    title: "Asset",
    dataIndex: "assetName",
    key: "assetName",
    className: "ben-w-30",
  },
  {
    title: "Asset Type",
    dataIndex: "assetType",
    key: "assetType",
    className: "ben-w-50",
  },
  {
    title: "",
    key: "action",
    dataIndex: "action",
    align: "right",
    className: "ben-w-20",
  } as const,
];
