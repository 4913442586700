export const inputFormatDate = (date: string, nativeEvent: any): string => {
  if (nativeEvent.inputType !== "insertText") {
    return date;
  }

  if (date.length > 10) {
    return date.slice(0, -1);
  }

  const arrayDate = date.split("").filter((value) => value.match(/[0-9]/));

  if (arrayDate.length > 1) {
    arrayDate.splice(2, 0, "/");
  }

  if (arrayDate.length > 4) {
    arrayDate.splice(5, 0, "/");
  }

  return arrayDate.join("");
};
