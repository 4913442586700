import { useTrustbenSelector } from "domains/trustben";

export const useInternalAltQuoteFunctionality = () => {
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const isInternalAltQuote = JSON.parse(
    sessionStorage.getItem("IS_INTERNAL_ALTQUOTE_FUNCTIONALITY") || "false",
  );
  const isInternalAltQuoteFunctionality = isInternalAltQuote || isTrustbenAltQuote;

  return { isInternalAltQuoteFunctionality, isInternalAltQuote };
};
