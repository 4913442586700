import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { AccountType } from "common/lib/constants";

export const entityTypes = [
  {
    label: "Individual Investor",
    value: "individual",
    type: AccountType.Individual,
  },
  {
    label: "Corporation, LLC, Partnership or Trust",
    value: "corp_and_llc",
    type: AccountType.Institution,
  },
  {
    label: "IRA, Employee Benefit Plan or other retirement plan",
    value: "ira_or_employee_benefit_or_retirement",
    type: AccountType.Institution,
  },
  {
    label: "Individual - Joint Tenants with Rights of Survivorship",
    value: "individual_tenant_joint",
    type: AccountType.Individual,
  },
  {
    label: "Entity - Joint Tenants with Rights of Survivorship",
    value: "entity_tenant_joint",
    type: AccountType.Institution,
  },
  {
    label: "Individual - Tenants in Common",
    value: "individual_tenant_common",
    type: AccountType.Individual,
  },
  {
    label: "Entity - Tenants in Common",
    value: "entity_tenant_common",
    type: AccountType.Institution,
  },
  {
    label: "Other (e.g. custodian or minor)",
    value: "other",
    type: AccountType.Institution,
  },
];

export const useEntityTypes = () => {
  const { t } = useTranslation("myProfile");

  const translatedEntityTypes = useMemo(() =>
    entityTypes.map((item) => {
      item.label = t(item.label, { ns: "myProfile" });

      return item;
    }), []);

  return { translatedEntityTypes };
};
