import { IDocument } from "domains/documents/shared/types";

export interface IOwnerName {
  account_id?: string;
  account_display_id?: string;
  name: string;
  asset_name?: string;
}

export interface IClientFormInfo {
  name: string;
  owner: string;
  type: string;
  isInput?: boolean;
  nav?: number;
}

export interface IOwnership {
  name: string;
  id: number;
  client_forms: IClientForm[];
  client_account_type: string;
  client_owner_type: string;
  client_account_id: string;
  client_forms_status: string;
}

export interface IOwnershipForm {
  documents_type?: string;
  other?: string;
}

export enum BeneficialTarget {
  Owner,
  Trust,
}

export interface IClientForm {
  id: string;
  name: string;
  isCompleted: boolean;
  isLoading: boolean;
  values: TClientFormValues;
  currentId?: number;
  target?: BeneficialTarget;
  currentBeneficialOwner?: IBeneficialOwner;
  currentBeneficialTrust?: IBeneficialTrust;
}

export type TClientFormValues = ISuitability &
  TCIPForms &
  TCustomerInformation &
  IBeneficialOwnership &
  TAccreditation &
  TW9Request &
  IDirectDeposit &
  TWireTransfers &
  IUnknownClientFormValues;

// This is needed for update documents Api
export interface IUnknownClientFormValues {
  [key: string]: any;
}

export interface ISuitability {
  is_ses_category: boolean;
  is_bd_employee: boolean;
  is_relation_to_bd_employee: boolean;
  is_finra_or_family_authorized: boolean;
  is_principal_share_holder: boolean;
  is_benses: boolean;
  ses_country?: string;
  b_de_name?: string;
  name_of_employee?: string;
  relation_to_bd_employee?: string;
  relation_to_bd_name?: string;
  relation_to_bd_employee_name?: string;
  account_name?: string;
  bonds: boolean;
  options_derivatives: boolean;
  limited_partnerships: boolean;
  unregistered_securities: boolean;
  stocks: boolean;
  commodities: boolean;
  venture_capital: boolean;
  annual_income: number;
  net_worth: string;
  liquid_net_worth: number;
  is_aggregated_joint_account_holder: boolean;
  tax_rate?: string;
  investment_objectives?: string;
  liquidity_needs?: string;
  time_horizon_years: number;
  time_horizon_months: number;
  risk_exposure?: string;
  earnings: boolean;
  inheritance: boolean;
  insurance_proceeds: boolean;
  investment_proceeds: boolean;
  pension_or_ira_savings: boolean;
  sale_of_business: boolean;
  others_source_of_funds: boolean;
  others_source_of_funds_type?: string;
  equities?: number;
  et_fs?: number;
  mutual_funds?: number;
  fixed_income?: number;
  variable_annuities?: number;
  fixed_annuities?: number;
  insurance?: number;
  others_asset_one_type?: string;
  others_asset_one_value?: number;
  others_asset_two_type?: string;
  others_asset_two_value?: number;
  principal_share_holder_company_name: string;
  mutual_fund_experience: string;
  bonds_experience: string;
  options_or_derivatives_experience: string;
  limited_partnerships_experience: string;
  variable_annuities_experience: string;
  unregistered_securities_experience: string;
  stocks_experience: string;
  commodities_experience: string;
  venture_capital_experience: string;
}

export type TCIPForms = IStandardCustomerProfile &
  IRegulatoryAuthorities &
  IRegistration &
  IBusinessCategories &
  IProhibitedCategories &
  IAdditionalQuestions &
  IDirectors &
  IPrimaryBusinessAddress;

export type TCustomerInformation = TNameAndAddress & TPersonalInformation & IOwnershipForm & IIdentification;

export interface IBeneficialOwnership {
  beneficial_owner_type?: string;
  entity_type?: string;
  legal_entity_customer_ownership_category: string;
  legal_name: string;
  cif: string;
  owners: IBeneficialOwner[];
  trusts: IBeneficialTrust[];
}

export interface IBeneficialOwner {
  id: string;
  beneficial_ownership_type: string;
  city: string;
  country: string;
  date_of_birth: string;
  expiration: string;
  number: string;
  name: string;
  ssn: string;
  state: string;
  street_address1: string;
  street_address2: string;
  title: string;
  type: string;
  us_citizen: boolean;
  zip: string;
  legal_entity_customer_id: string;
  is_loading: boolean;
  non_us_country_of_citizenship?: string;
}

export interface IBeneficialTrust {
  id: string;
  city: string;
  country: string;
  state: string;
  street_address1: string;
  street_address2: string;
  trust_name: string;
  trust_percent: string;
  trustee_name: string;
  trustee_date_of_birth: string;
  trustee_tin: string;
  zip: string;
  is_loading: boolean;
}

export interface INameAndAddress {
  street_address1: string;
  street_address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IPrimaryBusinessAddress {
  primary_street_address1: string;
  primary_street_address2: string;
  primary_city: string;
  primary_state: string;
  primary_zip: string;
}

export interface IInstitutionalNameAndAddress extends INameAndAddress {
  investor_name?: string;
}

export interface IIndividualNameAndAddress extends INameAndAddress {
  investor_name?: string;
}

export interface IPersonalInformation {
  phone_number: string;
  fax: string | null;
  email: string;
}

export interface IIndividualPersonalInformation extends IPersonalInformation {
  individual_investor_ssn?: string;
  individual_investor_date_of_birth?: string;
}

type TIndividualPersonalInformation = IPersonalInformation & ISpouseJointTenant & IIndividualPersonalInformation;

export interface ISpouseJointTenant {
  joint_tenant_name?: string;
  joint_tenant_ssn?: string;
  joint_tenant_date_of_birth?: string;
}

export interface IInstitutionalPersonalInformation extends IPersonalInformation {
  ein?: string;
}

export type TPersonalInformation = TIndividualPersonalInformation & IInstitutionalPersonalInformation;

export type TNameAndAddress = IInstitutionalNameAndAddress & IIndividualNameAndAddress;

export interface IStandardCustomerProfile {
  legal_name: string;
  business_email_address: string;
  naics_code: string;
  narrative_description_of_business: string;
  date_entity_established: string;
}

export interface IIdentification {
  identificationDocuments: IDocument[];
}

export interface IRegulatoryAuthorities {
  is_business_regulated_entity: boolean;
  regulatory_authorities?: string;
  additional_comments?: string;
}

export interface IRegistration {
  primary_country_of_registration: string;
  primary_state_of_registration: string;
  entity_classification: string;
}

export interface IBusinessCategories {
  business_categories: string;
}

export interface IProhibitedCategories {
  prohibited_categories: string | string[];
  pep: boolean;
  dba: boolean;
  dba_name: string;
  company_type: BusinessType;
  business_website: string;
  is_home_based_business: boolean;
  different_physical_presence: boolean;
  street_address1: string;
  street_address2: string;
  city: string;
  state: string;
  zip: string;
}

export enum BusinessType {
  PubliclyTraded = "publicly_traded",
  PrivatelyOwned = "privately_owned",
}

export interface IAdditionalQuestions {
  has_any_enforcement_actions: boolean;
  is_licensed_in_all_jurisdictions: boolean;
  has_business_transactions_internationally: boolean;
  has_international_business: boolean;
  has_business_changes: boolean;
  enforcement_detail?: string;
  licensing_detail?: string;
  business_transactions_international_details?: string;
  international_business_detail?: string;
  business_change_detail?: string;
}

export interface IDirectors {
  principals_partners_officers_and_directors: string;
}

export type TWireTransfers = IWireTransfersInstructions;

export interface IWireTransfersInstructions {
  bank_name: string;
  bank_address: string;
  bank_routing_number: string;
  swift_no: string;
  for_credit_to?: string;
  for_further_credit_to?: string;
  bank_account_number: string;
  bank_account_number_confirmation?: string;
}

export type TAccreditation = IGeneralInformation & IBadActorDisqualificationQuestionnaire;

export interface IGeneralInformation {
  investor_legal_name: string;
  organization_country: string;
  principal_place_of_business_country: string;
  principal_place_of_business_state: string;
}

export interface IBadActorDisqualificationQuestionnaire {
  has_been_convicted: boolean;
  has_restraint_decreed: boolean;
  has_final_order: boolean;
  has_sec_restrictions: boolean;
  has_sec_cease_and_desist: boolean;
  has_barred_securities: boolean;
  has_suspended_regulation_a_exemption: boolean;
  has_usps_suspension: boolean;
}

export type TW9Request = IW9RequestName & IW9RequestAddress & IRequesterAddress & IW9RequestTIN;

export interface IW9RequestName {
  business_name: string;
  federal_tax_classification: string;
  limited_liability_company_tax_classification: string;
}

export interface IW9RequestAddress extends INameAndAddress {
  account_numbers: string;
  zip_code: number | string;
}

export interface IRequesterAddress {
  requester_name: string;
  requester_street_address1: string;
  requester_street_address2: string;
  requester_city: string;
  requester_state: string;
  requester_zip: number;
}

export interface IW9RequestTIN {
  ssn?: string;
  ein?: string;
}

export enum DirectDepositOptions {
  Checking = "checking",
  Saving = "savings",
  Others = "other",
}

export interface IDirectDeposit {
  secondary_account_owner: string;
  account_type: DirectDepositOptions;
  bank_routing_number: string;
  bank_account_number: string;
  bank_account_number_confirmation?: string;
  bank_name: string;
  branch_location: string;
}

export interface ISelectValue {
  label: string;
  value: string;
}

export interface IChangedValue {
  op: string;
  path: string;
  value: any;
}

export enum ClientFormStatuses {
  Active = "active",
  InActive = "in_active",
  HasWarnings = "has_warnings",
  Completed = "completed",
}

export interface IFormName {
  first_name: string;
  last_name: string;
}

export enum EntityAttributes {
  ThereAreNoIndividuals = "no_more_than25_percent",
  EntityOperatesAsACharity = "charity_non_profit_religious",
  EntityIsAPooledInvestmentVehicle = "pooled_investment_vehicle",
  NotApplicable = "no_statements_apply",
}

export enum BeneficialOwnerType {
  Individual = "individual",
  Entity = "entity",
  Trust = "trust",
  Institution = "institution",
}
