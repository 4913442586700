import { FC, MouseEvent } from "react";

import { Button } from "common/lib/components";

interface IProps {
  buttonText: string | null;
  onClick: ((e: MouseEvent) => void) | null;
}

const MyClientsAltQuotesButton: FC<IProps> = ({ buttonText, onClick }) => {
  return (
    <>
      {buttonText && onClick && (
        <Button className="ben-request-formal-proposal-btn" onClick={ onClick }>
          {buttonText}
        </Button>
      )}
    </>
  );
};

export default MyClientsAltQuotesButton;
