import { FC, useEffect, useRef, useState } from "react";

import { Modal } from "antd";
import { Formik, FormikProps } from "formik";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Form, Input, Checkbox } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { URLs } from "common/lib/constants";
import { Button, Spinner } from "common/lib/components";
import { MyClientsPages } from "domains/clients/shared/constants";
import { useAddNewClientModalValidationSchema } from "./validationSchema";
import { useAltQuotesSelector } from "domains/altQuotes";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";

import "./AddNewClientModal.scss";

interface IProps {
  handleCancel: () => void;
  isVisible: boolean;
  isIndividual: boolean;
  isConvertingAdvisorAltQuoteWithoutClient?: boolean;
  openRequestFormalProposalModal?: (altQuote: ILiquidityRequest) => void;
}

const individualInitialValues = {
  first_name: "",
  last_name: "",
  email_address: "",
  complete_full_client_checkbox: false,
};
const organizationInitialValues = {
  company_name: "",
  email_address: "",
  complete_full_client_checkbox: false,
};

const AddNewClientModal: FC<IProps> = ({
  isVisible,
  handleCancel,
  isIndividual,
  isConvertingAdvisorAltQuoteWithoutClient,
  openRequestFormalProposalModal,
}) => {
  const { t } = useTranslation([ "dashboard", "common", "identity" ]);
  const navigate = useNavigate();

  const { currentAltQuote } = useAltQuotesSelector();
  const [ addNewClient, { isLoading: isCreatingClient, isSuccess, reset } ] =
    clientsApiReducer.useAddNewClientMutation();

  const { individualValidationSchema, organizationValidationSchema } = useAddNewClientModalValidationSchema();
  const [ isCompleteFullClientCheckboxChecked, setIsCompleteFullClientCheckboxChecked ] = useState(false);

  const formRef = useRef<FormikProps<typeof individualInitialValues | typeof organizationInitialValues>>(null);

  const onContinueClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSubmit = (value: any) => {
    value.investor_type = isIndividual ? "individual" : "institution";
    if (isCompleteFullClientCheckboxChecked) {
      navigate(URLs.PROTECTED.MY_CLIENTS + MyClientsPages.ADD_CLIENT, {
        state: {
          formValues: value,
          isLRCreation: true,
          isCompleteFullClientCheckboxChecked,
          isConvertingAdvisorAltQuoteWithoutClient,
        },
      });
      return;
    }

    addNewClient({
      data: value,
      isLRCreation: true,
      isIndividual,
      isCompleteFullClientCheckboxChecked: undefined,
      isLRSharing: undefined,
      isConvertingAdvisorAltQuoteWithoutClient,
    });
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsCompleteFullClientCheckboxChecked(e.target.checked);
  };

  useEffect(() => {
    if (!isSuccess) return;

    if (isConvertingAdvisorAltQuoteWithoutClient && openRequestFormalProposalModal && currentAltQuote) {
      openRequestFormalProposalModal(currentAltQuote);
      handleCancel();
    }

    reset();
  }, [ isSuccess ]);

  return (
    <Modal
      destroyOnClose
      afterClose={ () => setIsCompleteFullClientCheckboxChecked(false) }
      footer={ null }
      width={ 650 }
      onCancel={ handleCancel }
      open={ isCreatingClient || isVisible }
    >
      <div className="ben-add-new-client-modal-container">
        <div className="ben-add-new-client-modal-title">
          <h1>{isIndividual ? t("ADD A NEW INDIVIDUAL CLIENT") : t("ADD A NEW BUSINESS/ORGANIZATIONAL CLIENT")}</h1>
        </div>
        <p>{t("Please provide the information below to add a new client.")}</p>
        <Formik
          validateOnMount
          validationSchema={ isIndividual ? individualValidationSchema : organizationValidationSchema }
          innerRef={ formRef }
          onSubmit={ handleSubmit }
          initialValues={ isIndividual ? individualInitialValues : organizationInitialValues }
        >
          {({ isValid }) => (
            <Form disabled={ isCreatingClient } className="ben-add-new-client-modal-form">
              {isIndividual ? (
                <>
                  <Form.Item className="ben-add-new-client-modal-form-item" label={ t("First Name") } name="first_name">
                    <Input maxLength={ maxLengthText } className="small" type="text" name="first_name" suffix />
                  </Form.Item>

                  <Form.Item className="ben-add-new-client-modal-form-item" label={ t("Last Name") } name="last_name">
                    <Input maxLength={ maxLengthText } className="small" type="text" name="last_name" suffix />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  className="ben-add-new-client-modal-form-item ben-label-width"
                  label={ t("Organization Name") }
                  name="company_name"
                >
                  <Input maxLength={ maxLengthText } className="small" type="text" name="company_name" suffix />
                </Form.Item>
              )}

              <Form.Item name="complete_full_client_checkbox" className="ben-add-new-client-modal-form-checkbox">
                <Checkbox
                  name="complete_full_client_checkbox"
                  checked={ isCompleteFullClientCheckboxChecked }
                  onChange={ onCheckboxChange }
                >
                  {t("Add A New Client Modal Checkbox Text")}
                </Checkbox>
              </Form.Item>

              {isCreatingClient ? (
                <Spinner fullWidth />
              ) : (
                <Form.Item name="buttons" className="ben-add-new-client-modal-form-buttons">
                  <Button onClick={ handleCancel } type="primary">
                    {t("Cancel", { ns: "common" })}
                  </Button>
                  <Button disabled={ !isValid } onClick={ onContinueClick } type="primary">
                    {t("Continue", { ns: "common" })}
                  </Button>
                </Form.Item>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddNewClientModal;
