import { object, string } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";
import { useValidationSchemas } from "common/shared/constants/validationSchemas";

export const useAddAnonymousClientNameModalValidationSchema = () => {
  const { required } = useValidationErrorMessages();
  const { onlyLettersValidationSchema } = useValidationSchemas();

  const validationSchema = object().shape({
    anonymous_client_name: onlyLettersValidationSchema.required(required),
    investor_type: string().required(required),
  });

  return { validationSchema };
};
