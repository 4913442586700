import { TCustodyAccountType } from "./constants";

export interface ICustodyAccount {
  account_name: string;
  account_type: TCustodyAccountType;
  account_status: string;
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  street1: string;
  street2: string;
  city: string;
  country: string;
  country_of_citizenship: string;
  country_of_incorporation: string;
  country_code: string;
  phone_country: string;
  tax_id_country: string;
  state: string;
  region: string;
  zip_code: string;
  postal_code: string;
  ssn: number;
  date_of_birth: string;
  telephone_number: number;
  phone_number: string;
  tax_id: string;
  email_address: string;
  email: string;
  owner_type: TCustodyAccountType;
  custody_agreement: boolean | undefined;
  parent_account_id: string;
  account_id: string;
  account_display_id: string;
  user_id: string;
  user_display_id: string;
  id_country: string;
  id_type: string;
  id_number: string;
  id_expiration: string;
  advisory_firm_country_of_incorporation: string;
  advisory_firm_tax_id: string;
  status?: string;
}

export interface ICustodyAccountAPI {
  account_id: string;
  account_name: string;
  account_type: TCustodyAccountType;
  account_status: string;
  created_by_user_id: null;
  created_date: string;
  custodial_agreement_date: string | null;
  custodial_agreement_expiry_date: string | null;
  display_id: string;
  expiry_date: string;
  id: number;
  is_test_data: false;
  relationship_type: string;
  status: string;
  updated_by: null;
  updated_date: null;
  user_id: number;
}

export interface IIndividualCustodyAccount {
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  zip_code: string;
  ssn: string;
  date_of_birth: string;
  telephone_number: string;
  email_address: string;
  custody_agreement: boolean;
}

export interface IInstitutionCustodyAccount extends IIndividualCustodyAccount {
  account_name: string;
  tax_id: string;
}

export enum CustodyAccountStatus {
  Active = "on_boarded",
  NeedsId = "needs_id",
  Pending = "pending",
  Closed = "closed",
  ClosedForNewAccount = "closed_for_new_account",
  Duplicate = "duplicate",
}
