import { FC, useEffect } from "react";

import { useFormikContext } from "formik";

interface IProps {
  onChange: () => void;
}

const FormikObserver: FC<IProps> = ({ onChange }) => {
  const { values, touched } = useFormikContext();

  useEffect(() => {
    onChange();
  }, [ touched, values ]);

  return null;
};

export default FormikObserver;
