import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, InputNumber } from "common/lib/formik-antd";
import { NavigationButtons, Tooltip } from "common/lib/components";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { currencyFormatter, currencyParser } from "domains/liquidity/shared/utils/inputValueFormatter";
import { useInvestmentDetailsPageValidationSchema } from "./validationSchema";
import { useInternalAltQuoteFunctionality } from "domains/altQuotes";
import { useHandlers } from "./useHandlers";

const InvestmentDetailsForm = () => {
  const { t } = useTranslation("liquidity");

  const { currentAsset, isLA } = useLiquiditySelector();

  const { onSubmit, onBack } = useHandlers();
  const { altQuoteValidationSchema, liquidityApplicationValidationSchema } = useInvestmentDetailsPageValidationSchema();
  const { isInternalAltQuote } = useInternalAltQuoteFunctionality();

  const translationPrefix = isInternalAltQuote
    ? "Investment Details Page.Trustben or Internal AltQuote"
    : "Investment Details Page.Default";

  return (
    <Formik
      onSubmit={ onSubmit }
      initialValues={ { unfunded_commitment_type: "$", ...currentAsset } }
      validationSchema={ isLA ? liquidityApplicationValidationSchema : altQuoteValidationSchema }
      validateOnMount
    >
      {({ isValid, values }) => (
        <Form layout="vertical" requiredMark="optional">
          <Form.Item
            name="current_net_asset_value"
            required={ !isLA }
            label={
              <>
                {t(`${ translationPrefix }.Form Labels.0`)}{" "}
                <Tooltip content={ t(`${ translationPrefix }.Form Tooltips.0`) } dark />
              </>
            }
          >
            <InputNumber
              name="current_net_asset_value"
              className="small"
              formatter={ currencyFormatter }
              parser={ currencyParser }
              controls={ false }
              prefix
            />
          </Form.Item>

          <Form.Item
            name="capital_commitment"
            required={ !isLA }
            label={
              <>
                {t(`${ translationPrefix }.Form Labels.1`)}{" "}
                <Tooltip content={ t(`${ translationPrefix }.Form Tooltips.1`) } dark />
              </>
            }
          >
            <InputNumber
              name="capital_commitment"
              className="small"
              formatter={ currencyFormatter }
              parser={ currencyParser }
              controls={ false }
              prefix
            />
          </Form.Item>

          <Form.Item
            name="unfunded_commitment_currency"
            required={ !isLA }
            hidden={ values.unfunded_commitment_type !== "$" }
            label={
              <>
                {t(`${ translationPrefix }.Form Labels.2`)}{" "}
                <Tooltip content={ t(`${ translationPrefix }.Form Tooltips.2`) } dark />
              </>
            }
          >
            <InputNumber
              name="unfunded_commitment_currency"
              className="small"
              formatter={ currencyFormatter }
              parser={ currencyParser }
              controls={ false }
              prefix
            />
          </Form.Item>

          <NavigationButtons onBack={ onBack } disabled={ !isValid } />
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
