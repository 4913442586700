import { AxiosError } from "axios";

import { responseAPIErrors } from "common/shared/constants/responseAPIErrors";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import trustbenSessionService from "domains/trustben/shared/services/trustben.session.service/trustben.session.service";

const defaultConfig = {
  handlerEnabled: true,
  isPublic: false,
};

export const isHandlerEnabled = (config = defaultConfig) => {
  // eslint-disable-next-line no-prototype-builtins
  return config.hasOwnProperty("handlerEnabled") ? config.handlerEnabled : true;
};

export const isPublic = (config = defaultConfig) => {
  // eslint-disable-next-line no-prototype-builtins
  return config.hasOwnProperty("isPublic") ? config.isPublic : false;
};

export const logAPIError = (error: any) => {
  if (error) {
    if (error.browserErrorMessage) {
      console.error(`LOGOUT ERROR MESSAGE: ${ error.browserErrorMessage }`);
    }
    if (error.request && error.request.status) {
      console.error(`LOGOUT ERROR STATUS: ${ error.request.status }`);
    }
    if (error.request && error.request.statusText) {
      console.error(`LOGOUT ERROR STATUS_TEXT: ${ error.request.statusText }`);
    }
    if (error.response && error.response.data && error.response.data.message) {
      console.error(`LOGOUT ERROR RESPONSE: ${ error.response.data.message }`);
    }
  }
};

export const isTrustbenSignInError = (error: AxiosError<any>) => {
  const isTrustbenAltQuote = trustbenSessionService.getIsTrustbenAltQuote();
  const isAuthenticationError = error.response?.config.url?.includes("oauth2");

  return isTrustbenAltQuote && isAuthenticationError;
};

export const isCustodyAccountError = (error: AxiosError<any>) => {
  const isDuplicateCustodyAccountError =
    error.response?.data?.message === responseAPIErrors.duplicateCustodyAccount.message &&
    error.response?.data?.error_details?.error_code === responseAPIErrors.duplicateCustodyAccount.code;
  const isSharedCustodyAccountError =
    error.response?.data.message === responseAPIErrors.sharedCustodyAccount.message &&
    error.response?.data?.error_details?.error_code === responseAPIErrors.sharedCustodyAccount.code;

  return isDuplicateCustodyAccountError || isSharedCustodyAccountError;
};

export const isTrustbenAltQuoteResultsError = () => {
  const isTrustbenAltQuote = trustbenSessionService.getIsTrustbenAltQuote();
  const isAltQuoteResultsPages = location.pathname.split("/").at(-1)?.includes(MyApplicationsPages.ALTQUOTE);

  return isTrustbenAltQuote && isAltQuoteResultsPages;
};
