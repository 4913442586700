export const MyProfilePages = {
  Main: "/",
  Accreditation: "accreditation",
  MultipleOwners: "multiple-owners",
  CustomerInformation: "customer-information",
  WireTransferInstructions: "wire-transfer-instructions",
  Suitability: "suitability",
  W9Request: "w-9-request",
  DirectDeposit: "direct-deposit",
  BeneficialOwnership: "beneficial-ownership",
  CIP: "cip",
};

export const ClientFormTypes = {
  CustomerInformation: "Customer Information",
  W9Form: "W-9 Form",
  Suitability: "Suitability",
  Accreditation: "Accreditation",
  BeneficialOwnership: "Beneficial Ownership",
  CIP: "CIP",
  DirectDeposit: "Direct Deposit",
  WireTransfers: "Wire Transfers",
};

export const ApiClientFormTypes = {
  [ ClientFormTypes.Accreditation ]: "client_forms_accreditation",
  [ ClientFormTypes.BeneficialOwnership ]: "client_forms_beneficial_ownership",
  [ ClientFormTypes.CIP ]: "client_forms_cip",
  [ ClientFormTypes.CustomerInformation ]: "client_forms_customer_information",
  [ ClientFormTypes.DirectDeposit ]: "client_forms_direct_deposit",
  [ ClientFormTypes.Suitability ]: "client_forms_suitability",
  [ ClientFormTypes.W9Form ]: "client_forms_w9",
  [ ClientFormTypes.WireTransfers ]: "client_forms_wire_transfer",
};

