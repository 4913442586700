import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IGPQuestionnaire } from "domains/trustben/shared/types";
import { TAltQuoteQuestionnaire } from "domains/trustben/pages/AltQuoteQuestionnairePage/AltQuoteQuestionnaireForm/types";
import { trustbenApiReducer } from "./trustben.api.reducer";

interface IInitialState {
  isTrustbenUserAuthenticated: boolean;
  isTrustbenAltQuote: boolean;
  altQuoteQuestionnaire: TAltQuoteQuestionnaire | null;
  gpQuestionnaire: IGPQuestionnaire | null;
}

const initialState: IInitialState = {
  isTrustbenUserAuthenticated: false,
  isTrustbenAltQuote: false,
  altQuoteQuestionnaire: null,
  gpQuestionnaire: null,
};

const trustbenReducer = createSlice({
  name: "[Trustben]",
  initialState,
  reducers: {
    saveAltQuoteQuestionnaire: (state, action: PayloadAction<TAltQuoteQuestionnaire | null>) => ({
      ...state,
      altQuoteQuestionnaire: action.payload,
    }),

    saveGPQuestionnaire: (state, action: PayloadAction<IGPQuestionnaire>) => ({
      ...state,
      gpQuestionnaire: action.payload,
    }),

    setIsTrustbenAltQuote: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isTrustbenAltQuote: action.payload,
    }),

    setIsTrustbenUserAuthenticated: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isTrustbenUserAuthenticated: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(trustbenApiReducer.endpoints.sendAltQuoteQuestionnaire.matchFulfilled, (state) => ({
      ...state,
      altQuoteQuestionnaire: null,
    }));
  },
});

export const {
  saveAltQuoteQuestionnaire,

  saveGPQuestionnaire,

  setIsTrustbenAltQuote,

  setIsTrustbenUserAuthenticated,
} = trustbenReducer.actions;

export default trustbenReducer.reducer;
