import {
  IAcceptIdentityResponseData,
  IResetPasswordResponseData,
  IRefreshAccessTokenParams,
  IRefreshAccessTokenRequestBody,
  IRefreshAccessTokenResponseData,
} from "./types";

export function prepareRefreshAccessTokenRequestBody(
  data: IRefreshAccessTokenParams,
  clientId: string,
): IRefreshAccessTokenRequestBody {
  const { refreshToken } = data;
  const requestBody = {
    client_id: clientId,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };

  return requestBody;
}

export function parseRefreshAccessTokenResponseData(data: any): IRefreshAccessTokenResponseData {
  const result: IRefreshAccessTokenResponseData = {
    accessToken: "",
  };

  if (!data || typeof data !== "object" || !data.access_token) {
    return result;
  }

  result.accessToken = data.access_token;

  return result;
}

export function parseAcceptIdentityResponseData(data: any): IAcceptIdentityResponseData {
  return {
    token: data?.token,
  };
}

export function parseAcceptResetPasswordResponseData(data: any): IResetPasswordResponseData {
  return {
    token: data?.token,
    accepted_terms: data?.accepted_terms,
  };
}
