// A function to retry loading a chunk to avoid chunk load error for out of date code
const lazyWithRetry = <T>(componentImport: () => Promise<T>, chunkName: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${ chunkName }-refreshed`) || "false");

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${ chunkName }-refreshed`, "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(`retry-${ chunkName }-refreshed`, "true");

          window.location.reload();
          return;
        }
        reject(error);
      });
  });
};

export default lazyWithRetry;
