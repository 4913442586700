import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import identityApiService from "domains/identity/shared/identity.api.service";
import { sessionService } from "common/shared/services/session/session.service";
import {
  IIdentity,
  ICreateRegistrationRequest,
  IGetSharingRequest,
  ICreateRegistrationResponse,
} from "domains/identity/shared/types";
import { apiService } from "common/shared";

export const identityApiReducer = rootApiReducer.injectEndpoints({
  endpoints: (build) => ({
    createRegistration: build.mutation<ICreateRegistrationResponse, ICreateRegistrationRequest>({
      queryFn: async (data) => {
        try {
          const response = await identityApiService.createRegistration(data);

          return { data: response };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    fetchIdentity: build.query<IIdentity, void>({
      queryFn: async () => {
        try {
          const email = sessionService.getUserEmail();
          const data = await identityApiService.fetchIdentity(email);
          sessionService.setSessionIds({ accountId: data.account_id, userId: data.user_id });
          sessionService.setWhiteLabelInstance(data.white_label_instance);

          return { data };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    getSharing: build.query<string, IGetSharingRequest>({
      queryFn: async (data) => {
        try {
          const response = await identityApiService.getSharing(data);
          return { data: response.token };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),
  }),
});
