import { FC, useCallback, MouseEvent } from "react";

import { useTranslation } from "react-i18next";
import { Collapse, CollapseProps } from "antd";
import { useNavigate } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { MyClientsList , PanelExtraButton } from "domains/dashboard";
import { MyClientsPages } from "domains/clients/shared/constants";

const { Panel } = Collapse;

const MyClientsPanel: FC<CollapseProps> = (props) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const handleClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    navigate(URLs.PROTECTED.MY_CLIENTS + MyClientsPages.ADD_CLIENT, { state: { isNewClient: true } });
  }, []);

  return (
    <Panel { ...props } header={ t("My Clients") } key="My Clients" extra={ <PanelExtraButton onClick={ handleClick } title="Add a New Client" /> }>
      <MyClientsList />
    </Panel>
  );
};

export default MyClientsPanel;
