import React from "react";

import "./ErrorBoundary.scss";

interface IProps {
  children: JSX.Element;
}

export default class ErrorBoundary extends React.Component<IProps> {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="ben-error-indicator">
          <span className="ben-error-indicator-title">BOOM!</span>
          <span>something has gone terribly wrong</span>
          <span>we are doing our best to fix it</span>
        </div>
      );
    }

    return children;
  }
}
