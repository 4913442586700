import { useCallback, useState } from "react";

import { DatePicker as AntdDatePicker } from "antd";
import { useField } from "formik";
import dayjs, { Dayjs } from "dayjs";

import { dateFormat } from "common/shared/constants/validationConstants";
import Input from "common/lib/formik-antd/Input";
import { inputFormatDate } from "common/shared/helpers";
import { Button } from "common/lib/components";

interface IProps {
  name: string;
  placeholder?: string;
  inputClassName?: string;
  disabledDate?: (currentDate: Dayjs) => boolean;
}

const DatePicker = ({ name, placeholder, inputClassName, disabledDate }: IProps) => {
  const [ { value }, _meta, { setValue } ] = useField(name);

  const [ open, setOpen ] = useState(false);

  const showPanel = useCallback(() => setOpen(true), []);
  const hidePanel = useCallback(() => setOpen(false), []);

  const renderExtraFooter = useCallback(
    () => (
      <>
        <Button
          onClick={ () => {
            setValue("");
            setOpen(false);
          } }
        >
          Clear
        </Button>
        <Button
          type="primary"
          onClick={ () => {
            setOpen(false);
          } }
        >
          Close
        </Button>
      </>
    ),
    [],
  );

  return (
    <>
      <Input
        id={ name }
        name={ name }
        placeholder={ placeholder }
        className={ inputClassName }
        onClick={ showPanel }
        onBlur={ hidePanel }
        onChange={ (e) => {
          setValue(inputFormatDate(e.target.value, e.nativeEvent));
        } }
        suffix
      />
      <AntdDatePicker
        id={ name }
        name={ name }
        value={ value && dayjs(value).isValid() ? dayjs(value) : null }
        format={ dateFormat }
        autoFocus={ true }
        open={ open }
        onOpenChange={ (op) => setOpen(op) }
        showToday={ false }
        allowClear={ false }
        renderExtraFooter={ renderExtraFooter }
        disabledDate={ disabledDate }
        onChange={ (_date, dateString) => setValue(dateString) }
      />
    </>
  );
};

export default DatePicker;
