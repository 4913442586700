import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as AddFile } from "common/assets/images/icons/Add File.svg";

const AddFileFilled: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon { ...props } className={ props?.className + " ben-icon-primary-color" } component={ AddFile } />
);

export default AddFileFilled;
