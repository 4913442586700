import { FC } from "react";

import { useScreen } from "common/shared-hooks";

import "./FormHeader.scss";

interface IProps {
  title?: string | JSX.Element;
  subtitle?: string;
  hint?: string | JSX.Element;
  assetName?: string;
  className?: string;
}

const FormHeader: FC<IProps> = ({ title, subtitle, hint, assetName, className }) => {
  const { mobile } = useScreen();
  return (
    <div className={ className ? `ben-form-header ${ className }` : "ben-form-header" }>
      { !mobile && title && <span className="ben-form-header-title">{ title }</span> }
      { subtitle && <span className="ben-form-header-subtitle">{ subtitle }</span> }
      { hint && <span className="ben-form-header-hint">{ hint }</span> }
      { assetName && <span className="ben-form-header-asset-name">{ assetName }</span> }
    </div>
  );
};

export default FormHeader;
