import { ILiquidityRequest, LiquidityRequestStatus } from "domains/liquidity/shared/types";

export const sortLiquidityApplications = (liquidityRequests: ILiquidityRequest[]) => {
  const compareFn = (lr1: ILiquidityRequest, lr2: ILiquidityRequest) => {
    if (!lr1.currentTask || !lr2.currentTask) return 0;

    if (
      lr1.currentTask.liquidity_request_status === LiquidityRequestStatus.InProposal &&
      lr2.currentTask.liquidity_request_status !== LiquidityRequestStatus.InProposal
    )
      return -1;

    return 0;
  };

  return liquidityRequests.sort(compareFn);
};

export const getPhoneNumber = (
  phone: string | number | undefined,
  countryCode: string | number | undefined,
) => {
  if (!phone || !countryCode) return "";
  if (String(phone).startsWith("+")) return phone;

  return `+${ countryCode }${ phone }`;
};
