import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as Notification } from "common/assets/images/icons/Notification.svg";

const NotificationFilled: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon { ...props } component={ Notification } />
);

export default NotificationFilled;
