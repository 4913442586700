import { apiEnvs, apiService } from "common/shared";
import { IProposals } from "./types";

export class ProposalsApiService {
  private config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async fetchProposals(LRId: string): Promise<IProposals | null> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/proposals`, this.config);
  }

  async fetchPreviousProposals(LRId: string): Promise<IProposals[] | []> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/proposals_history`, this.config);
  }

  async updateProposals(id: number, value: boolean): Promise<void> {
    const data = [
      {
        op: "replace",
        path: "/is_accepted",
        value,
      },
    ];
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/offers/${ id }`, data, this.config);
  }

  async requestReactivationProposal(id: number): Promise<void> {
    const data = [
      {
        op: "replace",
        path: "/customer_note",
        value: "Customer Requests Reactivation of Offer",
      },
    ];
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/proposals/${ id }`, data, this.config);
  }

  async resubmitAssets(optionId: string, note: string): Promise<void> {
    const data = [
      {
        op: "replace",
        path: "/customer_note",
        value: note,
      },
    ];
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/offers/${ optionId }`, data, this.config);
  }
}

export default new ProposalsApiService();
