import { AxiosRequestConfig } from "axios";

export enum WhiteLabelType {
  Default = "default",
  Partner = "partner",
  Trustben = "trustben",
}

export type TWhiteLabel = "default" | "partner" | "trustben";

export type TMakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> & Partial<Pick<Type, Key>>;

export interface IErrorResponse {
  data: any;
  status?: number;
}

export enum REQUEST_METHODS {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
}

export type TRequestMethods = `${ REQUEST_METHODS }`;

export interface IRequestConfig extends AxiosRequestConfig {
  url: string;
  method: TRequestMethods;
}

export interface IUpdateAPIObject {
  op: string;
  path: string;
  value: string | number | boolean;
}
