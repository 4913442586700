import { FC, useCallback, useEffect, useRef, useState } from "react";

import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { Form, Select, Input, DatePicker } from "common/lib/formik-antd";
import { maxIdExpirationDate, maxLengthIdentificationNumber } from "common/shared/constants/validationConstants";
import { countries, URLs } from "common/lib/constants";
import { Caret, NavigationButtons, StatusMessage } from "common/lib/components";
import { UploadDocuments, UploadedDocumentsTable } from "domains/myProfile/components";
import { useDocumentSelector } from "domains/documents/store/documents.selector";
import { useCustodyAccountIDFormValidationSchema } from "./validationSchema";
import { LAPages } from "domains/liquidity";
import { getInitialValues } from "./helpers";
import { CustodyAccountStatus, ICustodyAccount } from "domains/custodyAccounts/shared/types";
import { getChangedFormValues } from "common/shared/helpers";
import { useBootstrapSelector } from "common/shared-store/bootstrap.selectors";
import { setNavigateTo } from "common/shared-store/bootstrap.reducer";
import { usDocTypes, nonUSDocTypes } from "./IdDocumentTypes/constants";
import IdDocumentTypes from "./IdDocumentTypes";
import { DocumentType } from "domains/documents/shared/constants";
import { useAppDispatch } from "store/store.hooks";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { formatCustodyAccountDataToUpdate } from "domains/custodyAccounts/shared/apiFormatter";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { IUpdateAPIObject } from "common/shared/types";
import { IDocument } from "domains/documents/shared/types";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";

import "./CustodyAccountIDForm.scss";

const { Option } = Select;

interface IProps {
  documents: IDocument[];
}

const CustodyAccountIDForm: FC<IProps> = ({ documents }) => {
  const { t } = useTranslation([ "myProfile", "liquidity", "common" ]);
  const { LRId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [ isDisabled, setIsDisabled ] = useState(true);
  const [ checkboxValue, setCheckboxValue ] = useState(false);
  const [ isAllDocumentsDeteled, setisAllDocumentsDeteled ] = useState(false);

  const [ updateAccountLevelDetails, { isSuccess, reset } ] = clientsApiReducer.useUpdateAccountLevelDetailsMutation({
    fixedCacheKey: "update-custody-account-id-form",
  });
  const [ _deleteDocument, { data: documentId, isSuccess: isDocumentDeleted, reset: resetDeleteDocumentMutation } ] =
    documentsApiReducer.useDeleteDocumentMutation({
      fixedCacheKey: "delete-document",
    });
  const { navigateTo } = useBootstrapSelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const initialValues = getInitialValues(currentCustodyAccount);
  const validationSchema = useCustodyAccountIDFormValidationSchema(checkboxValue);
  const { uploadingDocuments } = useDocumentSelector();

  const hideDeleteDocumentAction = currentCustodyAccount?.account_status === CustodyAccountStatus.Active;
  const allDocuments = [ ...documents, ...uploadingDocuments ];
  const filteredDocuments = allDocuments.filter((document) => document.document_type === DocumentType.Identification);
  const isIdDocumentUploaded = !!documents.find((document) => document.document_type === DocumentType.Identification);
  const isUploading = !!uploadingDocuments.length;
  const formRef = useRef<FormikProps<typeof initialValues>>(null);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setCheckboxValue(e.target.checked);
  };

  useEffect(() => {
    if (!isDocumentDeleted) return;

    const remainingDocuments = filteredDocuments.filter((document) => document.id !== documentId);
    const isPendingStatus = currentCustodyAccount?.account_status === CustodyAccountStatus.Pending;

    if (!remainingDocuments.length && isPendingStatus) {
      setisAllDocumentsDeteled(true);
      const changes = { status: CustodyAccountStatus.NeedsId } as ICustodyAccount;
      const formattedChanges = formatCustodyAccountDataToUpdate(
        changes as any,
        currentCustodyAccount.owner_type || currentCustodyAccount.account_type,
      ) as IUpdateAPIObject[];

      updateAccountLevelDetails({
        accountId: currentCustodyAccount.account_id,
        rawChanges: changes,
        formattedChanges,
      });
      dispatch(
        setCurrentCustodyAccount({
          ...currentCustodyAccount,
          ...changes,
        }),
      );
    }

    resetDeleteDocumentMutation();
  }, [ isDocumentDeleted ]);

  useEffect(() => {
    if (!isSuccess) return;

    if (isAllDocumentsDeteled) {
      setisAllDocumentsDeteled(false);
    } else if (navigateTo) {
      navigate(navigateTo);
      dispatch(setNavigateTo(null));
    } else {
      LRId
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmApplication)
        : navigate(URLs.PROTECTED.DASHBOARD);
    }

    dispatch(custodyAccountsApiReducer.util.invalidateTags([ { type: "custodyAccounts", id: "LIST" } ]));
    reset();
  }, [ isSuccess, LRId, isAllDocumentsDeteled, navigateTo ]);

  const handleSubmit = (formValues: any) => {
    const changedValues = getChangedFormValues(formValues, initialValues);
    const status = isIdDocumentUploaded ? CustodyAccountStatus.Pending : CustodyAccountStatus.NeedsId;
    const isStatusChanged = currentCustodyAccount?.account_status !== status;
    const changes = (isStatusChanged ? { ...changedValues, status } : changedValues) as any;

    if (Object.keys(changes).length && currentCustodyAccount) {
      const formattedChanges = formatCustodyAccountDataToUpdate(
        changes,
        currentCustodyAccount.owner_type || currentCustodyAccount.account_type,
      ) as IUpdateAPIObject[];

      updateAccountLevelDetails({ accountId: currentCustodyAccount.account_id, rawChanges: changes, formattedChanges });
      dispatch(
        setCurrentCustodyAccount({
          ...currentCustodyAccount,
          ...changes,
        }),
      );
    } else if (navigateTo) {
      navigate(navigateTo);
      dispatch(setNavigateTo(null));
    } else {
      LRId
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmApplication)
        : navigate(URLs.PROTECTED.DASHBOARD);
    }
  };

  const onBack = useCallback(() => {
    LRId
      ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement)
      : navigate(URLs.PROTECTED.LIQUIDITY + LAPages.CustodyAccountAgreement);
  }, [ LRId ]);

  if (isIdDocumentUploaded && checkboxValue) {
    setCheckboxValue(false);
  }

  useEffect(() => {
    if (!checkboxValue) return;

    formRef.current?.validateForm();
  }, [ checkboxValue ]);

  return (
    <div className="ben-custody-account-id-form-container">
      <span className="ben-custody-account-id-form-info ben-mt-3">
        {t("Custody Account ID Page.Text1", { ns: "liquidity" })}
      </span>
      <div className="ben-form-container">
        <Formik
          initialValues={ initialValues }
          onSubmit={ handleSubmit }
          innerRef={ formRef }
          validateOnMount
          validateOnChange
          enableReinitialize
          validationSchema={ validationSchema }
        >
          {({ isValid, setFieldValue, values }) => {
            setIsDisabled(!isValid);

            return (
              <Form layout="vertical" style={ { margin: "0 auto 0 0" } } className="ben-custody-account-id-form">
                <Form.Item name="id_country" label={ t("Identification Country") } className="ben-input">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                    name="id_country"
                    onSelect={ () => setFieldValue("id_type", "", false) }
                    placeholder={ t("Country") }
                  >
                    {countries.map((country) => (
                      <Option key={ country } value={ country }>
                        {country}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="id_type" label={ t("Identification Type") } className="ben-input">
                  <Select
                    name="id_type"
                    placeholder={ t("Identification Type") }
                    suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                  >
                    {(values.id_country === "United States" ? usDocTypes : nonUSDocTypes).map(
                      (item: { key: string; value: string }) => (
                        <Option key={ item.key } value={ item.key }>
                          {item.value}
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>

                <Form.Item name="id_number" label={ t("Identification Number") } className="ben-input">
                  <Input
                    className="small"
                    placeholder={ t("Identification Number") }
                    name="id_number"
                    maxLength={ maxLengthIdentificationNumber }
                    suffix
                  />
                </Form.Item>

                <Form.Item className="ben-input" name="id_expiration" label={ t("Identification Expiration Date") }>
                  <DatePicker
                    name="id_expiration"
                    inputClassName="small"
                    disabledDate={ (current) => {
                      return current.toDate() < new Date() || current.toDate() > maxIdExpirationDate;
                    } }
                    placeholder={ t("Identification Expiration Date") }
                  />
                </Form.Item>

                <IdDocumentTypes idCountry={ values.id_country! } />
              </Form>
            );
          }}
        </Formik>
      </div>

      {!!filteredDocuments.length && (
        <UploadedDocumentsTable type="custody" documents={ filteredDocuments } hideAction={ hideDeleteDocumentAction } />
      )}

      <span className="ben-mt-4 ben-custody-account-id-form-text">
        {t("Custody Account ID Page.Text3", { ns: "liquidity" })}
      </span>

      <UploadDocuments type="custody" />

      {!isIdDocumentUploaded && (
        <Checkbox
          onChange={ handleCheckboxChange }
          checked={ checkboxValue }
          className="ben-mt-3 ben-custody-account-id-form-checkbox"
        >
          {t("Custody Account ID Page.Checkbox", { ns: "liquidity" })}
        </Checkbox>
      )}

      {!isIdDocumentUploaded && checkboxValue && (
        <div className="ben-d-flex ben-justify-content-center ben-mt-1">
          <StatusMessage message={ t("Custody Account ID Page.WarningMessage", { ns: "liquidity" }) } error />
        </div>
      )}

      <NavigationButtons
        onBack={ onBack }
        onNext={ () => formRef.current?.handleSubmit() }
        submitButtonText={ t("Submit", { ns: "common" }) }
        disabled={ (isDisabled && isIdDocumentUploaded) || (!checkboxValue && !isIdDocumentUploaded) || isUploading }
      />
    </div>
  );
};

export default CustodyAccountIDForm;
