import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserClient, IUserDetails } from "domains/clients/shared/types";
import { clientsApiReducer } from "./clients.api.reducer";

interface IInitialState {
  user: IUserDetails | null;
  currentClient: IUserClient | null;
}

const initialState: IInitialState = {
  user: null,
  currentClient: null,
};

const clientsReducer = createSlice({
  name: "[Clients]",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserDetails>) => ({
      ...state,
      user: action.payload,
    }),

    setCurrentClient: (state, action: PayloadAction<IUserClient | null>) => ({
      ...state,
      currentClient: action.payload,
    }),

    resetClientsReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(clientsApiReducer.endpoints.acceptCookies.matchFulfilled, (state, action) => ({
      ...state,
      user: {
        ...(state.user as IUserDetails),
        user_strictly_necessary_cookies: true,
        user_performance_and_targeted_cookies: action.payload,
      },
    }));
  },
});

export const {
  setUser,

  setCurrentClient,

  resetClientsReducer,
} = clientsReducer.actions;

export default clientsReducer.reducer;
