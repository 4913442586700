import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "react-router-dom";

interface IInitialState {
  navigateToAfterLogin: null | Location;
  navigateTo: null | Location;
}

const initialState: IInitialState = {
  navigateToAfterLogin: null,
  navigateTo: null,
};

const bootstrapReducer = createSlice({
  name: "[Bootstrap]",
  initialState,
  reducers: {
    setNavigateToAfterLogin: (state, action: PayloadAction<Location | null>) => ({
      ...state,
      navigateToAfterLogin: action.payload,
    }),

    setNavigateTo: (state, action: PayloadAction<Location | null>) => ({
      ...state,
      navigateTo: action.payload,
    }),
  },
});

export const {
  setNavigateToAfterLogin,

  setNavigateTo,
} = bootstrapReducer.actions;

export default bootstrapReducer.reducer;
