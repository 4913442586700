import { apiEnvs, apiService } from "common/shared";
import { ITask } from "./types";

export class MyApplicationsApiService {
  private config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async fetchAllWorkflowTasks(LRId: string): Promise<ITask[]> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/workflow_tasks/all`, this.config);
  }

  async fetchCurrentTask(LRId: string) {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/workflow_tasks/current`, this.config);
  }
}

export default new MyApplicationsApiService();
