/* eslint-disable import/no-named-as-default-member */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { apiEnvs } from "common/shared";

declare module 'i18next' {
  // eslint-disable-next-line
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    ns: [ "authentication", "common", "identity" ],
    debug: process.env.NODE_ENV === "development",
    load: "languageOnly",
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    backend: {
      loadPath: async () => {
        const { whiteLabel } = await apiEnvs.getApiEnv();
        return `/locales/ben/${ whiteLabel }/{{lng}}/{{ns}}.json`;
      },
      requestOptions: {
        cache: 'no-store',
      },
    },
  });

export default i18n;
