import { FC, useMemo, useState } from "react";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Tooltip } from "common/lib/components";
import { CollapsedAltQuoteOffers } from "domains/altQuotes/components";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { IAltQuoteOffersOptions } from "domains/altQuotes/shared/types";
import { formatValue } from "common/shared/helpers";
import { AssetType } from "domains/liquidity/shared/constants";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./AllAltQuoteOffers.scss";

interface IProps {
  isViewByOffer: boolean;
  viewByFundDataSource: IAltQuoteOffersOptions[];
  viewByOfferDataSource: IAltQuoteOffersOptions[];
  liquidityRequest: ILiquidityRequest | null;
}

const AllAltQuoteOffers: FC<IProps> = ({
  isViewByOffer,
  viewByOfferDataSource,
  viewByFundDataSource,
  liquidityRequest,
}) => {
  const { t } = useTranslation("altQuotes");

  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(liquidityRequest?.id ?? skipToken);

  const [ activeKeyCollapse, setactiveKeyCollapse ] = useState([ "" ]);

  const collapsedItems = viewByOfferDataSource?.filter((offer) => offer.offer_sub_options.length);
  const notCollapsedItems = viewByOfferDataSource?.filter((offer) => !offer.offer_sub_options.length);
  const isReturnRate = viewByOfferDataSource.some((offer) => offer?.return_rate);
  const fundAssets = useMemo(
    () => assets?.filter((asset) => asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
    [ assets?.length ],
  );
  const viewByFundActiveKeys = viewByFundDataSource
    ?.map((item) => {
      const copyItem = { ...item };
      const activeKey = item.offer_option;
      copyItem.offer_sub_options = item.offer_sub_options.filter((sub_offer) => sub_offer.offer_option?.includes("&"));
      const nestedActiveKeys = copyItem.offer_sub_options.map((item) => item.offer_option + item.asset_name);

      return [ ...nestedActiveKeys, activeKey ];
    })
    .flat();

  const expandAll = () => {
    isViewByOffer
      ? setactiveKeyCollapse(viewByOfferDataSource?.map((item) => item.offer_option) as string[])
      : setactiveKeyCollapse(viewByFundActiveKeys);
  };

  const onCollapseChange = (value: string[]) => {
    setactiveKeyCollapse(value);
  };

  const getFundsTotal = () => {
    if (isViewByOffer && viewByOfferDataSource.length) {
      return formatValue(viewByOfferDataSource[ 0 ].current_net_asset_value, "USDollar");
    }

    if (!isViewByOffer && viewByFundDataSource.length) {
      return formatValue(
        viewByFundDataSource.reduce((acc, cur) => (acc += cur.current_net_asset_value), 0),
        "USDollar",
      );
    }
  };

  return (
    <>
      <div className="ben-altquote-page-total-nav-container ben-mb-3">
        <p>
          {t("Total NAV of funds", { count: fundAssets?.length ?? 0 })}
          <span> {getFundsTotal()}</span>
        </p>
      </div>
      <Row gutter={ { xs: 8, sm: 8 } } className="ben-altquote-page-collapse-title">
        <Col xs={ isViewByOffer && isReturnRate ? 6 : 8 } md={ 5 }>
          <div className="ben-altquote-page-offer-options">
            {t("ALTQUOTE OPTIONS")}
            {viewByOfferDataSource.length > 0
              ? viewByOfferDataSource.length && (
                  <span className="ben-altquote-page-expand-all-button" onClick={ expandAll }>
                    {t("Expand All")}
                  </span>
                )
              : ""}
          </div>
        </Col>
        <Col xs={ isViewByOffer && isReturnRate ? 6 : 8 } md={ 5 } className="ben-altquote-page-title-with-tooltip">
          {t("ALTQUOTE RANGE")}
          <Tooltip content={ <span>{t("AltQuote.Tooltips.0")}</span> } />
        </Col>
        <Col xs={ isViewByOffer && isReturnRate ? 6 : 8 } md={ 4 } className="ben-altquote-page-title-with-tooltip">
          {`% ${ t("OF NAV RANGE") }`}
          <Tooltip content={ <span>{t("AltQuote.Tooltips.1")}</span> } />
        </Col>
        {isViewByOffer && isReturnRate && (
          <Col xs={ 6 } md={ 4 } className="ben-altquote-page-title-with-tooltip">
            {t("RETURN RATE")}
            <Tooltip content={ <span>{t("AltQuote.Tooltips.2")}</span> } />
          </Col>
        )}
      </Row>
      <div className={ `ben-altquote-page-title-border-bottom ${ isViewByOffer && isReturnRate ? "" : "fund-view" }` } />
      <CollapsedAltQuoteOffers
        isViewByOffer={ isViewByOffer }
        altQuoteCollapsedItems={ isViewByOffer ? collapsedItems : viewByFundDataSource }
        notCollapsedItems={ notCollapsedItems }
        activeKeyCollapse={ activeKeyCollapse }
        onCollapseChange={ onCollapseChange }
      />
    </>
  );
};

export default AllAltQuoteOffers;
