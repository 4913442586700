import { FC } from "react";

import { Table } from "antd";
import { useTranslation } from "react-i18next";

import { CloseWindowOutlined, DeleteDocumentIcon } from "common/lib/components";
import { IDocument } from "domains/documents/shared/types";
import { apiService } from "common/shared";
import { getIconForFile } from "domains/documents/shared/utils";
import { getDateFromISO } from "common/shared/utils/date.utils";

import "./UploadedDocumentsTable.scss";

interface IProps {
  type: "id" | "custody";
  documents: IDocument[];
  hideAction?: boolean;
}

const UploadedDocumentsTable: FC<IProps> = ({ type, documents, hideAction }) => {
  const { t } = useTranslation([ "myProfile" ]);

  const columnsDesktop = [
    {
      title: t("Documents Uploaded"),
      dataIndex: "file_name",
      render: (file_name: string, document: IDocument) => (
        <div>
          {getIconForFile(document.file_name)}
          <p>{file_name}</p>
        </div>
      ),
      className: "ben-uploaded-documents-table-file-name",
    },
    {
      title: t("Date Provided"),
      render: ({ date_provided, percent }: IDocument) => {
        return <p>{percent ? `${ t("Uploading...") } ${ percent }%` : `${ getDateFromISO(date_provided) }`}</p>;
      },
      className: "ben-uploaded-documents-table-date-provided",
    },
    {
      title: "",
      width: "10%",
      render: ({ id, cancelToken }: IDocument) => {
        if (hideAction) return null;

        return cancelToken ? (
          <CloseWindowOutlined onClick={ () => apiService.cancel(cancelToken) } />
        ) : (
          <DeleteDocumentIcon documentId={ id } type={ type } />
        );
      },
      className: "ben-uploaded-documents-table-action",
    },
  ];

  return (
    <Table
      className="ben-uploaded-documents-table"
      dataSource={ documents }
      columns={ columnsDesktop }
      pagination={ false }
    />
  );
};

export default UploadedDocumentsTable;
