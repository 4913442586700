import { object, string } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";
import { useValidationSchemas } from "common/shared/constants/validationSchemas";

export const useCustodyAccountIDFormValidationSchema = (checkboxValue: boolean) => {
  const { required } = useValidationErrorMessages();
  const { expirationDateValidationSchema, identificationNumberValidationSchema, countryValidationSchema } =
    useValidationSchemas();

  const validationSchema = object().shape({
    id_country: string()
      .nullable()
      .when([], () => {
        return checkboxValue
          ? countryValidationSchema.nullable().notRequired()
          : countryValidationSchema.required(required);
      }),
    id_type: string()
      .nullable()
      .when([], () => {
        return checkboxValue ? string().nullable().notRequired() : string().nullable().required(required);
      }),
    id_number: string()
      .nullable()
      .when([], () => {
        return checkboxValue
          ? identificationNumberValidationSchema.nullable().notRequired()
          : identificationNumberValidationSchema.required(required);
      }),
    id_expiration: string()
      .nullable()
      .when([], () => {
        return checkboxValue
          ? expirationDateValidationSchema.nullable().notRequired()
          : expirationDateValidationSchema.required(required);
      }),
  });

  return validationSchema;
};
