import { object, number, ref } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";
import { percentageFormatter } from "domains/liquidity/shared/utils/inputValueFormatter";
import { useValidationSchemas } from "common/shared/constants/validationSchemas";

export const useInvestmentDetailsPageValidationSchema = () => {
  const { required, maxNum, positive } = useValidationErrorMessages();
  const { capitalCommitmentValidationSchema, currentNetAssetValueValidationSchema } = useValidationSchemas();

  const altQuoteValidationSchema = object().shape({
    current_net_asset_value: currentNetAssetValueValidationSchema.required(required),
    capital_commitment: capitalCommitmentValidationSchema.required(required),
    unfunded_commitment_currency: number()
      .nullable()
      .when("unfunded_commitment_type", {
        is: "$",
        then: (schema) =>
          schema
            .required(required)
            .moreThan(-1, positive)
            .max(ref("capital_commitment"), "Can't exceed Capital Commitment's value"),
      }),
    unfunded_commitment_percentage: number()
      .nullable()
      .when("unfunded_commitment_type", {
        is: "%",
        then: (schema) =>
          schema
            .required(required)
            .positive(positive)
            .max(100, maxNum(percentageFormatter(100))),
      }),
  });

  const liquidityApplicationValidationSchema = object().shape({
    current_net_asset_value: currentNetAssetValueValidationSchema,
    capital_commitment: capitalCommitmentValidationSchema,
    unfunded_commitment_currency: number()
      .nullable()
      .when("unfunded_commitment_type", {
        is: "$",
        then: (schema) =>
          schema.moreThan(-1, positive).max(ref("capital_commitment"), "Can't exceed Capital Commitment's value"),
      }),
    unfunded_commitment_percentage: number()
      .nullable()
      .when("unfunded_commitment_type", {
        is: "%",
        then: (schema) => schema.positive(positive).max(100, maxNum(percentageFormatter(100))),
      }),
  });

  return { altQuoteValidationSchema, liquidityApplicationValidationSchema };
};
