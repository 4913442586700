import { FC } from "react";

import { Spin, SpinProps } from "antd";

interface IProps extends SpinProps {
  fullWidth?: boolean;
}

const Spinner: FC<IProps> = ({ fullWidth, spinning = true, children, ...props }) => {
  return (
    <Spin
      style={ fullWidth ? { width: "100%" } : {} }
      spinning={ spinning }
      { ...props }
    >
      {children}
    </Spin>
  );
};

export default Spinner;
