import { object, string } from "yup";

import { noSpacesInStartAndEndRegExp } from "common/shared/constants/validationRegExps";
import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";

export const useAddManuallyPageValidationSchema = () => {
  const { required, noSpacesInStartAndEnd } = useValidationErrorMessages();

  const validationSchema = object().shape({
    asset_name: string().required(required).matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd),
  });

  return { validationSchema };
};
