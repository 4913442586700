import { apiEnvs, apiService } from "common/shared";
import { getIsTrustbenAPIRequest } from "domains/trustben/shared/helpers";

export class AltQuotesApiService {
  private config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async getAltQuoteOffers(LRId: string) {
    const { isTrustbenAPIRequest, sessionId } = getIsTrustbenAPIRequest();
    if (isTrustbenAPIRequest) {
      return apiService.get(
        `${ apiEnvs.holdingsAPI }/v1/liquidity_request/${ LRId }/altquoteoffers?session_id=${ sessionId }`,
        this.config,
      );
    }

    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_request/${ LRId }/altquoteoffers`, this.config);
  }
}

const altQuotesApiService = new AltQuotesApiService();

export default altQuotesApiService;
