import { FC, MouseEvent, useState } from "react";

import { Collapse, Checkbox, CollapseProps } from "antd";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import { MyApplicationsPanel } from "domains/dashboard/components";
import { useClientsSelector } from "domains/clients";
import { ILiquidityRequest, LiquidityRequestStatus } from "domains/liquidity/shared/types";

import "./MyClientsLiquidityApplicationsPanel.scss";

interface IProps {
  clientsLiquidityApplications: ILiquidityRequest[];
  openModal: (e: MouseEvent) => void;
}

const MyClientsLiquidityApplicationsPanel: FC<CollapseProps & IProps> = ({
  clientsLiquidityApplications,
  openModal,
  ...props
}) => {
  const { t } = useTranslation([ "dashboard" ]);
  const { user } = useClientsSelector();

  const [ applicationsCheckboxValue, setApplicationsCheckboxValue ] = useState(false);
  const clientsLiquidityRequestsFilteredByActive = applicationsCheckboxValue
    ? clientsLiquidityApplications?.filter(
        (lr) => lr.status !== LiquidityRequestStatus.Closed,
      ) ?? []
    : clientsLiquidityApplications ?? [];

  const onApplicationCheckboxChange = (e: CheckboxChangeEvent) => {
    setApplicationsCheckboxValue(e.target.checked);
  };

  return (
    <>
      <div className="ben-advisor-client-applications-start-section-checkbox">
        <Checkbox checked={ applicationsCheckboxValue } onChange={ onApplicationCheckboxChange }>
          {t("Only Show Active Applications")}
        </Checkbox>
      </div>
      <Collapse defaultActiveKey={ "My Clients Applications" } className="ben-mb-4">
        <MyApplicationsPanel
          openModal={ openModal }
          user={ user }
          liquidityApplications={ clientsLiquidityRequestsFilteredByActive }
          isClientPage
          key="My Clients Applications"
          { ...props }
        />
      </Collapse>
    </>
  );
};

export default MyClientsLiquidityApplicationsPanel;
