import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILiquidityRequest } from "domains/liquidity/shared/types";

export interface IInitialState {
  isAltQuoteTab: boolean;
  currentAltQuote: ILiquidityRequest | null;
}

const initialState: IInitialState = {
  isAltQuoteTab: false,
  currentAltQuote: null,
};

const altQuotesReducer = createSlice({
  name: "[AltQuotes]",
  initialState,
  reducers: {
    setIsAltQuoteTab: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAltQuoteTab: action.payload,
    }),

    setCurrentAltQuote: (state, action: PayloadAction<ILiquidityRequest | null>) => ({
      ...state,
      currentAltQuote: action.payload,
    }),

    resetAltQuotesReducer: (state) => ({
      ...initialState,
      currentAltQuote: state.currentAltQuote,
    }),
  },
});

export const {
  setIsAltQuoteTab,

  setCurrentAltQuote,

  resetAltQuotesReducer,
} = altQuotesReducer.actions;

export default altQuotesReducer.reducer;
