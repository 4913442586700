import { lazy } from "react";

import { lazyWithRetry } from "common/shared";

export const DashboardPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "DashboardPage" */ "pages/DashboardPage"), "DashboardPage"),
);

export const SignInPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "SignInPage" */ "pages/SignInPage"), "SignInPage"),
);

export const LoginHelpPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "LoginHelpPage" */ "pages/LoginHelpPage"), "LoginHelpPage"),
);

export const MainPageWrapper = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "MainPageWrapper" */ "pages/common/MainPageWrapper"),
    "MainPageWrapper",
  ),
);

export const AltDataPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "AltDataPage" */ "domains/altData/pages/AltDataPage"), "AltDataPage"),
);

export const AltCustodyPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "AltCustodyPage" */ "domains/altData/pages/AltCustodyPage"),
    "AltCustodyPage",
  ),
);

export const MyClientsPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "MyClientsPage" */ "pages/MyClientsPage"), "MyClientsPage"),
);

export const ClientApplicationsPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "ClientApplicationsPage" */ "pages/ClientApplicationsPage"),
    "ClientApplicationsPage",
  ),
);

export const VerifyResetPasswordPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "VerifyResetPasswordPage" */ "pages/VerifyResetPasswordPage"),
    "VerifyResetPasswordPage",
  ),
);

export const VerifyIdentityPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "VerifyIdentityPage" */ "pages/VerifyIdentityPage"),
    "VerifyIdentityPage",
  ),
);

export const VerifySharingPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "VerifySharingPage" */ "pages/VerifySharingPage"),
    "VerifySharingPage",
  ),
);

export const InternalAltQuotePage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "InternalAltQuotePage" */ "domains/altQuotes/pages/InternalAltQuotePage"),
    "InternalAltQuotePage",
  ),
);

export const TrustbenWrapper = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "TrustbenWrapper" */ "domains/trustben/pages/TrustbenWrapper"),
    "TrustbenWrapper",
  ),
);

export const TrustBenAltQuotePage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "TrustBenAltQuotePage" */ "domains/trustben/pages/TrustBenAltQuotePage"),
    "TrustBenAltQuotePage",
  ),
);

export const GPFundMonetizationPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "GPFundMonetizationPage" */ "domains/trustben/pages/GPFundMonetizationPage"),
    "GPFundMonetizationPage",
  ),
);

export const GPPrimaryCommitmentPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "GPPrimaryCommitmentPage" */ "domains/trustben/pages/GPPrimaryCommitmentPage"),
    "GPPrimaryCommitmentPage",
  ),
);

export const TrustbenApplicationPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "TrustbenApplicationPage" */ "domains/trustben/pages/TrustbenApplicationPage"),
    "TrustbenApplicationPage",
  ),
);

export const PrivacyNoticePage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "PrivacyNoticePage" */ "domains/dashboard/components/PrivacyNoticePage"),
    "PrivacyNoticePage",
  ),
);

export const ResetPasswordExpiredPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "ResetPasswordExpiredPage" */ "pages/ResetPasswordExpiredPage"),
    "ResetPasswordExpiredPage",
  ),
);

export const SetPasswordPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "SetPasswordPage" */ "pages/SetPasswordPage"), "SetPasswordPage"),
);

export const ResendWelcomeEmailPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "ResendWelcomeEmailPage" */ "pages/ResendWelcomeEmailPage"),
    "ResendWelcomeEmailPage",
  ),
);

export const ApplicationPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "ApplicationPage" */ "pages/ApplicationPage"), "ApplicationPage"),
);

export const ResendSharingInvitationPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "ResendSharingInvitationPage" */ "pages/ResendSharingInvitationPage"),
    "ResendSharingInvitationPage",
  ),
);

export const MyAssetsPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "MyAssetsPage" */ "pages/MyAssetsPage"), "MyAssetsPage"),
);

export const MyAltCustodyAssetsPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "MyAltCustodyAssetsPage" */ "domains/altCustody/pages/MyAssetsPage"),
    "MyAltCustodyAssetsPage",
  ),
);

export const MyClientsAssetsPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "MyClientsAssetsPage" */ "domains/altCustody/pages/MyClientsAssetsPage"),
    "MyClientsAssetsPage",
  ),
);

export const AccountBlockedPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "AccountBlockedPage" */ "pages/AccountBlockedPage"),
    "AccountBlockedPage",
  ),
);

export const CodeExpiredPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "CodeExpiredPage" */ "pages/CodeExpiredPage"), "CodeExpiredPage"),
);

export const AuthenticationPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "AuthenticationPage" */ "pages/AuthenticationPage"),
    "AuthenticationPage",
  ),
);

export const VerifyIdentificationCodePage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "VerifyIdentificationCodePage" */ "pages/VerifyIdentificationCodePage"),
    "VerifyIdentificationCodePage",
  ),
);

export const MyApplicationsPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "MyApplicationsPage" */ "pages/MyApplicationsPage"),
    "MyApplicationsPage",
  ),
);

export const LiquidityApplicationPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "LiquidityApplicationPage" */ "pages/LiquidityApplicationPage"),
    "LiquidityApplicationPage",
  ),
);

export const AltCustodyAssetPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "AltCustodyAssetPage" */ "domains/altCustody/pages/AltCustodyAssetPage"),
    "AltCustodyAssetPage",
  ),
);

export const PreferencesPage = lazy(() =>
  lazyWithRetry(
    () => import(/* webpackChunkName: "PreferencesPage" */ "domains/preferences/pages/PreferencesPage"),
    "PreferencesPage",
  ),
);

export const NotFoundPage = lazy(() =>
  lazyWithRetry(() => import(/* webpackChunkName: "NotFoundPage" */ "pages/NotFoundPage"), "NotFoundPage"),
);
