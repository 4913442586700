import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";

const BenPhone: FC = () => {
  const { t } = useTranslation();

  return (
    <Button href={ `tel:${ t("Default Phone Number") }` } type="link">
      { t("Default Phone Number") }
    </Button>
  );
};

export default BenPhone;
