import { FC } from "react";

import { NavLink as BaseNavLink, NavLinkProps } from "react-router-dom";

interface IProps {
  className: string;
}

const NavLink: FC<NavLinkProps & IProps> = (props) => {
  const { className } = props;

  return (
    <BaseNavLink { ...props } className={ ({ isActive }) => (isActive ? `${ className } ben-active-link` : className) } />
  );
};

export default NavLink;
