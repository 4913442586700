import { Outlet, useParams } from "react-router-dom";

import NotFoundPage from "pages/NotFoundPage";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { useTrustbenSelector } from "domains/trustben/store/trustben.selectors";

const ProtectedRoutesTrustben = () => {
  const { LRId } = useParams();

  const { liquidity_request_id } = useLiquiditySelector();

  const { isTrustbenAltQuote } = useTrustbenSelector();

  if (isTrustbenAltQuote && LRId && liquidity_request_id && liquidity_request_id !== LRId) {
    return <NotFoundPage />;
  }

  return <Outlet />;
};

export default ProtectedRoutesTrustben;
