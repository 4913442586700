import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { URLs } from "common/lib/constants";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { FeatureNames, useFeature } from "domains/features";
import { UserType } from "domains/clients/shared/types";
import { DocumentType } from "domains/documents/shared/constants";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

interface INavLink {
  title: string;
  url: string;
  className: string;
}

const useAppNavigation = () => {
  const { t } = useTranslation("common");

  const { user, isAdvisor } = useClientsSelector();
  const { data: documents } = documentsApiReducer.useFetchDocumentsByIdQuery(user?.account_id ?? skipToken);
  const { isFeatureEnabled } = useFeature(FeatureNames.Consent, [
    UserType.Principal,
    UserType.Employee,
    UserType.Advisor,
  ]);
  const { isFeatureActive } = useFeature(FeatureNames.AltCustody, []);

  const altDataDocuments = useMemo(() => {
    return documents
      ? documents.filter(
          (document) =>
            document.document_type === DocumentType.AltDataReport ||
            document.document_type === DocumentType.BenMacroLetterReport,
        )
      : [];
  }, [ documents?.length ]);
  const altCustodyDocuments = useMemo(() => {
    return documents ? documents.filter((document) => document.document_type === DocumentType.AltCustodyReport) : [];
  }, [ documents?.length ]);

  const navLinks = useMemo(() => {
    const advisorLinks = [
      {
        title: t("Menu.Dashboard"),
        url: URLs.PROTECTED.DASHBOARD,
        className: "ben-link",
      },
      altDataDocuments.length && {
        title: t("Menu.AltData"),
        url: URLs.PROTECTED.ALT_DATA,
        className: "ben-link",
      },
      altCustodyDocuments.length && {
        title: t("Menu.AltData"),
        url: URLs.PROTECTED.ALT_DATA,
        className: "ben-link",
      },
      {
        title: t("Menu.My Clients"),
        url: URLs.PROTECTED.MY_CLIENTS,
        className: "ben-link",
      },
      isFeatureActive && {
        title: t("Menu.My Clients Assets"),
        url: URLs.PROTECTED.MY_CLIENTS_ASSETS,
        className: "ben-link",
      },
      {
        title: t("Menu.Client Applications"),
        url: URLs.PROTECTED.CLIENT_APPLICATIONS,
        className: "ben-link",
      },
      isFeatureEnabled && {
        title: t("Menu.Preferences"),
        url: URLs.PROTECTED.PREFERENCES,
        className: "ben-link",
      },
      {
        title: t("Menu.Contact Us"),
        url: URLs.PROTECTED.CONTACT_US,
        className: "ben-link",
      },
    ];

    const defaultLinks = [
      {
        title: t("Menu.Dashboard"),
        url: URLs.PROTECTED.DASHBOARD,
        className: "ben-link",
      },
      altDataDocuments.length && {
        title: t("Menu.AltData"),
        url: URLs.PROTECTED.ALT_DATA,
        className: "ben-link",
      },
      altCustodyDocuments.length && {
        title: t("Menu.AltCustody"),
        url: URLs.PROTECTED.ALT_CUSTODY,
        className: "ben-link",
      },
      {
        title: t("Menu.My Applications"),
        url: URLs.PROTECTED.MY_APPLICATIONS,
        className: "ben-link",
      },
      {
        title: t("Menu.My Assets"),
        url: isFeatureActive ? URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS : URLs.PROTECTED.MY_ASSETS,
        className: "ben-link",
      },
      isFeatureEnabled && {
        title: t("Menu.Preferences"),
        url: URLs.PROTECTED.PREFERENCES,
        className: "ben-link",
      },
      {
        title: t("Menu.Contact Us"),
        url: URLs.PROTECTED.CONTACT_US,
        className: "ben-link",
      },
    ];

    return (isAdvisor ? advisorLinks.filter((link) => !!link) : defaultLinks.filter((link) => !!link)) as INavLink[];
  }, [ isAdvisor, isFeatureEnabled, altDataDocuments.length, altCustodyDocuments.length, isFeatureActive ]);

  return { navLinks };
};

export default useAppNavigation;
