import React, { FC, Fragment } from "react";

import { ExclamationCircleFilled } from "@ant-design/icons";

import "./CardHelp.scss";

export interface IProps {
  title?: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  lastUpdate?: string | React.ReactElement;
  content?: string | React.ReactElement;
  noPanel?: boolean;
  children?: React.ReactNode;
}

const CardHelp: FC<IProps> = ({
  title,
  subtitle,
  lastUpdate,
  noPanel,
  children,
}) => {

  const withoutPanel = (
    <div className="ben-card-help">
      { title && (
        <div className="ben-panel-header">
          <h3>{ title }</h3>
        </div>
      ) }

      { subtitle && (
        <div className="ben-card-help-info">
          <strong className="ben-card-help-subtitle">
            <ExclamationCircleFilled className="ben-attention-icon" />
            { subtitle }
          </strong>
          { lastUpdate && <em className="ben-card-help-date">{ lastUpdate }</em> }
        </div>
      ) }

      { children && <div className="ben-card-help-content">{ children }</div> }
    </div>
  );

  const withPanel = <div className="ben-panel ben-pb-2">{ withoutPanel }</div>;

  const getContent = () => {
    return noPanel ? withoutPanel : withPanel;
  };

  return <Fragment>{ getContent() }</Fragment>;
};

export default CardHelp;
