import { FC } from "react";

import { Collapse, Col, Row } from "antd";

import { IAltQuoteOffersOptions } from "domains/altQuotes/shared/types";
import { formatValue } from "common/shared/helpers";
import { AltQuoteOffers, AltQuoteOfferPanelHeader } from "domains/altQuotes/components";

import "./CollapsedAltQuoteOffers.scss";

const { Panel } = Collapse;

interface IProps {
  altQuoteCollapsedItems: IAltQuoteOffersOptions[];
  notCollapsedItems: IAltQuoteOffersOptions[];
  activeKeyCollapse: string[];
  onCollapseChange: (value: string[]) => void;
  isViewByOffer: boolean;
}

const CollapsedAltQuoteOffers: FC<IProps> = ({
  altQuoteCollapsedItems,
  notCollapsedItems,
  activeKeyCollapse,
  onCollapseChange,
  isViewByOffer,
}) => {
  return (
    <>
      {isViewByOffer && notCollapsedItems.length > 0 && (
        <AltQuoteOffers isViewByOffer={ isViewByOffer } notCollapsedItems={ notCollapsedItems } />
      )}
      <Collapse
        className="ben-altquote-page-collapse-offers"
        activeKey={ activeKeyCollapse }
        onChange={ (value) => onCollapseChange(value as string[]) }
      >
        {altQuoteCollapsedItems
          .toSorted((firstEl, secondEl) => {
            if (firstEl.alt_quote_asset_id! > secondEl.alt_quote_asset_id!) {
              return 1;
            } else if (firstEl.alt_quote_asset_id! < secondEl.alt_quote_asset_id!) {
              return -1;
            } else if (firstEl.option_group === "total_range") {
              return -1;
            } else if (secondEl.option_group === "total_range") {
              return 1;
            } else {
              return 0;
            }
          })
          .map((item) => (
            <Panel
              key={ item.offer_option }
              header={ <AltQuoteOfferPanelHeader isViewByOffer={ isViewByOffer } item={ item } /> }
            >
              {item.offer_sub_options.map((el) =>
                el.offer_sub_options.length ? (
                  <Collapse
                    key={ el.asset_name }
                    className="ben-altquote-page-nested-collapse-offers"
                    activeKey={ activeKeyCollapse }
                    onChange={ (value) => onCollapseChange(value as string[]) }
                  >
                    <Panel
                      key={ (el.offer_option + el.asset_name) as string }
                      header={ <AltQuoteOfferPanelHeader isViewByOffer={ isViewByOffer } item={ el } /> }
                    >
                      {el.offer_sub_options.map((sub_offer) => (
                        <Row
                          key={ sub_offer.option_group + sub_offer.option_type }
                          className="ben-altquote-page-panel-item"
                          align="middle"
                        >
                          <Col xs={ 6 } md={ 5 }>
                            {sub_offer.offer_option}
                          </Col>
                          <Col xs={ 6 } md={ 5 } className="ben-text-center ben-nested-col-1">
                            {`${ formatValue(sub_offer.offer_low_currency, "USDollar") } to ${ formatValue(
                              sub_offer.offer_high_currency,
                              "USDollar",
                            ) }`}
                          </Col>
                          <Col xs={ 5 } md={ 4 } className="ben-text-center ben-nested-col-2">
                            {`${ formatValue(sub_offer.percent_of_nav_low, "Percentage") } to ${ formatValue(
                              sub_offer.percent_of_nav_high,
                              "Percentage",
                            ) }`}
                          </Col>
                        </Row>
                      ))}
                    </Panel>
                  </Collapse>
                ) : (
                  <Row align="middle" key={ el.option_group + el.option_type } className="ben-altquote-page-panel-item">
                    <Col xs={ isViewByOffer ? 6 : 8 } md={ 5 }>
                      {el.offer_option}
                    </Col>
                    <Col xs={ isViewByOffer ? 6 : 8 } md={ 5 } className="ben-text-center">
                      {`${ formatValue(el.offer_low_currency, "USDollar") } to ${ formatValue(
                        el.offer_high_currency,
                        "USDollar",
                      ) }`}
                    </Col>
                    <Col xs={ isViewByOffer ? 6 : 8 } md={ 4 } className="ben-text-center">
                      {`${ formatValue(el.percent_of_nav_low, "Percentage") } to ${ formatValue(
                        el.percent_of_nav_high,
                        "Percentage",
                      ) }`}
                    </Col>
                    {isViewByOffer && (
                      <Col xs={ 6 } md={ 4 } className="ben-text-center">
                        {el.return_rate}
                      </Col>
                    )}
                  </Row>
                ),
              )}
              {item.subscript_text && (
                <Row align="middle" className="ben-altquote-page-panel-item-option-row">
                  {item.subscript_text}
                </Row>
              )}
            </Panel>
          ))}
      </Collapse>
    </>
  );
};

export default CollapsedAltQuoteOffers;
