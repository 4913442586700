export const TrustbenPages = {
  AltQuote: "/altquote",
  GPFundMonetization: "/gp_fund_monetization",
  GPPrimaryCommitment: "/gp_primary_commitment",
};

export const TBAltQuotePages = {
  Questionnaire: "/questionnaire",
  SelectAssets: "/select_assets",
  InvestmentDetails: "/investment_details",
  ReviewAssets: "/review_assets",
  LinkToQuote: "/link_to_quote",
  ThankYou: "/thank_you",
  ReminderThankYou: "/reminder_thank_you",
};

export const GPPages = {
  FundInformation: "/fund_information",
  ContactInformation: "/contact_information",
  ThankYou: "/thank_you",
};
