import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, Select } from "common/lib/formik-antd";
import { FormHeader, NavigationButtons, Tooltip } from "common/lib/components";
import { fundStrategies, geographicFocuses, generateVintages } from "./constants";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { useFundDetailsPageValidationSchema } from "domains/liquidity/pages/FundDetailsPage/validationSchema";
import { useHandlers } from "./useHandlers";

const FundDetailsPage = () => {
  const { validationSchema, requiredValidationSchema } = useFundDetailsPageValidationSchema();
  const { t } = useTranslation([ "liquidity", "common" ]);

  const { currentAsset, isAddManually, isLA } = useLiquiditySelector();

  const { onSubmit } = useHandlers();

  const vintages = isAddManually ? generateVintages().slice(0, -1) : generateVintages();
  const filteredFundStrategies = isAddManually ? fundStrategies.slice(0, -1) : fundStrategies;

  return (
    <>
      <FormHeader
        title={ t("Assets to Exchange") }
        subtitle={ t("Fund Details: Step 2 of 3") }
        assetName={ currentAsset.asset_name }
      />
      <div className="ben-form-container">
        <Formik
          onSubmit={ onSubmit }
          initialValues={ currentAsset }
          validationSchema={ !isLA && isAddManually ? requiredValidationSchema : validationSchema }
          validateOnMount
        >
          {({ isValid }) => (
            <Form layout="vertical" requiredMark="optional">
              <Form.Item
                name="vintage"
                required={ !isLA && isAddManually }
                label={
                  <>
                    {t("Vintage")} <Tooltip content={ t("Tooltips.Vintage") } dark />
                  </>
                }
              >
                <Select name="vintage" placeholder={ t("Select One", { ns: "common" }) }>
                  {vintages.map((item, i) => (
                    <Select.Option key={ i } value={ item.key ?? item.label }>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="fund_strategy"
                required={ !isLA && isAddManually }
                label={
                  <>
                    {t("Fund Strategy")} <Tooltip content={ t("Tooltips.Fund Strategy") } dark />
                  </>
                }
              >
                <Select name="fund_strategy" placeholder={ t("Select One", { ns: "common" }) }>
                  {filteredFundStrategies.map((item, i) => (
                    <Select.Option key={ i } value={ item.key ?? i }>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="geographic_focus"
                required={ !isLA && isAddManually }
                label={
                  <>
                    {t("Geographic Focus")} <Tooltip content={ t("Tooltips.Geographic Focus") } dark />
                  </>
                }
              >
                <Select name="geographic_focus" placeholder={ t("Select One", { ns: "common" }) }>
                  {geographicFocuses.map((item, i) => (
                    <Select.Option key={ i } value={ i }>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <NavigationButtons disabled={ !isValid } />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default FundDetailsPage;
