import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { URLs } from "common/lib/constants";
import { CardErrorSection } from "common/lib/components";
import image from "common/assets/images/404-error.svg";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const handleRedirectTo = () => {
    navigate(URLs.AUTH.SIGN_IN, { replace: true });
  };

  return (
    <CardErrorSection
      title={ t("Not Found Page.Title") }
      message={ t("Not Found Page.Message") }
      loading={ false }
      handleOnClick={ handleRedirectTo }
      isBtnDisabled={ false }
      btnText={ t("Not Found Page.Button Text") }
      image={ image }
      noPanel={ true }
    />
  );
};

export default NotFoundPage;
