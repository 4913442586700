import { FC, ReactNode } from "react";

import { Tooltip as AntdTooltip } from "antd";
import { AbstractTooltipProps } from "antd/lib/tooltip";

import { InfoFilled } from "common/lib/components";

import "./Tooltip.scss";

interface IProps extends AbstractTooltipProps {
  content: ReactNode;
  dark?: boolean;
}

const Tooltip: FC<IProps> = ({
  children,
  dark = false,
  content,
  className,
  overlayClassName,
  ...props
}) => {
  return (
    <AntdTooltip className={ `ben-tooltip ${ dark && "dark" } ${ className ?? "" }` }
      overlayClassName={ `ben-tooltip-content ${ overlayClassName ?? "" }` }
      overlay={ content } color="white"  { ...props }
    >
      { children } <InfoFilled dark={ dark } />
    </AntdTooltip>
  );
};

export default Tooltip;
