import { FC, ReactNode, useCallback } from "react";

import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useScreen } from "common/shared-hooks";
import { MyProfileFormHeader } from "domains/myProfile/components";
import { NavigationButtons } from "common/lib/components";
import { setCurrentClientFormValues } from "domains/myProfile/store/myProfile.reducer";

import "./MyProfileFormWrapper.scss";

interface IProps {
  title: string;
  subtitle?: string;
  leftColumnContent: ReactNode;
  rightColumnContent?: ReactNode;
  isDisabled?: boolean;
  fullWidth?: boolean;
  isBorderedRightColumn?: boolean;
  className?: string;
  onNext: () => void;
  formRef: any;
  onBack?: () => void;
}

const MyProfileFormWrapper: FC<IProps> = ({
  title,
  subtitle,
  leftColumnContent,
  rightColumnContent,
  onNext,
  isBorderedRightColumn = true,
  isDisabled,
  fullWidth,
  className,
  formRef,
  onBack,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mobile } = useScreen();

  const handleBack = useCallback(() => {
    dispatch(setCurrentClientFormValues(formRef.current?.values));
    navigate(-1);
  }, []);

  return (
    <div className={ "ben-my-profile-form-wrapper " + className }>
      <MyProfileFormHeader title={ title } subtitle={ subtitle } />
      <div className="ben-page-content">
        <Row gutter={ mobile ? 0 : 70 }>
          <Col xs={ 24 } sm={ 24 } md={ !rightColumnContent && fullWidth ? 24 : 12 }>
            <div className="ben-page-col">
              <div className="ben-col-content">{leftColumnContent}</div>
              <NavigationButtons
                onBack={ onBack ? onBack : handleBack }
                onNext={ onNext }
                disabled={ isDisabled }
                submitButtonText="Next"
              />
            </div>
          </Col>
          {rightColumnContent && (
            <Col xs={ 24 } sm={ 24 } md={ 12 }>
              <div className="ben-page-col ben-right-col">
                <div className={ `ben-col-content ${ isBorderedRightColumn ? "" : "ben-no-border" }` }>
                  {rightColumnContent}
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default MyProfileFormWrapper;
