import { FC, DragEvent } from "react";

import { message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AddFileFilled } from "common/lib/components/Icons";
import { useScreen } from "common/shared-hooks";
import { IDocument } from "domains/documents/shared/types";
import { setDocumentUploadProgress } from "domains/documents/store/documents.reducer";
import apiService from "common/shared/services/api.service/api.service";
import { allowedMimeTypes, DocumentType } from "domains/documents/shared/constants";
import { truncateFileName } from "domains/documents/shared/utils";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { useMyProfileSelector } from "domains/myProfile/store/myProfile.selectors";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";

import "./UploadDocuments.scss";

const { Dragger } = Upload;

interface IProps {
  type: "id" | "custody";
  isDisabled?: boolean;
}

const UploadDocuments: FC<IProps> = ({ type, isDisabled = false }) => {
  const { t } = useTranslation([ "myProfile", "common", "liquidity" ]);
  const { mobile } = useScreen();
  const { LRId } = useParams();
  const dispatch = useDispatch();

  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const { currentOwnerId } = useMyProfileSelector();
  const [ uploadDocument ] = documentsApiReducer.useUploadDocumentMutation();

  const onUploadProgress = (id: string) => (progress: number) => {
    dispatch(setDocumentUploadProgress({ docId: id, progress }));
  };

  const draggerProps = {
    accept: allowedMimeTypes.join(","),
    multiple: true,
    showUploadList: false,
    onDrop: (event: DragEvent<any>) => {
      const invalid = Array.from(event.dataTransfer!.files).some((file) => !allowedMimeTypes.includes(file.type));
      if (invalid) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
      }
    },
    beforeUpload: (file: any): boolean => {
      if (!allowedMimeTypes.includes(file.type)) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
        return false;
      }
      if ((file.size ?? 0) > 500 * 1_000_000) {
        message.error(t("validation.fileSize", { ns: "common", value: 500 }));
        return false;
      }

      let doc;
      let accountId;

      if (type === "custody") {
        accountId = currentCustodyAccount?.account_id;

        doc = {
          file_name: truncateFileName(file.name.replace(/[^- _a-zA-Z0-9!.'()]/g, "-")),
          document_name: null,
          document_type: DocumentType.Identification,
          reference_type: "account",
          file_size: file.size,
          asset_id: null,
          id: file.uid,
          uid: file.uid,
          cancelToken: apiService.getCancelToken(),
          mimeType: file.type,
        };
      } else {
        accountId = currentOwnerId;

        doc = {
          file_name: truncateFileName(file.name.replace(/[^- _a-zA-Z0-9!.'()]/g, "-")),
          document_name:
            file.name.substring(0, file.name.lastIndexOf(".")).substring(0, 123) || file.name.substring(0, 123),
          document_type: DocumentType.Other,
          reference_type: "liquidity_request",
          file_size: file.size,
          liquidity_request_id: LRId,
          asset_id: null,
          id: file.uid,
          uid: file.uid,
          cancelToken: apiService.getCancelToken(),
          mimeType: file.type,
        };
      }

      uploadDocument({
        LRId: doc.liquidity_request_id,
        accountId: accountId as string,
        document: doc as IDocument,
        file,
        onUploadProgress: onUploadProgress(doc.id),
      });

      return true;
    },
  };

  return (
    <>
      {mobile ? (
        <div className="ben-mobile-upload-file-container">
          <Upload disabled={ isDisabled } className="ben-upload" { ...draggerProps }>
            <div>
              <AddFileFilled width={ 30 } height={ 30 } />
              <p>{t("Add File")}</p>
            </div>
          </Upload>
        </div>
      ) : (
        <div className="ben-upload-file-container">
          <Dragger disabled={ isDisabled } { ...draggerProps }>
            <p>{t("Drag and drop files here", { ns: "liquidity" })}</p>
          </Dragger>
        </div>
      )}
    </>
  );
};

export default UploadDocuments;
