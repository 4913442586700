import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import { apiService, router } from "common/shared";
import { altCustodyApiService } from "domains/altCustody/shared/altCustody.api.service";
import { TAppState } from "store/root.store";
import { formatInvestmentForRequest, formatInvestmentsForReducer } from "domains/altCustody/shared/utils/apiFormatter";
import { LiquidityRequestStatus, TAssetType } from "domains/liquidity/shared/types";
import { IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import {
  formatAssetBodyForRequest,
  formatDocuemtGridDataForRequest,
  formatLiquidityRequestBodyForRequest,
} from "domains/liquidity/shared/utils/apiFormatter";
import liquidityApiService from "domains/liquidity/shared/liquidity.api.service";
import { DocumentGridStatus } from "domains/documents/shared/types";
import documentsApiService from "domains/documents/shared/document.api.service";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity";
import { AssetType } from "domains/liquidity/shared/constants";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";

export const altCustodyApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "investments" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchInvestments: build.query<TAssetType[], string>({
        queryFn: async (accountId) => {
          try {
            const response = await altCustodyApiService.fetchInvestments(accountId);
            const investments = formatInvestmentsForReducer(response) as TAssetType[];

            return { data: investments };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, accountId) => [ { type: "investments", id: accountId } ],
      }),

      fetchInvestment: build.query<any, string>({
        queryFn: async (investmentId: string) => {
          try {
            const investment = await altCustodyApiService.fetchInvestment(investmentId);

            return { data: investment };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      createInvestment: build.mutation<any, any>({
        queryFn: async (_, { dispatch, getState }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
            const currentAsset = (getState() as TAppState).liquidityReducer.currentAsset as TAssetType;

            const requestBody = formatInvestmentForRequest(
              currentAsset,
              isAdvisor
                ? ({ ...currentClient, user_type: currentClient.account_type } as unknown as IUserDetails)
                : ({
                    ...user,
                    user_type: user.user_type === UserType.Principal ? "individual" : "institution",
                  } as unknown as IUserDetails),
            );

            const response = await altCustodyApiService.createInvestment(requestBody);

            const investmentWithId = { ...currentAsset, id: response.id, asset_id: response.id };

            const createdInvestmentIds = JSON.parse(sessionStorage.getItem("CREATED_INVESTMENT_IDS") || "[]");
            sessionStorage.setItem("CREATED_INVESTMENT_IDS", JSON.stringify([ ...createdInvestmentIds, response.id ]));

            dispatch(
              altCustodyApiReducer.util.updateQueryData(
                "fetchInvestments",
                isAdvisor ? currentClient.account_id : user.account_id,
                (draft) => {
                  draft.push(investmentWithId as unknown as TAssetType);
                },
              ),
            );

            dispatch(
              altCustodyApiReducer.util.invalidateTags([
                { type: "investments", id: isAdvisor ? currentClient.account_id : user.account_id },
              ]),
            );

            return { data: investmentWithId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateInvestment: build.mutation<any, any>({
        queryFn: async ({ accountId, data }) => {
          try {
            const response = await altCustodyApiService.updateInvestment(accountId, data);

            return { data: response };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      deleteInvestment: build.mutation<string, string>({
        queryFn: async (investmentId, { dispatch, getState }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
            const accountId = isAdvisor ? currentClient.account_id : user.account_id;

            await altCustodyApiService.deleteInvestment(investmentId);

            dispatch(
              altCustodyApiReducer.util.updateQueryData("fetchInvestments", accountId, (draft) =>
                //@ts-ignore
                draft.filter((investment) => !investmentId.includes(investment.id)),
              ),
            );

            return { data: investmentId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      createProductWithAssets: build.mutation<any, { assets: TAssetType[]; client?: IUserClient }>({
        queryFn: async ({ assets, client }, { dispatch, getState }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const body = formatLiquidityRequestBodyForRequest("LiquidityRequest", user, client);
            const data = {
              ...body,
              status: LiquidityRequestStatus.PendingAssetStatements,
            };

            const { id: LRId } = await liquidityApiService.createLiquidityRequest(data);

            const formattedAssetsToCreate = assets.map((asset: TAssetType) =>
              formatAssetBodyForRequest(asset, user, LRId.slice(-6)),
            );

            const response = await Promise.all(
              formattedAssetsToCreate.map((body: any) => liquidityApiService.createAsset(body)),
            );

            const assetsToSetDocGrid = assets.filter(
              (asset) => asset.asset_type === AssetType.PreqinFund || asset.asset_type === AssetType.Fund,
            );

            if (assetsToSetDocGrid.length) {
              const formattedAssetsDocGrid = assetsToSetDocGrid.map((asset: TAssetType, index: number) => {
                const assetWithId = { ...asset, asset_id: response[ index ].id };
                return formatDocuemtGridDataForRequest(assetWithId, user, LRId, DocumentGridStatus.NotReceived);
              });
              await Promise.all(
                formattedAssetsDocGrid.map((body: any) => documentsApiService.setDocumentGridData(LRId, body)),
              );
            }

            dispatch(liquidityApiReducer.endpoints.fetchLiquidityRequest.initiate(LRId));
            dispatch(liquidityApiReducer.endpoints.fetchLRAccounts.initiate(LRId));
            dispatch(liquidityApiReducer.endpoints.updateLRListAfterCreation.initiate(LRId));
            isAdvisor &&
              client &&
              dispatch(clientsApiReducer.util.invalidateTags([ { type: "clientAssets", id: client.account_id } ]));

            router.navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ReviewAssets);

            return { data: response };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
