import { useEffect } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Form, Input } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { useValidationSchema } from "./useValidationSchema";
import { NavigationButtons } from "common/lib/components";
import { useTrustbenLogs } from "domains/trustben/shared-hooks";
import { getChangedFormValues } from "common/shared/helpers";
import { isEmptyObject } from "common/shared/utils/object.utils";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { URLs } from "common/lib/constants";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { TrustbenPages, TBAltQuotePages } from "domains/trustben/shared/constants";

import "./EmailForm.scss";

const EmailForm = () => {
  const { t } = useTranslation("trustben");
  const navigate = useNavigate();
  const { LRId } = useParams();

  const { currentLiquidityRequest } = useLiquiditySelector();
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const [ updateLiquidityRequest, { isLoading: isUpdatingLR, isSuccess, reset } ] =
    liquidityApiReducer.useUpdateLiquidityRequestMutation();

  const { validationSchema } = useValidationSchema();
  const { sendLogs } = useTrustbenLogs();

  const initialValues = {
    email:
      liquidityRequests?.[ 0 ]?.alt_quote_submission_email ?? currentLiquidityRequest?.alt_quote_submission_email ?? "",
  };

  const handleSubmit = (formValues: typeof initialValues) => {
    if (!LRId) return;

    const changes = getChangedFormValues(formValues, initialValues);

    !isEmptyObject(changes) &&
      updateLiquidityRequest({
        LRId,
        changes: {
          alt_quote_submission_email: formValues.email,
        },
      });
  };

  useEffect(() => {
    if (!isSuccess) return;

    navigate(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LRId }${ TBAltQuotePages.ReminderThankYou }`, {
      replace: true,
    });
    reset();
  }, [ isSuccess ]);

  return (
    <div className="ben-form-container trustben-email-form">
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        onSubmit={ handleSubmit }
      >
        {({ isValid, values, dirty }) => {
          return (
            <Form layout="vertical">
              <Form.Item name="email" label={ t("AltQuote Email Section.Email") }>
                <Input
                  maxLength={ maxLengthText }
                  name="email"
                  placeholder={ t("AltQuote Email Section.Email") }
                  onBlur={ () =>
                    sendLogs({
                      what: "email",
                      details: `email:${ values.email }:${ LRId }`,
                    })
                  }
                  suffix
                />
              </Form.Item>

              <NavigationButtons
                disabled={ !isValid || !LRId || !dirty }
                hideBack
                submitButtonText={ t("AltQuote Email Section.Button") }
                isSubmitButtonLoading={ isUpdatingLR || !LRId }
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmailForm;
