import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as FileUpload } from "common/assets/images/icons/File - Upload.svg";

const FileUploadOutlined: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon { ...props } className={ props?.className + " ben-icon-input-color" } component={ FileUpload } />
);

export default FileUploadOutlined;
