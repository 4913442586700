import { FC, MouseEvent } from "react";

import { useDispatch } from "react-redux";
import { Collapse, CollapseProps, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { LAPages } from "domains/liquidity";
import { URLs } from "common/lib/constants";
import { Button } from "common/lib/components";
import { setCurrentClient } from "domains/clients/store/clients.reducer";
import { CustodyAccountStatus, ICustodyAccount, ICustodyAccountAPI } from "domains/custodyAccounts/shared/types";
import { getDateFromISO, addDays } from "common/shared/utils/date.utils";
import { getCustodyAccountStatus } from "domains/liquidity/shared/utils/formatter";
import { CollapseTitle } from "domains/dashboard/components";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { useClientsSelector } from "domains/clients";

import "./MyCustodyAccountsPanel.scss";

const { Panel } = Collapse;

const MyCustodyAccountsPanel: FC<CollapseProps> = (props) => {
  const { t } = useTranslation("liquidity");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useClientsSelector();
  const { data: custodyAccounts, isFetching: isFetchingCustodyAccounts } =
    custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
      user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
    );

  const sortedCustodyAccounts = custodyAccounts
    ?.filter(
      (custodyAccount) =>
        custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
        custodyAccount.account_status !== CustodyAccountStatus.Duplicate,
    )
    .sort((a, b) => new Date(b.created_date).valueOf() - new Date(a.created_date).valueOf());

  const handleCustodyAccountNameClick = (custodyAccount: ICustodyAccountAPI) => {
    if (
      custodyAccount.account_status === CustodyAccountStatus.Active ||
      custodyAccount.account_status === CustodyAccountStatus.Pending ||
      custodyAccount.account_status === CustodyAccountStatus.Closed
    ) {
      dispatch(setCurrentCustodyAccount(custodyAccount as unknown as ICustodyAccount));
      navigate(URLs.PROTECTED.LIQUIDITY + LAPages.CustodyAccountAgreement);
    }

    if (custodyAccount.account_status === CustodyAccountStatus.NeedsId) {
      dispatch(setCurrentCustodyAccount(custodyAccount as unknown as ICustodyAccount));
      navigate(URLs.PROTECTED.LIQUIDITY + LAPages.CustodyAccountId);
    }
  };

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    dispatch(setCurrentCustodyAccount(null));
    dispatch(setCurrentClient(null));
    navigate(URLs.PROTECTED.LIQUIDITY + LAPages.CustodyAccountAgreement);
  };

  const columns = [
    {
      title: t("MyCustodyAccountsPanel.ColumnTitles.0"),
      key: "action",
      align: "left",
      width: "20%",
      render: (custodyAccount: ICustodyAccountAPI) => (
        <span onClick={ () => handleCustodyAccountNameClick(custodyAccount) }>{custodyAccount.account_name}</span>
      ),
    } as const,
    {
      title: t("MyCustodyAccountsPanel.ColumnTitles.1"),
      dataIndex: "created_date",
      key: "dateOpened",
      align: "center",
      width: "20%",
      render: (created_date: string) => getDateFromISO(created_date),
    } as const,
    {
      title: t("MyCustodyAccountsPanel.ColumnTitles.2"),
      dataIndex: "account_status",
      key: "accountStatus",
      align: "center",
      width: "14%",
      render: (account_status: string) => getCustodyAccountStatus(account_status),
    } as const,
    {
      title: t("MyCustodyAccountsPanel.ColumnTitles.3"),
      key: "expiryDate",
      align: "center",
      width: "22%",
      render: (custodyAccount: ICustodyAccountAPI) => {
        const date = custodyAccount.custodial_agreement_expiry_date ?? addDays(custodyAccount.created_date, 30);
        return `${ getDateFromISO(date, { timeZone: "America/Chicago" }) } (CST)`;
      },
    } as const,
    {
      title: t("MyCustodyAccountsPanel.ColumnTitles.4"),
      dataIndex: "display_id",
      key: "accountNumber",
      align: "center",
      width: "20%",
    } as const,
  ];

  return (
    <Panel
      header={
        <CollapseTitle
          title={ t("MyCustodyAccountsPanel.Header") }
          count={ sortedCustodyAccounts?.length }
          button={
            <Button onClick={ handleClick } type="text">
              {t("MyCustodyAccountsPanel.Button")}
            </Button>
          }
        />
      }
      key="My Custody Accounts"
      { ...props }
    >
      {sortedCustodyAccounts?.length ? (
        <Table
          sticky
          loading={ isFetchingCustodyAccounts }
          pagination={ false }
          scroll={ { x: 550 } }
          className="ben-table ben-my-custody-accounts-panel-table"
          columns={ columns }
          dataSource={ sortedCustodyAccounts }
          rowKey="account_id"
        />
      ) : (
        <span className="text">{t("MyCustodyAccountsPanel.FallbackText")}</span>
      )}
    </Panel>
  );
};

export default MyCustodyAccountsPanel;
