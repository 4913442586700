import { apiEnvs, apiService, sessionService } from "common/shared";
import { IClientProfile, IUserDetails, RelationshipTypes } from "./types";
import { IUpdateAPIObject } from "common/shared/types";

export class ClientsService {
  async fetchUserLevelDetails(accountId: string, userId: string): Promise<IUserDetails> {
    const { accountsAPI } = apiEnvs;

    return apiService.get(`${ accountsAPI }/v1/accounts/${ accountId }/users/${ userId }`);
  }

  async fetchAccountLevelDetailsById(accountId: string) {
    const { accountsAPI } = apiEnvs;

    return apiService.get(`${ accountsAPI }/v1/accounts/${ accountId }`);
  }

  async fetchUserClients(accountId: string, userId: string) {
    const { accountsAPI } = apiEnvs;

    return apiService.get(`${ accountsAPI }/v1/accounts/${ accountId }/users/${ userId }/clients?page.size=100&page.index=0`);
  }

  async updateAccountLevelDetails(formattedChanges: IUpdateAPIObject[], accountId: string) {
    const { accountsAPI } = apiEnvs;

    return apiService.patch(`${ accountsAPI }/v1/accounts/${ accountId }`, formattedChanges);
  }

  async updateUserLevelDetails(accountId: string, userId: string, formattedChanges: IUpdateAPIObject[]) {
    const { accountsAPI } = apiEnvs;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return apiService.patch(`${ accountsAPI }/v1/accounts/${ accountId }/users/${ userId }`, formattedChanges, config);
  }

  async createClientFromDummyAccount(accountId: string, data: Partial<IUserDetails>) {
    const { accountsAPI } = apiEnvs;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const white_label_instance = sessionService.getWhiteLabelInstance();

    return apiService.post(`${ accountsAPI }/v1/accounts/${ accountId }/users`, { ...data, white_label_instance }, config);
  }

  async addNewClient(
    data: IClientProfile,
    isLRCreation: boolean,
    isIndividual: boolean,
    isCompleteFullClientCheckboxChecked?: boolean,
  ) {
    const isAnonymous = data?.isAnonymous || false;
    const version = isLRCreation && !isCompleteFullClientCheckboxChecked ? "v1" : "v2";
    const white_label_instance = sessionService.getWhiteLabelInstance();
    const individualUser =
      isLRCreation && !isCompleteFullClientCheckboxChecked
        ? {
            account_name: data?.company_name || `${ data.first_name } ${ data.last_name }`,
            account_type: "individual",
            relationship_type: RelationshipTypes.Client,
            status: "on_boarded",
            access_granted: true,
            salesforce_account_id: null,
            is_test_data: false,
            is_check_for_name_matches: null,
            is_anonymous: isAnonymous,
          }
        : {
            account_name: `${ data.first_name } ${ data.last_name }`,
            account_type: "individual",
            access_granted: false,
            relationship_type: RelationshipTypes.Client,
            status: "prospective",
            is_anonymous: isAnonymous,
            user: {
              first_name: data.first_name,
              middle_name: "",
              last_name: data.last_name,
              nick_name: "",
              time_zone_abbreviation: null,
              suffix: null,
              phones: [
                {
                  country_code: "1",
                  is_validated: false,
                  accepts_text_messages: false,
                  phone_number: data.phone_number,
                  is_default: true,
                },
              ],
              email_addresses: [
                {
                  email: data.email_address,
                  is_default: true,
                },
              ],
              addresses: [
                {
                  street1: "",
                  street2: "",
                  city: "",
                  region: "",
                  country: "USA",
                  postal_code: "",
                  is_default: true,
                },
              ],
              user_type: "principal",
              white_label_instance,
            },
          };

    const institutionUser =
      isLRCreation && !isCompleteFullClientCheckboxChecked
        ? {
            tax_id: "",
            relationship_type: RelationshipTypes.Client,
            account_name: data.company_name,
            account_type: "institution",
            status: "prospective",
            access_granted: true,
            is_anonymous: isAnonymous,
            institution_data: { tax_id: "", institution_type: "asset_management_firm", primary_contact_user_id: null },
          }
        : {
            account_name: data.company_name,
            account_type: "institution",
            access_granted: false,
            relationship_type: RelationshipTypes.Client,
            status: "prospective",
            is_anonymous: isAnonymous,
            institution_data: {
              clientType: "Institution",
              addresses: [
                {
                  street1: "",
                  street2: "",
                  city: "",
                  region: "",
                  country: "USA",
                  postal_code: "",
                  is_default: true,
                },
              ],
              institution_type: "asset_management_firm",
            },
            user: {
              first_name: data.first_name,
              middle_name: "",
              last_name: data.last_name,
              nick_name: "",
              time_zone_abbreviation: null,
              suffix: null,
              phones: [
                {
                  country_code: "1",
                  is_validated: false,
                  accepts_text_messages: false,
                  phone_number: data.phone_number,
                  is_default: true,
                },
              ],
              email_addresses: [
                {
                  email: data.email_address,
                  is_default: true,
                },
              ],
              user_type: "employee",
              white_label_instance,
            },
          };

    return apiService.post(
      `${ apiEnvs.accountsAPI }/${ version }/accounts`,
      isIndividual ? individualUser : institutionUser,
    );
  }

  async addNewClientToAdvisor(account_id: string, user_id: string, client_id: string, isAnonymous: boolean) {
    const data = {
      client_id,
      is_created_as_anonymous: isAnonymous,
    };

    return apiService.post(`${ apiEnvs.accountsAPI }/v1/accounts/${ account_id }/users/${ user_id }/clients`, data);
  }

  acceptCookies = async (switchValue: boolean) => {
    const { accountId, userId } = sessionService.getSessionIds();
    const { accountsAPI } = await apiEnvs.getApiEnv();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = [
      {
        op: "replace",
        path: "/user_strictly_necessary_cookies",
        value: true,
      },
      {
        op: "replace",
        path: "/user_performance_and_targeted_cookies",
        value: switchValue,
      },
    ];

    return await apiService.patch(`${ accountsAPI }/v1/accounts/${ accountId }/users/${ userId }`, data, config);
  };
}

const clientsService = new ClientsService();
export default clientsService;
