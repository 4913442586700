import { FC } from 'react';

import { useTranslation } from "react-i18next";

import { IClientForm } from "domains/myProfile/shared/types";

interface IProps {
  currentClientForm: IClientForm;
}

const NonControllingPartyOwnersList: FC<IProps> = ({ currentClientForm }) => {
  const { t } = useTranslation([ "myProfile" ]);
    
  return (
    <div className="ben-non-controlling-party-owner-container">
      { currentClientForm.values.owners.slice(1).map((owner, index) => (
        <div key={ owner.name }>
          <div className="ben-non-controlling-party-owner-info ben-pb-2">
            { t("Beneficial Owner") } { index + 1 } - { t("Contact Information") }
          </div>
          <ul className="ben-non-controlling-party-owner-list">
            <li>{ owner.name }</li>
            <li>
              { owner.street_address1 + ", " + (owner.street_address2 ? owner.street_address2 + ", " : "") + owner.city + ", "
              + (owner.us_citizen ? owner.state + ", United States" : owner?.non_us_country_of_citizenship) + ", " + owner.zip }
            </li>
          </ul>
        </div>
      )) }
      { currentClientForm.values.trusts.map((owner, index) => (
        <div key={ owner.trust_name }>
          <div className="ben-non-controlling-party-owner-info ben-pb-2">
            { t("Beneficial Owner") } { currentClientForm.values.owners.slice(1).length + 1 + index } - { t("Contact Information") }
          </div>
          <ul className="ben-non-controlling-party-owner-list">
            <li>{ owner.trust_name }</li>
            <li>
              { owner.street_address1 + ", " + (owner.street_address2 ? owner.street_address2 + ", " : "") + owner.city + ", "
              + owner.country + ", " + owner.zip }
            </li>
          </ul>
        </div>
      )) }
    </div>)
}

export default NonControllingPartyOwnersList;
