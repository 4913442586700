import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  BeneficialTarget,
  ClientFormStatuses,
  IBeneficialOwner,
  IBeneficialTrust,
  IClientForm,
  IOwnership,
  TClientFormValues,
} from "domains/myProfile/shared/types";
import { myProfileApiReducer } from "./myProfile.api.reducer";

export interface IInitialState {
  ownerships: IOwnership[];
  currentOwnerId: string | null;
  currentOwnershipName: string;
  currentClientFormsStatus: string;
  currentEntityType: string;
  currentClientForm: IClientForm;
  isCurrentClientFormLoading: boolean;
  source: string;
  fullSource: string;
}

const initialState: IInitialState = {
  ownerships: [],
  currentOwnerId: null,
  currentOwnershipName: "",
  currentClientFormsStatus: "",
  currentEntityType: "",
  currentClientForm: { values: {} as TClientFormValues } as IClientForm,
  isCurrentClientFormLoading: false,
  source: "",
  fullSource: "",
};

const myProfileReducer = createSlice({
  name: "[My Profile]",
  initialState,
  reducers: {
    setCurrentClientFormValues: (state, action: PayloadAction<Partial<TClientFormValues>>) => ({
      ...state,
      currentClientForm: {
        ...state.currentClientForm,
        values: {
          ...state.currentClientForm.values,
          ...action.payload,
        },
      },
    }),

    setClientFormValues: (
      state,
      action: PayloadAction<{
        ownershipName: string;
        clientFormName: string;
        data: Partial<TClientFormValues>;
      }>,
    ) => ({
      ...state,
      ownerships: state.ownerships.map((ownership) =>
        ownership.name === action.payload.ownershipName
          ? {
              ...ownership,
              client_forms: ownership.client_forms.map((clientForm) =>
                clientForm.name === action.payload.clientFormName
                  ? {
                      ...clientForm,
                      values: {
                        ...clientForm.values,
                        ...action.payload.data,
                      },
                    }
                  : clientForm,
              ),
            }
          : ownership,
      ),
    }),

    setCurrentBeneficialOwner: (
      state,
      action: PayloadAction<{
        owner: IBeneficialOwner;
        index: number;
      }>,
    ) => ({
      ...state,
      currentClientForm: {
        ...state.currentClientForm,
        currentBeneficialOwner: action.payload.owner,
        currentId: action.payload.index,
        target: BeneficialTarget.Owner,
      },
    }),

    setCurrentBeneficialOwnerValues: (state, action: PayloadAction<Partial<IBeneficialOwner>>) => ({
      ...state,
      currentClientForm: {
        ...state.currentClientForm,
        currentBeneficialOwner: {
          ...state.currentClientForm.currentBeneficialOwner!,
          ...action.payload,
        },
      },
    }),

    setCurrentBeneficialTrust: (
      state,
      action: PayloadAction<{
        trust: IBeneficialTrust;
        index: number;
      }>,
    ) => ({
      ...state,
      currentClientForm: {
        ...state.currentClientForm,
        currentBeneficialTrust: action.payload.trust,
        currentId: action.payload.index,
        target: BeneficialTarget.Trust,
      },
    }),

    setCurrentBeneficialTrustValues: (state, action: PayloadAction<Partial<IBeneficialTrust>>) => ({
      ...state,
      currentClientForm: {
        ...state.currentClientForm,
        currentBeneficialTrust: {
          ...state.currentClientForm.currentBeneficialTrust!,
          ...action.payload,
        },
      },
    }),

    deleteBeneficialTrustRequest: (state, action: PayloadAction<string>) => ({
      ...state,
      currentClientForm: {
        ...state.currentClientForm,
        values: {
          ...state.currentClientForm.values,
          trusts: state.currentClientForm.values.trusts.map((trust) =>
            trust.id === action.payload
              ? {
                  ...trust,
                  is_loading: true,
                }
              : trust,
          ),
        },
      },
    }),

    resetMyProfileReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(myProfileApiReducer.endpoints.fetchOwnerships.matchFulfilled, (state, action) => ({
      ...state,
      ownerships: action.payload,
    })),
      builder.addMatcher(myProfileApiReducer.endpoints.submitClientForms.matchFulfilled, (state, action) => ({
        ...state,
        ownerships: state.ownerships.map((ownership) =>
          ownership.id === action.meta.arg.originalArgs
            ? {
                ...ownership,
                client_forms_status: ClientFormStatuses.Completed,
                isSubmittingClientForms: false,
              }
            : ownership,
        ),
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.completeClientForm.matchPending, (state) => ({
        ...state,
        ownerships: state.ownerships.map((ownership) =>
          ownership.name === state.currentOwnershipName
            ? {
                ...ownership,
                client_forms: ownership.client_forms.map((clientForm) =>
                  clientForm.name === state.currentClientForm.name
                    ? {
                        ...clientForm,
                        isLoading: true,
                      }
                    : clientForm,
                ),
              }
            : ownership,
        ),
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.completeClientForm.matchFulfilled, (state, action) => ({
        ...state,
        ownerships: state.ownerships.map((ownership) =>
          ownership.name === action.payload.currentOwnershipName
            ? {
                ...ownership,
                client_forms: ownership.client_forms.map((clientForm) =>
                  clientForm.name === action.payload.currentClientForm.name
                    ? {
                        ...action.payload.currentClientForm,
                        isCompleted: action.payload.isCompleted,
                        isLoading: false,
                      }
                    : clientForm,
                ),
              }
            : ownership,
        ),
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.completeClientForm.matchRejected, (state, action) => ({
        ...state,
        ownerships: state.ownerships.map((ownership) =>
          ownership.name === action.meta.arg.originalArgs.currentOwnershipName
            ? {
                ...ownership,
                client_forms: ownership.client_forms.map((clientForm) =>
                  clientForm.name === action.meta.arg.originalArgs.currentClientForm.name
                    ? {
                        ...action.meta.arg.originalArgs.currentClientForm,
                        isLoading: false,
                      }
                    : clientForm,
                ),
              }
            : ownership,
        ),
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.setCurrentClientForm.matchPending, (state, action) => ({
        ...state,
        currentOwnershipName: action.meta.arg.originalArgs.owner.name,
        currentEntityType: action.meta.arg.originalArgs.owner.client_account_type,
        currentClientFormsStatus: action.meta.arg.originalArgs.owner.client_forms_status,
        currentClientForm: state.ownerships
          .find((ownership) => ownership.name === action.meta.arg.originalArgs.owner.name)!
          .client_forms.find((clientForm) => clientForm.name === action.meta.arg.originalArgs.clientFormName)!,
        source: action.meta.arg.originalArgs.source,
        fullSource: action.meta.arg.originalArgs.fullSource,
        isCurrentClientFormLoading: true,
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.setCurrentClientForm.matchFulfilled, (state, action) => ({
        ...state,
        currentOwnerId:
          state.ownerships.find((owner) => owner.client_forms.some((form) => form.id === action.payload.values.id))
            ?.client_account_id ?? null,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            ...action.payload.values,
          },
        },
        ownerships: state.ownerships.map((ownership) =>
          ownership.name === state.currentOwnershipName
            ? {
                ...ownership,
                client_forms: ownership.client_forms.map((clientForm) =>
                  clientForm.name === state.currentClientForm.name
                    ? {
                        ...clientForm,
                        values: {
                          ...action.payload.valuesFromApi,
                        },
                      }
                    : clientForm,
                ),
              }
            : ownership,
        ),
        isCurrentClientFormLoading: false,
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.setCurrentClientForm.matchRejected, (state) => ({
        ...state,
        isCurrentClientFormLoading: false,
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.addBeneficialOwner.matchPending, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: [ ...state.currentClientForm.values.owners, { ...action.meta.arg.originalArgs, is_loading: true } ],
          },
          currentBeneficialOwner: {
            ...state.currentClientForm.currentBeneficialOwner!,
            is_loading: true,
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.addBeneficialOwner.matchFulfilled, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner, index) =>
              index === state.currentClientForm.values.owners.length - 1
                ? {
                    ...owner,
                    id: action.payload,
                    is_loading: false,
                  }
                : owner,
            ),
          },
          currentBeneficialOwner: {
            ...state.currentClientForm.currentBeneficialOwner!,
            id: action.payload,
            is_loading: false,
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.addBeneficialOwner.matchRejected, (state) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner, index) =>
              index === state.currentClientForm.values.owners.length - 1
                ? {
                    ...owner,
                    is_loading: false,
                  }
                : owner,
            ),
          },
          currentBeneficialOwner: {
            ...state.currentClientForm.currentBeneficialOwner!,
            is_loading: false,
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.updateBeneficialOwner.matchPending, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner) =>
              owner.id === action.meta.arg.originalArgs.id
                ? {
                    ...owner,
                    is_loading: true,
                  }
                : owner,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.updateBeneficialOwner.matchFulfilled, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner) =>
              owner.id === action.payload.id
                ? {
                    ...owner,
                    ...action.payload,
                    is_loading: false,
                  }
                : owner,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.updateBeneficialOwner.matchRejected, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner) =>
              owner.id === action.meta.arg.originalArgs.id
                ? {
                    ...owner,
                    is_loading: false,
                  }
                : owner,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.deleteBeneficialOwner.matchPending, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner) =>
              owner.id === action.payload
                ? {
                    ...owner,
                    is_loading: true,
                  }
                : owner,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.deleteBeneficialOwner.matchFulfilled, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.filter((owner) => owner.id !== action.payload),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.deleteBeneficialOwner.matchRejected, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            owners: state.currentClientForm.values.owners.map((owner) =>
              owner.id === action.meta.arg.originalArgs
                ? {
                    ...owner,
                    is_loading: false,
                  }
                : owner,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.addBeneficialTrust.matchPending, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: [ ...state.currentClientForm.values.trusts, { ...action.meta.arg.originalArgs, is_loading: true } ],
          },
          currentBeneficialTrust: {
            ...state.currentClientForm.currentBeneficialTrust!,
            is_loading: true,
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.addBeneficialTrust.matchFulfilled, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.map((trust, index) =>
              index === state.currentClientForm.values.trusts.length - 1
                ? {
                    ...trust,
                    id: action.payload,
                    is_loading: false,
                  }
                : trust,
            ),
          },
          currentBeneficialTrust: {
            ...state.currentClientForm.currentBeneficialTrust!,
            id: action.payload,
            is_loading: false,
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.addBeneficialTrust.matchRejected, (state) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.map((trust, index) =>
              index === state.currentClientForm.values.trusts.length - 1
                ? {
                    ...trust,
                    is_loading: false,
                  }
                : trust,
            ),
          },
          currentBeneficialTrust: {
            ...state.currentClientForm.currentBeneficialTrust!,
            is_loading: false,
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.updateBeneficialTrust.matchPending, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.map((trust) =>
              trust.id === action.meta.arg.originalArgs.id
                ? {
                    ...trust,
                    is_loading: true,
                  }
                : trust,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.updateBeneficialTrust.matchFulfilled, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.map((trust) =>
              trust.id === action.payload.id
                ? {
                    ...trust,
                    ...action.payload,
                    is_loading: false,
                  }
                : trust,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.updateBeneficialTrust.matchRejected, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.map((trust) =>
              trust.id === action.meta.arg.originalArgs.id
                ? {
                    ...trust,
                    is_loading: false,
                  }
                : trust,
            ),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.deleteBeneficialTrust.matchPending, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.filter((trust) => trust.id !== action.payload),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.deleteBeneficialTrust.matchFulfilled, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.filter((trust) => trust.id !== action.payload),
          },
        },
      })),
      builder.addMatcher(myProfileApiReducer.endpoints.deleteBeneficialTrust.matchRejected, (state, action) => ({
        ...state,
        currentClientForm: {
          ...state.currentClientForm,
          values: {
            ...state.currentClientForm.values,
            trusts: state.currentClientForm.values.trusts.map((trust) =>
              trust.id === action.meta.arg.originalArgs
                ? {
                    ...trust,
                    is_loading: false,
                  }
                : trust,
            ),
          },
        },
      }));
  },
});

export const {
  setClientFormValues,

  setCurrentBeneficialOwner,
  setCurrentBeneficialOwnerValues,

  setCurrentBeneficialTrust,
  setCurrentBeneficialTrustValues,

  setCurrentClientFormValues,

  resetMyProfileReducer,
} = myProfileReducer.actions;

export default myProfileReducer.reducer;
