import { FC } from "react";

import { useTranslation } from "react-i18next";

import { usDocTypes, nonUSDocTypes } from "./constants";

import "./IdDocumentTypes.scss";

interface IProps {
  idCountry: string;
}

const IdDocumentTypes: FC<IProps> = ({ idCountry }) => {
  const { t } = useTranslation("liquidity");

  return (
    <>
      <p className="ben-custody-account-id-form-info">{t("Custody Account ID Page.Text2")}</p>
      <ul className="ben-custody-account-id-doc-list">
        {(idCountry === "United States" ? usDocTypes : nonUSDocTypes).map((item: { key: string; value: string }) => (
          <li key={ item.key }>{item.value}</li>
        ))}
      </ul>
    </>
  );
};

export default IdDocumentTypes;
