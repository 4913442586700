import { IClientForm, IClientFormInfo, IOwnership, TClientFormValues } from "./types";
import { ClientFormTypes } from "domains/myProfile/shared/constants";
import { IUserDetails, UserType } from "domains/clients/shared/types";
import { AccountType } from "common/lib/constants";
import { entityTypes } from "domains/myProfile/pages/MainMyProfilePage/constants";

export const findClientForm = (
  ownerships: IOwnership[],
  currentOwnershipName: string,
  clientFormType: string,
): IClientForm | undefined => {
  const ownership = ownerships.find(ownership => ownership.name === currentOwnershipName);
  return ownership!.client_forms.find(clientForm => clientForm.name === clientFormType);
};

export const findOwnership = (
  ownerships: IOwnership[],
  currentOwnershipName: string,
): IOwnership | undefined => {
  return ownerships.find(ownership => ownership.name === currentOwnershipName);
};

export const getFormatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = +( minutes < 10 ? "0" + minutes : minutes );

  const strTime = hours + ":" + minutes + " " + ampm;

  return strTime;
};

export const defineSourceClientForm = (clientFromName: string, clientForms: IClientForm[]) => {
  let sourceClientFormNames: string[] = [];
  switch (clientFromName) {
    case ClientFormTypes.WireTransfers:
      sourceClientFormNames = [ ClientFormTypes.DirectDeposit ];
      break;
    case ClientFormTypes.DirectDeposit:
      sourceClientFormNames = [ ClientFormTypes.WireTransfers ];
      break;
    case ClientFormTypes.W9Form:
      sourceClientFormNames = [ ClientFormTypes.CustomerInformation, ClientFormTypes.CIP,
        ClientFormTypes.BeneficialOwnership, ClientFormTypes.Accreditation ];
      break;
    case ClientFormTypes.CustomerInformation:
      sourceClientFormNames = [ ClientFormTypes.W9Form, ClientFormTypes.CIP, ClientFormTypes.Accreditation ];
      break;
    case ClientFormTypes.CIP:
      sourceClientFormNames = [ ClientFormTypes.CustomerInformation, ClientFormTypes.W9Form,
        ClientFormTypes.BeneficialOwnership ];
      break;
    case ClientFormTypes.BeneficialOwnership:
      sourceClientFormNames = [ ClientFormTypes.W9Form, ClientFormTypes.CIP, ClientFormTypes.Accreditation ];
      break;
    case ClientFormTypes.Accreditation:
      sourceClientFormNames = [ ClientFormTypes.W9Form, ClientFormTypes.CustomerInformation,
        ClientFormTypes.BeneficialOwnership ];
      break;
  }

  const sourceClientForms = sourceClientFormNames.map(sourceClientFormName => {
    return clientForms.find(clientForm =>
      clientForm.name === sourceClientFormName);
  });

  return sourceClientForms.find(sourceClientForm => {
    return sourceClientForm?.isCompleted;
  });
};

export const completeValuesFromSourceClientForm = (
  currentClientForm: IClientForm,
  values: TClientFormValues,
  sourceClientForm: IClientForm,
  sourceClientFormValues: TClientFormValues,
  currentEntityType: string,
) => {
  if (!currentClientForm.isCompleted && sourceClientForm?.isCompleted) {
    switch (currentClientForm.name) {
      case ClientFormTypes.WireTransfers:
        if (sourceClientForm.name === ClientFormTypes.DirectDeposit) {
          values.bank_routing_number = values.bank_routing_number || sourceClientFormValues?.bank_routing_number;
          values.bank_account_number = values.bank_account_number || sourceClientFormValues?.bank_account_number;
          values.bank_name = values.bank_name || sourceClientFormValues?.bank_name;
          values.bank_address = values.bank_address || sourceClientFormValues?.branch_location;
        }
        break;
      case ClientFormTypes.DirectDeposit:
        if (sourceClientForm.name === ClientFormTypes.WireTransfers) {
          values.bank_routing_number = values.bank_routing_number || sourceClientFormValues?.bank_routing_number;
          values.bank_account_number = values.bank_account_number || sourceClientFormValues?.bank_account_number;
          values.bank_name = values.bank_name || sourceClientFormValues?.bank_name;
          values.branch_location = values.branch_location || sourceClientFormValues?.bank_address;
        }
        break;
      case ClientFormTypes.W9Form:
        if (sourceClientForm.name === ClientFormTypes.CustomerInformation) {
          values.street_address1 = values.street_address1 || sourceClientFormValues?.street_address1;
          values.street_address2 = values.street_address2 || sourceClientFormValues?.street_address2;
          values.city = values.city || sourceClientFormValues?.city;
          values.state = values.state || sourceClientFormValues?.state;
          values.zip_code = values.zip_code || +sourceClientFormValues?.zip;
          values.business_name = values.business_name || sourceClientFormValues?.investor_name || "";
          if (currentEntityType === AccountType.Individual) {
            values.ssn = values.ssn || sourceClientFormValues?.individual_investor_ssn || undefined;
          } else {
            values.ein = values.ein || sourceClientFormValues?.ein;
          }
        } else if (sourceClientForm.name === ClientFormTypes.CIP) {
          values.business_name = values.business_name || sourceClientFormValues?.legal_name;
          values.street_address1 = values.street_address1 || sourceClientFormValues?.primary_street_address1;
          values.street_address2 = values.street_address2 || sourceClientFormValues?.primary_street_address2;
          values.city = values.city || sourceClientFormValues?.primary_city;
          values.state = values.state || sourceClientFormValues?.primary_state;
          values.zip_code = values.zip_code +sourceClientFormValues?.primary_zip;
        } else if (sourceClientForm.name === ClientFormTypes.BeneficialOwnership) {
          values.business_name = values.business_name || sourceClientFormValues?.legal_name;
        } else if (sourceClientForm.name === ClientFormTypes.Accreditation) {
          if (currentEntityType === AccountType.Individual) {
            values.business_name = values.business_name || sourceClientFormValues?.investor_legal_name || "";
          }
        }
        break;
      case ClientFormTypes.CustomerInformation:
        if (sourceClientForm.name === ClientFormTypes.W9Form) {
          values.street_address1 = values.street_address1 || sourceClientFormValues?.street_address1;
          values.street_address2 = values.street_address2 || sourceClientFormValues?.street_address2;
          values.city = values.city || sourceClientFormValues?.city;
          values.state = values.state || sourceClientFormValues?.state;
          values.zip = values.zip || sourceClientFormValues?.zip_code + "";
          if (currentEntityType === AccountType.Individual) {
            values.investor_name = values.investor_name || sourceClientFormValues?.business_name;
            values.individual_investor_ssn = values.individual_investor_ssn || sourceClientFormValues?.ssn;
          } else {
            values.ein = values.ein || sourceClientFormValues?.ein;
          }
        } else if (sourceClientForm.name === ClientFormTypes.CIP) {
          values.street_address1 = values.street_address1 || sourceClientFormValues?.primary_street_address1;
          values.street_address2 = values.street_address2 || sourceClientFormValues?.primary_street_address2;
          values.city = values.city || sourceClientFormValues?.primary_city;
          values.state = values.state || sourceClientFormValues?.primary_state;
          values.zip = values.zip || sourceClientFormValues?.primary_zip;
        } else if (sourceClientForm.name === ClientFormTypes.Accreditation) {
          if (currentEntityType === AccountType.Individual) {
            values.investor_name = values.investor_name || sourceClientFormValues?.investor_legal_name || "";
          }
        }
        break;
      case ClientFormTypes.CIP:
        if (sourceClientForm.name === ClientFormTypes.W9Form) {
          values.legal_name = values.legal_name || sourceClientFormValues?.business_name;
          values.primary_street_address1 = values.primary_street_address1 || sourceClientFormValues?.street_address1;
          values.primary_street_address2 = values.primary_street_address2 || sourceClientFormValues?.street_address2;
          values.primary_city = values.primary_city || sourceClientFormValues?.city;
          values.primary_state = values.primary_state || sourceClientFormValues?.state;
          values.primary_zip = values.primary_zip || sourceClientFormValues?.zip_code + "";
        } else if (sourceClientForm.name === ClientFormTypes.CustomerInformation) {
          values.primary_street_address1 = values.primary_street_address1 || sourceClientFormValues?.street_address1;
          values.primary_street_address2 = values.primary_street_address2 || sourceClientFormValues?.street_address2;
          values.primary_city = values.primary_city || sourceClientFormValues?.city;
          values.primary_state = values.primary_state || sourceClientFormValues?.state;
          values.primary_zip = values.primary_zip || sourceClientFormValues?.zip;
        } else if (sourceClientForm.name === ClientFormTypes.BeneficialOwnership) {
          values.legal_name = values.legal_name || sourceClientFormValues?.legal_name;
        }
        break;
      case ClientFormTypes.BeneficialOwnership:
        if (sourceClientForm.name === ClientFormTypes.W9Form) {
          values.legal_name = values.legal_name || sourceClientFormValues?.business_name;
        } else if (sourceClientForm.name === ClientFormTypes.CIP) {
          values.legal_name = values.legal_name || sourceClientFormValues?.legal_name;
        } else if (sourceClientForm.name === ClientFormTypes.Accreditation) {
          if (currentEntityType === AccountType.Individual) {
            values.legal_name = values.legal_name || sourceClientFormValues?.investor_legal_name;
          }
        }
        break;
      case ClientFormTypes.Accreditation:
        if (sourceClientForm.name === ClientFormTypes.CustomerInformation) {
          values.investor_legal_name = values.investor_legal_name || sourceClientFormValues?.investor_name || "";
        } else if (sourceClientForm.name === ClientFormTypes.W9Form) {
          values.investor_legal_name = values.investor_legal_name || sourceClientFormValues?.business_name || "";
        } else if (sourceClientForm.name === ClientFormTypes.BeneficialOwnership) {
          values.investor_legal_name = values.investor_legal_name || sourceClientFormValues?.legal_name || "";
        }
        break;
    }
  }
};

export const completeValuesFromUserInfo = (
  currentClientForm: IClientForm,
  values: TClientFormValues,
  user: IUserDetails,
) => {
  if (!currentClientForm.isCompleted) {
    const defaultUserAddress = user.addresses.find(address => address.is_default);
    const defaultPhone = user.phones.find(phone => phone.is_default);
    const defaultEmail = user.email_addresses.find(email => email.is_default);

    switch (currentClientForm.name) {
      case ClientFormTypes.W9Form:
        if (defaultUserAddress) {
          values.street_address1 = defaultUserAddress.street1 || "";
          values.street_address2 = defaultUserAddress.street2 || "";
          values.city = defaultUserAddress.city || "";
          values.state = defaultUserAddress.region || "";
          values.zip_code = defaultUserAddress.postal_code ? parseInt(defaultUserAddress.postal_code) : "";
        }
        if (user.user_type === UserType.Principal) {
          values.business_name = user.account_name;
          values.ssn = user.ssn as string;
        }
        break;
      case ClientFormTypes.CustomerInformation:
        if (defaultUserAddress) {
          values.street_address1 = defaultUserAddress.street1 || "";
          values.street_address2 = defaultUserAddress.street2 || "";
          values.city = defaultUserAddress.city || "";
          values.state = defaultUserAddress.region || "";
          values.zip = defaultUserAddress.postal_code || "";
        }
        if (defaultPhone) {
          values.phone_number = defaultPhone.phone_number || "";
        }
        if (defaultEmail) {
          values.email = defaultEmail.email || "";
        }
        if (user.user_type === UserType.Principal) {
          values.investor_name = user.account_name;
          values.individual_investor_ssn = user.ssn as string;
          values.individual_investor_date_of_birth = user.date_of_birth || "";
        } else {
          values.investor_name = user.account_name;
        }
        break;
      case ClientFormTypes.CIP:
        if (defaultUserAddress) {
          values.primary_street_address1 = defaultUserAddress.street1 || "";
          values.primary_street_address2 = defaultUserAddress.street2 || "";
          values.primary_city = defaultUserAddress.city || "";
          values.primary_state = defaultUserAddress.region || "";
          values.primary_zip = defaultUserAddress.postal_code || "";
        }
        break;
      case ClientFormTypes.Accreditation:
        values.investor_legal_name = user.account_name;
        break;
    }
  }
};

export const defineCurrentEntityType = (ownerEntityType: string): AccountType => {
  return entityTypes.find((entityType) => entityType.value === ownerEntityType)!.type;
};

export const isOwnerIsJointTenant = (owners: IOwnership[], ownerName: string): boolean => {
  const type = owners.find((owner) => owner.name === ownerName)!.client_owner_type;
  return type === entityTypes[ 3 ].value || type === entityTypes[ 4 ].value;
};

export const filterAssets = (assets: IClientFormInfo[]): IClientFormInfo[] => {
  return assets.filter((asset: IClientFormInfo, index: number) => {
    const slicedAssets = assets.slice(index + 1);

    const slicedIndex = slicedAssets.findIndex((innerAsset) => asset.owner === innerAsset.owner);

    return slicedIndex === -1;
  });
};
