import { Suspense } from "react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { rootStore } from "./store";
import { persistor } from "store/root.store";
import "common/assets/styles/root.css";
import "common/assets/styles/main.scss";

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  <Provider store={ rootStore }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Suspense fallback={ null }>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
);
