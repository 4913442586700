import { object } from "yup";

import { useValidationSchemas } from "common/shared/constants/validationSchemas";
import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";

export const useAddNewClientModalValidationSchema = () => {
  const { required } = useValidationErrorMessages();
  const { onlyLettersValidationSchema, onlyLettersAndPunctuationAndDigitsValidationSchema } = useValidationSchemas();

  const individualValidationSchema = object().shape({
    first_name: onlyLettersValidationSchema.required(required),
    last_name: onlyLettersValidationSchema.required(required),
  });

  const organizationValidationSchema = object().shape({
    company_name: onlyLettersAndPunctuationAndDigitsValidationSchema.required(required),
  });

  return { individualValidationSchema, organizationValidationSchema };
};
