import { FC } from "react";

import { useParams } from "react-router-dom";

import DeleteOutlined from "common/lib/components/Icons/DeleteOutlined";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { useMyProfileSelector } from "domains/myProfile/store/myProfile.selectors";
import { useClientsSelector } from "domains/clients";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";

interface IProps {
  type: "custody" | "asset" | "id";
  documentId: string;
  iconClassName?: string;
}

const DeleteDocumentIcon: FC<IProps> = ({ documentId, type, iconClassName }) => {
  const { LRId } = useParams();

  const { user } = useClientsSelector();
  const { currentOwnerId } = useMyProfileSelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const [ deleteDocument ] = documentsApiReducer.useDeleteDocumentMutation({
    fixedCacheKey: "delete-document",
  });

  const accountId =
    type === "id" ? currentOwnerId : type === "asset" ? user?.account_id : currentCustodyAccount?.account_id;

  return (
    <DeleteOutlined
      className={ iconClassName }
      onClick={ () => {
        if (!accountId) return;

        deleteDocument({
          accountId,
          documentId,
          LRId: type === "asset" ? LRId : undefined,
        });
      } }
    />
  );
};

export default DeleteDocumentIcon;
