import { object, string } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";

export const useValidationSchema = () => {
  const { required } = useValidationErrorMessages();

  const validationSchema = object().shape({
    reason_for_seeking_liquidity: string().required(required),
  });

  return validationSchema;
};
