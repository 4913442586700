import { IFundSearchItem } from "domains/liquidity/shared/types";
import { fundSearchService } from "common/shared";
import { AssetType } from "domains/liquidity/shared/constants";
import { isNumber } from "common/shared/utils/number.utils";

export const getMatchedFunds = (fundName: string, fundSearchItems: IFundSearchItem[]) => {
  fundName = fundName.trim().toLowerCase();
  const maxLength = 50;
  const startsWithResults: IFundSearchItem[] = [];
  const includesResults: IFundSearchItem[] = [];

  // The /^/ is so that fund "MyFund Z" with fund manager "John Smith" does not get a match for 'zjo'.
  for (const item of fundSearchItems) {
    const lowerCaseFundName = item.fundName.toLowerCase();
    const lowerCaseFundManager = item.fundManager.toLowerCase();
    const lowerCaseSynonyms = item.synonyms.toLowerCase();
    const searchString = lowerCaseFundName + '^' + lowerCaseFundManager + '^' + lowerCaseSynonyms;

    if (lowerCaseFundName.startsWith(fundName) || lowerCaseFundManager.startsWith(fundName)) {
      startsWithResults.push(item);
      if (startsWithResults.length === maxLength) break;
    }

    if (searchString.indexOf(fundName) > 0) {
      includesResults.push(item);
    }
  }

  // Up to the first 50 matching items or empty.
  return startsWithResults.concat(includesResults.slice(0, maxLength - startsWithResults.length));
};

export const getFundItemWithBIDSFundIdIfExists = async (fundName: string, fundManager: string, fundId: string ) => {
  const lowerCaseFundName = fundName.toLowerCase();
  const bidsFundItems = await fundSearchService.getBidsFundSearchItems();
  const foundItem = bidsFundItems.find((item) => item.fundName.toLowerCase() === lowerCaseFundName);
  const baseItem = {
    asset_type: AssetType.PreqinFund,
    fund_manager: fundManager,
    fund_id: isNumber(fundId) ? Number(fundId) : 0,
    asset_name: fundName,
  }

  return foundItem ? {
    ...baseItem,
    bidsFundId: foundItem.id,
  } : baseItem
}
