import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import { trustbenAPIService } from "domains/trustben";
import { IGPQuestionnaire, ILogs } from "domains/trustben/shared/types";
import { TAltQuoteQuestionnaire } from "domains/trustben/pages/AltQuoteQuestionnairePage/AltQuoteQuestionnaireForm/types";
import {
  formatAltQuoteQuestionnaireForReducer,
  formatAltQuoteQuestionnaireForRequest,
} from "domains/trustben/shared/utils/apiFormatter";
import { apiService } from "common/shared";

export const trustbenApiReducer = rootApiReducer.injectEndpoints({
  endpoints: (build) => ({
    sendLogs: build.mutation<null, ILogs>({
      queryFn: async (logs) => {
        try {
          await trustbenAPIService.sendLogs(logs);

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    fetchAltQuoteQuestionnaire: build.query<TAltQuoteQuestionnaire, { LRId: string; sessionId: string }>({
      queryFn: async ({ LRId, sessionId }) => {
        try {
          const response = await trustbenAPIService.fetchAltQuoteQuestionnaire(LRId, sessionId);
          const altQuoteQuestionnaire = formatAltQuoteQuestionnaireForReducer(response);

          return { data: altQuoteQuestionnaire };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    sendAltQuoteQuestionnaire: build.mutation<null, { questionnaire: TAltQuoteQuestionnaire; LRId: string }>({
      queryFn: async ({ questionnaire, LRId }) => {
        try {
          const requestBody = formatAltQuoteQuestionnaireForRequest(questionnaire, LRId);
          await trustbenAPIService.sendAltQuoteQuestionnaire(requestBody, LRId);

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    sendGPQuestionnaire: build.mutation<null, { questionnaire: IGPQuestionnaire; sessionId: string }>({
      queryFn: async ({ questionnaire, sessionId }) => {
        try {
          await trustbenAPIService.sendGPQuestionnaire(questionnaire, sessionId);

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),
  }),
});
