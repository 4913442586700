import axios, { AxiosRequestConfig } from "axios";
import { strFromU8, decompressSync } from "fflate";

import { getNoCacheHeaders } from "common/shared/services/api.headers/api.headers";
import { IBidsFundSearchItem, IFundSearchItem } from "domains/liquidity/shared/types";

export class FundSearchService {
  static readonly TIMEOUT = 60000;
  private preqinFundsSearchItems: Promise<IFundSearchItem[]> | IFundSearchItem[];
  private otherFundsSearchItems: Promise<IFundSearchItem[]> | IFundSearchItem[];
  private bidsFundsSearchItems: Promise<IBidsFundSearchItem[]> | IBidsFundSearchItem[];

  constructor() {
    this.preqinFundsSearchItems = new Promise(() => {});
    this.bidsFundsSearchItems = new Promise(() => {});
    this.otherFundsSearchItems = new Promise(() => {});

    this.fetchFundSearchItems().then(
      (items: [ArrayBuffer, ArrayBuffer, ArrayBuffer]) => {
        const [ preqinFundsSearchItems, bidsFundsSearchItems, otherFundsSearchItems ] = items;

        this.setPreqinFundSearchItems(this.parseCompressedResponse(preqinFundsSearchItems));
        this.setBidsFundSearchItems(this.parseCompressedResponse(bidsFundsSearchItems));
        this.setOtherFundsSearchItems(this.parseCompressedResponse(otherFundsSearchItems));
      },
      () => {
        this.setPreqinFundSearchItems([]);
        this.setOtherFundsSearchItems([]);
        this.setBidsFundSearchItems([]);
      },
    );
  }

  public getPreqinFundSearchItems() {
    return this.preqinFundsSearchItems;
  }

  public getBidsFundSearchItems() {
    return this.bidsFundsSearchItems;
  }

  public getOtherFundsSearchItems() {
    return this.otherFundsSearchItems;
  }

  setPreqinFundSearchItems(items: IFundSearchItem[]) {
    this.preqinFundsSearchItems = items;
  }

  setBidsFundSearchItems(items: IBidsFundSearchItem[]) {
    this.bidsFundsSearchItems = items;
  }

  setOtherFundsSearchItems(items: IFundSearchItem[]) {
    this.otherFundsSearchItems = items;
  }

  parseCompressedResponse(arrayBuffer: ArrayBuffer): IFundSearchItem[] {
    return JSON.parse(strFromU8(decompressSync(new Uint8Array(arrayBuffer))));
  }

  async fetchFundSearchItems() {
    const host = window.location.hostname === "localhost" ? "https://baadev.bcpbenapp-dev.bankben.org" : "";
    const config = {
      responseType: "arraybuffer",
      headers: {
        ...getNoCacheHeaders(),
      },
    } as AxiosRequestConfig;

    const knownFundsSearchItemsPromise: Promise<ArrayBuffer> = axios.get(
      `${ host }/fundSearchItems/knownFunds.json.gz`,
      config,
    );
    const bidsFundsSearchItemsPromise: Promise<ArrayBuffer> = axios.get(
      `${ host }/fundSearchItems/bidsFunds.json.gz`,
      config,
    );
    const otherFundsSearchItemsPromise: Promise<ArrayBuffer> = axios.get(
      `${ host }/fundSearchItems/otherFunds.json.gz`,
      config,
    );

    return Promise.all([ knownFundsSearchItemsPromise, bidsFundsSearchItemsPromise, otherFundsSearchItemsPromise ]);
  }
}

const fundSearchService = new FundSearchService();
export default fundSearchService;
