import { FC } from "react";

import { Page, Document, StyleSheet, Font } from "@react-pdf/renderer";

// @ts-ignore
import regularFont from "common/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf";
// @ts-ignore
import boldFont from "common/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf";
// @ts-ignore
import italicFont from "common/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf";
import { IUserClient, IUserDetails } from "domains/clients/shared/types";
import ElectronicSignature from "./ElectronicSignature";
import PDFText from "./PDFText";
import { TCustodyAccountPDFTypes } from "domains/liquidity/shared/types";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";

interface IProps {
  pdfType: TCustodyAccountPDFTypes;
  custodyAccount?: ICustodyAccount;
  user?: IUserDetails;
  currentClient?: IUserClient;
  formValues?: Partial<IUserDetails> & {
    isFullName: boolean;
  };
}

Font.register({
  family: "IBM Plex Sans",
  fonts: [
    { src: regularFont, fontWeight: "normal", format: "truetype" },
    { src: boldFont, fontWeight: "bold", format: "truetype" },
    { src: italicFont, fontStyle: "italic", format: "truetype" },
  ],
});

export const styles = StyleSheet.create({
  page: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "normal",
    paddingRight: 45,
    paddingTop: 35,
    paddingLeft: 60,
    paddingBottom: 65,
    lineHeight: 1.6,
    fontSize: "14px",
    textAlign: "justify",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#055073",
    textAlign: "center",
    paddingRight: 10,
    marginBottom: 20,
  },
  header: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20,
  },
  marginHorizontal: {
    marginHorizontal: 30,
  },
  textIndent: {
    textIndent: "50px",
  },
  bold: {
    fontWeight: "bold",
  },
  border: {
    borderBottom: "1px solid #D6D8DB",
  },
  marginTop: {
    marginTop: 100,
  },
  marginBottom: {
    marginBottom: 25,
  },
  spacing: {
    marginBottom: 25,
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 5,
  },
  image: {
    width: "200px",
    paddingBottom: 5,
    borderBottom: "1px solid #D6D8DB",
  },
  date: {
    flexDirection: "row",
  },
});

const CustodyAgreementPDF: FC<IProps> = ({ pdfType, custodyAccount, user, formValues, currentClient }) => {
  return (
    <Document>
      <Page size="A4" style={ styles.page }>
        <PDFText
          custodyAccount={ custodyAccount }
          user={ user }
          currentClient={ currentClient }
          formValues={ formValues }
          pdfType={ pdfType }
        />

        <ElectronicSignature
          custodyAccount={ custodyAccount }
          user={ user }
          currentClient={ currentClient }
          pdfType={ pdfType }
        />
      </Page>
    </Document>
  );
};

export default CustodyAgreementPDF;
