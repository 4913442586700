import { useTranslation } from "react-i18next";

import { FormHeader } from "common/lib/components";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import InvestmentDetailsForm from "./InvestmentDetailsForm";

const InvestmentDetailsPage = () => {
  const { t } = useTranslation("liquidity");

  const { currentAsset, isLA, isAddManually } = useLiquiditySelector();

  const subtitle = t(`Investment Details Page.${ isAddManually || isLA ? "SubTitle With Steps" : "SubTitle" }`);

  return (
    <>
      <FormHeader
        title={ t("Assets to Exchange") }
        subtitle={ subtitle }
        hint={ t("Investment Details Page.Hint") }
        assetName={ currentAsset.asset_name }
      />
      <div className="ben-form-container">
        <InvestmentDetailsForm />
      </div>
    </>
  );
};

export default InvestmentDetailsPage;
