import React, { FC } from "react";

export interface IProps {
  tel?: string | React.ReactElement;
  email?: string | React.ReactElement;
  isFullPhoneLabel?: boolean;
  isFullEmailLabelTo?: boolean;
  organization?: string | React.ReactElement;
}

const ContactInfo: FC<IProps> = ({
  tel,
  email,
  isFullPhoneLabel,
  isFullEmailLabelTo,
  organization,
}) => {
  const telText =
    isFullPhoneLabel ? (
      "Call us at "
    ) : (
      "Phone: "
    );
  const emailText =
    isFullEmailLabelTo ? (
      "Email us at "
    ) : (
      "Email: "
    );
  const organizationText = "Contact your ";

  return (
    <ul className="ben-mb-0">
      {
        organization && (
          <li>
            { organizationText } {
            organization }
          </li>
        ) }
      {
        email && (
          <li>
            { emailText }
            <a href={ `mailto:${ email }` }>
              { email }
            </a>
          </li>
        ) }

      {
        tel && (
          <li>
            { telText }
            <a href={ `tel:${ tel }` }>
              { tel }
            </a>
          </li>
        ) }
    </ul>
  );
};

export default ContactInfo;
