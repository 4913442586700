import { getNumberFromString } from "common/shared/utils/number.utils";
import {
  SelfReportedRoles,
  assetTypeforLiquidityOptions,
  describeYourInterestsAdvisorOptions,
  describeYourInterestsGPOptions,
  reasonForAssetExchangeOptions,
  selfReportedInstitutionTypeOptions,
  selfReportedRoleOptions,
} from "domains/trustben/pages/AltQuoteQuestionnairePage/AltQuoteQuestionnaireForm/constants";
import {
  IAltQuoteQuestionnaireAPIResponse,
  TAltQuoteQuestionnaire,
} from "domains/trustben/pages/AltQuoteQuestionnairePage/AltQuoteQuestionnaireForm/types";
import { IQuestion, IQuestionnaire } from "domains/trustben/shared/types";

export const getQuestionCode = (question: keyof TAltQuoteQuestionnaire) => {
  switch (question) {
    case "self_reported_role":
      return 0;

    case "asset_type_for_liquidity":
      return 1;

    case "reason_for_asset_exchange":
      return 2;

    case "self_reported_institution":
      return 3;

    case "describe_your_interests":
      return 4;
  }
};

const getQuestionText = (question: keyof TAltQuoteQuestionnaire) => {
  switch (question) {
    case "self_reported_role":
      return "What’s your role?";

    case "asset_type_for_liquidity":
      return "What type of fund vehicle are you MOST interested in discussing?";

    case "reason_for_asset_exchange":
      return "What’s your primary reason for discussing your assets?";

    case "self_reported_institution":
      return "What type of institution do you represent?";

    case "describe_your_interests":
      return "Which of the following best describes your interest in Ben solutions?";
  }
};

const getAnswerCode = (
  role: (typeof selfReportedRoleOptions)[number],
  question: keyof TAltQuoteQuestionnaire,
  answer: string,
) => {
  switch (question) {
    case "self_reported_role":
      return selfReportedRoleOptions.findIndex((item) => item === role);

    case "asset_type_for_liquidity":
      return assetTypeforLiquidityOptions.findIndex((item) => item === answer);

    case "reason_for_asset_exchange":
      return reasonForAssetExchangeOptions.findIndex((item) => item === answer);

    case "self_reported_institution":
      return selfReportedInstitutionTypeOptions.findIndex((item) => item === answer);

    case "describe_your_interests":
      switch (role) {
        case SelfReportedRoles.WealthAdvisor:
          return describeYourInterestsAdvisorOptions.findIndex((item) => item === answer);

        case SelfReportedRoles.GeneralPartner:
          return describeYourInterestsGPOptions.findIndex((item) => item === answer);
      }
  }
};

const getAnswerText = (
  role: (typeof selfReportedRoleOptions)[number],
  question: keyof TAltQuoteQuestionnaire,
  answer: string,
) => {
  switch (question) {
    case "self_reported_role":
      return selfReportedRoleOptions.find((item) => item === role);

    case "asset_type_for_liquidity":
      return assetTypeforLiquidityOptions.find((item) => item === answer);

    case "reason_for_asset_exchange":
      return reasonForAssetExchangeOptions.find((item) => item === answer);

    case "self_reported_institution":
      return selfReportedInstitutionTypeOptions.find((item) => item === answer);

    case "describe_your_interests":
      switch (role) {
        case SelfReportedRoles.WealthAdvisor:
          return describeYourInterestsAdvisorOptions.find((item) => item === answer);

        case SelfReportedRoles.GeneralPartner:
          return describeYourInterestsGPOptions.find((item) => item === answer);
      }
  }
};

export const formatAltQuoteQuestionnaireForRequest = (data: TAltQuoteQuestionnaire, LRId: string): IQuestionnaire => {
  const role = data.self_reported_role!;
  const entries = Object.entries(data) as [keyof TAltQuoteQuestionnaire, string][];
  const questions = entries.map(
    ([ question, answer ]) =>
      ({
        liquidity_request_id: getNumberFromString(LRId),
        question_code: getQuestionCode(question),
        question_text: getQuestionText(question),
        answer_code: getAnswerCode(role, question, answer),
        answer_text: getAnswerText(role, question, answer),
      }) as IQuestion,
  );

  return { questions };
};

export const getFieldNameByQuestionCode = (questionCode: number) => {
  switch (questionCode) {
    case 0:
      return "self_reported_role";

    case 1:
      return "asset_type_for_liquidity";

    case 2:
      return "reason_for_asset_exchange";

    case 3:
      return "self_reported_institution";

    case 4:
      return "describe_your_interests";
  }
};

export const formatAltQuoteQuestionnaireForReducer = (data: IAltQuoteQuestionnaireAPIResponse[]) => {
  const altQuoteQuestionnaire = data.reduce((acc, question) => {
      acc[ getFieldNameByQuestionCode(question.question_code) as string ] = question.answer_text;

      return acc;
    }, {} as any) as TAltQuoteQuestionnaire;

  return altQuoteQuestionnaire;
};
