import { apiEnvs, apiService } from "common/shared";

export class FeaturesApiService {
  async fetchFeatures(): Promise<string> {
    const { accountsAPI } = apiEnvs;

    return apiService.get(`${ accountsAPI }/v1/feature_flags`);
  }
}

export default new FeaturesApiService();
