import { ICustodyAccount } from "domains/custodyAccounts/shared/types";

export const getInitialValues = (custodyAccount: ICustodyAccount | null | undefined) => {
  if (!custodyAccount) return {};

  return {
    id_country: custodyAccount?.id_country,
    id_type: custodyAccount?.id_type,
    id_number: custodyAccount?.id_number,
    id_expiration: custodyAccount?.id_expiration,
  };
};
