import * as Yup from "yup";
import "yup-phone-lite";
import dayjs from "dayjs";
import { isPossiblePhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { useValidationErrorMessages } from "./validationErrorMessages";
import {
  noPOBox,
  noSpacesInStartAndEndRegExp,
  onlyLettersAndDigits,
  onlyLettersAndPunctuation,
  onlyLettersAndPunctuationAndDigits,
  phoneRegEx,
  noFilesAndScripts,
} from "./validationRegExps";
import {
  maxBirthDate,
  maxLengthText,
  minDate,
  maxLengthIdentificationNumber,
  maxIdExpirationDate,
  maxLength11,
  dateFormat,
} from "./validationConstants";
import { countries, getStates } from "common/lib/constants";

dayjs.extend(customParseFormat);

export const useValidationSchemas = () => {
  const {
    dateHasExpired,
    email,
    futureDate,
    invalidCharacters,
    invalidDateFormat,
    maxBirthDateErrorMessage,
    maxNumberOfCharacters,
    maxNumOfDigits,
    noPOBoxMessage,
    noSpacesInStartAndEnd,
    numOfDigits,
    numberOfCharacters,
    positive,
    integer,
    phoneUS,
    float,
  } = useValidationErrorMessages();

  const phoneNumberSchema = () => {
    const validationSchema = Yup.string().nullable().typeError("");

    return validationSchema.test("is-phone-number-valid", (value, { createError, parent }) => {
      const phoneNumber = value ? (value?.startsWith("+") ? value : `+${ parent.country_code }${ value }`) : "";
      const isPossible = isPossiblePhoneNumber(phoneNumber || "");
      const length = validatePhoneNumberLength(phoneNumber || "");

      if (length === "TOO_SHORT") {
        return createError({
          message: "Phone number is too short",
        });
      }

      if (length === "TOO_LONG") {
        return createError({
          message: "Phone number is too long",
        });
      }

      if (!isPossible) {
        return createError({
          message: "The field must be valid phone number",
        });
      }

      return true;
    });
  };

  const phoneValidationSchema = phoneNumberSchema();

  const onlyDigitsSchemaWithLeadingZeros = (expression: "==" | "<=", len: number) => {
    const validationSchema = Yup.string().typeError("").nullable();
    if (expression === "==") {
      return validationSchema.test("len", numOfDigits(len), (value) =>
        value ? String(value).length === len && Number(value) >= 0 : true,
      );
    } else {
      return validationSchema.test("len", maxNumOfDigits(len), (value) =>
        value ? String(value).length <= len && Number(value) >= 0 : true,
      );
    }
  };

  const onlyDigitsSchema = (expression: "==" | "<=", len: number) => {
    const validationSchema = Yup.number().typeError("").nullable().integer(integer).positive(positive);
    if (expression === "==") {
      return validationSchema.test("len", numOfDigits(len), (value) =>
        value ? String(value).length === len && Number(value) >= 0 : true,
      );
    } else {
      return validationSchema.test("len", maxNumOfDigits(len), (value) =>
        value ? String(value).length <= len && Number(value) >= 0 : true,
      );
    }
  };

  const onlyDigitsWithDecimalSchema = (expression: "==" | "<=", len: number, numberOfDecimal: number) => {
    const validationSchema = Yup.number().typeError("").nullable().positive(positive);
    if (expression === "==") {
      return validationSchema
        .test("float", float(numberOfDecimal), (_, testContext: any) => {
          const value = testContext.originalValue;
          const decimalLength = String(value).split(".")[ 1 ]?.length ?? 0;
          return value && decimalLength ? decimalLength === numberOfDecimal : true;
        })
        .test("len", numOfDigits(len), (value) =>
          value ? parseInt(String(value)).toString().length === len && Number(value) >= 0 : true,
        );
    } else {
      return validationSchema
        .test("float", float(numberOfDecimal), (_, testContext: any) => {
          const value = testContext.originalValue;
          const decimalLength = String(value).split(".")[ 1 ]?.length ?? 0;

          return value && decimalLength ? decimalLength <= numberOfDecimal : true;
        })
        .test("len", maxNumOfDigits(len), (value) =>
          value ? parseInt(String(value)).toString().length <= len && Number(value) >= 0 : true,
        );
    }
  };

  const baseStringValidationSchema = Yup.string()
    .matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd)
    .nullable();

  const baseStringWithMaxValidationSchema = baseStringValidationSchema.max(
    maxLengthText,
    maxNumberOfCharacters(maxLengthText),
  );

  const accountNameValidationSchema = baseStringWithMaxValidationSchema.matches(noFilesAndScripts, invalidCharacters);

  const onlyLettersValidationSchema = baseStringWithMaxValidationSchema.matches(
    onlyLettersAndPunctuation,
    invalidCharacters,
  );

  const onlyLettersAndPunctuationAndDigitsValidationSchema = baseStringWithMaxValidationSchema.matches(
    onlyLettersAndPunctuationAndDigits,
    invalidCharacters,
  );

  const phoneUSValidationSchema = baseStringValidationSchema.matches(phoneRegEx, phoneUS).phone("US", phoneUS);

  const ssnValidationSchema = onlyDigitsSchema("==", 9);

  const faxValidationSchema = baseStringValidationSchema.length(10, numberOfCharacters(11));

  const irsTaxIdValidationSchema = onlyDigitsSchema("==", 9);

  const parseDateString = (value: string, originalValue: string) => {
    return dayjs(originalValue, dateFormat).isValid() ? dayjs(originalValue, dateFormat).toDate() : originalValue;
  };

  const dateOfBirthValidationSchema = Yup.date()
    .transform(parseDateString)
    .max(maxBirthDate, maxBirthDateErrorMessage)
    .min(minDate, invalidDateFormat)
    .typeError(invalidDateFormat);

  const emailValidationSchema = baseStringWithMaxValidationSchema.email(email);

  const einValidationSchema = onlyDigitsSchema("==", 9);

  const tinValidationSchema = onlyDigitsSchema("==", 9);

  const zipCodeValidationSchema = onlyDigitsSchemaWithLeadingZeros("==", 5);

  const stateValidationSchema = Yup.string()
    .nullable()
    .oneOf([ ...getStates().map((state) => state.value), null ]);

  const countryValidationSchema = Yup.string()
    .nullable()
    .oneOf([ ...countries, null ]);

  const addressLine1ValidationSchema = baseStringWithMaxValidationSchema
    .matches(noPOBox, noPOBoxMessage)
    .test("len", "Not only digits", (value) => !+value!);

  const identificationNumberValidationSchema = baseStringValidationSchema
    .max(maxLengthIdentificationNumber, maxNumberOfCharacters(maxLengthIdentificationNumber))
    .matches(onlyLettersAndDigits, invalidCharacters);

  const expirationDateValidationSchema = Yup.date()
    .min(new Date(), dateHasExpired)
    .max(maxIdExpirationDate, futureDate)
    .typeError(invalidDateFormat);

  const NAICSCodeValidationSchema = onlyDigitsSchema("==", 6);

  const dateEntityEstablishedValidationSchema = Yup.date()
    .transform(parseDateString)
    .min(minDate, invalidDateFormat)
    .max(new Date(), futureDate)
    .typeError(invalidDateFormat);

  const annualIncomeValidationSchema = onlyDigitsSchema("<=", 11);

  const netWorthValidationSchema = onlyDigitsSchema("<=", 11);

  const yearsValidationSchema = onlyDigitsSchema("<=", 2);

  const monthsValidationSchema = onlyDigitsSchema("<=", 2);

  const ABARoutingNumberValidationSchema = onlyDigitsSchema("==", 9);

  const swiftNumberValidationSchema = baseStringValidationSchema
    .max(maxLength11, maxNumberOfCharacters(maxLength11))
    .matches(onlyLettersAndDigits, invalidCharacters);

  const accountNumberValidationSchema = onlyDigitsSchema("<=", 30);

  const currentNetAssetValueValidationSchema = onlyDigitsSchema("<=", 10);

  const capitalCommitmentValidationSchema = onlyDigitsSchema("<=", 10);

  const employerIdentificationNumberValidationSchema = onlyDigitsSchema("==", 9);

  const socialSecurityNumberValidationSchema = onlyDigitsSchema("==", 9);

  return {
    onlyDigitsSchema,
    onlyDigitsWithDecimalSchema,
    baseStringValidationSchema,
    baseStringWithMaxValidationSchema,
    accountNameValidationSchema,
    onlyLettersValidationSchema,
    onlyLettersAndPunctuationAndDigitsValidationSchema,
    ssnValidationSchema,
    faxValidationSchema,
    irsTaxIdValidationSchema,
    dateOfBirthValidationSchema,
    emailValidationSchema,
    phoneValidationSchema,
    phoneUSValidationSchema,
    einValidationSchema,
    tinValidationSchema,
    zipCodeValidationSchema,
    stateValidationSchema,
    countryValidationSchema,
    addressLine1ValidationSchema,
    identificationNumberValidationSchema,
    expirationDateValidationSchema,
    NAICSCodeValidationSchema,
    dateEntityEstablishedValidationSchema,
    annualIncomeValidationSchema,
    netWorthValidationSchema,
    yearsValidationSchema,
    monthsValidationSchema,
    ABARoutingNumberValidationSchema,
    swiftNumberValidationSchema,
    accountNumberValidationSchema,
    currentNetAssetValueValidationSchema,
    capitalCommitmentValidationSchema,
    employerIdentificationNumberValidationSchema,
    socialSecurityNumberValidationSchema,
  };
};
