import { FC } from "react";

import { Table } from "antd";
import { useTranslation } from "react-i18next";

import { CloseWindowOutlined, DeleteDocumentIcon } from "common/lib/components";
import { IDocument } from "domains/documents/shared/types";
import { apiService } from "common/shared";
import { getIconForFile } from "domains/documents/shared/utils";
import { getDateFromISO } from "common/shared/utils/date.utils";

import "./UploadedDocumentsTableMobile.scss";

interface IProps {
  type: "id" | "custody";
  documents: IDocument[];
}

const UploadedDocumentsTableMobile: FC<IProps> = ({ type, documents }) => {
  const { t } = useTranslation([ "myProfile" ]);

  const columnsMobile = [
    {
      title: t("Documents Uploaded"),
      render: ({ document_name, date_provided, id, percent, cancelToken, file_name }: IDocument) => {
        return (
          <div className="ben-uploaded-documents-table-mobile-wrapper" key={ id }>
            <div className="ben-uploaded-documents-table-mobile-file-name-container">
              {getIconForFile(file_name)}
              <p>{document_name}</p>
            </div>
            <p className="ben-uploaded-documents-table-mobile-date-provided">
              {percent ? `${ t("Uploading...") } ${ percent }%` : `${ getDateFromISO(date_provided) }`}
            </p>
            {cancelToken ? (
              <CloseWindowOutlined onClick={ () => apiService.cancel(cancelToken) } />
            ) : (
              <DeleteDocumentIcon documentId={ id } type={ type } />
            )}
          </div>
        );
      },
      className: "ben-uploaded-documents-table-mobile-documents-uploaded-mobile",
    },
  ];

  return (
    <Table
      className="ben-uploaded-documents-table-mobile-table"
      dataSource={ documents }
      columns={ columnsMobile }
      pagination={ false }
    />
  );
};

export default UploadedDocumentsTableMobile;
