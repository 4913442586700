import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as Circle } from "common/assets/images/icons/Circle-X.svg";

interface IProps extends Partial<CustomIconComponentProps> {
  onClick: () => void;
}

const CircleX: FC<IProps> = (props) => (
  <Icon { ...props } className={ props?.className + " ben-icon-primary-color" } component={ Circle } />
);

export default CircleX;
