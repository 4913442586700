import { Navigate, RouteObject } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { ProtectedRoutes, RouteErrorBoundary } from "common/lib/components";
import {
  DashboardPage,
  SignInPage,
  LoginHelpPage,
  MainPageWrapper,
  MyClientsPage,
  ClientApplicationsPage,
  VerifyResetPasswordPage,
  VerifyIdentityPage,
  VerifySharingPage,
  InternalAltQuotePage,
  TrustbenWrapper,
  TrustBenAltQuotePage,
  TrustbenApplicationPage,
  GPPrimaryCommitmentPage,
  GPFundMonetizationPage,
  PrivacyNoticePage,
  ResetPasswordExpiredPage,
  SetPasswordPage,
  ResendWelcomeEmailPage,
  ApplicationPage,
  ResendSharingInvitationPage,
  MyAssetsPage,
  MyAltCustodyAssetsPage,
  MyClientsAssetsPage,
  AccountBlockedPage,
  CodeExpiredPage,
  AuthenticationPage,
  VerifyIdentificationCodePage,
  MyApplicationsPage,
  LiquidityApplicationPage,
  AltCustodyAssetPage,
  PreferencesPage,
  NotFoundPage,
  AltDataPage,
  AltCustodyPage,
} from "common/shared/lazy/pages";
import { TrustbenPages } from "domains/trustben/shared";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { ProtectedRoutesTrustben } from "domains/trustben";
import { GPPages, TBAltQuotePages } from "domains/trustben/shared/constants";

export const routes: RouteObject[] = [
  {
    element: <MainPageWrapper />,
    path: "/",
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: <SignInPage />,
        path: URLs.AUTH.SIGNIN,
      },
      {
        element: <SignInPage />,
        path: URLs.AUTH.SIGN_IN,
      },
      {
        element: <SetPasswordPage />,
        path: URLs.AUTH.SET_PASSWORD,
      },
      {
        element: <LoginHelpPage />,
        path: URLs.AUTH.LOGIN_HELP,
      },
      {
        element: <ResetPasswordExpiredPage />,
        path: URLs.AUTH.RESET_PASSWORD_EXPIRED,
      },
      {
        element: <ResendWelcomeEmailPage />,
        path: URLs.AUTH.RESEND_WELCOME_EMAIL,
      },
      {
        element: <VerifyIdentificationCodePage />,
        path: URLs.AUTH.VERIFY_IDENTIFICATION_CODE,
      },
      {
        element: <AuthenticationPage />,
        path: URLs.AUTH.AUTHENTICATION,
      },
      {
        element: <CodeExpiredPage />,
        path: URLs.AUTH.CODE_EXPIRED,
      },
      {
        element: <AccountBlockedPage />,
        path: URLs.AUTH.ACCOUNT_BLOCKED,
      },
      {
        element: <InternalAltQuotePage />,
        path: URLs.AUTH.INTERNAL_ALTQUOTE_FUNCTIONALITY,
      },
      {
        element: <ResendSharingInvitationPage />,
        path: URLs.AUTH.RESEND_SHARING_INVITATION_IN,
      },
      {
        element: <NotFoundPage />,
        path: URLs.AUTH.NOT_FOUND,
      },
      {
        element: <ProtectedRoutes />,
        children: [
          {
            element: <DashboardPage />,
            path: `${ URLs.PROTECTED.DASHBOARD }/*?`,
          },
          {
            element: <AltDataPage />,
            path: `${ URLs.PROTECTED.ALT_DATA }/*`,
          },
          {
            element: <AltCustodyPage />,
            path: `${ URLs.PROTECTED.ALT_CUSTODY }/*`,
          },
          {
            element: <AltCustodyAssetPage />,
            path: `${ URLs.PROTECTED.DASHBOARD }${ URLs.PROTECTED.ALT_CUSTODY }/:current`,
          },
          {
            element: <LoginHelpPage />,
            path: URLs.PROTECTED.CONTACT_US,
          },
          {
            element: <PrivacyNoticePage />,
            path: URLs.PROTECTED.PRIVACY_NOTICE,
          },
          {
            element: <MyClientsPage />,
            path: `${ URLs.PROTECTED.MY_CLIENTS }:current?/:accountId?`,
          },
          {
            element: <MyClientsAssetsPage />,
            path: URLs.PROTECTED.MY_CLIENTS_ASSETS,
          },
          {
            element: <ClientApplicationsPage />,
            path: URLs.PROTECTED.CLIENT_APPLICATIONS,
          },
          {
            element: <LiquidityApplicationPage />,
            path: `${ URLs.PROTECTED.LIQUIDITY }:LRId?/:current`,
          },
          {
            element: <ApplicationPage />,
            path: `${ URLs.PROTECTED.MY_APPLICATIONS }:LRId/:currentSection/*?`,
          },
          {
            element: <MyApplicationsPage />,
            path: URLs.PROTECTED.MY_APPLICATIONS,
          },
          {
            element: <MyAssetsPage />,
            path: URLs.PROTECTED.MY_ASSETS,
          },
          {
            element: <MyAltCustodyAssetsPage />,
            path: URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS,
          },
          {
            element: <PreferencesPage />,
            path: URLs.PROTECTED.PREFERENCES,
          },
        ],
      },
    ],
  },
  {
    element: <TrustbenWrapper />,
    path: URLs.AUTH.TRUSTBEN_ALTQUOTE,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: (
          <Navigate to={ `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }${ TBAltQuotePages.Questionnaire }` } replace />
        ),
        index: true,
      },
      {
        element: <ProtectedRoutesTrustben />,
        children: [
          {
            element: <TrustbenApplicationPage />,
            path: `${ URLs.AUTH.TRUSTBEN_ALTQUOTE }/:LRId/${ MyApplicationsPages.ALTQUOTE }`,
          },
          {
            element: <TrustbenApplicationPage />,
            path: `${ URLs.AUTH.TRUSTBEN_ALTQUOTE }/:LRId/${ MyApplicationsPages.ALTQUOTE_ASSETS_INCLUDED }`,
          },
        ],
      },
    ],
  },
  {
    element: <TrustbenWrapper />,
    path: URLs.AUTH.TRUSTBEN,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: (
          // added for backward compatibility
          <Navigate to={ `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPPrimaryCommitment }${ GPPages.FundInformation }` } replace />
        ),
        path: `${ URLs.AUTH.TRUSTBEN }/gp_fund_information`,
      },
      {
        element: <GPPrimaryCommitmentPage />,
        path: `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPPrimaryCommitment }/:current`,
      },
      {
        element: <GPFundMonetizationPage />,
        path: `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPFundMonetization }/:current`,
      },
      {
        element: <TrustBenAltQuotePage />,
        path: `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/:LRId?/:current`,
      },
    ],
  },
  {
    element: <TrustbenWrapper />,
    path: URLs.AUTH.GP_FUND_MONETIZATION,
    errorElement: <RouteErrorBoundary />,
    children: [
      {
        element: (
          // added for backward compatibility
          <Navigate to={ `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPFundMonetization }${ GPPages.FundInformation }` } replace />
        ),
        path: `${ URLs.AUTH.GP_FUND_MONETIZATION }/gp_fund_information`,
      },
    ],
  },
  {
    element: <VerifyResetPasswordPage />,
    path: URLs.AUTH.VERIFY_RESET_PASSWORD,
    errorElement: <RouteErrorBoundary />,
  },
  {
    element: <VerifyIdentityPage />,
    path: URLs.AUTH.VERIFY_IDENTITY,
    errorElement: <RouteErrorBoundary />,
  },

  {
    element: <VerifySharingPage />,
    path: URLs.AUTH.VERIFY_SHARING,
    errorElement: <RouteErrorBoundary />,
  },
];
