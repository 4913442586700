import { DefaultOptionType } from "antd/lib/select";

export const filterStates = (inputValue: string, option: DefaultOptionType | undefined) => {
  const trimmedInputValue = inputValue.trim().toLowerCase();
  const optionValue = String(option?.label ?? "").toLowerCase();

  if (trimmedInputValue.length === 2) {
    return optionValue.startsWith(trimmedInputValue) || optionValue.slice(-4).indexOf(trimmedInputValue) >= 0;
  }

  return optionValue.startsWith(trimmedInputValue);
};
