import { FC } from "react";

import { Col, Row } from "antd";

import { formatValue } from "common/shared/helpers";
import { IAltQuoteOffersOptions, IAltQuoteOffersSubOptions } from "domains/altQuotes/shared/types";

import "./AltQuoteOfferPanelHeader.scss";

interface IProps {
  item: IAltQuoteOffersOptions | IAltQuoteOffersSubOptions;
  isViewByOffer: boolean;
}

const AltQuoteOfferPanelHeader: FC<IProps> = ({ item, isViewByOffer }) => {
  return (
    <Row align="middle" className="ben-altquote-page-panel-header-row">
      <Col className="ben-panel-header-offer-options" xs={ isViewByOffer ? 6 : 8 } md={ 5 }>
        {item.offer_option}
      </Col>
      <Col className="ben-panel-header-text ben-text-center ben-nested" xs={ isViewByOffer ? 6 : 8 } md={ 5 }>
        {`${ formatValue(item.offer_low_currency, "USDollar") } to ${ formatValue(item.offer_high_currency, "USDollar") }`}
      </Col>
      <Col className="ben-panel-header-text ben-text-center ben-nested-range" xs={ isViewByOffer ? 6 : 8 } md={ 4 }>
        {`${ formatValue(item.percent_of_nav_low, "Percentage") } to ${ formatValue(
          item.percent_of_nav_high,
          "Percentage",
        ) }`}
      </Col>
      {isViewByOffer && (
        <Col className="ben-panel-header-text ben-text-center" xs={ 6 } md={ 4 }>
          {item.return_rate}
        </Col>
      )}
    </Row>
  );
};

export default AltQuoteOfferPanelHeader;
