import {
  ClientFormStatuses,
  IChangedValue,
  IClientFormInfo,
  IClientForm,
  IOwnerName,
  IOwnership,
  TClientFormValues,
} from "domains/myProfile/shared/types";
import { TAssetType } from "domains/liquidity/shared/types";
import { getAssetName } from "domains/liquidity/shared/utils/formatter";
import { ClientFormTypes } from "domains/myProfile/shared/constants";
import { defineCurrentEntityType } from "domains/myProfile/shared/helpers";

export const formatClientFormToUpdate = (currentClientForm: IClientForm, stateClientForm: IClientForm) => {
  const currentValues: any = { ...currentClientForm.values };
  const stateValues: any = { ...stateClientForm.values };
  delete currentValues.beneficial_owner_type;
  delete currentValues.entity_type;

  if (currentClientForm.name === ClientFormTypes.BeneficialOwnership) {
    delete currentValues.owners;
    delete currentValues.trusts;

    delete stateValues.owners;
    delete stateValues.trusts;
  }

  const keys: string[] = Object.keys(currentValues) as string[];
  const changes: IChangedValue[] = [];

  keys.forEach(
    (key) =>
      Object.hasOwn(stateValues, key) &&
      currentValues[ key ] !== stateValues[ key ] &&
      changes.push({
        op: "replace",
        path: `/${ key }`,
        value: currentValues[ key ],
      }),
  );

  return { changes, id: currentClientForm.id };
};

export const formatOwnerToUpdate = (currentOwner: any, stateOwner: any) => {
  const currentOwnerCopy = { ...currentOwner };
  const stateOwnerCopy = { ...stateOwner };

  delete currentOwnerCopy.is_loading;
  delete stateOwnerCopy.is_loading;

  const keys: string[] = Object.keys(currentOwnerCopy) as string[];
  const changes: IChangedValue[] = [];

  keys.forEach(
    (key) =>
      currentOwnerCopy[ key ] !== stateOwnerCopy[ key ] &&
      changes.push({
        op: "replace",
        path: `/${ key }`,
        value: currentOwnerCopy[ key ],
      }),
  );

  return changes;
};

export const formatAssetsForMyProfile = (assets: TAssetType[]): IClientFormInfo[] => {
  return assets.map((asset) => ({
    name: getAssetName(asset)!,
    owner: "",
    type: "",
    nav: asset.current_net_asset_value,
    isOwnerNameLoading: false,
  }));
};

export const formatClientFormForCreate = (asset: IClientFormInfo, liquidity_request_id: string, owner: IOwnerName) => {
  return {
    liquidity_request_id: liquidity_request_id,
    client_account_id: owner.account_id,
    client_account_display_id: owner.account_display_id,
    client_account_name: owner.name,
    client_account_type: defineCurrentEntityType(asset.type),
    client_owner_type: asset.type,
    is_test_data: false,
  };
};

export const formatClientFormsForReducer = (response: any[]): IOwnership[] => {
  return response.map((item) => {
    return {
      id: item.id,
      name: item.client_account_name,
      client_account_type: item.client_account_type,
      client_account_id: item.client_account_id,
      client_owner_type: item.client_owner_type,
      client_forms_status: item.client_forms_status,
      client_forms: getClientForms(item),
    };
  });
};

const getClientForms = (item: any): IClientForm[] => {
  const client_forms: IClientForm[] = [];

  if (item.customer_information_form_id && item.customer_information_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.customer_information_form_id,
      name: ClientFormTypes.CustomerInformation,
      isCompleted: item.customer_information_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.w9_form_id && item.w9_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.w9_form_id,
      name: ClientFormTypes.W9Form,
      isCompleted: item.w9_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.cip_form_id && item.cip_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.cip_form_id,
      name: ClientFormTypes.CIP,
      isCompleted: item.cip_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.legal_entity_customer_form_id && item.legal_entity_customer_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.legal_entity_customer_form_id,
      name: ClientFormTypes.BeneficialOwnership,
      isCompleted: item.legal_entity_customer_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.accreditation_form_id && item.accreditation_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.accreditation_form_id,
      name: ClientFormTypes.Accreditation,
      isCompleted: item.accreditation_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.suitability_form_id && item.suitability_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.suitability_form_id,
      name: ClientFormTypes.Suitability,
      isCompleted: item.suitability_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.wire_transfer_form_id && item.wire_transfer_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.wire_transfer_form_id,
      name: ClientFormTypes.WireTransfers,
      isCompleted: item.wire_transfer_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  if (item.direct_deposit_form_id && item.direct_deposit_form_status !== ClientFormStatuses.InActive) {
    client_forms.push({
      id: item.direct_deposit_form_id,
      name: ClientFormTypes.DirectDeposit,
      isCompleted: item.direct_deposit_form_status === ClientFormStatuses.Completed,
      isLoading: false,
      values: {} as TClientFormValues,
    });
  }

  return client_forms;
};
