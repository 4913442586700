import { TAltQuoteQuestionnaire } from "./types";

export const trustbenQuestionnaireKeys = [
  "self_reported_role",
  "asset_type_for_liquidity",
  "reason_for_asset_exchange",
  "self_reported_institution",
  "describe_your_interests",
] as (keyof TAltQuoteQuestionnaire)[];

export enum SelfReportedRoles {
  IndividualInvestor = "Individual Investor",
  InstitutionalInvestor = "Institutional Investor",
  WealthAdvisor = "Wealth Advisor",
  GeneralPartner = "General Partner",
}

export const selfReportedRoleOptions = [
  "Individual Investor",
  "Institutional Investor",
  "Wealth Advisor",
  "General Partner",
] as const;

export const assetTypeforLiquidityOptions = [
  "Limited Partnerships",
  "Fund of Funds",
  "Co-Investments",
  "Non-Traded REITs",
  "Gated Hedge Funds",
  "Feeder Funds",
  "Non-Traded BDCs",
  "Other/Multiple Funds",
] as const;

export const reasonForAssetExchangeOptions = [
  "Pursue new investment opportunities",
  "Unexpected expenses",
  "Capital call relief",
  "Realize investment return",
  "Other",
] as const;

export const selfReportedInstitutionTypeOptions = [
  "Foundation",
  "Endowment",
  "Insurance",
  "Plan Sponsor",
  "Consultant",
  "Corporate",
  "Small Business",
  "Other",
] as const;

export const describeYourInterestsAdvisorOptions = [
  "I'm here on behalf of a client",
  "I'm here on behalf of myself",
  "Other",
] as const;

export const describeYourInterestsGPOptions = [
  "I'm here on behalf of a fund",
  "I'm here on behalf of my LPs",
  "I'm interested in establishing a Preferred Liquidity Provider program",
  "Other",
] as const;
