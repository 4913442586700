import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Document, Page, Text, StyleSheet, Font, View } from "@react-pdf/renderer";

const emptySpaceString = "   ";

// @ts-ignore
import medium from "common/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf";
// @ts-ignore
import regularFont from "common/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf";
// @ts-ignore
import boldFont from "common/assets/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf";

Font.register({
  family: "IBM Plex Sans",
  fonts: [
    { src: regularFont, fontWeight: "normal", format: "truetype" },
    { src: boldFont, fontWeight: "bold", format: "truetype" },
    { src: medium, fontWeight: "medium", format: "truetype" },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "normal",
    paddingRight: 45,
    paddingTop: 35,
    paddingLeft: 60,
    paddingBottom: 65,
    lineHeight: 1.6,
    fontSize: "14px",
    textAlign: "justify",
  },
  section: {
    padding: 10,
  },
  text: {
  },
  textIndent: {
    textIndent: "50px",
  },
  header: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "18px",
  },
  title: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#055073",
    textAlign: "center",
    marginLeft: "15px",
    marginBottom: 20,
  },
  importantText: {
    fontWeight: "medium",
    textDecoration: "underline",
  },
});

interface IProps {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
}

const ConfidentialityAgreementPDF: FC<IProps> = ({
  firstName,
  lastName,
  email,
  userId,
}) => {
  const { t } = useTranslation([ "authentication" ]);

  const footerDate = `${ new Date().toLocaleString(undefined, { timeZoneName: "short" }) }`;

  return (
    <Document>
      <Page size="A4" style={ styles.page }>
        <Text style={ styles.title }>{t("CONFIDENTIALITY AGREEMENT")}</Text>
        <View style={ styles.section }>
          <Text  style={ [ styles.text, styles.textIndent ] }>
            { t("Agreement Content.Info.0") }
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            { t("Agreement Content.Info.1") }
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ styles.header }>
            { t("Agreement Content.RECITALS") }
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text ] }>
            <Text style={ [ styles.textIndent, styles.importantText ] }>
              { t("Agreement Content.WHEREAS") }
            </Text>
            {emptySpaceString}
            <Text style={ [ styles.text, styles.textIndent ] }>
              { t("Agreement Content.Info.2") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ styles.text }>
            <Text style={ [ styles.textIndent, styles.importantText ] }>
              { t("Agreement Content.WHEREAS") }
            </Text>
            {emptySpaceString}
            <Text style={ [ styles.text, styles.textIndent ] }>
              { t("Agreement Content.Info.3") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ styles.text }>
            <Text style={ [ styles.textIndent, styles.importantText ] }>
              { t("Agreement Content.NOW, THEREFORE") }
            </Text>
            {emptySpaceString}
            <Text style={ [ styles.text, styles.textIndent ] }>
              { t("Agreement Content.Info.4") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ styles.header }>
            { t("Agreement Content.AGREEMENT") }
          </Text>
        </View>

        <View>
          <Text style={ [ styles.text, styles.textIndent ] }>
            1.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.0.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.0.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            2.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.1.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.1.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            3.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.2.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.2.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            4.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.3.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.3.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            5.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.4.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.4.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            6.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.5.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.5.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            7.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.6.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.6.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            8.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.7.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.7.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            9.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.8.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.8.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            10.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.9.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.9.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            11.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.10.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.10.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            12.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.11.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.11.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            13.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.12.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.12.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            14.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.13.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.13.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            15.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.14.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.14.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            16.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.15.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.15.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            17.
            {emptySpaceString}
            <Text style={ [ styles.importantText ] }>
              { t("Agreement Content.Agreement.16.Title") }
            </Text>
            {emptySpaceString}
            <Text>
              { t("Agreement Content.Agreement.16.Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text style={ [ styles.text, styles.textIndent ] }>
            <Text style={ [ styles.text, styles.textIndent ] }>
              { t("Agreement Content.Footer Info") }
            </Text>
          </Text>
        </View>

        <View style={ styles.section }>
          <Text>{`Electronic Signature Accepted ${ footerDate }`}</Text>
          <Text>{`By: ${ firstName } ${ lastName }`}</Text>
          <Text>{`User Email: ${ email }`}</Text>
          <Text>{`User Id: ${ userId }`}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default ConfidentialityAgreementPDF;
