import { apiEnvs, apiService } from "common/shared";

export class AltCustodyApiService {
  async fetchInvestments(accountId: string): Promise<any> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/investments_client/${ accountId }`);
  }

  async fetchInvestment(investmentId: string): Promise<any> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/investments/${ investmentId }`);
  }

  async createInvestment(data: any): Promise<any> {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/investments`, data);
  }

  async updateInvestment(investmentId: string, data: any): Promise<any> {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/investments/${ investmentId }`, data);
  }

  async deleteInvestment(investmentId: string) {
    return apiService.delete(`${ apiEnvs.holdingsAPI }/v1/investments/${ investmentId }`);
  }
}

export const altCustodyApiService = new AltCustodyApiService();
