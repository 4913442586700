import { useEffect } from "react";

import { message } from "antd";
import { useTranslation } from "react-i18next";

const useOnlineStatus = () => {
  const { t } = useTranslation("common");

  useEffect(() => {
    let isOnline = true;
    let hide = () => {};

    const onOffline = () => {
      if (isOnline) {
        isOnline = false;
        hide = message.warning(t("Connection Lost Info"), 0);
      }
    };

    const onOnline = () => {
      if (!isOnline) {
        isOnline = true;
        hide();
        message.success(t("Connection Back Info"));
      }
    };

    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, []);
};

export default useOnlineStatus;
