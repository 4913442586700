import { TWhiteLabel } from "common/shared/types";
import { IFeature } from "domains/features/shared/types";

export const formatFeaturesForReducer = (featureFlagString: string) => {
  const formattedFeatures: IFeature[] = [];
  const features = featureFlagString
    .replaceAll(" ", "")
    .split("|")
    .filter((feature) => feature.length);

  features.forEach((feature) => {
    const featureName = feature.substring(0, feature.indexOf(":"));
    const featureWhiteLabels = feature.substring(feature.indexOf(":") + 1).split(";");

    featureWhiteLabels.forEach((featureWhiteLabel) => {
      const featureWhiteLabelValues = featureWhiteLabel.split(":");
      formattedFeatures.push({
        featureName,
        whiteLabelInstance: featureWhiteLabelValues[ 0 ] as TWhiteLabel,
        isActive: featureWhiteLabelValues[ 1 ] !== "0",
      });
    });
  });

  return formattedFeatures;
};
