import { FC, useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { FileCompleteOutlined, FileUploadOutlined, Spinner } from "common/lib/components";
import { ClientFormTypes, MyProfilePages } from "domains/myProfile/shared/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { IOwnership } from "domains/myProfile/shared/types";
import { myProfileApiReducer } from "domains/myProfile/store/myProfile.api.reducer";

import "./Documents.scss";

interface IProps {
  owner: IOwnership;
}

const Documents: FC<IProps> = ({ owner }) => {
  const navigate = useNavigate();
  const { LRId } = useParams();

  const [ setCurrentClientForm ] = myProfileApiReducer.useSetCurrentClientFormMutation();
  const [ _, { isLoading: isSubmittingClientForms } ] = myProfileApiReducer.useSubmitClientFormsMutation({
    fixedCacheKey: "submit-client-forms",
  });

  const handleEdit = useCallback(
    (documentName: string) => {
      const path = URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.MY_APPLICATION_DOCS;

      setCurrentClientForm({
        owner,
        clientFormName: documentName,
        source: path,
        fullSource: path + MyProfilePages.MultipleOwners,
      });

      switch (documentName) {
        case ClientFormTypes.Suitability:
          navigate(path + MyProfilePages.Suitability);
          return;
        case ClientFormTypes.BeneficialOwnership:
          navigate(path + MyProfilePages.BeneficialOwnership);
          return;
        case ClientFormTypes.W9Form:
          navigate(path + MyProfilePages.W9Request);
          return;
        case ClientFormTypes.CustomerInformation:
          navigate(path + MyProfilePages.CustomerInformation);
          return;
        case ClientFormTypes.CIP:
          navigate(path + MyProfilePages.CIP);
          return;
        case ClientFormTypes.Accreditation:
          navigate(path + MyProfilePages.Accreditation);
          return;
        case ClientFormTypes.DirectDeposit:
          navigate(path + MyProfilePages.DirectDeposit);
          return;
        case ClientFormTypes.WireTransfers:
          navigate(path + MyProfilePages.WireTransferInstructions);
      }
    },
    [ owner ],
  );

  return (
    <div className="ben-my-profile-multiple-owners-documents-container">
      {owner.client_forms.map((document: any) => (
        <div key={ document.name } className="ben-my-profile-multiple-owners-document-container">
          {document.isLoading || isSubmittingClientForms ? (
            <div className="ben-my-profile-multiple-owners-loading">
              <Spinner />
            </div>
          ) : document.isCompleted ? (
            <div onClick={ () => handleEdit(document.name) }>
              <FileCompleteOutlined className="ben-my-profile-multiple-owners-icon" />
            </div>
          ) : (
            <div onClick={ () => handleEdit(document.name) }>
              <FileUploadOutlined className="ben-my-profile-multiple-owners-icon" />
            </div>
          )}
          <div
            className={ `${
              document.isCompleted
                ? "ben-my-profile-multiple-owners-completed"
                : "ben-my-profile-multiple-owners-pending"
            }` }
          >
            {document.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Documents;
