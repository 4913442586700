import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as MenuIcon } from "common/assets/images/icons/Menu-icon.svg";

interface IProps extends Partial<CustomIconComponentProps> {
  onClick: () => void;
}

const Menu: FC<IProps> = (props) => (
  <Icon { ...props } className={ props?.className + " ben-icon-primary-color" } component={ MenuIcon } />
);

export default Menu;
