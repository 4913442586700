import { useEffect } from "react";

import { Navigate, Outlet, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { URLs } from "common/lib/constants";
import { getIsAuth } from "domains/authentication/store/auth.selectors";
import { setNavigateToAfterLogin } from "common/shared-store/bootstrap.reducer";
import NotFoundPage from "pages/NotFoundPage";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";

const ProtectedRoutes = () => {
  const isAuth = useSelector(getIsAuth);
  const location = useLocation();
  const dispatch = useDispatch();
  const [ searchParams ] = useSearchParams();
  const { LRId } = useParams();

  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { sendLogs } = useTrustbenLogs();

  const trustbenSessionId = searchParams.get("session_id");

  useEffect(() => {
    const isAltQuotePage =
      location.pathname.includes(MyApplicationsPages.ALTQUOTE) &&
      location.pathname.includes(URLs.PROTECTED.MY_APPLICATIONS);

    if (!trustbenSessionId || !LRId || !isAltQuotePage) return;

    sendLogs({
      what: "email_link_clicked",
      details: `email_link_clicked:${ LRId }`,
      what_id: trustbenSessionId,
      display_id: trustbenSessionId,
    });
  }, []);

  if (isTrustbenAltQuote) {
    return <NotFoundPage />;
  }

  if (!isAuth) {
    dispatch(setNavigateToAfterLogin(location));
    return <Navigate to={ URLs.AUTH.SIGN_IN } replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
