import { createSlice } from "@reduxjs/toolkit";

import { IIdentity } from "domains/identity/shared/types";
import { identityApiReducer } from "./identity.api.reducer";

interface IInitialState {
  identity: IIdentity | null;
  token: string | null;
}

const initialState: IInitialState = {
  identity: null,
  token: null,
};

const identityReducer = createSlice({
  name: "[Identity]",
  initialState,
  reducers: {
    resetIdentityReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(identityApiReducer.endpoints.fetchIdentity.matchFulfilled, (state, action) => ({
      ...state,
      identity: action.payload,
    })),
      builder.addMatcher(identityApiReducer.endpoints.getSharing.matchFulfilled, (state, action) => ({
        ...state,
        token: action.payload,
      }));
  },
});

export const { resetIdentityReducer } = identityReducer.actions;

export default identityReducer.reducer;
