import { InputNumber as AntdInputNumber, InputNumberProps as AntdInputNumberProps } from "antd";
import { useField } from "formik";

const InputNumber = ({
  name,
  // eslint-disable-next-line no-unused-vars
  value: $value,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: AntdInputNumberProps & { name: string }) => {
  const [ { value, onBlur }, _meta, { setValue } ] = useField(name);

  return (
    <AntdInputNumber
      name={ name }
      id={ name }
      value={ value }
      onChange={ (value) => {
        setValue(value);
        $onChange && $onChange(value);
      } }
      onBlur={ (event) => {
        onBlur(event);
        $onBlur && $onBlur(event);
      } }
      { ...restProps }
    />
  );
};

export default InputNumber;
