import { FC, useCallback, useState, MouseEvent } from "react";

import { Collapse, CollapseProps } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useScreen } from "common/shared-hooks";
import { URLs } from "common/lib/constants";
import { setCurrentAltQuote } from "domains/altQuotes/store/altQuotes.reducer";
import { IUserDetails, RelationshipTypes, UserType } from "domains/clients/shared/types";
import { ILiquidityRequest, LiquidityRequestTypes } from "domains/liquidity/shared/types";
import { CollapseTitle } from "domains/dashboard";
import { RequestFormalProposalModal } from "domains/altQuotes/components";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import MyClientsAltQuotesPanelButton from "domains/dashboard/components/MyClientsAltQuotesPanelButton";
import { ActionButtons } from "domains/dashboard/components/MyClientsAltQuotesPanelButton/constants";
import { Spinner, Button } from "common/lib/components";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { FeatureNames, useFeature } from "domains/features";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { ApplyForLiquidityModal } from "domains/dashboard/components";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { altQuotesApiReducer } from "domains/altQuotes/store/altQuotes.api.reducer";

import "./MyAltQuotesPanel.scss";

const { Panel } = Collapse;

interface IProps {
  altQuotes: ILiquidityRequest[];
  user: IUserDetails | null;
  isClientPage?: boolean;
  openModal: (e: MouseEvent) => void;
}

const MyAltQuotesPanel: FC<CollapseProps & IProps> = ({
  altQuotes,
  user,
  openModal,
  isClientPage = false,
  ...props
}) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mobile } = useScreen();

  const { isFeatureEnabled } = useFeature(FeatureNames.EmbeddedAltQuote, Object.values(UserType));
  const { isAdvisor } = useClientsSelector();
  const [ fetchLRAssets ] = liquidityApiReducer.useLazyFetchLRAssetsQuery();
  const [ fetchAltQuoteOffers ] = altQuotesApiReducer.useLazyFetchAltQuoteOffersQuery();

  const [ areAllVisible, setAreAllVisible ] = useState(false);
  const [ isConvertingAdvisorAltQuoteWithoutClient, setIsAdvisorWithoutClients ] = useState(false);
  const [ isRequestFormalProposalModalVisible, setIsRequestFormalProposalModalVisible ] = useState(false);
  const [ isApplyForLiquidityModalVisible, setIsApplyForLiquidityModalVisible ] = useState(false);

  const onRequestFormalProposal = (altQuote: ILiquidityRequest) => {
    const client = altQuote.accounts.find(
      (account) => account.is_active && account.relationship_type === RelationshipTypes.Client,
    );
    if (isAdvisor && !client) {
      setIsApplyForLiquidityModalVisible(true);
      setIsAdvisorWithoutClients(true);
      dispatch(setCurrentAltQuote(altQuote));
      return;
    }

    fetchLRAssets(altQuote.id);
    setIsRequestFormalProposalModalVisible(true);
    sessionStorage.setItem("LIQUIDITY_REQUEST_ID", altQuote.id);
  };

  const openRequestFormalProposalModal = useCallback((liquidityRequest: ILiquidityRequest) => {
    setIsRequestFormalProposalModalVisible(true);
    fetchLRAssets(liquidityRequest.id);
    sessionStorage.setItem("LIQUIDITY_REQUEST_ID", liquidityRequest.id);
  }, []);

  const handleRequestFormalProposalModalCancel = useCallback(() => {
    setIsRequestFormalProposalModalVisible(false);
  }, []);

  const handleApplyForLiquidityModalCancel = useCallback(() => {
    setIsApplyForLiquidityModalVisible(false);
  }, []);

  const onAltQuoteClick = (LRId: string) => {
    fetchAltQuoteOffers(LRId);
    navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.ALTQUOTE);
  };

  const getButtonType = (date: string) =>
    new Date().getTime() - 30 * 24 * 60 * 60 * 1000 > new Date(date).getTime()
      ? ActionButtons.RequestReactivationButton
      : ActionButtons.StartAnApplicationButton;

  if (
    !user?.user_alt_quote_switch &&
    !altQuotes?.find((lr) => lr.liquidity_request_type === LiquidityRequestTypes.AltQuote)
  ) {
    return null;
  }

  const collapseTitle = isClientPage
    ? t("My Clients AltQuotes")
    : user?.user_type === UserType.Advisor
      ? t("Active AltQuotes")
      : t("My AltQuotes");

  const collapseButton = isFeatureEnabled ? (
    <Button type="text" onClick={ openModal }>
      {t("New AltQuote +")}
    </Button>
  ) : null;

  return (
    <>
      <Panel
        { ...props }
        header={ <CollapseTitle title={ collapseTitle } count={ altQuotes.length } button={ collapseButton } /> }
        key="My AltQuotes"
      >
        <Spinner spinning={ !user || !altQuotes }>
          <div className="ben-alt-quotes-content">
            {mobile && (
              <Button type="text" onClick={ openModal }>
                {t("New AltQuote +")}
              </Button>
            )}
            {areAllVisible ? (
              <div>
                {altQuotes.map((altQuote) => (
                  <div
                    className={ isClientPage ? "ben-alt-quote-item ben-border-top ben-pt-1" : "ben-alt-quote-item" }
                    key={ altQuote.display_id }
                  >
                    <div onClick={ () => onAltQuoteClick(altQuote.id) }>
                      <p className="ben-alt-quote-name">
                        {isClientPage
                          ? `${ altQuote.client.account_name }: ${ altQuote.liquidity_request_name }`
                          : altQuote.display_id}
                      </p>
                      <p className="ben-date-initiated">{`${ t("Initiated") } ${ getDateFromISO(altQuote.created_at) }`}</p>
                      {!isClientPage && user?.user_type === UserType.Advisor && (
                        <p>{t("By") + " " + altQuote.created_by_name}</p>
                      )}
                    </div>
                    {isClientPage ? (
                      <MyClientsAltQuotesPanelButton
                        buttonType={ getButtonType(altQuote.created_at) }
                        viewAltQuote={ () => onAltQuoteClick(altQuote.id) }
                        startAnApplication={ () => onRequestFormalProposal(altQuote) }
                        requestReactivation={ () => console.log("Request Reactivation") }
                      />
                    ) : (
                      <Button
                        className="ben-request-formal-proposal-btn"
                        onClick={ () => onRequestFormalProposal(altQuote) }
                      >
                        {t("Request Formal Proposal")}
                      </Button>
                    )}
                  </div>
                ))}
                {altQuotes.length > 1 && (
                  <Button onClick={ () => setAreAllVisible(!areAllVisible) } type="text">
                    {`${ t("Hide") }`}
                  </Button>
                )}
              </div>
            ) : (
              altQuotes.length > 0 && (
                <>
                  <div className={ isClientPage ? "ben-alt-quote-item ben-border-top ben-pt-1" : "ben-alt-quote-item" }>
                    <div onClick={ () => onAltQuoteClick(altQuotes[ 0 ].id) }>
                      <p className="ben-alt-quote-name">
                        {isClientPage
                          ? `${ altQuotes[ 0 ].client.account_name }: ${ altQuotes[ 0 ].liquidity_request_name }`
                          : altQuotes[ 0 ].display_id}
                      </p>
                      <p className="ben-date-initiated">
                        {`${ t("Initiated") } ${ getDateFromISO(altQuotes[ 0 ].created_at) }`}
                      </p>
                      {!isClientPage && user?.user_type === UserType.Advisor && (
                        <p>{t("By") + " " + altQuotes[ 0 ].created_by_name}</p>
                      )}
                    </div>
                    {isClientPage ? (
                      <MyClientsAltQuotesPanelButton
                        buttonType={ getButtonType(altQuotes[ 0 ].created_at) }
                        viewAltQuote={ () => onAltQuoteClick(altQuotes[ 0 ].id) }
                        startAnApplication={ () => onRequestFormalProposal(altQuotes[ 0 ]) }
                        requestReactivation={ () => console.log("Request Reactivation") }
                      />
                    ) : (
                      <Button
                        className="ben-request-formal-proposal-btn"
                        onClick={ () => onRequestFormalProposal(altQuotes[ 0 ]) }
                      >
                        {t("Request Formal Proposal")}
                      </Button>
                    )}
                  </div>
                  {altQuotes.length > 1 && (
                    <Button onClick={ () => setAreAllVisible(!areAllVisible) } type="text">
                      {`${ t("View all") } ${ altQuotes.length } ${ t("Quotes") }`}
                    </Button>
                  )}
                </>
              )
            )}
          </div>
        </Spinner>
      </Panel>
      <RequestFormalProposalModal
        isVisible={ isRequestFormalProposalModalVisible }
        handleCancel={ handleRequestFormalProposalModalCancel }
      />
      <ApplyForLiquidityModal
        isVisible={ isApplyForLiquidityModalVisible }
        handleCancel={ handleApplyForLiquidityModalCancel }
        isLA={ true }
        isConvertingAdvisorAltQuoteWithoutClient={ isConvertingAdvisorAltQuoteWithoutClient }
        openRequestFormalProposalModal={ openRequestFormalProposalModal }
      />
    </>
  );
};

export default MyAltQuotesPanel;
