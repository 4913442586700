import { ChangeEvent, FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { Form, Input, Select } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { Caret, CircleX, Button } from "common/lib/components";
import { useScreen } from "common/shared-hooks";
import { IClientFormInfo } from "domains/myProfile/shared/types";

import "./SelectOwner.scss";

const { Option } = Select;

const anotherOwner = "Add Another Owner...";

interface IProps {
  inputName: string;
  selectName: string;
  typeName: string;
  assetName: string;
  isInputName: string;
  index: number;
  isInputError: boolean;
  previouslyOwners: string[];
  addOwner: (name: string, assetName: string, entityType: string) => void;
  setFieldValue: any;
  setIsEdit: (value: boolean) => void;
  values: { assets: IClientFormInfo[] };
}

const SelectOwner: FC<IProps> = ({
  inputName,
  selectName,
  typeName,
  assetName,
  isInputName,
  index,
  isInputError,
  previouslyOwners,
  setFieldValue,
  addOwner,
  setIsEdit,
  values,
}) => {
  const { mobile } = useScreen();
  const [ inputValue, setInputValue ] = useState("");
  const [ selectValue, setSelectValue ] = useState("");
  const { t } = useTranslation([ "common" ]);

  const resetStates = () => {
    setIsEdit(false);
    setInputValue("");
    setFieldValue(isInputName, false);
  };

  const handleChangeSelect = (value: string) => {
    if (value === anotherOwner) {
      setFieldValue(selectName, "");
      setFieldValue(isInputName, true);
      setIsEdit(true);
    } else {
      setSelectValue(value);
      const asset = values.assets.find((asset) => asset.owner === value);
      if (asset !== undefined) setFieldValue(typeName, asset.type);
    }
  };

  const handleClickIcon = () => {
    const asset = values.assets.find((asset) => asset.owner === selectValue);
    if (asset !== undefined) setFieldValue(typeName, asset.type);
    setFieldValue(selectName, selectValue);
    resetStates();
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClickButton = () => {
    addOwner(inputValue, assetName, values.assets[ index ].type);
    setFieldValue(selectName, inputValue);
    resetStates();
  };

  return (
    <>
      {values.assets[ index ].isInput ? (
        <Form.Item className="ben-input" name={ inputName } label={ mobile ? "Owner" : "" }>
          <Input
            maxLength={ maxLengthText }
            name={ inputName }
            className="ben-small ben-main-my-profile-page-input"
            placeholder={ anotherOwner }
            value={ inputValue }
            onChange={ handleChangeInput }
            suffix={
              <>
                <Button onClick={ handleClickButton } disabled={ isInputError || !inputValue }>
                  ADD
                </Button>
                <div className="ben-main-my-profile-page-input-icon">
                  <CircleX onClick={ handleClickIcon } />
                </div>
              </>
            }
          />
        </Form.Item>
      ) : (
        <Form.Item className="ben-input" name={ selectName } label={ mobile ? "Owner" : "" }>
          <Select
            name={ selectName }
            suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
            placeholder={ t("Select One") }
            onChange={ handleChangeSelect }
          >
            {previouslyOwners!.map((owner) => (
              <Option key={ owner } name={ selectName } value={ owner }>
                {owner}
              </Option>
            ))}
            <Option name={ selectName } value={ anotherOwner }>
              {anotherOwner}
            </Option>
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default SelectOwner;
