export const LAPages = {
  SelectAssets: "select-assets",
  AddManually: "add-manually",
  FundDetails: "fund-details",
  InvestmentDetails: "investment-details",
  ReviewAssets: "review-assets",
  ConfirmApplication: "confirm-application",
  CustodyAccount: "custody-account",
  CustodyAccountId: "custody-account-id",
  CustodyAccountAgreement: "custody_account_agreement",
  AssetDocuments: "asset-documents",
  ConfirmAssetDocuments: "confirm-documents",
};

export enum AssetType {
  PreqinFund = 0,
  Fund = 1,
  CoInvestment = 2,
  DirectRealEstateInvestment = 3,
  PrivateSharesInSinglePrivatelyHeldCompany = 4,
  DirectInterestsInNaturalResourcesAsset = 5,
  NonTradedREIT = 6,
  NonTradedBDC = 7,
}

export enum RealEstatePropertyType {
  Retail = 0,
  Commercial = 1,
  SingleFamilyResidential = 2,
  MultiFamilyResidential = 3,
  Industrial = 4,
  UndevelopedLand = 5,
  Other = 6,
}

export enum LocationRegion {
  NorthAmerica = 0,
  EuropeWest = 1,
  EuropeEast = 2,
  AsiaDeveloped = 3,
  AsiaEmerging = 4,
  CentralAndSouthAmerica = 5,
  GeneralOrUnknown = 6,
  NorthAmericaNonUS = 7,
}

export interface ICustomerInformation {
  account_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  custody_agreement: boolean;
  date_of_birth: string | null;
  email_address: string;
  first_name: string;
  last_name: string;
  ssn: string;
  state: string;
  telephone_number: string;
  tin: number;
  zip_code: string;
}
