import { AxiosError } from "axios";

import { apiService } from "common/shared";
import { rootApiReducer } from "store/root.api.reducer";
import { IDocumentUrlData } from "domains/documents/shared/types";
import documentApiService from "domains/documents/shared/document.api.service";
import myApplicationsApiService from "domains/myApplications/shared/myApplications.api.service";
import { ITask } from "domains/myApplications/shared/types";

export const myApplicationsApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "allWorkflowTasks" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchAllWorkflowTasks: build.query<ITask[], string>({
        queryFn: async (LRId) => {
          try {
            const data = await myApplicationsApiService.fetchAllWorkflowTasks(LRId);

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: [ { type: "allWorkflowTasks", id: "LIST" } ],
      }),

      fetchBTSDocuments: build.query<IDocumentUrlData[], { documentsIds: string[]; lrAccountId: string }>({
        queryFn: async ({ documentsIds, lrAccountId }) => {
          try {
            const docs = await Promise.all(
              documentsIds.map((doc) => documentApiService.getDownloadUrl(lrAccountId, doc)),
            );

            return { data: docs };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      downloadBTSDocument: build.mutation<null, { btsDocument: IDocumentUrlData; lrAccountId: string }>({
        queryFn: async ({ btsDocument, lrAccountId }) => {
          try {
            const downloadUrl = (await documentApiService.getDownloadUrl(lrAccountId, btsDocument.document_id)).url;

            const link = document.createElement("a");
            link.download = "proposed_file_name";
            link.href = downloadUrl;
            link.click();

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
