import { useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { LAPages } from "domains/liquidity/shared/constants";
import { URLs } from "common/lib/constants";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { resetCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";

export const useHandlers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { LRId } = useParams();

  const [ deleteLRAsset ] = liquidityApiReducer.useDeleteLRAssetMutation({
    fixedCacheKey: "delete-lr-asset",
  });

  const onDelete = useCallback((assetId: string, LRId: string) => {
    deleteLRAsset({ assetId, LRId });
  }, []);

  const addFund = useCallback(() => {
    dispatch(resetCurrentAssetData());
    navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.SelectAssets);
  }, [ LRId ]);

  return {
    onDelete,
    addFund,
  };
};
