import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as FileComplete } from "common/assets/images/icons/File - Complete.svg";

const FileCompleteOutlined: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon { ...props } component={ FileComplete } />
);

export default FileCompleteOutlined;
