import { forwardRef } from "react";

import { useField } from "formik";
import { TextAreaProps as AntdTextAreaProps } from "antd/lib/input";
import { Input as AntdInput, InputRef as AntdInputRef } from "antd";

const InputTextArea = forwardRef<AntdInputRef, AntdTextAreaProps & { name: string }>(
  (
    {
      name,
      // eslint-disable-next-line no-unused-vars
      value: $value,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref,
  ) => {
    const [ { value, onChange, onBlur } ] = useField(name);

    return (
      <AntdInput.TextArea
        ref={ ref }
        name={ name }
        id={ name }
        value={ value }
        onChange={ (event) => {
          onChange(event);
          $onChange && $onChange(event);
        } }
        onBlur={ (event) => {
          onBlur(event);
          $onBlur && $onBlur(event);
        } }
        { ...restProps }
      />
    );
  },
);

InputTextArea.displayName = "InputTextArea";

export default InputTextArea;
