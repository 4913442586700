import { useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { setCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity/shared/constants";
import { IFundAsset } from "domains/liquidity/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useAppDispatch } from "store/store.hooks";

export const useHandlers = (isAssetEditing: boolean = false) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { LRId } = useParams();

  const [ createLRAsset ] = liquidityApiReducer.useCreateLRAssetMutation({
    fixedCacheKey: "create-lr-asset",
  });
  const [ updateLRAsset ] = liquidityApiReducer.useUpdateLRAssetMutation({
    fixedCacheKey: "update-lr-asset",
  });
  const { isLA, isAddManually } = useLiquiditySelector();

  const onSubmit = useCallback(
    (values: Partial<IFundAsset>) => {
      if (values.unfunded_commitment_type === "$") {
        values.unfunded_commitment_percentage = 0;
      } else {
        values.unfunded_commitment_currency = 0;
      }
      dispatch(setCurrentAssetData(values));
      isAssetEditing
        ? LRId && updateLRAsset(LRId)
        : LRId && createLRAsset(LRId);
      navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ReviewAssets, { replace: true });
    },
    [ LRId, isAssetEditing ],
  );

  const onBack = useCallback(() => {
    isAddManually || isLA
      ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.FundDetails, { replace: true })
      : navigate(-1);
  }, [ LRId, isAddManually, isLA ]);

  return {
    onSubmit,
    onBack,
  };
};
