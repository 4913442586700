import { useCallback, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { message } from "antd";

import { AccountType } from "common/lib/constants";
import { IUserDetails } from "domains/clients/shared/types";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { formatId } from "common/shared/helpers";
import { isEmptyObject, omit, pick } from "common/shared/utils/object.utils";
import { userDetailsFields, advisorDetailsFields } from "./constants";
import { getCustodyAccountTypeByUserType } from "./helpers";
import { apiEnvs } from "common/shared";
import { CustodyAccountStatus, ICustodyAccount } from "domains/custodyAccounts/shared/types";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { formatCustodyAccountDataToUpdate } from "domains/custodyAccounts/shared/apiFormatter";
import { formatAccountDataToUpdate } from "domains/clients/shared/utils/apiFormatter";
import { IErrorResponse, IUpdateAPIObject } from "common/shared/types";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";

export const useHelpers = () => {
  const dispatch = useDispatch();
  const { LRId } = useParams();

  const { user, currentClient, isAdvisor } = useClientsSelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const [ updateAccountLevelDetails ] = clientsApiReducer.useUpdateAccountLevelDetailsMutation({
    fixedCacheKey: "update-custody-account-level-details-agreement-form",
  });
  const [ updateUserLevelDetails ] = clientsApiReducer.useUpdateUserLevelDetailsMutation({
    fixedCacheKey: "update-custody-user-level-details-agreement-form",
  });
  const [ createCustodyAccountRequest ] = custodyAccountsApiReducer.useCreateCustodyAccountMutation({
    fixedCacheKey: "create-custody-account-agreement-form",
  });

  const [ emailAddressInvestOps, setEmailAddressInvestOps ] = useState("");

  const custodyAccountType = getCustodyAccountTypeByUserType(user, currentCustodyAccount, currentClient);
  const isAdvisorCustodyAccount =
    (currentClient?.is_anonymous ||
      currentCustodyAccount?.account_type === AccountType.CustodialAdvisor ||
      currentCustodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
    false;

  const getCustodyAccountDetailsToCreate = useCallback(
    (formValues: any) => {
      const parentAccountId =
        (currentCustodyAccount?.account_id && formatId("ACC-", currentCustodyAccount.account_id)) ||
        currentCustodyAccount?.parent_account_id;
      const isCustodyAccountClosed = currentCustodyAccount?.account_status === CustodyAccountStatus.Closed;

      return {
        ...formValues,
        owner_type: isAdvisor && isAdvisorCustodyAccount ? AccountType.CustodialAdvisor : formValues.owner_type,
        parent_account_id: isCustodyAccountClosed ? null : parentAccountId,
        is_switch_account_for_liquidity_request: parentAccountId && LRId ? false : true,
      };
    },
    [ currentCustodyAccount, LRId, isAdvisor, isAdvisorCustodyAccount ],
  );

  const createCustodyAccount = useCallback(
    (formValues: any) => {
      const custodyAccountDetails = getCustodyAccountDetailsToCreate(formValues);

      createCustodyAccountRequest({ accountDetails: custodyAccountDetails, LRId });
    },
    [ createCustodyAccountRequest, getCustodyAccountDetailsToCreate, LRId ],
  );

  const updateCustodyAccount = useCallback(
    (changes: any) => {
      if (
        custodyAccountType === AccountType.CustodialInstitution ||
        custodyAccountType === AccountType.CustodialAdvisor
      ) {
        const userDetailsToUpdate = pick(changes, ...userDetailsFields) || {};

        const accountDetailsToUpdate = omit(changes, ...userDetailsFields, ...advisorDetailsFields);

        if (!isEmptyObject(userDetailsToUpdate) && currentCustodyAccount) {
          const formattedChanges = formatCustodyAccountDataToUpdate(
            userDetailsToUpdate as any,
            custodyAccountType,
          ) as IUpdateAPIObject[];

          updateUserLevelDetails({
            accountId: currentCustodyAccount.account_id,
            userId: currentCustodyAccount.user_id,
            rawChanges: userDetailsToUpdate,
            formattedChanges,
          });
          dispatch(
            setCurrentCustodyAccount({
              ...currentCustodyAccount,
              ...userDetailsToUpdate,
            }),
          );
        }

        if (!isEmptyObject(accountDetailsToUpdate) && currentCustodyAccount) {
          const formattedChanges = formatCustodyAccountDataToUpdate(
            accountDetailsToUpdate,
            custodyAccountType,
          ) as IUpdateAPIObject[];

          updateAccountLevelDetails({
            accountId: currentCustodyAccount.account_id,
            rawChanges: accountDetailsToUpdate,
            formattedChanges,
          });
          dispatch(
            setCurrentCustodyAccount({
              ...currentCustodyAccount,
              ...accountDetailsToUpdate,
            }),
          );
        }
      } else {
        if (currentCustodyAccount) {
          const formattedChanges = formatCustodyAccountDataToUpdate(changes, custodyAccountType) as IUpdateAPIObject[];

          updateUserLevelDetails({
            accountId: currentCustodyAccount.account_id,
            userId: currentCustodyAccount.user_id,
            rawChanges: changes,
            formattedChanges,
          });
          dispatch(
            setCurrentCustodyAccount({
              ...currentCustodyAccount,
              ...changes,
            }),
          );
        }
      }
    },
    [ currentCustodyAccount, custodyAccountType ],
  );

  const showErrorMessageIfDuplicateAccount = useCallback(
    (error: IErrorResponse) => {
      let content;

      if (error.data.error_details?.error_code === "1002") {
        content = (
          <span>
            Please contact admin on <a href={ `mailto:${ emailAddressInvestOps }` }>{emailAddressInvestOps}</a> to continue.
          </span>
        );
      }

      if (error.data.error_details?.error_code === "1004") {
        content = (
          <span>
            Please contact admin at <a href={ `mailto:${ emailAddressInvestOps }` }>{emailAddressInvestOps}</a> to change
            this shared custody account.
          </span>
        );
      }

      content &&
        message.error({
          content,
          duration: 0,
          style: {
            marginTop: "7vh",
          },
        });
      content = null;
    },
    [ emailAddressInvestOps ],
  );

  const updateAdvisorDetails = useCallback(
    (changes: any) => {
      const advisorDetailsToUpdate = pick(changes, ...advisorDetailsFields) || {};

      if (!isEmptyObject(advisorDetailsToUpdate) && user) {
        const formattedChanges = formatAccountDataToUpdate(advisorDetailsToUpdate as unknown as IUserDetails);

        updateAccountLevelDetails({ accountId: user.account_id, rawChanges: advisorDetailsToUpdate, formattedChanges });
        dispatch(setCurrentCustodyAccount({ ...currentCustodyAccount, ...advisorDetailsToUpdate } as ICustodyAccount));
      }
    },
    [ currentCustodyAccount ],
  );

  useEffect(() => {
    (async () => {
      const { emailAddressInvestOps } = await apiEnvs.getApiEnv();
      setEmailAddressInvestOps(emailAddressInvestOps);
    })();
  }, []);

  return {
    createCustodyAccount,
    updateCustodyAccount,
    showErrorMessageIfDuplicateAccount,
    updateAdvisorDetails,
  };
};
