import { useCallback } from "react";

import { ILogs } from "domains/trustben/shared/types";
import { trustbenSessionService } from "domains/trustben";
import { trustbenApiReducer } from "domains/trustben/store/trustben.api.reducer";

const useTrustbenLogs = () => {
  const [ sendLogsRequest ] = trustbenApiReducer.useSendLogsMutation();

  const sendLogs = useCallback((logsData: Partial<ILogs> & Required<Pick<ILogs, "what" | "details">>) => {
    const baseLogs: Omit<ILogs, "what" | "details"> = {
      where: "TrustBen BAA",
      what_id: trustbenSessionService.getTrustbenSessionId(),
      display_id: trustbenSessionService.getTrustbenSessionId(),
      type: "TrustBen BAA Logging",
      who: "TrustBen Internal",
      source: "TrustBen BAA",
      successful: true,
      impersonated: true,
      comment: null,
      is_test_data: false,
    };
    const logs = { ...baseLogs, ...logsData };

    sendLogsRequest(logs);
  }, []);

  return { sendLogs };
};

export default useTrustbenLogs;
