import { useTranslation } from "react-i18next";

export const useValidationErrorMessages = () => {
  const { t } = useTranslation("common");

  // COMMON
  const required = t("validation.required", { ns: "common" });

  // FOR STRING
  const email = t("validation.email", { ns: "common" });
  const websiteURL = t("validation.websiteURL", { ns: "common" });
  const phoneUS = t("validation.phoneUS", { ns: "common" });
  const invalidCharacters = t("validation.invalidCharacters", { ns: "common" });
  const noSpaces = t("validation.noSpaces", { ns: "common" });
  const numberOfCharacters = (value: number) =>
    t("validation.numberOfCharacters", {
      ns: "common",
      value,
    });
  const maxNumberOfCharacters = (value: number) =>
    t("validation.maxNumberOfCharacters", {
      ns: "common",
      value,
    });
  const minNumberOfCharacters = (value: number) =>
    t("validation.minNumberOfCharacters", {
      ns: "common",
      value,
    });
  const noSpacesInStartAndEnd = t("validation.noSpacesInStartAndEnd", { ns: "common" });
  const noPOBoxMessage = t("validation.noPOBoxMessage", { ns: "common" });
  const accountNumbersMustMatch = t("validation.accountNumbersMustMatch", { ns: "common" });

  // FOR NUMBER
  const positive = t("validation.positive", { ns: "common" });
  const integer = t("validation.integer", { ns: "common" });
  const maxNum = (value: number) => t("validation.maxNum", { ns: "common", value });
  const minNum = (value: number) => t("validation.minNum", { ns: "common", value });
  const numOfDigits = (value: number) =>
    t("validation.numOfDigits", {
      ns: "common",
      value,
    });
  const maxNumOfDigits = (value: number) =>
    t("validation.maxNumOfDigits", {
      ns: "common",
      value,
    });
  const float = (value: number) => t("validation.float", { ns: "common", value });

  // FOR PASSWORD
  const minPassword = (value: number) =>
    t("validation.minPassword", {
      ns: "common",
      value,
    });
  const maxPassword = (value: number) =>
    t("validation.maxPassword", {
      ns: "common",
      value,
    });
  const passwordsMustMatch = t("validation.passwordsMustMatch", { ns: "common" });
  const passwordSpecialCharacters = t("validation.passwordSpecialCharacters", { ns: "common" });
  const onlyDigits = t("validation.onlyDigits", { ns: "common" });
  const ssn = t("validation.ssn", { ns: "common" });

  // FOR DATE
  const futureDate = t("validation.futureDate", { ns: "common" });
  const dateHasExpired = t("validation.dateHasExpired", { ns: "common" });
  const invalidDateFormat = t("validation.invalidDateFormat", { ns: "common" });
  const maxBirthDateErrorMessage = t("validation.maxBirthDate", { ns: "common" });

  // FOR AGREEMENT
  const agreementMustBeAccepted = (agreement: string) =>
    t("validation.agreementMustBeAccepted", {
      ns: "common",
      agreement,
    });

  return {
    required,
    email,
    websiteURL,
    phoneUS,
    invalidCharacters,
    noSpaces,
    numberOfCharacters,
    maxNumberOfCharacters,
    minNumberOfCharacters,
    noSpacesInStartAndEnd,
    noPOBoxMessage,
    positive,
    integer,
    maxNum,
    minNum,
    numOfDigits,
    maxNumOfDigits,
    float,
    minPassword,
    maxPassword,
    passwordsMustMatch,
    accountNumbersMustMatch,
    passwordSpecialCharacters,
    onlyDigits,
    ssn,
    futureDate,
    dateHasExpired,
    invalidDateFormat,
    maxBirthDateErrorMessage,
    agreementMustBeAccepted,
  };
};
