import { useEffect, useState } from "react";

import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pdf } from "@react-pdf/renderer";

import { setIsAuth } from "domains/authentication/store/auth.reducer";
import { URLs } from "common/lib/constants";
import { idUtils, sessionService } from "common/shared";
import { UserType } from "domains/clients/shared/types";
import { useIdentitySelector } from "domains/identity/store/identity.selectors";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { getIsAuth } from "domains/authentication/store/auth.selectors";
import { useBootstrapSelector } from "common/shared-store/bootstrap.selectors";
import { setNavigateToAfterLogin } from "common/shared-store/bootstrap.reducer";
import ConfidentialityAgreementPDF from "domains/authentication/components/ConfidentialityAgreementPDF";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { IDocument } from "domains/documents/shared/types";
import { useTrustbenSelector } from "domains/trustben";
import { featuresApiReducer } from "domains/features/store/features.api.reducer";
import { identityApiReducer } from "domains/identity/store/identity.api.reducer";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { IErrorResponse } from "common/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";
import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { FeatureNames, useFeature } from "domains/features";

const useInit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isFeatureActive } = useFeature(FeatureNames.AltCustody, []);
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const { navigateToAfterLogin } = useBootstrapSelector();
  const isAuth = useSelector(getIsAuth);
  const { user, isAdvisor } = useClientsSelector();
  const { identity } = useIdentitySelector();
  const [ fetchUserClients ] = clientsApiReducer.useLazyFetchUserClientsQuery();
  const [ fetchDocumentsByAccountId, { data: documents } ] = documentsApiReducer.useLazyFetchDocumentsByIdQuery();
  const [ fetchFeatures ] = featuresApiReducer.useLazyFetchFeaturesQuery();
  const [ fetchIdentity ] = identityApiReducer.useLazyFetchIdentityQuery();
  const [ fetchInvestments ] = altCustodyApiReducer.useLazyFetchInvestmentsQuery();
  const [ fetchUserLevelDetails, { isError, error } ] = clientsApiReducer.useLazyFetchUserLevelDetailsQuery();
  const [ fetchAccountLevelDetailsById ] = clientsApiReducer.useLazyFetchAccountLevelDetailsByIdQuery();
  const [ uploadDocument ] = documentsApiReducer.useUploadDocumentMutation();
  const [ fetchAllAssets ] = liquidityApiReducer.useLazyFetchAllAssetsQuery();
  const [ fetchLiquidityRequests ] = liquidityApiReducer.useLazyFetchLiquidityRequestsQuery();
  const [ logout ] = authApiReducer.useLogoutMutation();

  const [ isLoaderDisplayed, setIsLoaderDisplayed ] = useState(true);
  const [ initialDocuments ] = useState<IDocument[] | undefined>(documents);

  const isUserLoggedIn = sessionService.isAuthenticated();
  const isAuthPage = Object.values(URLs.AUTH).some((url) => pathname.startsWith(url));

  useEffect(() => {
    (async () => {
      if (sessionService.start()) {
        dispatch(setIsAuth(true));
      }
      setIsLoaderDisplayed(false);
    })();
  }, []);

  useEffect(() => {
    if (isAuth) {
      fetchIdentity();
    }
  }, [ isAuth ]);

  useEffect(() => {
    if (!isAuth) return;

    fetchFeatures();
  }, [ isAuth ]);

  useEffect(() => {
    if (identity && isAuth) {
      fetchUserLevelDetails({ accountId: identity.account_id, userId: identity.user_id, type: "user" });
    }
  }, [ identity?.email, isAuth ]);

  useEffect(() => {
    if (!isError) return;

    if ((error as IErrorResponse).status === 403) {
      logout();
      navigate(URLs.AUTH.SIGN_IN);
    }
  }, [ isError, error ]);

  useEffect(() => {
    if (user && isAuth) {
      fetchLiquidityRequests();
    }
  }, [ user?.user_id, isAuth ]);

  useEffect(() => {
    if (user && user.user_type !== UserType.Principal) {
      fetchAccountLevelDetailsById({ accountId: user.account_id, type: "user" });
    }
  }, [ user?.user_type ]);

  useEffect(() => {
    if (!isAuth || !user?.account_id || !isFeatureActive) return;

    if (!isAdvisor) {
      fetchInvestments(user.account_id);
    }
  }, [ isAuth, isAdvisor, user?.account_id, isFeatureActive ]);

  useEffect(() => {
    if (user && isAdvisor) {
      fetchUserClients({ accountId: user.account_id, userId: user.user_id });
    }
  }, [ user?.user_id, isAdvisor ]);

  useEffect(() => {
    if (liquidityRequests && isAuth) {
      fetchAllAssets();
    }
  }, [ !!liquidityRequests, isAuth ]);

  useEffect(() => {
    if (isAuth && user?.account_id) {
      fetchDocumentsByAccountId(user.account_id);
    }
  }, [ isAuth, user?.account_id ]);

  useEffect(() => {
    const sendConfidentialityAgreementIfNeeded = async () => {
      if (initialDocuments === documents) {
        return;
      }

      if (!user?.user_id || !documents || !identity?.email) {
        return;
      }

      if (documents.some(({ document_type }) => document_type === "confidentiality_agreement")) {
        return;
      }

      const blob = await pdf(
        <ConfidentialityAgreementPDF
          firstName={ user.first_name }
          lastName={ user.last_name }
          email={ identity.email }
          userId={ user.user_id }
        />,
      ).toBlob();

      const confidentialityAgreementDocument = {
        asset_id: null,
        file_name: `Confidentiality Agreement(${ getDateFromISO() }).pdf`.replace(/[^- _a-zA-Z0-9!.'()]/g, "-"),
        document_name: null,
        document_type: "confidentiality_agreement",
        reference_type: "account",
        file_size: blob.size,
        liquidity_request_id: null,
        investment_id: null,
        do_not_have_this_document: false,
        id: idUtils.uuidv4(),
        mimeType: blob.type,
      } as unknown as IDocument;

      uploadDocument({
        accountId: user.account_id,
        document: confidentialityAgreementDocument,
        file: blob,
        onUploadProgress: () => {},
      });
    };

    sendConfidentialityAgreementIfNeeded();
  }, [ user?.user_id, identity?.email, documents?.length, initialDocuments?.length ]);

  useEffect(() => {
    (() => {
      if (!isLoaderDisplayed) {
        if (isAuth && isAuthPage) {
          setIsLoaderDisplayed(true);
          if (navigateToAfterLogin) {
            navigateToAfterLogin.search
              ? navigate(
                  {
                    pathname: navigateToAfterLogin.pathname,
                    search: createSearchParams(navigateToAfterLogin.search).toString(),
                  },
                  { replace: true },
                )
              : navigate(navigateToAfterLogin, { state: navigateToAfterLogin.state, replace: true });
            dispatch(setNavigateToAfterLogin(null));
          } else {
            !isTrustbenAltQuote && navigate(URLs.PROTECTED.DASHBOARD);
          }
          setIsLoaderDisplayed(false);
        }
      }
    })();
  }, [ pathname, isLoaderDisplayed, isAuth ]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      setIsLoaderDisplayed(false);
    }
  }, [ isUserLoggedIn ]);

  return isLoaderDisplayed;
};

export default useInit;
