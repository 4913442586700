import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { setCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity/shared/constants";
import { IFundAsset } from "domains/liquidity/shared/types";
import { useTrustbenSelector } from "domains/trustben";
import { TrustbenPages } from "domains/trustben/shared/constants";

export const useHandlers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LRId } = useParams();

  const { isTrustbenAltQuote } = useTrustbenSelector();

  const onSubmit = useCallback(
    (values: Partial<IFundAsset>) => {
      dispatch(setCurrentAssetData(values));
      navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.InvestmentDetails, { replace: true });
    },
    [ LRId ],
  );

  const handleSubmitTrustben = useCallback(
    (values: Partial<IFundAsset>) => {
      dispatch(setCurrentAssetData(values));
      LRId
        ? navigate(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LRId }/${ LAPages.InvestmentDetails }`, {
            replace: true,
          })
        : navigate(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LAPages.InvestmentDetails }`, { replace: true });
    },
    [ LRId ],
  );

  return { onSubmit: isTrustbenAltQuote ? handleSubmitTrustben : onSubmit };
};
