import { AxiosError } from "axios";
import dayjs from "dayjs";

import { apiService } from "common/shared";
import { rootApiReducer } from "store/root.api.reducer";
import myProfileApiService from "domains/myProfile/shared/myProfile.api.service";
import {
  ClientFormStatuses,
  IBeneficialOwner,
  IBeneficialTrust,
  IClientForm,
  IClientFormInfo,
  IOwnerName,
  IOwnership,
  TClientFormValues,
} from "domains/myProfile/shared/types";
import { IAssetsIncluded } from "domains/myApplications/shared/types";
import { TAppState } from "store/root.store";
import {
  completeValuesFromSourceClientForm,
  completeValuesFromUserInfo,
  defineSourceClientForm,
  filterAssets,
  findClientForm,
} from "domains/myProfile/shared/helpers";
import { formatId } from "common/shared/helpers";
import {
  formatClientFormForCreate,
  formatClientFormToUpdate,
  formatClientFormsForReducer,
  formatOwnerToUpdate,
} from "domains/myProfile/shared/utils/apiFormatter";
import { ApiClientFormTypes, ClientFormTypes } from "domains/myProfile/shared/constants";
import { setClientFormValues } from "./myProfile.reducer";
import { dateFormat } from "common/shared/constants/validationConstants";

export const myProfileApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "ownerships" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchOwnerships: build.query<IOwnership[], string>({
        queryFn: async (LRId) => {
          try {
            const response = await myProfileApiService.getClientForms(LRId);

            return { data: formatClientFormsForReducer(response) };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: [ { type: "ownerships", id: "LIST" } ],
      }),

      createOwnership: build.mutation<
        null,
        { LRId: string; assets: IClientFormInfo[]; assetsIncluded: IAssetsIncluded[] }
      >({
        queryFn: async ({ LRId, assets, assetsIncluded }, { getState, dispatch }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const email = (getState() as TAppState).identityReducer.identity!.email;

            const filteredOwnerNames: IOwnerName[] = await Promise.all(
              filterAssets(assets).map(async (filteredAsset: IClientFormInfo) => {
                const data = {
                  account_name: filteredAsset.owner,
                  parent_account_id: user.account_id,
                  account_type: "owner",
                  owner_type: filteredAsset.type,
                  relationship_type: "Owner",
                  status: "on_boarded",
                  access_granted: true,
                  salesforce_account_id: null,
                  is_test_data: false,
                  email,
                  is_check_for_name_matches: null,
                };

                const ownerIDs = await myProfileApiService.createOwner(data);

                return {
                  ...ownerIDs,
                  name: filteredAsset.owner,
                  asset_name: filteredAsset.name,
                } as IOwnerName;
              }),
            );

            const requests = filteredOwnerNames.map((ownerName: IOwnerName) =>
              formatClientFormForCreate(assets.find((asset) => asset.owner === ownerName.name)!, LRId, ownerName),
            );

            await Promise.all(
              requests.map(async (request: any): Promise<void> => {
                await myProfileApiService.createOwnership(LRId, request);
              }),
            );

            const assetsWithCreatedOwners = assets.reduce((acc: IOwnerName[], asset) => {
              const owner = filteredOwnerNames.find((owner) => owner.name === asset.owner);
              acc.push({
                account_display_id: owner?.account_display_id,
                account_id: owner?.account_id,
                asset_name: asset.name,
                name: asset.owner,
              });
              return acc;
            }, []);

            await Promise.all(
              assetsWithCreatedOwners.map(async (owner: IOwnerName): Promise<void> => {
                const assetIdNumber = assetsIncluded.filter(
                  (asset: IAssetsIncluded) => asset.asset_name === owner.asset_name,
                )[ 0 ].asset_id;
                const assetId = formatId("AST-", assetIdNumber);
                const data = {
                  liquidity_request_id: LRId,
                  account_id: owner.account_id,
                  asset_id: assetId,
                  account_display_id: owner.account_display_id,
                  account_name: owner.name,
                  status: "in_portfolio",
                  is_active: true,
                };

                await myProfileApiService.createInvestment(data);
              }),
            );

            dispatch(myProfileApiReducer.endpoints.fetchOwnerships.initiate(LRId));

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: [ { type: "ownerships", id: "LIST" } ],
      }),

      submitClientForms: build.mutation<null, number>({
        queryFn: async (ownerId) => {
          try {
            const data = [ { op: "replace", path: "/form_status", value: ClientFormStatuses.Completed } ];
            await myProfileApiService.submitClientForms(ownerId, data);

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      completeClientForm: build.mutation<
        {
          currentClientForm: IClientForm;
          currentOwnershipName: string;
          isCompleted: boolean;
        },
        {
          currentClientForm: IClientForm;
          currentOwnershipName: string;
          isCompleted: boolean;
        }
      >({
        queryFn: async ({ currentOwnershipName, isCompleted }, { getState }) => {
          try {
            const { ownerships, currentClientForm } = (getState() as TAppState).myProfileReducer;
            const stateClientForm = findClientForm(ownerships, currentOwnershipName, currentClientForm.name);
            const { id, changes } = formatClientFormToUpdate({ ...currentClientForm }, stateClientForm!);

            if (!isCompleted) {
              await myProfileApiService.updateClientForm(
                id,
                [
                  {
                    op: "replace",
                    path: "/legal_name",
                    value: currentClientForm.values.legal_name,
                  },
                ],
                ApiClientFormTypes[ currentClientForm.name ],
              );

              return { data: { currentClientForm, currentOwnershipName, isCompleted } };
            }

            if (changes.length > 0) {
              await myProfileApiService.updateClientForm(
                id,
                isCompleted
                  ? [
                      ...changes,
                      {
                        op: "replace",
                        path: "/form_status",
                        value: ClientFormStatuses.Completed,
                      },
                    ]
                  : changes,
                ApiClientFormTypes[ currentClientForm.name ],
              );
            }

            return {
              data: {
                currentClientForm,
                currentOwnershipName,
                isCompleted: changes.length > 0 ? isCompleted : stateClientForm?.isCompleted || false,
              },
            };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      setCurrentClientForm: build.mutation<
        { values: TClientFormValues; valuesFromApi: TClientFormValues },
        {
          owner: IOwnership;
          clientFormName: string;
          source: string;
          fullSource: string;
        }
      >({
        queryFn: async ({ owner, clientFormName }, { getState, dispatch }) => {
          try {
            const currentClientForm = (getState() as TAppState).myProfileReducer.currentClientForm;
            const currentEntityType = (getState() as TAppState).myProfileReducer.currentEntityType;

            if (currentClientForm.isCompleted && JSON.stringify(currentClientForm.values) !== JSON.stringify({})) {
              return { data: { values: currentClientForm.values, valuesFromApi: currentClientForm.values } };
            }

            const { id } = currentClientForm;

            const sourceClientForm = defineSourceClientForm(clientFormName, owner.client_forms);
            let sourceClientFormValues = sourceClientForm?.values;

            let promise;
            if (!currentClientForm.isCompleted && sourceClientForm?.isCompleted) {
              promise = Promise.all([
                myProfileApiService.getClientForm(id, ApiClientFormTypes[ clientFormName ]),
                myProfileApiService.getClientForm(sourceClientForm.id, ApiClientFormTypes[ sourceClientForm.name ]),
              ]);
            } else {
              promise = Promise.all([ myProfileApiService.getClientForm(id, ApiClientFormTypes[ clientFormName ]) ]);
            }

            const response = await promise;

            let values = response[ 0 ];
            if (response.length === 2) {
              sourceClientFormValues = response[ 1 ];
            }

            if (clientFormName === ClientFormTypes.BeneficialOwnership) {
              values = {
                ...values,
                owners: values.owners.map((owner: IBeneficialOwner) => ({
                  ...owner,
                  is_loading: false,
                })),
                trusts: values.trusts.map((trust: IBeneficialTrust) => ({
                  ...trust,
                  is_loading: false,
                })),
              };
            }

            if (clientFormName === ClientFormTypes.CIP) {
              if (values?.date_entity_established) {
                values.date_entity_established = dayjs(values.date_entity_established.split(" ")[ 0 ]).format(dateFormat);
              }
            }

            const valuesFromApi = { ...values };

            const user = (getState() as TAppState).clientsReducer.user!;

            if (clientFormName === ClientFormTypes.BeneficialOwnership) {
              values = {
                ...values,
                legal_name: values.legal_name || values.owners.length > 0 ? values.owners[ 0 ].name : null,
              };
            }

            // @ts-ignore
            if (user.account_name === name) {
              completeValuesFromUserInfo(currentClientForm, values, user);
            } else if (!currentClientForm.isCompleted && sourceClientForm?.isCompleted && sourceClientFormValues) {
              completeValuesFromSourceClientForm(
                currentClientForm,
                values,
                sourceClientForm,
                sourceClientFormValues,
                currentEntityType,
              );

              dispatch(
                setClientFormValues({
                  ownershipName: owner.name,
                  clientFormName: sourceClientForm.name,
                  data: sourceClientFormValues,
                }),
              );
              return { data: { values, valuesFromApi } };
            }

            return { data: { values, valuesFromApi } };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      addBeneficialOwner: build.mutation<string, IBeneficialOwner>({
        queryFn: async (data) => {
          try {
            const response = await myProfileApiService.addOwner(data);

            return { data: response.id };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateBeneficialOwner: build.mutation<Partial<IBeneficialOwner>, Partial<IBeneficialOwner>>({
        queryFn: async (changes, { getState }) => {
          try {
            const stateOwner = (getState() as TAppState).myProfileReducer.currentClientForm.values.owners.find(
              (owner: IBeneficialOwner) => owner.id === changes.id,
            );
            const valuesToUpdate = formatOwnerToUpdate(changes, stateOwner);

            if (valuesToUpdate.length > 0) {
              await myProfileApiService.updateOwner(valuesToUpdate, changes.id!);
            }

            return { data: changes };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      deleteBeneficialOwner: build.mutation<string, string>({
        queryFn: async (ownerId) => {
          try {
            await myProfileApiService.deleteOwner(ownerId);

            return { data: ownerId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      addBeneficialTrust: build.mutation<string, IBeneficialTrust>({
        queryFn: async (data) => {
          try {
            const response = await myProfileApiService.addTrust(data);

            return { data: response.id };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateBeneficialTrust: build.mutation<Partial<IBeneficialTrust>, Partial<IBeneficialTrust>>({
        queryFn: async (changes, { getState }) => {
          try {
            const stateTrust = (getState() as TAppState).myProfileReducer.currentClientForm.values.trusts.find(
              (trust: IBeneficialTrust) => trust.id === changes.id,
            );
            const valuesToUpdate = formatOwnerToUpdate(changes, stateTrust);

            if (valuesToUpdate.length > 0) {
              await myProfileApiService.updateTrust(valuesToUpdate, changes.id!);
            }

            return { data: changes };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      deleteBeneficialTrust: build.mutation<string, string>({
        queryFn: async (trustId) => {
          try {
            await myProfileApiService.deleteTrust(trustId);

            return { data: trustId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
