import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { setCurrentAssetData, setIsAddManually } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { Button } from "common/lib/components";
import { LAPages } from "domains/liquidity/index";
import { sendGoogleAnalytics } from "domains/trustben/shared/helpers";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { AssetType } from "domains/liquidity/shared/constants";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { TrustbenPages, TBAltQuotePages } from "domains/trustben/shared/constants";

import "./AddManually.scss";

const AddManually = () => {
  const { t } = useTranslation([ "liquidity", "common" ]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { LRId } = useParams();

  const { currentAsset } = useLiquiditySelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { sendLogs } = useTrustbenLogs();

  if (isTrustbenAltQuote) {
    return (
      <Button
        onClick={ () => {
          const navigateTo = LRId
            ? `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LRId }${ TBAltQuotePages.InvestmentDetails }`
            : `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }${ TBAltQuotePages.InvestmentDetails }`;
          dispatch(setIsAddManually(true));
          dispatch(setCurrentAssetData({ asset_type: AssetType.Fund }));
          navigate(navigateTo, { replace: true });
          sendLogs({
            what: "asset_type",
            details: `asset_type:${ AssetType.Fund }:${ currentAsset.audit_logs_id }`,
          });
          sendGoogleAnalytics(location, "AltAccess – Assets to Exchange");
        } }
        style={ { display: "block", margin: "auto" } }
      >
        {t("My Fund Is Not Listed")}
      </Button>
    );
  }

  return (
    <div className="add-manually-container">
      <div>{t("My Fund Is Not Listed")}</div>
      <Button color="white" onClick={ () => navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.AddManually) }>
        {t("Add Manually", { ns: "common" })}
      </Button>
    </div>
  );
};

export default AddManually;
