import { FC, ReactElement } from "react";

import { Spinner } from "common/lib/components";

interface IProps {
  title: string | ReactElement;
}

const BenVerifyScreen: FC<IProps> = ({ title }) => {

  if (!title) {
    return null;
  }

  return (
    <Spinner>
      <section className="ben-main-section">
        <div className="ben-panel">
          <h1 className="ben-text-center">{ title }</h1>
        </div>
      </section>
    </Spinner>
  );
};

export default BenVerifyScreen;
