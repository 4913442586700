import { jwtDecode } from "jwt-decode";

export class TokenDecodeUtils {
  public decodeResetPassToken(token: string) {
    try {
      const decodedTokenData: any = jwtDecode(token);
      return {
        userEmail: decodedTokenData[ "custom:email" ],
        expirationTime: decodedTokenData?.exp,
      };
    } catch {
      return null;
    }
  }

  public decodeVerifyResetPasswordToken(token: string) {
    try {
      const decodedTokenData: any = jwtDecode(token);
      return {
        email: decodedTokenData[ "custom:email" ],
        isTokenExpired: this.isTokenExpired(token),
      };
    } catch {
      return null;
    }
  }

  public decodeVerifyIdentityToken(token: string) {
    try {
      const decodedTokenData: any = jwtDecode(token);
      return {
        email: decodedTokenData[ "custom:email" ],
        acceptedAgreementValue: decodedTokenData[ "custom:accepted_agreement" ],
        isTokenExpired: this.isTokenExpired(token),
      };
    } catch {
      return null;
    }
  }

  public decodeAuthToken(token: string): { clientId: string } | null {
    try {
      const decodedTokenData: any = jwtDecode(token);
      return {
        clientId: decodedTokenData.client_id,
      };
    } catch {
      return null;
    }
  }

  public isTokenExpired(token: string): boolean {
    const tokenData = this.decodeResetPassToken(token);
    return !tokenData || tokenData.expirationTime * 1000 < new Date().getTime();
  }
}

export default new TokenDecodeUtils();
