import { FC } from "react";

import { Select, InputNumberProps } from "antd";
import Flags, { FlagComponent } from "country-flag-icons/react/3x2";
import {
  CountryCode,
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { CaretDownOutlined } from "@ant-design/icons";
import { useFormikContext } from "formik";

import InputNumber from "common/lib/formik-antd/InputNumber";
import { filterPhoneCountries } from "./helpers";
import { countriesList } from "./constants";

import "./PhoneNumberInput.scss";

interface IProps extends InputNumberProps {
  name: string;
}

const PhoneNumberInput: FC<IProps> = ({ name, ...props }) => {
  const { t } = useTranslation("common");
  const { setFieldValue, values } = useFormikContext<any>();
  const countryCode: CountryCode = values?.phone_country || "US";

  const phoneNumberFormatter = (phone: any) => {
    const formattedPhone = formatIncompletePhoneNumber(phone, countryCode);
    const newPhoneValue = formattedPhone.startsWith("+")
      ? formattedPhone
      : `+${ getCountryCallingCode(countryCode) } ${ formattedPhone }`;

    return newPhoneValue;
  };

  const phoneNumberParser = (phone: any) => {
    try {
      const parsedPhone = parsePhoneNumber(phone, countryCode);
      if (!values?.country_code || !values?.phone_country) {
        setFieldValue("phone_country", countryCode, false);
        setFieldValue("country_code", getCountryCallingCode(countryCode), false);
      }

      return parsedPhone.nationalNumber;
    } catch (error) {
      return parseIncompletePhoneNumber(phone);
    }
  };

  const handleSelectCountry = (countryCode: CountryCode) => {
    const countryCallingCode = getCountryCallingCode(countryCode);
    setFieldValue("phone_country", countryCode, false);
    setFieldValue("country_code", countryCallingCode, false);
    setFieldValue(name, `+${ countryCallingCode }`);
  };

  return (
    <InputNumber
      { ...props }
      id={ name }
      name={ name }
      defaultValue={ `+${ getCountryCallingCode(countryCode) }` }
      className={ `ben-phone-input ${ props.className || "" }` }
      placeholder={ t("Telephone Number") }
      formatter={ phoneNumberFormatter }
      parser={ phoneNumberParser }
      step={ 0 }
      controls={ false }
      autoComplete="nope"
      addonBefore={
        <Select
          showSearch
          optionFilterProp="key"
          filterOption={ filterPhoneCountries }
          onSelect={ handleSelectCountry }
          suffixIcon={ <CaretDownOutlined /> }
          optionLabelProp="label"
          value={ countryCode }
          popupMatchSelectWidth={ false }
          placeholder={ <Flags.US className="ben-flag-icon" /> }
        >
          {countriesList.map((country) => {
            const Flag = Flags[ country.code as keyof typeof Flags ] as FlagComponent;

            return (
              <Select.Option value={ country.code } label={ <Flag className="ben-flag-icon" /> } key={ country.name }>
                {<Flag className="ben-flag-icon" />} {country.name} {`+${ country.callingCode }`}
              </Select.Option>
            );
          })}
        </Select>
      }
      prefix
    />
  );
};

export default PhoneNumberInput;
