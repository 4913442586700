import { FC, useEffect, useState } from "react";

import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { resetCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { FormHeader, Tooltip } from "common/lib/components";
import SelectFund from "./SelectFund";
import SelectOtherAssetForm from "./SelectOtherAsset";
import { TypesAssetsConsidered } from "domains/dashboard/components";
import { useQueryParams } from "common/shared-hooks";

import "./SelectAssetsPage.scss";

const SelectAssetsPage: FC = () => {
  const { t } = useTranslation("liquidity");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ isFundAssetsTab, setIsFundAssetsTab ] = useState(true);
  const params = useQueryParams();
  const tab = params.get("tab");

  const sections = [
    {
      key: "0",
      label: t("Select a Fund"),
      children: <SelectFund />,
    },
    {
      key: "1",
      label: t("Select Other Assets"),
      children: <SelectOtherAssetForm />,
    },
  ];

  useEffect(() => {
    dispatch(resetCurrentAssetData());
  }, []);

  return (
    <>
      <FormHeader
        title={ t("Assets to Exchange") }
        subtitle={ isFundAssetsTab ? t("Select Fund Title") : t("Select Non Fund Title") }
      />

      <div className="ben-form-container">
        <Tabs
          defaultActiveKey={ tab ?? "0" }
          type="card"
          onChange={ (activeKey) => {
            setIsFundAssetsTab(activeKey === "0");
            navigate(`${ location.pathname }?tab=${ activeKey }`, { replace: true });
          } }
          destroyInactiveTabPane
          tabBarExtraContent={ <Tooltip content={ <TypesAssetsConsidered /> } /> }
          items={ sections }
        />
      </div>
    </>
  );
};

export default SelectAssetsPage;
