import { formatCurrencyWithoutFractional } from "common/shared/helpers";

export const formatValue = (
  value: string | number, 
  format: string, 
  isOnlyDigits: boolean = false,
) => {
  switch(format) {
    case "USDollar": 
      return isOnlyDigits 
        ? formatCurrencyWithoutFractional(value as number).slice(1) 
        : formatCurrencyWithoutFractional(value as number);
    case "Percentage": 
      return `${ Math.round(value as number * 100) }${ isOnlyDigits ? "" : "%" }`;
    case "Months":
      return `${ value } months`;
    default: 
      return value;
  }
}
