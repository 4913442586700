import { FC } from "react";

import { useField } from "formik";

import Input from "common/lib/formik-antd/Input";

interface IProps {
  name: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: () => void;
}

const InputOnlyDigits: FC<IProps> = ({ name, className, placeholder, maxLength, onChange }) => {
  const [ { value }, _meta, { setValue } ] = useField(name);

  const handleChange = (e: any) => {
    setValue(e.target.value.replace(/\D/g, ""));
    onChange && onChange();
  };

  return (
    <Input
      id={ name }
      name={ name }
      value={ value }
      className={ className }
      placeholder={ placeholder }
      onChange={ handleChange }
      maxLength={ maxLength }
      autoComplete="nope"
      suffix
    />
  );
};

export default InputOnlyDigits;
