import { getStates } from "common/lib/constants";

export const onStateSearch = (
  searchValue: string,
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void,
) => {
  const stateAbbreviationLength = 2;

  if (searchValue.length > stateAbbreviationLength || searchValue.length === 1) {
    /* setFieldValue("state_or_province", "", false); */
    setFieldValue("state", "", false);
    return;
  }

  const searchResult = getStates().find((state) => state.value.toUpperCase() === searchValue.toUpperCase());

  if (searchResult) {
    /* setFieldValue("state_or_province", searchResult.value); */
    setFieldValue("state", searchResult.value);
  }
};
