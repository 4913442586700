import { createListenerMiddleware, addListener } from "@reduxjs/toolkit";
import type { TypedStartListening, TypedAddListener } from "@reduxjs/toolkit";

import { TRootState } from "store/root.reducer";
import { TAppDispatch } from "store/root.store";

export const listenerMiddleware = createListenerMiddleware();

export type TAppStartListening = TypedStartListening<TRootState, TAppDispatch>;

export const startAppListening = listenerMiddleware.startListening as TAppStartListening;

export const addAppListener = addListener as TypedAddListener<TRootState, TAppDispatch>;
