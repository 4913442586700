import { IVintage } from "domains/liquidity/shared/types";

export const generateVintages = () => {
  const vintages: IVintage[] = [];
  const currentYear = new Date().getFullYear();
  let startYear = 1990;

  while (startYear <= currentYear) {
    vintages.push({ label: startYear });
    startYear++;
  }
  vintages.push({ label: "Unknown", key: 99 });

  return vintages;
};

export const fundStrategies = [
  { label: "Private Equity - Buyout" },
  { label: "Private Equity - Growth Capital" },
  { label: "Private Equity - General" },
  { label: "Venture Capital - Early Stage" },
  { label: "Venture Capital - Late Stage" },
  { label: "Venture Capital - General" },
  { label: "Private Debt - Mezzanine" },
  { label: "Private Debt - Distressed" },
  { label: "Private Debt - General" },
  { label: "Private Real Estate" },
  { label: "Natural Resources" },
  { label: "Infrastructure" },
  { label: "Unknown", key: 99 },
];

export const geographicFocuses = [
  { label: "North America" },
  { label: "Europe – West (U.K., France, Germany, etc.)" },
  { label: "Europe – East (Poland, Russia, Greece, Turkey, etc.)" },
  { label: "Asia – Developed (Japan, Australia, New Zealand, Hong Kong, Singapore)" },
  { label: "Asia – Emerging (China, Taiwan, India, etc.)" },
  { label: "Central & South America" },
  { label: "General or unknown" },
];
