import { combineReducers } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";

import bootstrapReducer from "common/shared-store/bootstrap.reducer";
import authReducer from "domains/authentication/store/auth.reducer";
import identityReducer from "domains/identity/store/identity.reducer";
import clientsReducer from "domains/clients/store/clients.reducer";
import altQuotesReducer from "domains/altQuotes/store/altQuotes.reducer";
import custodyAccountsReducer from "domains/custodyAccounts/store/custodyAccounts.reducer";
import liquidityReducer from "domains/liquidity/store/liquidity.reducer";
import myProfileReducer from "domains/myProfile/store/myProfile.reducer";
import proposalsReducer from "domains/proposals/store/proposals.reducer";
import documentsReducer from "domains/documents/store/documents.reducer";
import trustbenReducer from "domains/trustben/store/trustben.reducer";
import { rootApiReducer } from "./root.api.reducer";

const appReducer = combineReducers({
  bootstrapReducer,
  authReducer,
  clientsReducer,
  custodyAccountsReducer,
  identityReducer,
  documentsReducer,
  liquidityReducer,
  myProfileReducer,
  altQuotesReducer,
  proposalsReducer,
  trustbenReducer,
  [ rootApiReducer.reducerPath ]: rootApiReducer.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: [
    "clientsReducer",
    "custodyAccountsReducer",
    "identityReducer",
    "liquidityReducer",
    "trustbenReducer",
    "rootApiReducer",
  ],
};

export type TRootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
