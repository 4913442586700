import { AccountType, countries } from "common/lib/constants";
import { IInstitutionData, IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import { TCustodyAccountType } from "domains/custodyAccounts/shared/constants";
import { ICustodyAccount, CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { getPhoneNumber } from "domains/liquidity/shared/utils/helpers";
import { DocumentType } from "domains/documents/shared/constants";
import { IDocument } from "domains/documents/shared/types";

const getAdvisorInitialValuesByCustodyAccountType = (
  user: IUserDetails & { institution_data?: IInstitutionData },
  currentCustodyAccount: ICustodyAccount | null,
  custodyAccountType: TCustodyAccountType,
  isAgreementSigned: boolean,
) => {
  if (custodyAccountType === AccountType.CustodialAdvisor) {
    return {
      owner_type: AccountType.CustodialInstitution,
      account_name: currentCustodyAccount?.account_name || user?.account_name,
      country_of_incorporation:
        currentCustodyAccount?.country_of_incorporation || user?.institution_data?.tax_id_country || countries[ 0 ],
      tax_id: currentCustodyAccount?.tax_id || user?.institution_data?.tax_id,
      first_name: currentCustodyAccount?.first_name || user?.first_name,
      last_name: currentCustodyAccount?.last_name || user?.last_name,
      country: currentCustodyAccount?.country,
      address_line_1: currentCustodyAccount?.address_line_1 || currentCustodyAccount?.street1,
      address_line_2: currentCustodyAccount?.address_line_2 || currentCustodyAccount?.street2,
      city: currentCustodyAccount?.city,
      state: currentCustodyAccount?.state || currentCustodyAccount?.region,
      zip_code: currentCustodyAccount?.zip_code || currentCustodyAccount?.postal_code,
      country_of_citizenship: currentCustodyAccount?.country_of_citizenship,
      ssn: currentCustodyAccount?.ssn,
      date_of_birth: currentCustodyAccount?.date_of_birth,
      telephone_number: getPhoneNumber(
        currentCustodyAccount?.phone_number || currentCustodyAccount?.telephone_number,
        currentCustodyAccount?.country_code,
      ),
      country_code: currentCustodyAccount?.country_code,
      phone_country: currentCustodyAccount?.phone_country,
      email_address: currentCustodyAccount?.email_address || currentCustodyAccount?.email,
      custody_agreement: currentCustodyAccount?.custody_agreement || isAgreementSigned,
    };
  }

  if (custodyAccountType === AccountType.CustodialIndividual) {
    return {
      owner_type:
        currentCustodyAccount?.owner_type || currentCustodyAccount?.account_type || AccountType.CustodialIndividual,
      account_name: currentCustodyAccount?.account_name,
      country_of_incorporation: currentCustodyAccount?.country_of_incorporation,
      tax_id: currentCustodyAccount?.tax_id,
      first_name: currentCustodyAccount?.first_name,
      last_name: currentCustodyAccount?.last_name,
      country: currentCustodyAccount?.country,
      address_line_1: currentCustodyAccount?.address_line_1 || currentCustodyAccount?.street1,
      address_line_2: currentCustodyAccount?.address_line_2 || currentCustodyAccount?.street2,
      city: currentCustodyAccount?.city,
      state: currentCustodyAccount?.state || currentCustodyAccount?.region,
      zip_code: currentCustodyAccount?.zip_code || currentCustodyAccount?.postal_code,
      country_of_citizenship: currentCustodyAccount?.country_of_citizenship,
      ssn: currentCustodyAccount?.ssn,
      date_of_birth: currentCustodyAccount?.date_of_birth,
      telephone_number: getPhoneNumber(
        currentCustodyAccount?.phone_number || currentCustodyAccount?.telephone_number,
        currentCustodyAccount?.country_code,
      ),
      country_code: currentCustodyAccount?.country_code,
      phone_country: currentCustodyAccount?.phone_country,
      email_address: currentCustodyAccount?.email_address || currentCustodyAccount?.email,
      advisory_firm_country_of_incorporation:
        currentCustodyAccount?.advisory_firm_country_of_incorporation ||
        user?.institution_data?.tax_id_country ||
        countries[ 0 ],
      advisory_firm_tax_id: currentCustodyAccount?.advisory_firm_tax_id || user?.institution_data?.tax_id,
      custody_agreement: currentCustodyAccount?.custody_agreement || isAgreementSigned,
    };
  }

  if (custodyAccountType === AccountType.CustodialInstitution) {
    return {
      owner_type:
        currentCustodyAccount?.owner_type || currentCustodyAccount?.account_type || AccountType.CustodialInstitution,
      account_name: currentCustodyAccount?.account_name,
      country_of_incorporation: currentCustodyAccount?.country_of_incorporation,
      tax_id: currentCustodyAccount?.tax_id,
      first_name: currentCustodyAccount?.first_name,
      last_name: currentCustodyAccount?.last_name,
      country: currentCustodyAccount?.country,
      address_line_1: currentCustodyAccount?.address_line_1 || currentCustodyAccount?.street1,
      address_line_2: currentCustodyAccount?.address_line_2 || currentCustodyAccount?.street2,
      city: currentCustodyAccount?.city,
      state: currentCustodyAccount?.state || currentCustodyAccount?.region,
      zip_code: currentCustodyAccount?.zip_code || currentCustodyAccount?.postal_code,
      country_of_citizenship: currentCustodyAccount?.country_of_citizenship,
      ssn: currentCustodyAccount?.ssn,
      date_of_birth: currentCustodyAccount?.date_of_birth,
      telephone_number: getPhoneNumber(
        currentCustodyAccount?.phone_number || currentCustodyAccount?.telephone_number,
        currentCustodyAccount?.country_code,
      ),
      country_code: currentCustodyAccount?.country_code,
      phone_country: currentCustodyAccount?.phone_country,
      email_address: currentCustodyAccount?.email_address || currentCustodyAccount?.email,
      advisory_firm_country_of_incorporation:
        currentCustodyAccount?.advisory_firm_country_of_incorporation ||
        user?.institution_data?.tax_id_country ||
        countries[ 0 ],
      advisory_firm_tax_id: currentCustodyAccount?.advisory_firm_tax_id || user?.institution_data?.tax_id,
      custody_agreement: currentCustodyAccount?.custody_agreement || isAgreementSigned,
    };
  }
};

export const getInitialValuesByUserType = (
  user: IUserDetails | null,
  currentCustodyAccount: ICustodyAccount | null,
  currentClient: IUserClient | null,
  isAgreementSigned: boolean,
) => {
  const custodyAccountType = getCustodyAccountTypeByUserType(user, currentCustodyAccount, currentClient);

  switch (user?.user_type) {
    case UserType.Employee:
    case UserType.Principal:
      return {
        owner_type:
          currentCustodyAccount?.owner_type || currentCustodyAccount?.account_type || AccountType.CustodialIndividual,
        account_name: currentCustodyAccount?.account_name,
        country_of_incorporation: currentCustodyAccount?.country_of_incorporation,
        tax_id: currentCustodyAccount?.tax_id,
        first_name: currentCustodyAccount?.first_name,
        last_name: currentCustodyAccount?.last_name,
        country: currentCustodyAccount?.country,
        address_line_1: currentCustodyAccount?.address_line_1 || currentCustodyAccount?.street1,
        address_line_2: currentCustodyAccount?.address_line_2 || currentCustodyAccount?.street2,
        city: currentCustodyAccount?.city,
        state: currentCustodyAccount?.state || currentCustodyAccount?.region,
        zip_code: currentCustodyAccount?.zip_code || currentCustodyAccount?.postal_code,
        country_of_citizenship: currentCustodyAccount?.country_of_citizenship,
        ssn: currentCustodyAccount?.ssn,
        date_of_birth: currentCustodyAccount?.date_of_birth,
        telephone_number: getPhoneNumber(
          currentCustodyAccount?.phone_number || currentCustodyAccount?.telephone_number,
          currentCustodyAccount?.country_code,
        ),
        country_code: currentCustodyAccount?.country_code,
        phone_country: currentCustodyAccount?.phone_country,
        email_address: currentCustodyAccount?.email_address || currentCustodyAccount?.email,
        custody_agreement: currentCustodyAccount?.custody_agreement || isAgreementSigned,
      };

    case UserType.Advisor:
      return getAdvisorInitialValuesByCustodyAccountType(
        user,
        currentCustodyAccount,
        custodyAccountType,
        isAgreementSigned,
      );

    default:
      return {};
  }
};

export const getCustodyAccountTypeByUserType = (
  user: IUserDetails | null,
  currentCustodyAccount: ICustodyAccount | null,
  currentClient: IUserClient | null,
): TCustodyAccountType => {
  switch (user?.user_type) {
    case UserType.Employee:
    case UserType.Principal:
      return (
        currentCustodyAccount?.owner_type || currentCustodyAccount?.account_type || AccountType.CustodialIndividual
      );

    case UserType.Advisor: {
      if (currentClient && currentClient.is_anonymous) {
        return AccountType.CustodialAdvisor;
      }

      if (currentClient) {
        return currentClient.account_type === AccountType.Individual
          ? AccountType.CustodialIndividual
          : AccountType.CustodialInstitution;
      }

      return (
        currentCustodyAccount?.owner_type || currentCustodyAccount?.account_type || AccountType.CustodialIndividual
      );
    }

    default:
      return AccountType.CustodialIndividual;
  }
};

export const getInitialValues = (
  user: IUserDetails | null,
  currentClient: IUserClient | null,
  currentCustodyAccount: ICustodyAccount | null,
  documents: IDocument[],
) => {
  const pdfDocument = documents.find((document) => document.document_type === DocumentType.CustodyAgreementRedacted);
  const isAgreementSigned = !!pdfDocument && currentCustodyAccount?.account_status !== CustodyAccountStatus.Closed;

  const initialValues: any = getInitialValuesByUserType(user, currentCustodyAccount, currentClient, isAgreementSigned);

  return initialValues;
};
