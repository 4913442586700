class IdUtils {
  uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      // tslint:disable
      const r = ( Math.random() * 16 ) | 0;
      const v = c === "x" ? r : ( r & 0x3 ) | 0x8;
      // tslint:enable
      return v.toString(16);
    });
  }
}

const idUtils = new IdUtils();
export default idUtils;
