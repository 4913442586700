import { createSlice } from "@reduxjs/toolkit";

import { IProposals } from "domains/proposals/shared/types";
import { proposalsApiReducer } from "./proposals.api.reducer";

export interface IInitialState {
  initProposals: IProposals | null;
}

const initialState: IInitialState = {
  initProposals: null,
};

const proposalsReducer = createSlice({
  name: "[Proposals]",
  initialState,
  reducers: {
    resetProposalsReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(proposalsApiReducer.endpoints.fetchProposals.matchFulfilled, (state, action) => ({
      ...state,
      initProposals: action.payload,
    }));
  },
});

export const { resetProposalsReducer } = proposalsReducer.actions;

export default proposalsReducer.reducer;
