import { FC, ReactNode } from "react";

import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";

import useWhiteLabel from "common/shared-hooks/useWhiteLabel";
import { WhiteLabelType } from "common/shared/types";

import "./Button.scss";

interface IProps extends AntButtonProps {
  children?: ReactNode;
  className?: string;
  ref?: any;
}

const Button: FC<IProps> = ({ children, className, ...props }) => {
  const whiteLabelType = useWhiteLabel();

  if (!whiteLabelType) return null;

  if (whiteLabelType === WhiteLabelType.Trustben) {
    return (
      <AntButton className={ `trustben-button ${ className }` } { ...props }>
        {children}
      </AntButton>
    );
  }

  return (
    <AntButton className={ `ben-button ${ className }` } { ...props }>
      {children}
    </AntButton>
  );
};

export default Button;
