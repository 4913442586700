import { useEffect, useMemo } from "react";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { resetAltQuotesReducer } from "domains/altQuotes/store/altQuotes.reducer";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { AltQuote } from "domains/altQuotes/components";
import { Spinner } from "common/lib/components";
import { useInternalAltQuoteFunctionality } from "domains/altQuotes";
import { AssetType } from "domains/liquidity/shared/constants";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { altQuotesApiReducer } from "domains/altQuotes/store/altQuotes.api.reducer";

const AltQuotePage = () => {
  const dispatch = useDispatch();
  const { LRId } = useParams();

  const { isInternalAltQuoteFunctionality } = useInternalAltQuoteFunctionality();
  const { currentLiquidityRequest } = useLiquiditySelector();
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const [ updateLRCurrentTask ] = liquidityApiReducer.useUpdateLRCurrentTaskMutation();
  const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });
  const [
    fetchAltQuoteOffers,
    {
      data: altQuoteOffers = {
        alt_quote_id: null,
        fundOffers: [],
        offers: [],
      },
      isFetching: isFetchingAltQuoteOffers,
    },
  ] = altQuotesApiReducer.useLazyFetchAltQuoteOffersQuery();

  const currentTask = liquidityRequests?.find((item) => item.id === currentLiquidityRequest?.id)?.currentTask;
  const isAltQuoteClosed = currentTask ? currentTask.liquidity_request_status === LiquidityRequestStatus.Closed : true;
  const isSingleFundView = !(currentLiquidityRequest && assets?.length > 1);
  const isDataReceived = !!altQuoteOffers.alt_quote_id;
  const fundAssets = useMemo(
    () => assets?.filter((asset) => asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
    [ assets?.length ],
  );
  const nonFundAssets = useMemo(
    () =>
      assets?.filter((asset) => !(asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund)),
    [ assets?.length ],
  );
  const isFundAssetsSubmitted = !!fundAssets?.length;
  const isNonFundAssetsSubmitted = !!nonFundAssets?.length;
  const isOnlyNonFundAssetsSubmitted = !isFundAssetsSubmitted && isNonFundAssetsSubmitted;
  const isMixOfAssetsSubmitted = isFundAssetsSubmitted && isNonFundAssetsSubmitted;

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;
    if (!isDataReceived && LRId && !isOnlyNonFundAssetsSubmitted) {
      intervalId = setInterval(() => {
        fetchAltQuoteOffers(LRId);
      }, 3000);
    }

    return () => clearInterval(intervalId);
  }, [ isDataReceived, LRId, isOnlyNonFundAssetsSubmitted ]);

  useEffect(() => {
    if (!LRId || isOnlyNonFundAssetsSubmitted) return;
    fetchAltQuoteOffers(LRId);

    return () => {
      dispatch(resetAltQuotesReducer());
    };
  }, [ LRId, isOnlyNonFundAssetsSubmitted ]);

  useEffect(() => {
    if (!isDataReceived || !LRId || isAltQuoteClosed) return;

    updateLRCurrentTask(LRId);
  }, [ isDataReceived, LRId, isAltQuoteClosed ]);

  useEffect(() => {
    if ((!fundAssets?.length && !nonFundAssets?.length) || !isOnlyNonFundAssetsSubmitted || !LRId || isAltQuoteClosed)
      return;

    updateLiquidityRequest({ LRId, changes: { status: LiquidityRequestStatus.Closed } });
  }, [ isOnlyNonFundAssetsSubmitted, LRId ]);

  return (
    <>
      <Spinner spinning={ isFetchingAltQuoteOffers }>
        <AltQuote
          altQuoteOffers={ altQuoteOffers }
          isSingleFundView={ isSingleFundView }
          LRId={ LRId ?? "" }
          liquidityRequest={ currentLiquidityRequest }
          isOnlyNonFundAssetsSubmitted={ isOnlyNonFundAssetsSubmitted }
          isMixOfAssetsSubmitted={ isMixOfAssetsSubmitted }
        />
      </Spinner>
      {/* TODO: Remove the internal AltQuote functionality when it's no longer needed */}
      {isInternalAltQuoteFunctionality &&
        !isOnlyNonFundAssetsSubmitted &&
        (!isDataReceived ? (
          <>
            <h3 className="ben-mt-4 ben-mr-2" style={ { display: "inline-block" } }>
              Waiting for AltQuote results
            </h3>
            <Spinner spinning={ true }></Spinner>
          </>
        ) : null)}
    </>
  );
};

export default AltQuotePage;
