import { apiEnvs, apiService } from "common/shared";

class MyProfileApiService {
  private config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async createOwnership(LRId: string, data: any) {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/clientform`, data, this.config);
  }

  async getClientForms(LRId: string) {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/clientforms`, this.config);
  }

  async createInvestment(data: any) {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/investments`, data, this.config);
  }

  async createOwner(data: any) {
    const config = {
      headers: {
        "Content-Type": "text/plain",
      },
    };
    return apiService.post(`${ apiEnvs.accountsAPI }/v1/accounts`, data, config);
  }

  async getClientForm(id: string, clientFormName: string) {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/${ clientFormName }/${ id }`, this.config);
  }

  async updateClientForm(id: string, values: any, clientFormName: string) {
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/${ clientFormName }/${ id }`, values, this.config);
  }

  async submitClientForms(ownerId: number, values: any) {
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/client_forms_main/${ ownerId }`, values, this.config);
  }

  async addOwner(values: any) {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/beneficial_owners`, values, this.config);
  }

  async deleteOwner(owner_id: string) {
    return apiService.delete(`${ apiEnvs.holdingsAPI }/v1/beneficial_owners/${ owner_id }`);
  }

  async addTrust(values: any) {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/trust_ownerships`, values, this.config);
  }

  async deleteTrust(trust_id: string) {
    return apiService.delete(`${ apiEnvs.holdingsAPI }/v1/trust_ownerships/${ trust_id }`);
  }

  async updateOwner(values: any, owner_id: string) {
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/beneficial_owners/${ owner_id }`, values, this.config);
  }

  async updateTrust(values: any, trust_id: string) {
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/trust_ownerships/${ trust_id }`, values, this.config);
  }
}

export default new MyProfileApiService();
