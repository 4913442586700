import { ForwardRefExoticComponent, RefAttributes, forwardRef } from "react";

import { useField } from "formik";
import {
  InputProps as AntdInputProps,
  PasswordProps as AntdPasswordProps,
  TextAreaProps as AntdTextAreaProps,
} from "antd/lib/input";
import { Input as AntdInput, InputRef as AntdInputRef } from "antd";

import InputPassword from "common/lib/formik-antd/InputPassword";
import InputTextArea from "common/lib/formik-antd/InputTextArea";

interface IInputType
  extends ForwardRefExoticComponent<{ name: string } & AntdInputProps & RefAttributes<AntdInputRef>> {
  Password: ForwardRefExoticComponent<{ name: string } & AntdPasswordProps & RefAttributes<AntdInputRef>>;
  TextArea: ForwardRefExoticComponent<{ name: string } & AntdTextAreaProps & RefAttributes<AntdInputRef>>;
}

const Input = forwardRef<AntdInputRef, AntdInputProps & { name: string }>(
  (
    {
      name,
      // eslint-disable-next-line no-unused-vars
      value: $value,
      onChange: $onChange,
      onBlur: $onBlur,
      ...restProps
    },
    ref,
  ) => {
    const [ { value, onChange, onBlur } ] = useField(name);

    return (
      <AntdInput
        ref={ ref }
        name={ name }
        id={ name }
        value={ value }
        onChange={ (event) => {
          onChange(event);
          $onChange?.(event);
        } }
        onBlur={ (event) => {
          onBlur(event);
          $onBlur?.(event);
        } }
        { ...restProps }
      />
    );
  },
);

const TypedInput = Input as IInputType;

TypedInput.Password = InputPassword;
TypedInput.TextArea = InputTextArea;

Input.displayName = "Input";

export default TypedInput;
