import { useCallback, useEffect, useMemo, useState } from "react";

import { Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";

import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { FormHeader, NavigationButtons, Button, Spinner, DividerWithOR } from "common/lib/components";
import { AccountType, URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity";
import { formatId } from "common/shared/helpers";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { useClientsSelector } from "domains/clients";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./CustodyAccountPage.scss";

const CustodyAccountPage = () => {
  const { t } = useTranslation("liquidity");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LRId } = useParams();

  const { user } = useClientsSelector();
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const [ connectCustodyAccountWithLR, { isLoading, isSuccess, reset } ] =
    custodyAccountsApiReducer.useConnectCustodyAccountWithLRMutation();
  const { refetch: refetchLRAccounts } = liquidityApiReducer.useFetchLRAccountsQuery(LRId ?? skipToken);

  const activeCustodyAccounts = custodyAccounts?.filter(
    (custodyAccount) =>
      custodyAccount.account_status !== CustodyAccountStatus.Closed &&
      custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
      custodyAccount.account_status !== CustodyAccountStatus.Duplicate &&
      custodyAccount.account_type !== AccountType.CustodialAdvisor,
  );

  const [ chosenAccountId, setChosenAccountId ] = useState<number | null>(null);

  const chosenCustodyAccount = useMemo(() => {
    if (!activeCustodyAccounts) return;

    return activeCustodyAccounts.find(
      (custodyAccount) => custodyAccount.account_id === formatId("ACC-", chosenAccountId || ""),
    );
  }, [ activeCustodyAccounts?.length, chosenAccountId ]);

  const columns = [
    {
      title: t("Custody Account Page.ColumnTitle"),
      dataIndex: "custodyAccount",
      key: "custodyAccount",
      align: "left",
      className: "ben-pl-1 ben-border-bottom",
    } as const,
  ];

  const custodyDataSource = activeCustodyAccounts?.map((custodyAccount: any) => ({
    key: custodyAccount.account_id,
    custodyAccount: custodyAccount.account_name,
  }));

  const handleCreateNewCustodyAccount = () => {
    dispatch(setCurrentCustodyAccount(null));
    navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
  };

  const onNext = useCallback(() => {
    if (!chosenCustodyAccount || !LRId) return;

    connectCustodyAccountWithLR({ custodyAccount: chosenCustodyAccount, LRId });
  }, [ chosenCustodyAccount, LRId ]);

  useEffect(() => {
    if (!isSuccess) return;

    refetchLRAccounts();
    navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmApplication);
    reset();
  }, [ isSuccess ]);

  return (
    <Spinner spinning={ isLoading }>
      <FormHeader subtitle={ t("Custody Account Page.Subtitle") } />
      <div className="ben-custody-account-page-container">
        <div className="ben-custody-account-page-left-content">
          <Table
            sticky
            rowClassName={ (record) => (record.key === chosenAccountId ? "ben-table-row-active" : "") }
            onRow={ (record) => ({
              onClick: () => setChosenAccountId(record.key),
            }) }
            className="ben-table"
            columns={ columns }
            dataSource={ custodyDataSource }
            pagination={ false }
          />
          <NavigationButtons onNext={ onNext } disabled={ !chosenAccountId } />
        </div>

        <DividerWithOR />

        <div className="ben-custody-account-page-right-content">
          <Button type="primary" onClick={ handleCreateNewCustodyAccount }>
            {t("Custody Account Page.ButtonText")}
          </Button>
          <p className="ben-mt-2 ben-custody-account-page-text">{t("Custody Account Page.InfoText")}</p>
        </div>
      </div>
    </Spinner>
  );
};

export default CustodyAccountPage;
