import { useTranslation } from "react-i18next";

import { FormHeader } from "common/lib/components";

const CustodyAccountAgreementHeader = () => {
  const { t } = useTranslation("liquidity");

  const customerHint = (
    <ul style={ { listStyleType: "none", padding: "0" } }>
      {(t("Customer Information Page.Info", { returnObjects: true }) as string[]).map((item, index) => (
        <li key={ index } className="ben-mb-1">
          {item}
        </li>
      ))}
    </ul>
  );

  return (
    <FormHeader
      title={ t("Customer Information Page.Title") }
      subtitle={ t("Customer Information Page.Subtitle") }
      hint={ customerHint }
      className="ben-customer-information-page-form-header"
    />
  );
};

export default CustodyAccountAgreementHeader;
