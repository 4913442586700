import {
  IChangedAssetValue,
  TAssetType,
  IFormattedCustomerInformationValue,
  ILiquidityRequest,
  LiquidityRequestStatus,
} from "domains/liquidity/shared/types";
import { IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import { AssetType, ICustomerInformation } from "domains/liquidity/shared/constants";
import { IChangedValue } from "domains/myProfile/shared/types";
import { DocumentGridStatus, IDocumentGridData } from "domains/documents/shared/types";
import { trustbenSessionService } from "domains/trustben";

export const formatAssetToUpdate = (asset: any, stateAsset: any): IChangedAssetValue[] => {
  const keys = Object.keys(asset);
  const changedValues: IChangedAssetValue[] = [];

  keys.forEach(
    (key) =>
      asset[ key ] !== stateAsset[ key ] &&
      changedValues.push({
        op: "replace",
        path: `/${ key }`,
        value: asset[ key ],
      }),
  );

  return changedValues;
};

export const formatLiquidityRequestBodyForRequest = (
  liquidity_request_type: string,
  user: IUserDetails,
  client?: IUserClient,
) => {
  const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
  const accounts = isAdvisor
    ? [
        {
          account_id: parseInt(user.account_id.slice(4)),
          account_name: user.account_name,
          account_display_id: user.account_display_id,
          relationship_type: "2",
          kyc_status: "None",
          is_active: true,
          is_test_data: false,
        },
      ]
    : [
        {
          account_id: parseInt(user.account_id.slice(4)),
          account_name: user.account_name,
          account_display_id: user.account_display_id,
          relationship_type: "3",
          kyc_status: "None",
          is_active: true,
          is_test_data: false,
        },
      ];

  if (isAdvisor && client) {
    accounts.push({
      account_id: parseInt(client.account_id.slice(4)),
      account_name: client.account_name,
      account_display_id: client.account_display_id,
      relationship_type: "1",
      kyc_status: "None",
      is_active: true,
      is_test_data: false,
    });
  }

  return {
    liquidity_request_type,
    account_id: user.account_id,
    account_display_id: user.account_display_id,
    account_name: user.account_name || "",
    created_by: user.user_id,
    created_by_name: user.first_name + " " + user.last_name,
    status: LiquidityRequestStatus.PendingSubmission,
    is_active: true,
    is_test_data: false,
    liquidity_request_name: "",
    session_id: trustbenSessionService.getIsTrustbenAltQuote() ? trustbenSessionService.getTrustbenSessionId() : null,
    accounts,
  };
};

export const formatAssetBodyForRequest = (
  asset: Partial<TAssetType>,
  user: IUserDetails,
  liquidity_request_id: string,
): any => {
  switch (asset.asset_type) {
    case AssetType.Fund:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_status: "needs_review_by_bids",
        sponsor: asset.fund_manager || asset.sponsor,
        fund_id: asset.fund_id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        fund_strategy: asset.fund_strategy,
        vintage_year: asset.vintage,
        geographic_focus: asset.geographic_focus,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            amount_invested: asset.amount_invested,
            current_net_asset_value: asset.current_net_asset_value,
            capital_commitment: asset.capital_commitment,
            unfunded_commitment_currency: asset.unfunded_commitment_currency,
            unfunded_commitment_percentage: asset.unfunded_commitment_percentage,
            is_active: true,
            is_test_data: false,
          },
        ],
      };

    case AssetType.PreqinFund:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_status: "locked_by_bids",
        sponsor: asset.fund_manager || asset.sponsor,
        asset_type: asset.asset_type,
        fund_id: asset.fund_id,
        asset_name: asset.asset_name,
        fund_strategy: asset.fund_strategy,
        vintage_year: asset.vintage,
        geographic_focus: asset.geographic_focus,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            amount_invested: asset.amount_invested,
            current_net_asset_value: asset.current_net_asset_value,
            capital_commitment: asset.capital_commitment,
            unfunded_commitment_currency: asset.unfunded_commitment_currency,
            unfunded_commitment_percentage: asset.unfunded_commitment_percentage,
            is_active: true,
            is_test_data: false,
          },
        ],
      };

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        asset_description: asset.asset_description,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            amount_invested: asset.amount_invested,
            isActive: true,
            is_test_data: false,
          },
        ],
      };

    case AssetType.CoInvestment:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        asset_description: asset.asset_description,
        sponsor: asset.sponsor,
        sponsor_description: asset.sponsor_description,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            isActive: true,
            is_test_data: false,
          },
        ],
      };

    case AssetType.DirectRealEstateInvestment:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        real_estate_property_type: asset.real_estate_property_type,
        real_estate_location: asset.real_estate_location,
        state_or_province: asset.state,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            isActive: true,
            is_test_data: false,
          },
        ],
      };

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_type: asset.asset_type,
        asset_name: asset.account_name,
        asset_description: asset.company_description,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            amount_invested: asset.amount_invested,
            isActive: true,
            is_test_data: false,
          },
        ],
      };

    case AssetType.DirectInterestsInNaturalResourcesAsset:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type === UserType.Principal ? "individual" : "institution",
        asset_type: asset.asset_type,
        asset_description: asset.asset_description,
        asset_id: asset.asset_id,
        investment_id: asset.id,
        external_asset_id: asset.external_asset_id,
        product_assets: [
          {
            product_id: parseInt(liquidity_request_id),
            isActive: true,
            is_test_data: false,
          },
        ],
      };
  }
};

export const formatAssetsForReducer = (response: any): Partial<TAssetType>[] => {
  const assets: Partial<TAssetType>[] = [];

  response.map((asset: any) => {
    switch (asset.asset.asset_type) {
      case AssetType.Fund:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
          created_date: asset.asset.created_date,
          fund_manager: asset.asset.sponsor,
          vintage: asset.asset.vintage,
          fund_strategy: asset.asset.fund_strategy,
          geographic_focus: asset.asset.geographic_focus,
          current_net_asset_value: asset.current_net_asset_value,
          capital_commitment: asset.capital_commitment,
          unfunded_commitment_currency: asset.unfunded_commitment_currency,
          unfunded_commitment_percentage: asset.unfunded_commitment_percentage,
        });
        return;

      case AssetType.PreqinFund:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
          fund_id: asset.asset.fund_id,
          created_date: asset.asset.created_date,
          fund_manager: asset.asset.sponsor,
          vintage: asset.asset.vintage,
          fund_strategy: asset.asset.fund_strategy,
          geographic_focus: asset.asset.geographic_focus,
          current_net_asset_value: asset.current_net_asset_value,
          capital_commitment: asset.capital_commitment,
          unfunded_commitment_currency: asset.unfunded_commitment_currency,
          unfunded_commitment_percentage: asset.unfunded_commitment_percentage,
        });
        return;

      case AssetType.NonTradedREIT:
      case AssetType.NonTradedBDC:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
        });
        return;

      case AssetType.CoInvestment:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
          sponsor: asset.asset.sponsor,
          sponsor_description: asset.asset.sponsor_description,
        });
        return;

      case AssetType.DirectRealEstateInvestment:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
          real_estate_property_type: asset.asset.real_estate_property_type,
          real_estate_location: asset.asset.real_estate_location,
          state: asset.asset.state /* state_or_province */,
        });
        return;

      case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
          account_name: asset.asset.asset_name,
          amount_invested: asset.amount_invested,
          company_description: asset.asset.asset_description,
        });
        return;

      case AssetType.DirectInterestsInNaturalResourcesAsset:
        assets.push({
          asset_type: asset.asset.asset_type,
          asset_name: asset.asset.asset_name,
          asset_id: asset.asset.id,
          asset_description: asset.asset.asset_description,
        });
        return;
    }
  });

  return assets;
};

export const formatCustomerInformationToUpdate = (formValues: ICustomerInformation) => {
  const formattedValues = Object.entries(formValues).reduce(
    (acc: IFormattedCustomerInformationValue[], [ key, value ]) => {
      acc.push({
        op: "replace",
        path: getPath(key),
        value: value,
      });

      return acc;
    },
    [],
  );

  return formattedValues;
};

const getPath = (key: string) => {
  if (key === "address_line_1" || key === "address_line_2") {
    return `/addresses/0/street${ key.slice(-1) }`;
  }

  if (key === "city") {
    return `/addresses/0/${ key }`;
  }

  if (key === "state") {
    return `/addresses/0/region`;
  }

  if (key === "zip_code") {
    return `/addresses/0/postal_code`;
  }

  if (key === "telephone_number" || key === "phone_number") {
    return `/phones/0/phone_number`;
  }

  if (key === "phone_country") {
    return `/phones/0/phone_country`;
  }

  if (key === "country_code") {
    return `/phones/0/country_code`;
  }

  if (key === "custody_agreement") {
    return `custodial_agreement`;
  }

  if (key === "company_name") {
    return `/account_name`;
  }

  if (key === "email_address") {
    return `/email_addresses/0/email`;
  }

  return `/${ key }`;
};

export const formatClientBodyForRequest = (client: any) => {
  return {
    shared_with_account_id: client.account_id,
    shared_with_user_id: client.principal_data?.user_id || client.institution_data.primary_contact_user.user_id,
    shared_with_user_name: client.account_name,
    shared_with_user_email: client.principal_data?.email || client.institution_data.primary_contact_user.email,
    status: "submitted",
  };
};

export const formatDocuemtGridDataForRequest = (
  asset: TAssetType,
  user: IUserDetails,
  liquidity_request_id: string,
  status: DocumentGridStatus,
) => {
  return {
    is_append_row: true,
    fund_assets: [
      {
        liquidity_request_id,
        account_id: user.account_id,
        asset_id: asset.asset_id,
        asset_name: asset.asset_name,
        annual_fund_and_financial_statement_status: status,
        balance_sheet_status: status,
        governing_documents_status: status,
        fund_reports_and_unaudited_financial_statements_status: status,
        ad_hoc_documents_status: status,
        notes: "",
        is_active: true,
        is_test_data: false,
      },
    ] as IDocumentGridData[],
  };
};

export const formatLRToUpdate = (changes: Partial<ILiquidityRequest>) => {
  const keys = Object.keys(changes);
  const formattedChanges: IChangedValue[] = [];

  keys.forEach((key) =>
    formattedChanges.push({
      op: "replace",
      path: `/${ key }`,
      value: changes[ key as keyof typeof changes ],
    }),
  );

  return formattedChanges;
};
