import { FC } from "react";

import { Col, Row } from "antd";

import { formatValue } from "common/shared/helpers";
import { IAltQuoteOffersOptions } from "domains/altQuotes/shared/types";

import "./AltQuoteOffers.scss";

interface IProps {
  notCollapsedItems: IAltQuoteOffersOptions[];
  isViewByOffer: boolean;
}

const AltQuoteOffers: FC<IProps> = ({ notCollapsedItems, isViewByOffer }) => {
  const isReturnRate = notCollapsedItems.some((item) => item?.return_rate);

  return (
    <div className="ben-altquote-page-offers">
      {notCollapsedItems.length &&
        notCollapsedItems.map((el) => (
          <Row align="middle" key={ el.offer_option } className="ben-altquote-page-panel-item">
            <Col xs={ isViewByOffer && isReturnRate ? 6 : 8 } md={ 5 }>
              {el.offer_option}
            </Col>
            <Col xs={ isViewByOffer && isReturnRate ? 6 : 8 } md={ 5 } className="ben-text-center">
              {`${ formatValue(el.offer_low_currency, "USDollar") } to ${ formatValue(
                el.offer_high_currency,
                "USDollar",
              ) }`}
            </Col>
            <Col xs={ isViewByOffer && isReturnRate ? 6 : 8 } md={ 4 } className="ben-text-center">
              {`${ formatValue(el.percent_of_nav_low, "Percentage") } to ${ formatValue(
                el.percent_of_nav_high,
                "Percentage",
              ) }`}
            </Col>
            {isViewByOffer && isReturnRate && (
              <Col xs={ 6 } md={ 4 } className="ben-text-center">
                {el.return_rate}
              </Col>
            )}
          </Row>
        ))}
    </div>
  );
};

export default AltQuoteOffers;
