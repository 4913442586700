import { FC } from "react";

import "./MobileTableCell.scss";

interface IProps {
  fundManager: string;
  fundName: string;
}

const MobileTableCell: FC<IProps> = ({ fundManager, fundName }) => {
  return (
    <>
      <div className="ben-table-cell-fund-manager">{ fundManager }</div>
      <div className="ben-table-cell-fund-name">{ fundName }</div>
    </>
  );
};

export default MobileTableCell;
