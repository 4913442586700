import { configureStore } from "@reduxjs/toolkit";
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import { listenerMiddleware } from "store/middlewares/listenerMiddleware";
import rootReducer from "./root.reducer";
import { rootApiReducer } from "./root.api.reducer";

export const analytics = () => (next: any) => (action: any) => {
  const dataLayer = window.dataLayer || [];
  if (action) {
    dataLayer.push({
      event: action.type,
    });
    return next(action);
  }
};

const rootStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
        ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ],
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(analytics, rootApiReducer.middleware),
});

export const persistor = persistStore(rootStore);
export default rootStore;

export type TAppState = ReturnType<typeof rootStore.getState>;
export type TAppDispatch = typeof rootStore.dispatch;
