import { impersonationService, sessionService } from "common/shared";
import apiEnvs from "common/shared/services/api.env.constants/api.env.constants";

interface IAuthorizationHeader {
  Authorization: string;
}

interface IImpersonationHeaders {
  "bcp-impersonation-payload": string;
  "bcp-impersonation-iv": string;
  "bcp-user-context"?: string;
}

interface IWhiteLabelHeader {
  "bcp-whitelabel": string;
}

interface ITenantHeader {
  "bcp-tenant": string;
}

interface IPrivateHeaders extends IWhiteLabelHeader {
  Authorization?: string;
}

export const getAuthorizationHeader = (): IAuthorizationHeader | null => {
  const tokens = sessionService.getTokens();
  return (tokens && { Authorization: `Bearer ${ tokens.access_token }` }) || null;
};

export const getImpersonationHeaders = (): IImpersonationHeaders | {} | undefined => {
  if (!sessionService.getIsAppian()) {
    return {};
  }

  const impersonateData = impersonationService.getImpersonationPayload();
  if (impersonateData) {
    const { encryptedPayload, initVector, userPayload } = impersonateData;
    const userContextHeaders = userPayload ? { "bcp-user-context": userPayload } : {};
    return {
      ...userContextHeaders,
      "bcp-impersonation-payload": encryptedPayload,
      "bcp-impersonation-iv": initVector,
    };
  }

  return;
};

export const getWhiteLabelHeader = async (): Promise<IWhiteLabelHeader> => {
  const { whiteLabel } = await apiEnvs.getApiEnv();
  return { "bcp-whitelabel": whiteLabel };
};

export const getTenantHeader = (): ITenantHeader => {
  return { "bcp-tenant": "ben" };
};

export const getPrivateHeaders = async (): Promise<IPrivateHeaders> => {
  const authHeader = getAuthorizationHeader();
  const whiteLabelHeader = await getWhiteLabelHeader();
  if (authHeader) {
    return { ...authHeader, ...whiteLabelHeader };
  }

  return { ...whiteLabelHeader };
};

export const getNoCacheHeaders = () => ({
  "Cache-Control": "no-cache",
  "Pragma": "no-cache",
});
