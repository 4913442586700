import { FC } from "react";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { Form, Input, InputNumber, Select } from "common/lib/formik-antd";
import { maxLength20, maxLengthTinOrEin } from "common/shared/constants/validationConstants";
import { Caret } from "common/lib/components";
import { countries } from "common/lib/constants";
import { tinOrEinFormatter, tinOrEinParser } from "domains/liquidity/shared/utils/inputValueFormatter";

const { Option } = Select;

interface IProps {
  handleKeyFieldChange: (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
}

const AdvisorCountryAndTaxIdFields: FC<IProps> = ({ handleKeyFieldChange }) => {
  const { t } = useTranslation([ "common", "liquidity", "authentication" ]);

  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <Row gutter={ 8 } align={ "bottom" }>
      <Col xs={ 24 } md={ 12 }>
        <Form.Item
          name="advisory_firm_country_of_incorporation"
          label={ t("Advisor Firm Country of Incorporation", { ns: "liquidity" }) }
        >
          <Select
            showSearch
            optionFilterProp="children"
            suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
            name="advisory_firm_country_of_incorporation"
            placeholder={ t("Select Country", { ns: "liquidity" }) }
          >
            {countries.map((country) => (
              <Option key={ country } value={ country }>
                {country}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col xs={ 24 } md={ 12 }>
        <Form.Item name="advisory_firm_tax_id" label={ t("Advisor Firm Tax ID", { ns: "liquidity" }) }>
          {values.advisory_firm_country_of_incorporation === "United States" ? (
            <InputNumber
              onChange={ () => handleKeyFieldChange(setFieldValue) }
              maxLength={ maxLengthTinOrEin }
              formatter={ tinOrEinFormatter }
              parser={ tinOrEinParser }
              className="small"
              name="advisory_firm_tax_id"
              placeholder={ t("Tax ID", { ns: "liquidity" }) }
              controls={ false }
              autoComplete="nope"
              prefix
            />
          ) : (
            <Input
              onChange={ () => handleKeyFieldChange(setFieldValue) }
              maxLength={ maxLength20 }
              name="advisory_firm_tax_id"
              placeholder={ t("Tax ID", { ns: "liquidity" }) }
              suffix
            />
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AdvisorCountryAndTaxIdFields;
