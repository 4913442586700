const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const currencyFormatterWithoutFractional = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

const formatCurrency = (currency: number) => currencyFormatter.format(currency);

const formatCurrencyWithoutFractional = (currency: number) => currencyFormatterWithoutFractional.format(currency);

export { formatCurrency, formatCurrencyWithoutFractional };
