import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as PDF } from "common/assets/images/icons/PowerPoint.svg";

const PowerPointFilled: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon style={ { "fontSize": "18px" } } { ...props } component={ PDF } />
);

export default PowerPointFilled;
