import { useEffect, useState } from "react";

import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { URLs } from "common/lib/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { getPageTitle } from "domains/trustben/shared/helpers";
import { useTrustbenSelector } from "domains/trustben";

const useTrustbenPageTracking = () => {
  const location = useLocation();
  const { LRId } = useParams();
  const [ searchParams ] = useSearchParams();

  const { user } = useClientsSelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const [ isTrustbenCookiesAccepted ] = useState(JSON.parse(searchParams.get("isCookiesAccepted") || "false"));

  const altQuoteResultsPage = LRId ? URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.ALTQUOTE : null;
  const isAltQuoteResultsPage = altQuoteResultsPage ? location.pathname === altQuoteResultsPage : false;
  const isCookiesAccepted = user?.user_performance_and_targeted_cookies ?? false;
  const tab = searchParams.get("tab") ? `?tab=${ searchParams.get("tab") }` : "";

  useEffect(() => {
    if (!window.gtag) return;

    if (
      (isTrustbenAltQuote && isTrustbenCookiesAccepted) ||
      (isAltQuoteResultsPage && isCookiesAccepted)
    ) {
      window.gtag("event", "page_view", {
        page_title: getPageTitle(location.pathname + tab),
        page_path: location.pathname + location.search,
      });
    }
  }, [ location, isCookiesAccepted, isAltQuoteResultsPage, isTrustbenAltQuote ]);
};

export default useTrustbenPageTracking;
