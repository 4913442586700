import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import clientsService from "domains/clients/shared/clients.api.service";
import {
  IAccountLevelDetails,
  IClientProfile,
  IUserClientsDetails,
  IUserDetails,
  UserType,
} from "domains/clients/shared/types";
import { apiService, router } from "common/shared";
import { TAppState } from "store/root.store";
import { ILiquidityRequest, TAssetType } from "domains/liquidity/shared/types";
import liquidityService from "domains/liquidity/shared/liquidity.api.service";
import { formatId } from "common/shared/helpers";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { flattenObj } from "common/shared/utils/object.utils";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";
import { setUser } from "./clients.reducer";
import { IUpdateAPIObject } from "common/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

export const clientsApiReducer = rootApiReducer
  .enhanceEndpoints({ addTagTypes: [ "userClients", "userLevelDetails", "accountLevelDetails", "clientAssets" ] })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchUserLevelDetails: build.query<
        IUserDetails,
        { accountId: string; userId: string; type?: "user" | "custody" }
      >({
        queryFn: async ({ accountId, userId, type }, { getState, dispatch }) => {
          try {
            const data = await clientsService.fetchUserLevelDetails(accountId, userId);

            if (data.user_type === UserType.Principal) {
              data.account_name = `${ data.first_name } ${ data.last_name }`;
            }

            if (type === "user") {
              dispatch(setUser(data));
            }

            if (type === "custody") {
              const currentCustodyAccount = (getState() as TAppState).custodyAccountsReducer.currentCustodyAccount;
              delete data.account_users;
              const formattedData = flattenObj(data);

              dispatch(
                setCurrentCustodyAccount({
                  ...currentCustodyAccount,
                  ...formattedData,
                  country_of_citizenship: formattedData?.tax_id_country || "",
                } as ICustodyAccount),
              );
            }

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, arg) => [ { type: "userLevelDetails", id: arg.accountId + arg.userId } ],
      }),

      updateUserLevelDetails: build.mutation<
        Record<string, any>,
        {
          accountId: string;
          userId: string;
          rawChanges: Record<string, any>;
          formattedChanges: IUpdateAPIObject[];
          type?: "client";
        }
      >({
        queryFn: async ({ accountId, userId, rawChanges, formattedChanges }) => {
          try {
            await clientsService.updateUserLevelDetails(accountId, userId, formattedChanges);

            return { data: rawChanges };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: (_result, _error, arg) =>
          arg.type === "client"
            ? [
                { type: "userLevelDetails", id: arg.accountId + arg.userId },
                { type: "userClients", id: "LIST" },
              ]
            : [ { type: "userLevelDetails", id: arg.accountId + arg.userId } ],
      }),

      fetchAccountLevelDetailsById: build.query<IAccountLevelDetails, { accountId: string; type?: "user" | "custody" }>(
        {
          queryFn: async ({ accountId, type }, { getState, dispatch }) => {
            try {
              const accId = formatId("ACC-", accountId);
              const data = await clientsService.fetchAccountLevelDetailsById(accId);

              if (type === "user") {
                const user = (getState() as TAppState).clientsReducer.user;

                dispatch(
                  setUser({
                    ...user,
                    institution_data: data.institution_data,
                  } as IUserDetails),
                );
              }

              if (type === "custody") {
                const currentCustodyAccount = (getState() as TAppState).custodyAccountsReducer.currentCustodyAccount;
                delete data.account_users;
                const formattedData = flattenObj(data);

                dispatch(
                  setCurrentCustodyAccount({
                    ...currentCustodyAccount,
                    ...formattedData,
                    country_of_incorporation: formattedData?.tax_id_country || "",
                  } as ICustodyAccount),
                );
              }

              return { data };
            } catch (error) {
              return apiService.formatResponseError(error as AxiosError);
            }
          },
          providesTags: (_result, _error, arg) => [ { type: "accountLevelDetails", id: arg.accountId } ],
        },
      ),

      updateAccountLevelDetails: build.mutation<
        Record<string, any>,
        { accountId: string; rawChanges: Record<string, any>; formattedChanges: IUpdateAPIObject[] }
      >({
        queryFn: async ({ accountId, rawChanges, formattedChanges }) => {
          try {
            await clientsService.updateAccountLevelDetails(formattedChanges, accountId);

            return { data: rawChanges };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: (_result, _error, arg) => [ { type: "accountLevelDetails", id: arg.accountId } ],
      }),

      fetchUserClients: build.query<IUserClientsDetails, { accountId: string; userId: string }>({
        queryFn: async ({ accountId, userId }) => {
          try {
            const clients = await clientsService.fetchUserClients(accountId, userId);

            return { data: clients };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: () => [ { type: "userClients", id: "LIST" } ],
      }),

      createClientFromDummyAccount: build.mutation<
        Partial<IUserDetails>,
        { accountId: string; formattedData: Partial<IUserDetails> }
      >({
        queryFn: async ({ accountId, formattedData }) => {
          try {
            await clientsService.createClientFromDummyAccount(accountId, formattedData);

            return { data: formattedData };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: [ { type: "userClients", id: "LIST" } ],
      }),

      addNewClient: build.mutation<
        null,
        {
          data: IClientProfile;
          isLRCreation: boolean;
          isIndividual: boolean;
          isCompleteFullClientCheckboxChecked?: boolean;
          isLRSharing?: boolean;
          isConvertingAdvisorAltQuoteWithoutClient?: boolean;
        }
      >({
        queryFn: async (
          {
            data,
            isLRCreation,
            isIndividual,
            isCompleteFullClientCheckboxChecked,
            isLRSharing,
            isConvertingAdvisorAltQuoteWithoutClient,
          },
          { getState, dispatch },
        ) => {
          try {
            const { account_id, user_id } = (getState() as TAppState).identityReducer.identity!;

            const responseAddClient = await clientsService.addNewClient(
              data,
              isLRCreation,
              isIndividual,
              isCompleteFullClientCheckboxChecked,
            );

            await clientsService.addNewClientToAdvisor(
              account_id,
              user_id,
              responseAddClient.account_id,
              data?.isAnonymous || false,
            );

            if (!isLRCreation && !isLRSharing && !isConvertingAdvisorAltQuoteWithoutClient) {
              router.navigate(URLs.PROTECTED.MY_CLIENTS);
            }

            if (isLRCreation && !isConvertingAdvisorAltQuoteWithoutClient) {
              const accountName = data?.company_name || `${ data.first_name } ${ data.last_name }`;
              const client = { ...data, ...responseAddClient, account_name: accountName };
              const isLA = JSON.parse(sessionStorage.getItem("IS_LIQUIDITY_APPLICATION")!);
              sessionStorage.removeItem("IS_LIQUIDITY_APPLICATION");

              dispatch(
                liquidityApiReducer.endpoints.createLiquidityRequest.initiate(
                  { isLA, client },
                  { fixedCacheKey: "create-liquidity-request" },
                ),
              );
              router.navigate(URLs.PROTECTED.LIQUIDITY + LAPages.SelectAssets);
            }

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: [ { type: "userClients", id: "LIST" } ],
      }),

      fetchCurrentClientAssets: build.query<TAssetType[], string>({
        queryFn: async (accountId, { getState }) => {
          try {
            const liquidityRequests = liquidityApiReducer.endpoints.fetchLiquidityRequests
              // @ts-ignore
              .select()(getState() as TAppState).data as ILiquidityRequest[];
            const currentClientLiquidityRequests = liquidityRequests.filter((lr) =>
              accountId?.includes(`${ lr?.client.account_id }`),
            );

            const assets = (
              await Promise.all(currentClientLiquidityRequests.map((lr) => liquidityService.fetchLRAssets(lr.id)))
            ).flat();

            return { data: assets };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, arg) => [ { type: "clientAssets", id: arg } ],
      }),

      acceptCookies: build.mutation<null, boolean>({
        queryFn: async (switchValue) => {
          try {
            if (!switchValue) {
              document.cookie = "_ga=; Path=/; Domain=.bankben.org; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
              document.cookie = "_ga_1EJ51ERQRW=; Path=/; Domain=.bankben.org; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            }

            await clientsService.acceptCookies(switchValue);

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
