import { AxiosError } from "axios";
import * as rdd from "react-device-detect";
import lscache from "lscache";
import { ClientPasswordChallenge, UserPool } from "cognito-srp";

import { apiEnvs, apiService, router, sessionService, tokenDecodeUtils } from "common/shared";
import { rootApiReducer } from "store/root.api.reducer";
import {
  IAcceptIdentityParams,
  IAcceptIdentityResponseData,
  IResetPasswordParams,
  IForgotPasswordParams,
  IResendWelcomeEmailParams,
  ISetMFAAuthenticationTypeRequestParams,
  ISetMFAAuthenticationTypeResponseData,
  IVerifyLoginPasswordParams,
  IVerifyLoginPasswordWithMFAResponseData,
  ISetPasswordParams,
  IVerifyIdentificationCodeParams,
  IVerifyIdentificationCodeResponseDataSuccess,
  IRememberLoginDeviceParams,
  ILoginParams,
  ILoginUsingRememberedDeviceParams,
} from "domains/authentication/shared/types";
import authService from "domains/authentication/shared/auth.api.service";
import { URLs } from "common/lib/constants";
import { resendReqDataSave, resetAuthReducer, setAcceptIdentityDataLinkWasUsed, setIsAuth } from "./auth.reducer";
import {
  FORGOT_PASSWORD_SUCCESS_MESSAGE,
  INVALID_CODE_MESSAGE,
  LOGIN_FAIL_MESSAGE,
  LOGIN_SUCCESS_MESSAGE,
  SET_PASSWORD_SUCCESS_MESSAGE,
  WRONG_EMAIL_OR_PASSWORD_MESSAGE,
} from "domains/authentication/shared/constants";
import { TAppState } from "store/root.store";
import { encryptValue } from "common/shared/utils/rsa.encryption.utils";
import { generateRandomString } from "common/shared/helpers";
import { setIsTrustbenUserAuthenticated } from "domains/trustben/store/trustben.reducer";
import { resetClientsReducer } from "domains/clients/store/clients.reducer";
import { resetIdentityReducer } from "domains/identity/store/identity.reducer";
import { resetDocumentsReducer } from "domains/documents/store/documents.reducer";
import { resetLiquidityReducer } from "domains/liquidity/store/liquidity.reducer";
import { resetMyProfileReducer } from "domains/myProfile/store/myProfile.reducer";
import { resetProposalsReducer } from "domains/proposals/store/proposals.reducer";
import { resetAltQuotesReducer } from "domains/altQuotes/store/altQuotes.reducer";

const getUserPool = async () => {
  const { userPoolId } = await apiEnvs.getApiEnv();
  const UserPoolId = userPoolId;
  const poolname = UserPoolId.split("_")[ 1 ];
  const userPool = new UserPool(poolname);

  return userPool;
};

let Challenge: ClientPasswordChallenge;
let Psw: string;
let device_key_frm_cky = "";
let device_grpkey_frm_cky = "";
let device_password = "";
let objDevice = rdd.deviceDetect("");

const generateSrpToken = async (userPool: UserPool, username: string, password: string) => {
  Psw = password;
  const challenge = await userPool.getClientChallenge({ username, password });
  Challenge = challenge;
  const srp_a = challenge.calculateA().toString("hex");

  return srp_a;
};

const getTimeStamp = (): string => {
  const monthW = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];
  const weekday = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ];
  const d = new Date();
  const month = monthW[ d.getUTCMonth() ];
  const year = d.getUTCFullYear();
  const day = d.getUTCDate();
  const dayw = weekday[ d.getUTCDay() ];
  const time = `${ d.getUTCHours() <= 9 ? "0" + d.getUTCHours() : d.getUTCHours() }:${
    d.getUTCMinutes() <= 9 ? "0" + d.getUTCMinutes() : d.getUTCMinutes()
  }:${ d.getUTCSeconds() <= 9 ? "0" + d.getUTCSeconds() : d.getUTCSeconds() }`;

  return dayw + " " + month + " " + day + " " + time + " UTC " + year;
};

const rememberLoginDevice = async (data: IVerifyIdentificationCodeResponseDataSuccess, user_name: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const randomPassword = generateRandomString(32);
    const loginDeviceParams: IRememberLoginDeviceParams = {
      access_token: data.access_token,
      device_key: data.device_key,
      device_name: objDevice.browserName,
      device_group_key: data.device_group_key,
      device_password: randomPassword,
      user_name,
    };

    await authService.rememberLoginDevice(loginDeviceParams);

    lscache.flushExpired();
    lscache.setExpiryMilliseconds(1);
    //         day  hour  min  sec  ms
    const ttl = 90 * 24 * 60 * 60 * 1000; // 90 days

    lscache.set(user_name + "_" + "device_key", data.device_key, ttl);
    lscache.set(user_name + "_" + "device_group_key", data.device_group_key, ttl);
    lscache.set(user_name + "_" + "device_password", randomPassword, ttl);
  } catch (error: any) {
    throw error;
  }
};

export const authApiReducer = rootApiReducer.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<string, ILoginParams>({
      queryFn: async (data, { dispatch }) => {
        try {
          const { oAuthClientId } = await apiEnvs.getApiEnv();
          const userPool: UserPool = await getUserPool();
          const srp_a = await generateSrpToken(userPool, data.user_name, data.password);

          device_key_frm_cky = lscache.get(data.user_name + "_" + "device_key");
          device_grpkey_frm_cky = lscache.get(data.user_name + "_" + "device_group_key");
          device_password = lscache.get(data.user_name + "_" + "device_password");

          if (device_key_frm_cky && device_grpkey_frm_cky && device_password) {
            data.device_key = device_key_frm_cky;
            data.device_group_key = device_grpkey_frm_cky;
            data.device_password = device_password;
          }

          const loginResponseData = await authService.login({ ...data, srp_a });

          if (loginResponseData.mfa_challenge_name === "account_locked") {
            router.navigate(URLs.AUTH.ACCOUNT_BLOCKED);

            throw new Error("");
          }

          Challenge.user.username = loginResponseData.user_id_for_srp;
          const timestamp: string = getTimeStamp();
          const session = Challenge.getSession(loginResponseData.srp_b, loginResponseData.salt);
          const signature = session.calculateSignature(loginResponseData.secret_block, timestamp);
          const verifyLoginPasswordParams: IVerifyLoginPasswordParams = {
            password_claim_secret_block: loginResponseData.secret_block,
            password_claim_signature: signature,
            user_name: data.user_name,
            password: Psw,
            timestamp: timestamp,
            srp_b: loginResponseData.srp_b,
            salt: loginResponseData.salt,
            device_key: device_key_frm_cky,
            device_group_key: device_grpkey_frm_cky,
            mfa_session: loginResponseData.session_id,
          };

          const verifyLoginPasswordResponseData = await authService.verifyLoginPassword(verifyLoginPasswordParams);

          if (verifyLoginPasswordResponseData.mfa_challenge_name === "account_locked") {
            router.navigate(URLs.AUTH.ACCOUNT_BLOCKED);

            throw new Error("");
          }

          if (
            verifyLoginPasswordResponseData.mfa_challenge_name === 0 &&
            "id_token" in verifyLoginPasswordResponseData
          ) {
            sessionService.setTokens(
              verifyLoginPasswordResponseData,
              oAuthClientId,
              sessionService.getGeneratedBrowserSessionId(),
            );
            sessionService.setUserName(data.user_name);
            sessionService.syncBrowserSessionId();

            dispatch(setIsAuth(true));
            return { data: LOGIN_SUCCESS_MESSAGE };
          }

          if (
            verifyLoginPasswordResponseData &&
            "session_id" in verifyLoginPasswordResponseData &&
            !device_key_frm_cky &&
            !device_grpkey_frm_cky
          ) {
            sessionStorage.setItem("session_id", verifyLoginPasswordResponseData.session_id);
            router.navigate(
              `${ URLs.AUTH.AUTHENTICATION }?session_id=${ verifyLoginPasswordResponseData.session_id }&user_name=${ data.user_name }&sms_delivery_destination=${ verifyLoginPasswordResponseData.sms_delivery_destination }&email_delivery_destination=${ verifyLoginPasswordResponseData.email_delivery_destination }&sms_delivery_destination_is_domestic=${ verifyLoginPasswordResponseData.sms_delivery_destination_is_domestic }`,
            );

            dispatch(resendReqDataSave(verifyLoginPasswordParams));
            return { data: "" };
          }

          const rememberedDeviceParams: ILoginUsingRememberedDeviceParams = {
            device_key: device_key_frm_cky,
            device_group_key: device_grpkey_frm_cky,
            device_password: device_password,
            user_name: data.user_name,
          };

          const loginUsingRememberedDeviceResponseData =
            await authService.loginUsingRememberedDevice(rememberedDeviceParams);

          sessionService.setTokens(
            loginUsingRememberedDeviceResponseData,
            oAuthClientId,
            sessionService.getGeneratedBrowserSessionId(),
          );
          sessionService.setUserName(data.user_name);
          sessionService.syncBrowserSessionId();

          sessionStorage.removeItem("retry-sign-in");

          dispatch(setIsAuth(true));
          return { data: LOGIN_SUCCESS_MESSAGE };
        } catch (err) {
          const error = err as AxiosError;
          const formattedError = apiService.formatResponseError(err as AxiosError);

          if (
            error.config?.data?.includes("password_verifier") &&
            !JSON.parse(sessionStorage.getItem(`retry-sign-in`) || "false")
          ) {
            sessionStorage.setItem("retry-sign-in", "true");
            setTimeout(() => dispatch(authApiReducer.endpoints.login.initiate(data)), 3000);

            formattedError.error.data = WRONG_EMAIL_OR_PASSWORD_MESSAGE;
            return formattedError;

            // @ts-ignore
          } else if (error?.loginResponseData?.status === 401) {
            formattedError.error.data = WRONG_EMAIL_OR_PASSWORD_MESSAGE;
            return formattedError;
          } else if (error.message === "Invalid hex string") {
            // In rare cases we receive an invalid spr_b key from the server
            // with an odd number of digits (must be an even number of digits)
            // This breaks the logic, in which case we automatically restart the login process
            dispatch(authApiReducer.endpoints.login.initiate(data));
            return formattedError;
          } else {
            formattedError.error.data = LOGIN_FAIL_MESSAGE;
            return formattedError;
          }
        }
      },
    }),

    acceptIdentity: build.mutation<IAcceptIdentityResponseData, IAcceptIdentityParams>({
      queryFn: async (data, { dispatch }) => {
        try {
          const response = await authService.acceptIdentity(data);
          const { token } = response;
          const isUserLoggedIn = sessionService.isAuthenticated();

          // identity exists if there is no tokenForPass
          if (!token && isUserLoggedIn) {
            router.navigate(URLs.PROTECTED.DASHBOARD);
          }

          if (!token && !isUserLoggedIn) {
            router.navigate(URLs.AUTH.SIGN_IN);
          }

          if (token) {
            router.navigate(
              `${ URLs.AUTH.SET_PASSWORD }?token=${ token }&preventSendingEmail=true${
                data.acceptedAgreementValue ? `&acceptedAgreementValue=${ data.acceptedAgreementValue }` : ""
              }&redirectedFrom=${ data.redirectedFrom }`,
            );
          }

          return { data: response };
        } catch (error) {
          const err = apiService.formatResponseError(error as AxiosError);
          if (err.error.status === 401) {
            dispatch(setAcceptIdentityDataLinkWasUsed(true));
          }

          // For all others error statuses we do not have cases, so redirect too
          router.navigate(URLs.AUTH.RESEND_WELCOME_EMAIL);
          return err;
        }
      },
    }),

    forgotPassword: build.mutation<string, IForgotPasswordParams>({
      queryFn: async (data) => {
        try {
          await authService.forgotPassword(data);

          return { data: FORGOT_PASSWORD_SUCCESS_MESSAGE };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    resendSharingInvitation: build.mutation<
      null,
      {
        token: string;
        liquidity_request_id: string;
        sharing_id: string;
      }
    >({
      queryFn: async ({ token, liquidity_request_id, sharing_id }) => {
        try {
          await authService.resendSharingInvitation(token, liquidity_request_id, sharing_id);

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    resendWelcomeEmail: build.mutation<null, IResendWelcomeEmailParams>({
      queryFn: async (data) => {
        try {
          await authService.resendWelcomeEmail(data);

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    sendIdentificationCode: build.mutation<string, IVerifyIdentificationCodeParams>({
      queryFn: async (data, { dispatch }) => {
        try {
          let isSuccess = true;
          const { oAuthClientId } = await apiEnvs.getApiEnv();
          const response = await authService.verifyIdentificationCode(data);

          if (response.mfa_challenge_name == "account_locked") {
            router.navigate(URLs.AUTH.ACCOUNT_BLOCKED);
            isSuccess = false;
          } else if (response.mfa_challenge_name == "mfa_code_expired") {
            router.navigate(URLs.AUTH.CODE_EXPIRED);
            isSuccess = false;
          } else if (response.mfa_challenge_name === "mfa_code" && "session_id" in response) {
            router.navigate(
              `${ URLs.AUTH.VERIFY_IDENTIFICATION_CODE }?session_id=${ response.session_id }&user_name=${ data.user_name }`,
            );

            throw new Error(INVALID_CODE_MESSAGE);
          }

          if (data.is_remember_device && isSuccess && "device_key" in response) {
            rememberLoginDevice(response, data.user_name);
          }

          if (isSuccess && "id_token" in response) {
            sessionService.setTokens(response, oAuthClientId, sessionService.getGeneratedBrowserSessionId());
            sessionService.setUserName(data.user_name);
            sessionService.syncBrowserSessionId();
          }

          if (!isSuccess) throw new Error("");

          dispatch(setIsAuth(true));
          return { data: SET_PASSWORD_SUCCESS_MESSAGE };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    resendIdentificationCode: build.mutation<null, IVerifyLoginPasswordParams>({
      queryFn: async (params, { dispatch, getState }) => {
        try {
          const data = { ...params };

          const authentication_type = (getState() as TAppState).authReducer.identificationCode.authentication_type!;
          let userPool: UserPool = await getUserPool();
          const srp_a = await generateSrpToken(userPool, data.user_name, data.password);
          const loginResponseData = await authService.login({ ...data, srp_a });

          if (loginResponseData.mfa_challenge_name === "account_locked") {
            router.navigate(URLs.AUTH.ACCOUNT_BLOCKED);
          }

          Challenge.user.username = loginResponseData.user_id_for_srp;
          const timestamp: string = getTimeStamp();
          const session = Challenge.getSession(loginResponseData.srp_b, loginResponseData.salt);
          const signature = session.calculateSignature(loginResponseData.secret_block, timestamp);
          data.password_claim_secret_block = loginResponseData.secret_block;
          data.password_claim_signature = signature;
          data.timestamp = timestamp;
          data.mfa_session = loginResponseData.session_id;

          const verifyLoginPasswordResponseData = (await authService.verifyLoginPassword(
            data,
          )) as IVerifyLoginPasswordWithMFAResponseData;

          if (verifyLoginPasswordResponseData.mfa_challenge_name === "account_locked") {
            router.navigate(URLs.AUTH.ACCOUNT_BLOCKED);

            return { data: null };
          }

          const setMFAAuthenticationTypeParams = {
            session_id: verifyLoginPasswordResponseData.session_id,
            user_name: data.user_name,
            authentication_type,
          };

          const setMFAAuthenticationTypeResponseData =
            await authService.setMFAAuthenticationType(setMFAAuthenticationTypeParams);

          router.navigate(
            `${ URLs.AUTH.VERIFY_IDENTIFICATION_CODE }?session_id=${ setMFAAuthenticationTypeResponseData.session_id }&user_name=${ data.user_name }`,
          );

          const verifyLoginPasswordParams: IVerifyLoginPasswordParams = {
            password_claim_secret_block: loginResponseData.secret_block,
            password_claim_signature: signature,
            user_name: data.user_name,
            password: Psw,
            timestamp: timestamp,
            srp_b: loginResponseData.srp_b,
            salt: loginResponseData.salt,
            device_key: device_key_frm_cky,
            device_group_key: device_grpkey_frm_cky,
          };

          dispatch(resendReqDataSave(verifyLoginPasswordParams));

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    setMFAAuthenticationType: build.mutation<
      ISetMFAAuthenticationTypeResponseData,
      ISetMFAAuthenticationTypeRequestParams
    >({
      queryFn: async (data) => {
        try {
          const response = await authService.setMFAAuthenticationType(data);

          router.navigate(
            `${ URLs.AUTH.VERIFY_IDENTIFICATION_CODE }?session_id=${ response.session_id }&user_name=${
              data.user_name
            }&code_delivery_destination=${ response.code_delivery_destination.replace("+", "%2B") }&authentication_type=${
              response.code_delivery_delivery_medium
            }`,
          );

          return { data: response };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    setPassword: build.mutation<ISetPasswordParams, ISetPasswordParams>({
      queryFn: async (data) => {
        try {
          let saltValue;
          let encryptedPassword;
          const { featureEncryptPassword, publicKey } = await apiEnvs.getApiEnv();

          if (featureEncryptPassword === "On") {
            const { encryptedValue, salt } = encryptValue(data.password, publicKey);
            saltValue = salt;
            encryptedPassword = encryptedValue;
          }

          const { resetToken } = data;
          const decodedTokenData = tokenDecodeUtils.decodeResetPassToken(resetToken);
          await authService.setPassword({
            ...data,
            password: encryptedPassword || data.password,
            salt: saltValue || "",
            userEmail: decodedTokenData && decodedTokenData.userEmail,
          });

          return { data };
        } catch (error) {
          const { error: err } = apiService.formatResponseError(error as AxiosError);

          if (err?.data?.fields?.mfa_phone_number?.error_code === "error.mfaphonenumber.reused") {
            err.data = "Previously used phone number cannot be repeated.";
          }
          if (err?.data?.fields?.password?.error_code === "error.password.reused") {
            err.data = "Previously used passwords cannot be repeated.";
          }

          return { error: err };
        }
      },
    }),

    resetPassword: build.mutation<null, IResetPasswordParams>({
      queryFn: async (data) => {
        try {
          const response = await authService.acceptResetPassword(data);
          const { token, accepted_terms } = response;

          if (!token) {
            router.navigate(URLs.AUTH.ERROR);
          } else {
            router.navigate(
              `${ URLs.AUTH.SET_PASSWORD }?token=${ token }&preventSendingEmail=true&redirectedFrom=${ data.redirectedFrom }&acceptedAgreementValue=${ accepted_terms }`,
            );
          }

          return { data: null };
        } catch (error) {
          // if error status 401, token was already used - we do not have pages or messages for this
          // case
          router.navigate(`${ URLs.AUTH.RESET_PASSWORD_EXPIRED }?token=${ data.token }&resetMode=true`);

          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    logout: build.mutation<null, void>({
      queryFn: async (_, { dispatch }) => {
        try {
          sessionService.clearSession();

          dispatch(setIsAuth(false));
          dispatch(setIsTrustbenUserAuthenticated(false));
          dispatch(resetAuthReducer());
          dispatch(resetClientsReducer());
          dispatch(resetIdentityReducer());
          dispatch(resetDocumentsReducer());
          dispatch(resetLiquidityReducer());
          dispatch(resetMyProfileReducer());
          dispatch(resetProposalsReducer());
          dispatch(resetAltQuotesReducer());
          dispatch(rootApiReducer.util.resetApiState());

          return { data: null };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),
  }),
});
