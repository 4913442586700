import { object, bool, string, mixed } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";
import { useValidationSchemas } from "common/shared/constants/validationSchemas";
import { AccountType } from "common/lib/constants";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";

export const useValidationSchema = () => {
  const { currentClient, isAdvisor } = useClientsSelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();

  const isAdvisorCustodyAccount =
    (currentClient?.is_anonymous ||
      currentCustodyAccount?.account_type === AccountType.CustodialAdvisor ||
      currentCustodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
    false;

  const { required } = useValidationErrorMessages();
  const {
    addressLine1ValidationSchema,
    baseStringWithMaxValidationSchema,
    accountNameValidationSchema,
    onlyLettersValidationSchema,
    onlyLettersAndPunctuationAndDigitsValidationSchema,
    dateOfBirthValidationSchema,
    emailValidationSchema,
    phoneValidationSchema,
    ssnValidationSchema,
    stateValidationSchema,
    zipCodeValidationSchema,
    einValidationSchema,
    countryValidationSchema,
  } = useValidationSchemas();

  const validationSchema = object().shape({
    owner_type: string().required(required),
    account_name: accountNameValidationSchema.when([ "owner_type" ], ([ owner_type ], schema) => {
      return owner_type === AccountType.CustodialInstitution ? schema.required(required) : schema.notRequired().strip();
    }),
    country_of_incorporation: countryValidationSchema.when([ "owner_type" ], ([ owner_type ], schema) => {
      return owner_type === AccountType.CustodialInstitution ? schema.required(required) : schema.notRequired();
    }),
    tax_id: mixed()
      .when([ "owner_type" ], ([ owner_type ], schema) => {
        return owner_type === AccountType.CustodialInstitution ? schema.required(required) : schema.notRequired();
      })
      .when([ "country_of_incorporation" ], ([ country_of_incorporation ], schema) => {
        return country_of_incorporation === "United States"
          ? schema.concat(einValidationSchema)
          : schema.concat(onlyLettersAndPunctuationAndDigitsValidationSchema);
      }),
    first_name: onlyLettersValidationSchema.required(required),
    last_name: onlyLettersValidationSchema.required(required),
    country: countryValidationSchema.required(required),
    address_line_1: addressLine1ValidationSchema.required(required),
    address_line_2: baseStringWithMaxValidationSchema,
    city: onlyLettersValidationSchema.required(required),
    state: string().when([ "country" ], ([ country ]) => {
      return country === "United States"
        ? stateValidationSchema.required(required)
        : onlyLettersAndPunctuationAndDigitsValidationSchema.required(required);
    }),
    zip_code: string().when([ "country" ], ([ country ]) => {
      return country === "United States"
        ? zipCodeValidationSchema.required(required)
        : onlyLettersAndPunctuationAndDigitsValidationSchema.required(required);
    }),
    country_of_citizenship: countryValidationSchema.required(required),
    ssn: mixed().when([ "country_of_citizenship" ], ([ country_of_citizenship ]) => {
      return country_of_citizenship === "United States"
        ? ssnValidationSchema.required(required)
        : onlyLettersAndPunctuationAndDigitsValidationSchema.required(required);
    }),
    date_of_birth: dateOfBirthValidationSchema.required(required),
    telephone_number: phoneValidationSchema.required(required),
    email_address: emailValidationSchema.required(required),
    custody_agreement: bool()
      .oneOf([ true ], "License and Custody Account Agreement must be accepted")
      .required(required),
  });

  const advisorValidationSchema = object().shape({
    advisory_firm_country_of_incorporation: countryValidationSchema.when([], (_, schema) => {
      return isAdvisorCustodyAccount ? schema.notRequired() : schema.required(required);
    }),
    advisory_firm_tax_id: mixed()
      .when([ "advisory_firm_country_of_incorporation" ], ([ advisory_firm_country_of_incorporation ], schema) => {
        return advisory_firm_country_of_incorporation === "United States"
          ? schema.concat(einValidationSchema)
          : schema.concat(onlyLettersAndPunctuationAndDigitsValidationSchema);
      })
      .when([], (_, schema) => {
        return isAdvisorCustodyAccount ? schema.notRequired() : schema.required(required);
      }),
  });

  return isAdvisor ? validationSchema.concat(advisorValidationSchema) : validationSchema;
};
