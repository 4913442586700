const getChangedFormValues = (formValues: any, initialValues: any) => {
  return Object.entries(formValues).reduce((acc, [ key, value ]) => {
    const hasChanged = String(initialValues[ key ]) !== String(value);
    if (hasChanged) {
      // @ts-ignore
      acc[ key ] = String(value);
    }

    return acc;
  }, {});
};

export default getChangedFormValues;
