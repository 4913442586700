import { FC } from "react";

import { Table } from "antd";
import { useTranslation } from "react-i18next";

import { useScreen } from "common/shared-hooks";
import { Button } from "common/lib/components";
import MobileTableCell from "./MobileTableCell";
import { useHandlers } from "./useHandlers";
import { useTrustbenSelector } from "domains/trustben";
import { IFundMobile, IFundDesktop } from "./types";
import { defaultDesktopColumns, defaultMobileColumns } from "./constants";
import AddManually from "./AddManually";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./SelectFundTable.scss";

interface IProps {
  inputValue: string;
}

const SelectFundTable: FC<IProps> = ({ inputValue }) => {
  const { t } = useTranslation([ "liquidity", "common" ]);

  const [ _, { data: foundFunds = [] } ] = liquidityApiReducer.useSearchByFundNameMutation({
    fixedCacheKey: "search-by-fund-name",
  });
  const { mobile } = useScreen();
  const { onSelect } = useHandlers();
  const { isTrustbenAltQuote } = useTrustbenSelector();

  const desktopColumns = [
    ...defaultDesktopColumns,
    {
      title: "",
      key: "action",
      className: "ben-w-20",
      align: "right",
      render: (value: IFundDesktop) => (
        <Button
          type={ isTrustbenAltQuote ? "primary" : "default" }
          onClick={ () => onSelect(value.fundName, value.fundManager, value.key) }
        >
          {t("Select", { ns: "common" })}
        </Button>
      ),
    } as const,
  ];

  const mobileColumns = [
    ...defaultMobileColumns,
    {
      title: "",
      key: "action",
      className: "ben-w-30",
      align: "right",
      render: (value: IFundMobile) => (
        <Button
          type={ isTrustbenAltQuote ? "primary" : "default" }
          onClick={ () => onSelect(value.fund.props.fundName, value.fundManager, value.key) }
        >
          {t("Select", { ns: "common" })}
        </Button>
      ),
    } as const,
  ];

  const getDataForDesktop = (): IFundDesktop[] => {
    return foundFunds.map((item) => ({
      key: item.id,
      fundManager: item.fundManager,
      fundName: item.fundName,
    }));
  };

  const getDataForMobile = (): IFundMobile[] => {
    return foundFunds.map((item) => ({
      key: item.id,
      fundManager: item.fundManager,
      fund: <MobileTableCell fundManager={ item.fundManager } fundName={ item.fundName } />,
    }));
  };

  if (!inputValue) {
    return null;
  }

  return (
    <div className="select-fund-table-container">
      {!!foundFunds.length && (
        <Table
          className="select-fund-table ben-pb-3"
          pagination={ false }
          columns={ mobile ? mobileColumns : desktopColumns }
          // @ts-ignore
          dataSource={ mobile ? getDataForMobile() : getDataForDesktop() }
          scroll={ { y: 250 } }
        />
      )}

      <AddManually />
    </div>
  );
};

export default SelectFundTable;
