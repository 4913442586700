import { Note, OptionGroup, OptionType } from "domains/altQuotes/shared/constants";
import { IAltQuoteOffers, IAltQuoteOffersOptions } from "domains/altQuotes/shared/types";

export const formatAltQuoteOffersForReducer = (response: any) => {
  const formattedResponse: IAltQuoteOffers = {
    alt_quote_id: response[ 0 ].alt_quote_id,
    fundOffers: [],
    offers: [],
  };

  formattedResponse.offers.push(...getAltQuoteOfferOffers(response));
  formattedResponse.fundOffers.push(...getAltQuoteFundOffers(response));

  return formattedResponse;
};

const getOfferOption = (item: IAltQuoteOffersOptions) => {
  if (item.option_group === OptionGroup.LiquidTrust) {
    return "Cash";
  }

  if (item.option_group === OptionGroup.ExchangeBond) {
    return "Bonds";
  }

  if (item.option_group === OptionGroup.ExchangePreferred) {
    return "Ben Preferred Stock";
  }

  if (item.option_group === OptionGroup.ExchangeCommon) {
    return "Ben Common Stock";
  }

  if (item.option_group === OptionGroup.InterchangeBond && item.option_type === OptionType.CashRange) {
    return "Cash";
  }

  if (item.option_group === OptionGroup.InterchangeBond && item.option_type === OptionType.BondRange) {
    return "Bonds";
  }

  if (item.option_group === OptionGroup.InterchangeBond) {
    return "Cash & Bonds";
  }

  if (item.option_group === OptionGroup.InterchangePreferred && item.option_type === OptionType.CashRange) {
    return "Cash";
  }

  if (item.option_group === OptionGroup.InterchangePreferred && item.option_type === OptionType.PreferredRange) {
    return "Ben Preferred Stock";
  }

  if (item.option_group === OptionGroup.InterchangePreferred) {
    return "Cash & Ben Preferred Stock";
  }

  if (item.option_group === OptionGroup.InterchangeCommon && item.option_type === OptionType.CashRange) {
    return "Cash";
  }

  if (item.option_group === OptionGroup.InterchangeCommon && item.option_type === OptionType.CommonRange) {
    return "Ben Common Stock";
  }

  if (item.option_group === OptionGroup.InterchangeCommon) {
    return "Cash & Ben Common Stock";
  }
};

const getReturnRate = (item: IAltQuoteOffersOptions) => {
  if (item.option_group === OptionGroup.ExchangeBond && item.option_type === OptionType.None) {
    return Note.Annual;
  }

  if (item.option_group === OptionGroup.InterchangeBond && item.option_type === OptionType.None) {
    return Note.Annual;
  }

  if (item.option_group === OptionGroup.ExchangePreferred && item.option_type === OptionType.None) {
    return Note.Return;
  }

  if (item.option_group === OptionGroup.InterchangePreferred && item.option_type === OptionType.None) {
    return Note.Return;
  }
};

const getFilterType = (item: IAltQuoteOffersOptions) => {
  if (item.option_group.includes("preferred")) {
    return "preferred";
  }

  if (item.option_group.includes("common")) {
    return "common";
  }
};

const getSubscriptText = (item: IAltQuoteOffersOptions) => {
  if (item.option_group === OptionGroup.InterchangeBond && item.option_type === OptionType.None) {
    return item.subscript_text;
  }

  if (item.option_group === OptionGroup.InterchangePreferred && item.option_type === OptionType.None) {
    return item.subscript_text;
  }

  if (item.option_group === OptionGroup.InterchangeCommon && item.option_type === OptionType.None) {
    return item.subscript_text;
  }

  return null;
};

const getAltQuoteFundOffers = (response: any) => {
  response = response.filter((item: IAltQuoteOffersOptions) => item.asset_name !== "All");
  const uniqData: string[] = [];
  response = response.filter((item: IAltQuoteOffersOptions) => {
    const isDuplicate = uniqData.includes(item.asset_name + item.option_group);

    if (!isDuplicate) {
      uniqData.push(item.asset_name + item.option_group);
      return true;
    }

    return false;
  });

  const offers: IAltQuoteOffersOptions[] = [];

  response.forEach((item: IAltQuoteOffersOptions) => {
    const subOfferArray = offers[ offers.length - 1 ]?.offer_sub_options;

    if (
      item.asset_name !== "All" &&
      item.asset_name === offers[ offers.length - 1 ]?.asset_name &&
      offers[ offers.length - 1 ]?.offer_sub_options[ subOfferArray.length - 1 ]?.offer_option!.includes("&") &&
      offers[ offers.length - 1 ]?.offer_sub_options[ subOfferArray.length - 1 ].offer_sub_options.length < 2
    ) {
      offers[ offers.length - 1 ].offer_sub_options[ subOfferArray.length - 1 ].offer_sub_options.push({
        alt_quote_asset_id: item.alt_quote_asset_id,
        fund_id: item.fund_id,
        asset_name: item.asset_name,
        current_net_asset_value: item.current_net_asset_value,
        percent_of_nav_low: item.percent_of_nav_low,
        percent_of_nav_high: item.percent_of_nav_high,
        offer_low_currency: item.offer_low_currency,
        offer_high_currency: item.offer_high_currency,
        option_group: item.option_group,
        option_type: item.option_type,
        type: "fund",
        offer_option: getOfferOption(item),
        return_rate: getReturnRate(item),
        filter_type: getFilterType(item),
        offer_sub_options: item.offer_sub_options || [],
      });
      return;
    }

    if (item.asset_name !== "All" && item.asset_name === offers[ offers.length - 1 ]?.asset_name) {
      offers[ offers.length - 1 ].offer_sub_options.push({
        alt_quote_asset_id: item.alt_quote_asset_id,
        fund_id: item.fund_id,
        asset_name: item.asset_name,
        current_net_asset_value: item.current_net_asset_value,
        percent_of_nav_low: item.percent_of_nav_low,
        percent_of_nav_high: item.percent_of_nav_high,
        offer_low_currency: item.offer_low_currency,
        offer_high_currency: item.offer_high_currency,
        option_group: item.option_group,
        option_type: item.option_type,
        type: "fund",
        offer_option: getOfferOption(item),
        return_rate: getReturnRate(item),
        filter_type: getFilterType(item),
        offer_sub_options: item.offer_sub_options || [],
      });
      return;
    }

    if (item.asset_name !== "All") {
      offers.push({
        alt_quote_asset_id: item.alt_quote_asset_id,
        fund_id: item.fund_id,
        asset_name: item.asset_name,
        current_net_asset_value: item.current_net_asset_value,
        percent_of_nav_low: item.percent_of_nav_low,
        percent_of_nav_high: item.percent_of_nav_high,
        offer_low_currency: item.offer_low_currency,
        offer_high_currency: item.offer_high_currency,
        option_group: item.option_group,
        option_type: item.option_type,
        offer_option: item.asset_name,
        type: "fund",
        offer_sub_options: item.offer_sub_options || [],
        subscript_text: item.subscript_text,
      });
    }
    return;
  });

  return offers;
};

const getAltQuoteOfferOffers = (response: any) => {
  const offers: IAltQuoteOffersOptions[] = [];

  response.forEach((item: IAltQuoteOffersOptions) => {
    if (item.asset_name === "All" && item.option_group === offers[ offers.length - 1 ]?.option_group) {
      offers[ offers.length - 1 ].offer_sub_options.push({
        alt_quote_asset_id: item.alt_quote_asset_id,
        fund_id: item.fund_id,
        asset_name: item.asset_name,
        current_net_asset_value: item.current_net_asset_value,
        percent_of_nav_low: item.percent_of_nav_low,
        percent_of_nav_high: item.percent_of_nav_high,
        offer_low_currency: item.offer_low_currency,
        offer_high_currency: item.offer_high_currency,
        option_group: item.option_group,
        option_type: item.option_type,
        type: "offer",
        offer_option: getOfferOption(item),
        filter_type: getFilterType(item),
        offer_sub_options: item.offer_sub_options || [],
      });
      return;
    }

    if (item.asset_name === "All") {
      offers.push({
        alt_quote_asset_id: item.alt_quote_asset_id,
        fund_id: item.fund_id,
        asset_name: item.asset_name,
        current_net_asset_value: item.current_net_asset_value,
        percent_of_nav_low: item.percent_of_nav_low,
        percent_of_nav_high: item.percent_of_nav_high,
        offer_low_currency: item.offer_low_currency,
        offer_high_currency: item.offer_high_currency,
        option_group: item.option_group,
        option_type: item.option_type,
        type: "offer",
        offer_option: getOfferOption(item) || "",
        filter_type: getFilterType(item),
        return_rate: getReturnRate(item),
        subscript_text: getSubscriptText(item),
        offer_sub_options: item.offer_sub_options || [],
      });
    }
    return;
  });

  return offers;
};
