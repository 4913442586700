export const getDateDiffDays = (date1: Date, date2: Date) => {
  const diffTime = date1.getTime() - date2.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getDateFromISO = (date?: string, options?: Intl.DateTimeFormatOptions) => {
  if (!date) {
    return options
      ? new Date().toLocaleDateString(undefined, options)
      : new Date().toLocaleDateString();
  }

  // Add time zone designator, if it's missing
  const dateWithTZD = date.endsWith("Z") ? date : `${ date }Z`;

  return options
    ? new Date(dateWithTZD).toLocaleDateString(undefined, options)
    : new Date(dateWithTZD).toLocaleDateString();
};

export const getLocalDate = (date?: string | null) => {
  if (!date) return null;

  return new Date(date).toLocaleDateString();
};

export const addDays = (date: string, days: number) => {
  const dateWithTZD = date.endsWith("Z") ? date : `${ date }Z`;
  const newDate = new Date(dateWithTZD);
  newDate.setDate(newDate.getDate() + days);

  return newDate.toISOString();
};
