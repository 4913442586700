export const keyFields = [
  "first_name",
  "last_name",
  "account_name",
  "ssn",
  "tax_id",
  "country_of_citizenship",
  "country_of_incorporation",
  "advisory_firm_tax_id",
];

export const userDetailsFields = [
  "email_address",
  "telephone_number",
  "date_of_birth",
  "country_code",
  "phone_country",
];

export const advisorDetailsFields = [ "advisory_firm_country_of_incorporation", "advisory_firm_tax_id" ];
