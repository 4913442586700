import { FC } from "react";

import { Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import { AccountType } from "common/lib/constants";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { styles } from "domains/liquidity/components/CustodyAgreementPDF/CustodyAgreementPDF";
import { CustodyAccountPDFTypes, TCustodyAccountPDFTypes } from "domains/liquidity/shared/types";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";

interface IProps {
  pdfType: TCustodyAccountPDFTypes;
  custodyAccount?: ICustodyAccount;
  formValues?: Partial<IUserDetails> & {
    isFullName: boolean;
  };
  user?: IUserDetails;
  currentClient?: IUserClient;
}

const PDFText: FC<IProps> = ({ pdfType, custodyAccount, formValues, user, currentClient }) => {
  const { t } = useTranslation("liquidity");

  const isAdvisor = user?.user_type === UserType.Advisor;
  const isAdvisorCustodyAccount =
    (currentClient?.is_anonymous ||
      custodyAccount?.account_type === AccountType.CustodialAdvisor ||
      custodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
    false;
  const isIndividual = custodyAccount?.owner_type === AccountType.CustodialIndividual;
  const date = getDateFromISO(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const renderFirstParagraph = () => {
    switch (pdfType) {
      case CustodyAccountPDFTypes.Raw: {
        if (isAdvisor) {
          const advisorName = `${ user?.first_name || "" } ${ user?.last_name || "" }`;
          const customerName = `${ formValues?.first_name || "" } ${ formValues?.last_name || "" }`;

          return (
            <>
              {isAdvisorCustodyAccount
                ? t("Custody Agreement Info.0", {
                    name: advisorName,
                    date,
                  })
                : t("Custody Agreement Advisor With Client Info", {
                    advisorName,
                    customerName,
                    date,
                  })}
            </>
          );
        }

        return (
          <>
            {formValues?.isFullName
              ? t("Custody Agreement Info.0", {
                  date,
                  name: `${ formValues?.first_name || "" } ${ formValues?.last_name || "" }`,
                })
              : t("Custody Agreement Info.1", {
                  date,
                  name: `${ formValues?.first_name || "" } ${ formValues?.last_name || "" }`,
                  clientName: formValues?.account_name || "",
                })}
          </>
        );
      }

      case CustodyAccountPDFTypes.Redacted:
      case CustodyAccountPDFTypes.Unredacted: {
        if (isAdvisor) {
          const advisorName = `${ user?.first_name || "" } ${ user?.last_name || "" }`;
          const customerName = `${ custodyAccount?.first_name || "" } ${ custodyAccount?.last_name || "" }`;

          return (
            <>
              {isAdvisorCustodyAccount
                ? t("Custody Agreement Info.0", {
                    name: advisorName,
                    date,
                  })
                : t("Custody Agreement Advisor With Client Info", {
                    advisorName,
                    customerName,
                    date,
                  })}
            </>
          );
        }

        return (
          <>
            {isIndividual
              ? t("Custody Agreement Info.0", {
                  date,
                  name: `${ custodyAccount?.first_name || "" } ${ custodyAccount?.last_name || "" }`,
                })
              : t("Custody Agreement Info.1", {
                  date,
                  name: `${ custodyAccount?.first_name || "" } ${ custodyAccount?.last_name || "" }`,
                  clientName: custodyAccount?.account_name || "",
                })}
          </>
        );
      }
    }
  };

  return (
    <>
      <Text style={ styles.title }>{t("License and Custody Account Agreement")}</Text>

      <Text style={ styles.textIndent }>{renderFirstParagraph()}</Text>

      <Text style={ [ styles.header, styles.marginHorizontal ] }>{t("Custody Agreement Headers.0")}</Text>
      <Text style={ styles.textIndent }>{t("Custody Agreement Info.2")}</Text>

      <Text style={ styles.header }>{t("Custody Agreement Headers.1")}</Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.2")}</Text> {t("Custody Agreement Info.3")}
      </Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.3")}</Text> {t("Custody Agreement Info.4")}
      </Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.4")}</Text> {t("Custody Agreement Info.5")}
      </Text>
      <Text style={ [ styles.textIndent, styles.spacing ] }>{t("Custody Agreement Info.6")}</Text>
      <Text>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.5")}</Text> {t("Custody Agreement Info.7")}
      </Text>
      <Text style={ styles.header }>{t("Custody Agreement Headers.6")}</Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.7")}</Text> {t("Custody Agreement Info.8")}
      </Text>
      <Text style={ styles.spacing }>• {t("Custody Agreement Info.9")}</Text>
      <Text style={ styles.spacing }>• {t("Custody Agreement Info.10")}</Text>
      <Text style={ styles.spacing }>• {t("Custody Agreement Info.11")}</Text>
      <Text>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.8")}</Text> {t("Custody Agreement Info.12")}
      </Text>

      <Text style={ styles.header }>{t("Custody Agreement Headers.9")}</Text>
      <Text style={ [ styles.textIndent, styles.spacing ] }>{t("Custody Agreement Info.13")}</Text>
      <Text style={ styles.spacing }>• {t("Custody Agreement Info.14")}</Text>
      <Text style={ styles.spacing }>• {t("Custody Agreement Info.15")}</Text>
      <Text style={ styles.spacing }>• {t("Custody Agreement Info.16")}</Text>
      <Text>• {t("Custody Agreement Info.17")}</Text>

      <Text style={ styles.header }>{t("Custody Agreement Headers.10")}</Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.11")}</Text> {t("Custody Agreement Info.18")}
      </Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.12")}</Text> {t("Custody Agreement Info.19")}
      </Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.13")}</Text> {t("Custody Agreement Info.20")}
      </Text>
      <Text style={ styles.spacing }>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.14")}</Text> {t("Custody Agreement Info.21")}
      </Text>
      <Text>
        <Text style={ styles.bold }>{t("Custody Agreement Headers.15")}</Text> {t("Custody Agreement Info.22")}
      </Text>
    </>
  );
};

export default PDFText;
