import { FC } from "react";

import { DownloadOutlined } from "@ant-design/icons";

import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import Spinner from "common/lib/components/Spinner";

interface IProps {
  documentId: string;
  accountId: string;
  spinnerClassName?: string;
  iconClassName?: string;
}

const DownloadDocumentIcon: FC<IProps> = ({ documentId, accountId, spinnerClassName, iconClassName }) => {
  const [ downloadDocument, { isLoading: isDownloading } ] = documentsApiReducer.useDownloadDocumentMutation();

  if (isDownloading) {
    return <Spinner className={ spinnerClassName } size="small" />;
  }
  return <DownloadOutlined className={ iconClassName } onClick={ () => downloadDocument({ documentId, accountId }) } />;
};

export default DownloadDocumentIcon;
