/* eslint-disable */
import { UploadFile } from "antd/lib/upload/interface";

import { apiEnvs, apiService } from "common/shared";
import {
  IDocument,
  IDocumentGridData,
  IDocumentPassword,
  IDocumentUrlData,
  IGetDocumentsByAccountIdResponse,
  IPDFDocument,
} from "domains/documents/shared/types";
import { DocumentType } from "./constants";

export class DocumentsApiService {
  async fetchLRDocuments(accountId: string, LRId: string) {
    // TODO: add paging
    const res = await apiService.get(
      `${
        apiEnvs.documentsAPI
      }/v1/accounts/${accountId}/documents?filter[0][field]=liquidity_request_id&filter[0][operator]=equal&filter[0][value]=${LRId.slice(
        4
      )}&page.size=1000`
    );
    const documents: IDocument[] = res.items;
    const filteredDocuments = documents.filter((doc) => doc.document_type === DocumentType.Other);

    return filteredDocuments;
  }

  async fetchDocumentsById(accountId: string): Promise<IGetDocumentsByAccountIdResponse> {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents?page.size=1000`);
  }

  async fetchDocumentGridData(LRId: string) {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/document_grid/${LRId}`);
  }

  async fetchDocumentURLData(accountId: string, documentId: string): Promise<IDocumentUrlData> {
    return apiService.get(
      `${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}/download_url/latest`
    );
  }

  async setDocumentGridData(LRId: string, data: { is_append_row: boolean; fund_assets: IDocumentGridData[] }) {
    return apiService.post(`${apiEnvs.documentsAPI}/v1/document_grid/${LRId}`, data);
  }

  async uploadDocument(
    account_id: string,
    document: IDocument | IPDFDocument,
    file: (File & UploadFile) | (Blob & UploadFile) | Blob,
    updateProgress: (percent: number) => void
  ) {
    const { document_id, version_id, upload_url } = await apiService.post(
      `${apiEnvs.documentsAPI}/v1/accounts/${account_id}/documents`,
      {
        ...document,
        ...("document_name" in document
          ? {
              document_name: document.document_name
                ? document.document_name + Math.random().toString(36).substring(0, 5)
                : null,
            }
          : {}),
      }
    );

    const binary = new Blob([file], { type: document.mimeType });

    await apiService.put(upload_url, binary, {
      headers: {
        "Content-Type": document.mimeType,
      },
      onUploadProgress(e: any) {
        updateProgress(Math.round((e.loaded * 100) / e.total));
      },
      ...("cancelToken" in document ? { cancelToken: document.cancelToken?.token } : {}),
    });
    await apiService.post(`${apiEnvs.documentsAPI}/v1/accounts/${account_id}/upload_document_confirmation`, {
      document_id,
      version_id,
      ...("uid" in file ? { file_id: file.uid } : {}),
    });
    return document_id;
  }

  async deleteDocument(accountId: string, documentId: string) {
    return apiService.patch(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}`, [
      {
        op: "replace",
        path: "/is_deleted",
        value: true,
      },
    ]);
  }

  async fetchDocumentPassword(account_id: string, document_id: string): Promise<void | IDocumentPassword[]> {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/accounts/${account_id}/documents/${document_id}/form_data`);
  }

  async setDocumentPassword(accountId: string, documentId: string, password: string) {
    return apiService.patch(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}`, [
      {
        op: "replace",
        path: "/document_password",
        value: password,
      },
    ]);
  }

  async getDownloadUrl(accountId: string, documentId: string): Promise<IDocumentUrlData> {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}/download_url/v1`);
  }
}

const documentsApiService = new DocumentsApiService();
export default documentsApiService;
