import { FC } from "react";

import { Text } from "@react-pdf/renderer";

import { IUserClient, IUserDetails } from "domains/clients/shared/types";
import { maskCharacters } from "common/shared";
import { styles } from "domains/liquidity/components/CustodyAgreementPDF/CustodyAgreementPDF";
import { CustodyAccountPDFTypes, TCustodyAccountPDFTypes } from "domains/liquidity/shared/types";
import DateAndName from "./DateAndName";
import Signature from "./Signature";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";

interface IProps {
  pdfType: TCustodyAccountPDFTypes;
  custodyAccount?: ICustodyAccount;
  user?: IUserDetails;
  currentClient?: IUserClient;
}

const ElectronicSignature: FC<IProps> = ({ pdfType, custodyAccount, user, currentClient }) => {
  if (pdfType === CustodyAccountPDFTypes.Raw) return null;

  if (pdfType === CustodyAccountPDFTypes.Redacted)
    return (
      <>
        <DateAndName user={ user } currentClient={ currentClient } custodyAccount={ custodyAccount } />

        {custodyAccount?.tax_id && <Text>{`EIN: ${ maskCharacters(custodyAccount.tax_id, "*", 4) }`}</Text>}
        {custodyAccount?.ssn && <Text>{`SSN: ${ maskCharacters(custodyAccount.ssn, "*", 3) }`}</Text>}
        <Text>{`IP Address: ${ maskCharacters(user?.customer_ip_address || "", "*", 3) }`}</Text>
        <Text style={ styles.marginBottom }>{`Custody Account ID: ${ custodyAccount?.account_display_id }`}</Text>

        <Signature />
      </>
    );

  if (pdfType === CustodyAccountPDFTypes.Unredacted)
    return (
      <>
        <DateAndName user={ user } currentClient={ currentClient } custodyAccount={ custodyAccount } />

        {custodyAccount?.tax_id && <Text>{`EIN: ${ custodyAccount.tax_id }`}</Text>}
        {custodyAccount?.ssn && <Text>{`SSN: ${ custodyAccount.ssn }`}</Text>}
        <Text>{`IP Address: ${ user?.customer_ip_address }`}</Text>
        <Text style={ styles.marginBottom }>{`Custody Account ID: ${ custodyAccount?.account_display_id }`}</Text>

        <Signature />
      </>
    );
};

export default ElectronicSignature;
