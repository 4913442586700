export interface IPortalEnv {
  defaultPhoneNumber: string;
  defaultTimezone: string;
  defaultCurrency: string;
  defaultLocale: string;
  defaultLanguage: string;
  googleTagManagerId: string;

  getLanguageFromLocale: (locale: string) => string | null;
  setData: (data: IPortalEnvParams) => void;
}

export interface IPortalEnvParams {
  DefaultPhoneNumber: string;
  DefaultTimezone: string;
  DefaultCurrency: string;
  DefaultLocale: string;
  GoogleTagManagerId: string;
}

export class PortalEnvConstants {
  public defaultPhoneNumber = "";
  public defaultTimezone = "";
  public defaultCurrency = "";
  public defaultLocale = "";
  public defaultLanguage = "";
  public googleTagManagerId = "";

  public setData(data: IPortalEnvParams) {
    if (!data) return;

    this.setDefaultPhoneNumber(data);
    this.setDefaultTimezone(data);
    this.setDefaultCurrency(data);
    this.setDefaultLocale(data);
    this.googleTagManagerId = data.GoogleTagManagerId || "";
  }

  public getLanguageFromLocale(locale: string): string | null {
    if (!locale) return null;

    const parsedArr = locale.split("-");

    if (!parsedArr[ 0 ]) {
      return null;
    }

    return parsedArr[ 0 ];
  }

  private setDefaultPhoneNumber(data: IPortalEnvParams): void {
    if (data.DefaultPhoneNumber) {
      this.defaultPhoneNumber = data.DefaultPhoneNumber;
    }
  }

  private setDefaultTimezone(data: IPortalEnvParams): void {
    if (data.DefaultTimezone) {
      this.defaultTimezone = data.DefaultTimezone;
    }
  }

  private setDefaultCurrency(data: IPortalEnvParams): void {
    if (data.DefaultCurrency) {
      this.defaultCurrency = data.DefaultCurrency;
    }
  }

  private setDefaultLocale(data: IPortalEnvParams): void {
    if (data.DefaultLocale) {
      this.defaultLocale = data.DefaultLocale;
      const lang = this.getLanguageFromLocale(data.DefaultLocale);
      if (!lang) {
        this.defaultLanguage = "en";
        return;
      }
      this.defaultLanguage = lang;
    }
  }
}

const portalEnvConstants: IPortalEnv = new PortalEnvConstants();
export default portalEnvConstants;
