import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { Spinner, Button } from "common/lib/components";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import ApplicationsListItem from "domains/dashboard/components/ApplicationsListItem";
import { useClientsSelector } from "domains/clients";

import "./ApplicationsList.scss";

interface IProps {
  hideAvailable?: boolean;
  isClientPage?: boolean;
  liquidityApplications: ILiquidityRequest[];
}

const ApplicationsList: FC<IProps> = ({ hideAvailable, liquidityApplications, isClientPage = false }) => {
  const { t } = useTranslation("dashboard");

  const { user, isAdvisor } = useClientsSelector();

  const [ areAllVisible, setAreAllVisible ] = useState(false);

  return (
    <Spinner spinning={ !user || !liquidityApplications }>
      <div className="ben-applications-list">
        { liquidityApplications.length === 0 && (
          <div className="ben-d-flex">
            { isAdvisor ? (
              <span className="ben-d-block ben-mr-5 text">
                { t("Active applications adviser accordion default text") }
              </span>
              ) : (
              <>
                <span className="ben-d-block ben-mr-5 text">
                  { t("My applications customer default text") }
                </span>
              </>
              )
            }
          </div>
        ) }
        { areAllVisible || !hideAvailable ? (
          <div>
            { liquidityApplications.map((application) => (
              <ApplicationsListItem isClientPage={ isClientPage } key={ application.id } application={ application } />
            )) }
            { liquidityApplications.length > 1 && hideAvailable && (
              <Button onClick={ () => setAreAllVisible(!areAllVisible) } type="text">
                { `${ t("Hide") }` }
              </Button>
            ) }
          </div>
        ) : (
          liquidityApplications.length > 0 && (
            <>
              { liquidityApplications.slice(0, 2).map((application) => (
                <ApplicationsListItem
                  isClientPage={ isClientPage }
                  key={ application.id }
                  application={ application }
                />
              )) }
              { liquidityApplications.length > 2 && (
                <Button onClick={ () => setAreAllVisible(!areAllVisible) } type="text">
                  { `${ t("View all") } ${ liquidityApplications.length } ${ t("Applications") }` }
                </Button>
              ) }
            </>
          )
        ) }
      </div>
    </Spinner>
  );
};

export default ApplicationsList;
