/* eslint-disable import/no-named-as-default-member */
import axios, { AxiosError, AxiosResponse } from "axios";
import { message } from "antd";

import {
  isTrustbenSignInError,
  isCustodyAccountError,
  isTrustbenAltQuoteResultsError,
} from "common/shared/services/api.helpers/api.helpers";

export const errorHandler = (error: AxiosError<any>) => {
  if (!axios.isAxiosError(error)) return Promise.reject({ ...(error as any) });

  const errorMessage = `Something went wrong, please try again later. 
  If the problem persists, then please contact a System Administrator.`;

  if (isCustodyAccountError(error)) {
    return Promise.reject({ ...error });
  }

  if (isTrustbenSignInError(error)) {
    return Promise.reject({ ...error });
  }

  if (isTrustbenAltQuoteResultsError()) {
    return Promise.reject({ ...error });
  }

  message.error(error.response?.data.message || errorMessage);
  return Promise.reject({ ...error });
};

export const successHandler = (response: AxiosResponse): any => {
  return response.data;
};
