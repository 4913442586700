import { MouseEvent, useCallback, useRef, useState, SetStateAction, useMemo } from "react";

import { FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { pdf } from "@react-pdf/renderer";

import { AccountType, URLs } from "common/lib/constants";
import { IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import { LAPages } from "domains/liquidity";
import { getInitialValues } from "./helpers";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { getChangedFormValues } from "common/shared/helpers";
import { isEmptyObject, omit, pick } from "common/shared/utils/object.utils";
import { DocumentType } from "domains/documents/shared/constants";
import { useHelpers } from "./useHelpers";
import { CustodyAgreementPDF } from "domains/liquidity/components";
import { CustodyAccountPDFTypes } from "domains/liquidity/shared/types";
import { idUtils } from "common/shared";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { IDocument, IPDFDocument } from "domains/documents/shared/types";
import { advisorDetailsFields, keyFields } from "./constants";
import { CustodyAccountStatus, ICustodyAccount } from "domains/custodyAccounts/shared/types";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

const useHandlers = (documents: IDocument[]) => {
  const navigate = useNavigate();
  const { LRId } = useParams();

  const { user, isAdvisor, currentClient } = useClientsSelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const [ uploadDocument ] = documentsApiReducer.useUploadDocumentMutation();

  const { createCustodyAccount, updateCustodyAccount, updateAdvisorDetails } = useHelpers();
  const initialValues = getInitialValues(user, currentClient, currentCustodyAccount, documents);
  const formRef = useRef<FormikProps<typeof initialValues>>(null);

  const isAdvisorCustodyAccount =
    (currentClient?.is_anonymous ||
      currentCustodyAccount?.account_type === AccountType.CustodialAdvisor ||
      currentCustodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
    false;
  const isAccountClosed = currentCustodyAccount?.account_status === CustodyAccountStatus.Closed;
  const pdfDocument = useMemo(() => {
    if (documents.length === 0) return;

    return documents.find((document) => document.document_type === DocumentType.CustodyAgreementRedacted);
  }, [ documents.length ]);
  const isCustodyAgreementSigned = !!currentCustodyAccount?.custody_agreement || (!!pdfDocument && !isAccountClosed);

  const [ isCustodyAgreementModalVisible, setIsCustodyAgreementModalVisible ] = useState(false);
  const [ isCustodyAgreementPDFModalVisible, setIsCustodyAgreementPDFModalVisible ] = useState(false);

  const handleSubmit = useCallback(
    (formValues: typeof initialValues) => {
      message.destroy();
      const changes = getChangedFormValues(formValues, initialValues) as any;
      const isKeyFieldsChanged = Object.keys(changes).some((key) => keyFields.includes(key));

      if (isKeyFieldsChanged || isAccountClosed || !isCustodyAgreementSigned) {
        if (
          (!isEmptyObject(pick(changes, "advisory_firm_tax_id") || {}) ||
            (isAdvisorCustodyAccount && !isEmptyObject(pick(changes, "tax_id", "country_of_incorporation") || {}))) &&
          isAdvisor
        ) {
          window.sessionStorage.setItem("formValues", JSON.stringify(formValues));
          isAdvisorCustodyAccount
            ? updateAdvisorDetails({
                ...(changes?.country_of_incorporation
                  ? { advisory_firm_country_of_incorporation: changes.country_of_incorporation }
                  : {}),
                ...(changes?.tax_id
                  ? {
                      advisory_firm_tax_id: changes.tax_id,
                    }
                  : {}),
              })
            : updateAdvisorDetails(changes);
          return;
        }

        createCustodyAccount(formValues);
        return;
      }

      !isEmptyObject(omit(changes, ...advisorDetailsFields)) && updateCustodyAccount(changes);
      !isEmptyObject(pick(changes, ...advisorDetailsFields) || {}) && isAdvisor && updateAdvisorDetails(changes);

      LRId
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountId)
        : navigate(URLs.PROTECTED.LIQUIDITY + LAPages.CustodyAccountId);
    },
    [
      createCustodyAccount,
      updateCustodyAccount,
      initialValues,
      isCustodyAgreementSigned,
      isAccountClosed,
      LRId,
      isAdvisor,
      updateAdvisorDetails,
      isAdvisorCustodyAccount,
    ],
  );

  const handleCustodyAgree = useCallback(
    (setValues: (fields: SetStateAction<{ [field: string]: any }>, shouldValidate?: boolean) => void) => {
      handleSubmit({ ...formRef.current?.values, custody_agreement: true });
      setIsCustodyAgreementModalVisible(false);
      setValues({
        ...formRef.current!.values,
        custody_agreement: true,
      });
    },
    [ handleSubmit ],
  );

  const handleCustodyAgreementCancel = useCallback(
    (setValues: (fields: SetStateAction<{ [field: string]: any }>, shouldValidate?: boolean) => void) => {
      setIsCustodyAgreementModalVisible(false);
      setValues({
        ...formRef.current!.values,
        custody_agreement: false,
      });
    },
    [],
  );

  const handleCustodyAgreementPDFCancel = useCallback(() => {
    setIsCustodyAgreementPDFModalVisible(false);
  }, []);

  const openCustodyAgreementModal = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCustodyAgreementModalVisible(true);
  }, []);

  const openCustodyAgreementPDFModal = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCustodyAgreementPDFModalVisible(true);
  }, []);

  const handleKeyFieldChange = useCallback(
    (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
      setFieldValue("custody_agreement", false, false);
    },
    [],
  );

  const touchAllFormFields = useCallback(() => {
    const formFields = { ...initialValues };
    Object.keys(formFields).forEach((key) => {
      formFields[ key ] = true;
    });
    formRef.current?.setTouched(formFields);
  }, [ initialValues ]);

  const handleCustodyAgreementOpen = useCallback(
    (e: MouseEvent, isCustodyAgreementSigned: boolean) => {
      if (isCustodyAgreementSigned) {
        openCustodyAgreementPDFModal(e);
      } else {
        openCustodyAgreementModal(e);
        touchAllFormFields();
      }
    },
    [ openCustodyAgreementPDFModal, openCustodyAgreementModal, touchAllFormFields ],
  );

  const getAgreementData = useCallback(() => {
    const formValues = formRef.current?.values;
    const isFullName = formValues?.owner_type === AccountType.CustodialIndividual;

    switch (user?.user_type) {
      case UserType.Advisor: {
        return {
          first_name: formValues?.first_name,
          last_name: formValues?.last_name,
          isFullName,
        };
      }

      case UserType.Principal:
      case UserType.Employee:
        return {
          first_name: formValues?.first_name,
          last_name: formValues?.last_name,
          account_name: formValues?.account_name,
          isFullName,
        };

      default:
        return {} as Partial<IUserDetails> & {
          isFullName: boolean;
        };
    }
  }, [ formRef.current?.values, currentClient, user ]);

  const exportPDF = useCallback(
    async (custodyAccount: ICustodyAccount) => {
      const redactedPDFBlob = await pdf(
        <CustodyAgreementPDF
          custodyAccount={ custodyAccount }
          user={ user! }
          currentClient={ currentClient as IUserClient }
          pdfType={ CustodyAccountPDFTypes.Redacted }
        />,
      ).toBlob();
      const unredactedPDFBlob = await pdf(
        <CustodyAgreementPDF
          custodyAccount={ custodyAccount }
          user={ user! }
          currentClient={ currentClient as IUserClient }
          pdfType={ CustodyAccountPDFTypes.Unredacted }
        />,
      ).toBlob();

      const redactedDocument = {
        asset_id: null,
        file_name: `Custody Agreement-Redacted(${ getDateFromISO() }).pdf`.replace(/[^- _a-zA-Z0-9!.'()]/g, "-"),
        document_name: null,
        document_type: DocumentType.CustodyAgreementRedacted,
        reference_type: "account",
        file_size: redactedPDFBlob.size,
        liquidity_request_id: null,
        investment_id: null,
        do_not_have_this_document: false,
        id: idUtils.uuidv4(),
        mimeType: redactedPDFBlob.type,
      };

      const unredactedDocument = {
        asset_id: null,
        file_name: `Custody Agreement-Unredacted(${ getDateFromISO() }).pdf`.replace(/[^- _a-zA-Z0-9!.'()]/g, "-"),
        document_name: null,
        document_type: DocumentType.CustodyAgreementUnredacted,
        reference_type: "account",
        file_size: unredactedPDFBlob.size,
        liquidity_request_id: null,
        investment_id: null,
        do_not_have_this_document: false,
        id: idUtils.uuidv4(),
        mimeType: unredactedPDFBlob.type,
      };

      uploadDocument({
        accountId: custodyAccount.account_id,
        document: unredactedDocument as IPDFDocument,
        file: unredactedPDFBlob,
        onUploadProgress: () => {},
      });
      uploadDocument({
        accountId: custodyAccount.account_id,
        document: redactedDocument as IPDFDocument,
        file: redactedPDFBlob,
        onUploadProgress: () => {},
      });
    },
    [ user?.customer_ip_address, currentClient?.is_anonymous ],
  );

  return {
    formRef,
    isCustodyAgreementModalVisible,
    isCustodyAgreementPDFModalVisible,
    handleCustodyAgreementCancel,
    handleCustodyAgreementPDFCancel,
    handleCustodyAgree,
    handleKeyFieldChange,
    handleSubmit,
    handleCustodyAgreementOpen,
    getAgreementData,
    exportPDF,
  };
};

export default useHandlers;
