import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as LinkedInIcon } from "common/assets/images/icons/trustben-linkedin-icon.svg";

const LinkedIn: FC<Partial<CustomIconComponentProps>> = (props) => <Icon { ...props } component={ LinkedInIcon } />;

export default LinkedIn;
