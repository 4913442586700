import { memo, useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { URLs } from "common/lib/constants";
import { NavLink, Spinner, Button } from "common/lib/components";
import { useAppNavigation } from "common/shared-hooks";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";

import "./MainMenu.scss";

const MainMenu = memo(function MainMenu() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const { user } = useClientsSelector();
  const { data: documents } = documentsApiReducer.useFetchDocumentsByIdQuery(user?.account_id ?? skipToken);
  const [ logout ] = authApiReducer.useLogoutMutation();

  const { navLinks } = useAppNavigation();

  const showSignOut = useMemo(() => {
    return !documents ? false : documents.some(({ document_type }) => document_type === "confidentiality_agreement");
  }, [ documents?.length ]);

  const signOut = useCallback(() => {
    logout();
    navigate(URLs.AUTH.SIGN_IN);
  }, []);

  if (!user || !documents) {
    return <Spinner fullWidth className="ben-d-flex ben-justify-content-center ben-align-items-center" />;
  }

  return (
    <span className="ben-main-menu">
      <nav className="ben-navbar">
        {navLinks.map((link, index) => (
          <NavLink key={ index } className={ link.className } to={ link.url }>
            {link.title}
          </NavLink>
        ))}
      </nav>

      <Button className={ showSignOut ? "" : "hidden" } type="primary" onClick={ signOut }>
        {t("Menu.Sign Out")}
      </Button>
    </span>
  );
});

export default MainMenu;
