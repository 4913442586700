import { object } from "yup";

import { useValidationSchemas } from "common/shared/constants/validationSchemas";

export const useValidationSchema = () => {
  const { emailValidationSchema } = useValidationSchemas();

  const validationSchema = object().shape({
    email: emailValidationSchema,
  });

  return { validationSchema };
};
