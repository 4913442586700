import { FC } from "react";

import "./TrustbenFormHeader.scss";

interface IProps {
  imgSrc?: string;
  imgAlt?: string;
  title?: string | JSX.Element;
  subtitle?: string;
  hint?: string | JSX.Element;
  assetName?: string;
  className?: string;
}

const TrustbenFormHeader: FC<IProps> = ({ imgSrc, imgAlt, title, subtitle, hint, assetName, className }) => {
  return (
    <div className={ className ? `trustben-form-header ${ className }` : "trustben-form-header" }>
      {imgSrc && <img className="trustben-form-header-img" src={ imgSrc } alt={ imgAlt } />}
      {title && <span className="trustben-form-header-title">{title}</span>}
      {subtitle && <span className="trustben-form-header-subtitle">{subtitle}</span>}
      {hint && <span className="trustben-form-header-hint">{hint}</span>}
      {assetName && <span className="trustben-form-header-asset-name">{assetName}</span>}
    </div>
  );
};

export default TrustbenFormHeader;
