import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Collapse, CollapseProps, Table } from "antd";

import { Spinner } from "common/lib/components";
import { getAssetName } from "domains/liquidity/shared/utils/formatter";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./MyAssetsPanel.scss";

const { Panel } = Collapse;

const MyAssetsPanel: FC<CollapseProps> = (props) => {
  const { t } = useTranslation("dashboard");

  const { data: allAssets = [], isFetching } = liquidityApiReducer.useFetchAllAssetsQuery();

  const columns = [
    {
      title: "Asset Name",
      render: (v: any) => v.asset.asset_name ?? getAssetName(v.asset),
      key: "asset_name",
      className: "ben-my-assets-panel-item",
    },
  ];

  return (
    <Panel { ...props } header={ t("My Assets") } key="My Assets">
      <Spinner spinning={ isFetching }>
        {allAssets.length ? (
          <Table
            className="ben-my-clients-assets-table-wrapper"
            pagination={ false }
            dataSource={ allAssets.length ? allAssets : undefined }
            columns={ columns }
            rowKey="id"
          />
        ) : (
          <span className="ben-mr-5 text">
            {t("Assets included in your liquidity application(s) will appear here.")}
          </span>
        )}
      </Spinner>
    </Panel>
  );
};

export default MyAssetsPanel;
