import { useLayoutEffect, useState } from "react";

import { TWhiteLabel, WhiteLabelType } from "common/shared/types";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { apiEnvs } from "common/shared";
import { useTrustbenSelector } from "domains/trustben";

const useWhiteLabel = () => {
  const { user } = useClientsSelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();

  const userWhiteLabelType = user?.white_label_instance;

  const [ whiteLabelType, setWhiteLabelType ] = useState<TWhiteLabel | null>(null);

  const setWhiteLabel = async () => {
    if (isTrustbenAltQuote) {
      setWhiteLabelType(WhiteLabelType.Trustben);
      return;
    }

    if (userWhiteLabelType) {
      setWhiteLabelType(userWhiteLabelType);
      return;
    }

    const { whiteLabel } = await apiEnvs.getApiEnv();
    setWhiteLabelType(whiteLabel);
  };

  useLayoutEffect(() => {
    setWhiteLabel();
  }, [ userWhiteLabelType, isTrustbenAltQuote ]);

  useLayoutEffect(() => {
    if (!whiteLabelType) return;

    switch (whiteLabelType) {
      case WhiteLabelType.Default: {
        document.documentElement.style.setProperty("--primary-color", "#09819F");
        document.documentElement.style.setProperty("--secondary-color", "#055073");
        document.documentElement.style.setProperty("--dark-primary-color", "#035073");
        document.documentElement.style.setProperty("--light-blue-color", "#80d1e4");
        document.documentElement.style.setProperty("--button-hover-color", "#279bb3");
        document.documentElement.style.setProperty("--input-color", "#0C87A4");
        document.documentElement.style.setProperty("--not-received-status-color", "#ff000080");
        document.documentElement.style.setProperty("--pending-review-status-color", "#ffff0080");
        document.documentElement.style.setProperty("--partially-received-status-color", "#ffff0080");
        document.documentElement.style.setProperty("--received-status-color", "#90ff6480");
        break;
      }

      case WhiteLabelType.Partner: {
        document.documentElement.style.setProperty("--primary-color", "#0a8725");
        document.documentElement.style.setProperty("--secondary-color", "#10c455ec");
        document.documentElement.style.setProperty("--dark-primary-color", "#02611a");
        document.documentElement.style.setProperty("--light-blue-color", "#70ff8d");
        document.documentElement.style.setProperty("--button-hover-color", "#70ff8d");
        document.documentElement.style.setProperty("--input-color", "#70ff8d");
        document.documentElement.style.setProperty("--not-received-status-color", "#FFFF0080");
        document.documentElement.style.setProperty("--pending-review-status-color", "#ffff0080");
        document.documentElement.style.setProperty("--partially-received-status-color", "#ffff0080");
        document.documentElement.style.setProperty("--received-status-color", "#90ff6480");
        break;
      }

      case WhiteLabelType.Trustben: {
        document.documentElement.style.setProperty("--primary-color", "#148ba9");
        document.documentElement.style.setProperty("--secondary-color", "#075173");
        document.documentElement.style.setProperty("--dark-primary-color", "#035073");
        document.documentElement.style.setProperty("--light-blue-color", "#80d1e4");
        document.documentElement.style.setProperty("--button-hover-color", "#279bb3");
        document.documentElement.style.setProperty("--input-color", "#0C87A4");
        document.documentElement.style.setProperty("--not-received-status-color", "#ff000080");
        document.documentElement.style.setProperty("--pending-review-status-color", "#ffff0080");
        document.documentElement.style.setProperty("--partially-received-status-color", "#ffff0080");
        document.documentElement.style.setProperty("--received-status-color", "#90ff6480");
        break;
      }
    }
  }, [ whiteLabelType ]);

  return whiteLabelType;
};

export default useWhiteLabel;
