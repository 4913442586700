import { Checkbox as AntdCheckbox, CheckboxProps as AntdCheckboxProps } from "antd";
import { useField } from "formik";

import CheckboxGroup from "common/lib/formik-antd/CheckboxGroup";

const Checkbox = ({ name, onChange: $onChange, ...restProps }: AntdCheckboxProps & { name: string }) => {
  const [ { value }, _meta, { setValue, setTouched } ] = useField(name);

  return (
    <AntdCheckbox
      id={ name }
      name={ name }
      checked={ value }
      onChange={ (event) => {
        setValue(event.target.checked);
        setTouched(true, false);

        $onChange?.(event);
      } }
      { ...restProps }
    />
  );
};

Checkbox.Group = CheckboxGroup;

export default Checkbox;
