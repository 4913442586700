import { Radio as AntdRadio, RadioGroupProps as AntdRadioGroupProps } from "antd";
import { useField } from "formik";

const RadioGroup = ({
  name,
  // eslint-disable-next-line no-unused-vars
  value: $value,
  onChange: $onChange,
  ...restProps
}: AntdRadioGroupProps & { name: string }) => {
  const [ { value }, _meta, { setValue, setTouched } ] = useField(name);

  return (
    <AntdRadio.Group
      id={ name }
      name={ name }
      value={ value }
      onChange={ (event) => {
        setValue(event.target.value);
        setTouched(true, false);
        $onChange?.(event);
      } }
      { ...restProps }
    />
  );
};

export default RadioGroup;
