import { FC, useState } from "react";

import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";
import AddNewClientModal from "domains/dashboard/components/AddNewClientModal";
import { ILiquidityRequest } from "domains/liquidity/shared/types";

import "./ChooseTypeOfClientModal.scss";

interface IProps {
  handleCancel: () => void;
  isVisible: boolean;
  isConvertingAdvisorAltQuoteWithoutClient?: boolean;
  openRequestFormalProposalModal?: (altQuote: ILiquidityRequest) => void;
}

const ChooseTypeOfClientModal: FC<IProps> = ({
  isVisible,
  handleCancel,
  isConvertingAdvisorAltQuoteWithoutClient,
  openRequestFormalProposalModal,
}) => {
  const { t } = useTranslation([ "dashboard", "myProfile" ]);
  const [ isAddNewClientModalVisible, setIsAddNewClientModalVisible ] = useState(false);
  const [ isIndividual, setIsIndividual ] = useState<boolean>();

  const handleAddNewClientModalCancel = () => {
    setIsAddNewClientModalVisible(false);
  };

  return (
    <>
      <Modal footer={ null } width={ 650 } onCancel={ handleCancel } open={ isVisible }>
        <div className="ben-choose-type-of-client-modal-container">
          <div className="ben-choose-type-of-client-modal-title">
            <h1>{t("WHAT TYPE OF CLIENT ARE YOU ADDING?")}</h1>
          </div>
          <div className="ben-choose-type-of-client-modal-buttons">
            <Button
              onClick={ () => {
                setIsAddNewClientModalVisible(true);
                setIsIndividual(true);
                handleCancel();
              } }
              type="primary"
            >
              {t("Individual")}
            </Button>
            <Button
              onClick={ () => {
                setIsAddNewClientModalVisible(true);
                setIsIndividual(false);
                handleCancel();
              } }
              type="primary"
            >
              {t("Business/Organization")}
            </Button>
          </div>
        </div>
      </Modal>

      {isAddNewClientModalVisible && (
        <AddNewClientModal
          isIndividual={ isIndividual! }
          handleCancel={ handleAddNewClientModalCancel }
          isVisible={ isAddNewClientModalVisible }
          isConvertingAdvisorAltQuoteWithoutClient={ isConvertingAdvisorAltQuoteWithoutClient }
          openRequestFormalProposalModal={ openRequestFormalProposalModal }
        />
      )}
    </>
  );
};

export default ChooseTypeOfClientModal;
