import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";

interface IProps {
  url: string;
}

const EmbeddedPDF: FC<IProps> = ({ url }) => {
  const { t } = useTranslation("common");

  return (
    <object data={ url } type="application/pdf" width="100%" height="100%">
      <iframe src={ url } width="100%" height="100%" style={ { border: "none" } }>
        <p className="ben-text-center">
          {t("Embedded PDF Fallback Text.1")}{" "}
          <Button type="link" href={ url } target="_blank">
            {t("Embedded PDF Fallback Text.2")}
          </Button>
        </p>
      </iframe>
    </object>
  );
};

export default EmbeddedPDF;
