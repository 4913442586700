import { object, string } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";
import { useValidationSchemas } from "common/shared/constants/validationSchemas";

export const useRequestAccessFormValidationSchema = () => {
  const { required } = useValidationErrorMessages();
  const { accountNameValidationSchema, emailValidationSchema, onlyLettersValidationSchema, phoneValidationSchema } =
    useValidationSchemas();

  const validationSchema = object().shape({
    account_type: string().required(required),
    first_name: onlyLettersValidationSchema.required(required),
    last_name: onlyLettersValidationSchema.required(required),
    email: emailValidationSchema.required(required),
    phone_number: phoneValidationSchema.required(required),
    account_name: accountNameValidationSchema.when([ "account_type" ], ([ account_type ], schema) => {
      return account_type !== "individual" ? schema.required(required) : schema.notRequired();
    }),
  });

  return { validationSchema };
};
