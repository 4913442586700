import { FC } from "react";

import { useTranslation } from "react-i18next";

import { getDateFromISO } from "common/shared/utils/date.utils";
import { IUserDetails, UserType } from "domains/clients/shared/types";
import { useClientsSelector } from "domains/clients";
import { AccountType } from "common/lib/constants";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";

interface IProps {
  formValues: Partial<IUserDetails> & { isFullName: boolean };
}

const CustodyAgreementContent: FC<IProps> = ({ formValues }) => {
  const { t } = useTranslation([ "liquidity" ]);

  const { user, currentClient } = useClientsSelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();

  const isAdvisorCustodyAccount =
    (currentClient?.is_anonymous ||
      currentCustodyAccount?.account_type === AccountType.CustodialAdvisor ||
      currentCustodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
    false;

  const date = getDateFromISO(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const renderFirstParagraph = () => {
    switch (user?.user_type) {
      case UserType.Advisor: {
        const advisorName = `${ user?.first_name || "" } ${ user?.last_name || "" }`;
        const customerName = `${ formValues?.first_name || "" } ${ formValues?.last_name || "" }`;

        return (
          <>
            {isAdvisorCustodyAccount
              ? t("Custody Agreement Info.0", {
                  name: advisorName,
                  date,
                })
              : t("Custody Agreement Advisor With Client Info", {
                  advisorName,
                  customerName,
                  date,
                })}
          </>
        );
      }

      default:
        return (
          <>
            {formValues?.isFullName
              ? t("Custody Agreement Info.0", {
                  date,
                  name: `${ formValues?.first_name || "" } ${ formValues?.last_name || "" }`,
                })
              : t("Custody Agreement Info.1", {
                  date,
                  name: `${ formValues?.first_name || "" } ${ formValues?.last_name || "" }`,
                  clientName: formValues?.account_name || "",
                })}
          </>
        );
    }
  };

  return (
    <div>
      <p className="ben-agreement-text-indent">{renderFirstParagraph()}</p>
      <p className="ben-agreement-header">{t("Custody Agreement Headers.0")}</p>
      <p className="ben-agreement-text-indent">{t("Custody Agreement Info.2")}</p>
      <p className="ben-agreement-header">{t("Custody Agreement Headers.1")}</p>
      <p>
        <b>{t("Custody Agreement Headers.2")}</b> {t("Custody Agreement Info.3")}
      </p>
      <p>
        <b>{t("Custody Agreement Headers.3")}</b> {t("Custody Agreement Info.4")}
      </p>
      <p>
        <b>{t("Custody Agreement Headers.4")}</b> {t("Custody Agreement Info.5")}
      </p>
      <p className="ben-agreement-text-indent">{t("Custody Agreement Info.6")}</p>
      <p>
        <b>{t("Custody Agreement Headers.5")}</b> {t("Custody Agreement Info.7")}
      </p>
      <p className="ben-agreement-header">{t("Custody Agreement Headers.6")}</p>
      <p>
        <b>{t("Custody Agreement Headers.7")}</b> {t("Custody Agreement Info.8")}
      </p>
      <p>• {t("Custody Agreement Info.9")}</p>
      <p>• {t("Custody Agreement Info.10")}</p>
      <p>• {t("Custody Agreement Info.11")}</p>
      <p>
        <b>{t("Custody Agreement Headers.8")}</b> {t("Custody Agreement Info.12")}
      </p>
      <p className="ben-agreement-header">{t("Custody Agreement Headers.9")}</p>
      <p className="ben-agreement-text-indent">{t("Custody Agreement Info.13")}</p>
      <p>• {t("Custody Agreement Info.14")}</p>
      <p>• {t("Custody Agreement Info.15")}</p>
      <p>• {t("Custody Agreement Info.16")}</p>
      <p>• {t("Custody Agreement Info.17")}</p>
      <p className="ben-agreement-header">{t("Custody Agreement Headers.10")}</p>
      <p>
        <b>{t("Custody Agreement Headers.11")}</b> {t("Custody Agreement Info.18")}
      </p>
      <p>
        <b>{t("Custody Agreement Headers.12")}</b> {t("Custody Agreement Info.19")}
      </p>
      <p>
        <b>{t("Custody Agreement Headers.13")}</b> {t("Custody Agreement Info.20")}
      </p>
      <p>
        <b>{t("Custody Agreement Headers.14")}</b> {t("Custody Agreement Info.21")}
      </p>
      <p>
        <b>{t("Custody Agreement Headers.15")}</b> {t("Custody Agreement Info.22")}
      </p>
    </div>
  );
};

export default CustodyAgreementContent;
