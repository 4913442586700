import React, { FC, Fragment } from "react";

import { Col, Row } from "antd";

import { Spinner, Button } from "common/lib/components";

import "./CardErrorSection.scss";

interface IProps {
  title?: string | React.ReactElement;
  message?: string | React.ReactElement;
  loading: boolean;
  handleOnClick?: () => void;
  isBtnDisabled?: boolean;
  btnText?: string | React.ReactElement;
  image?: string;
  noPanel?: boolean;
}

const CardErrorSection: FC<IProps> = ({
  title,
  message,
  loading,
  handleOnClick,
  image,
  isBtnDisabled,
  btnText,
  noPanel,
}) => {

  const withoutPanel = (
    <Fragment>
      { image && (
        <div className=" ben-pt-4 ben-pb-3">
          <img src={ image } alt="" />
        </div>
      ) }
      <div className="ben-pb-5 ben-mb-4 ben-border-bottom">
        { title && <h1 className="ben-mb-1">{ title }</h1> }

        { message && (
          <p className="ben-mb-4 ben-error-message">
            { message }
          </p>
        ) }
      </div>
      { btnText && (
        <Row gutter={ 30 }>
          <Col xs={ { span: 24 } } md={ { span: 12, offset: 6 } } className="ben-pb-2">
            { loading ? <Spinner /> :
              <Button
                type="primary"
                size="large"
                className="ben-w-100 ben-resend-sharing-invitation-btn"
                onClick={ handleOnClick }
                disabled={ isBtnDisabled }
                id="cardErrorBtnSubmit"
              >
                { btnText }
              </Button>
            }
          </Col>
        </Row>
      ) }
    </Fragment>
  );

  const withPanel = (
    <section className="ben-main-section ben-pt-5 ben-main-section-bp">
      <div className="ben-panel ben-pb-2 ben-text-center">{ withoutPanel }</div>
    </section>
  );

  const getContent = () => {
    return noPanel ? withoutPanel : withPanel;
  };

  return <div className="ben-text-center">{ getContent() }</div>;
};

export default CardErrorSection;
