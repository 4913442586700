import { FC } from "react";

import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { Row, Col } from "antd";

import { Form, Input, InputNumber, Select } from "common/lib/formik-antd";
import { maxLength20, maxLengthText, maxLengthTinOrEin } from "common/shared/constants/validationConstants";
import { tinOrEinFormatter, tinOrEinParser } from "domains/liquidity/shared/utils/inputValueFormatter";
import { countries } from "common/lib/constants";
import { Caret } from "common/lib/components";

const { Option } = Select;

interface IProps {
  handleKeyFieldChange: (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
}

const InstitutionOwnerFields: FC<IProps> = ({ handleKeyFieldChange }) => {
  const { t } = useTranslation([ "common", "liquidity", "authentication" ]);

  const { setFieldValue, values } = useFormikContext<any>();

  return (
    <>
      <Form.Item name="account_name" label={ t("Company Name", { ns: "liquidity" }) }>
        <Input
          onChange={ () => handleKeyFieldChange(setFieldValue) }
          maxLength={ maxLengthText }
          name="account_name"
          autoComplete="nope"
          suffix
        />
      </Form.Item>

      <Row gutter={ 8 }>
        <Col xs={ 24 } md={ 12 }>
          <Form.Item name="country_of_incorporation" label={ t("Country of Incorporation", { ns: "liquidity" }) }>
            <Select
              showSearch
              optionFilterProp="children"
              onChange={ () => handleKeyFieldChange(setFieldValue) }
              suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
              name="country_of_incorporation"
              placeholder={ t("Select Country", { ns: "liquidity" }) }
            >
              {countries.map((country) => (
                <Option key={ country } value={ country }>
                  {country}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={ 24 } md={ 12 }>
          <Form.Item
            name="tax_id"
            label={
              values.country_of_incorporation === "United States"
                ? t("Company EIN", { ns: "liquidity" })
                : t("Tax ID", { ns: "liquidity" })
            }
          >
            {values.country_of_incorporation === "United States" ? (
              <InputNumber
                onChange={ () => handleKeyFieldChange(setFieldValue) }
                maxLength={ maxLengthTinOrEin }
                formatter={ tinOrEinFormatter }
                parser={ tinOrEinParser }
                className="small"
                name="tax_id"
                placeholder={ t("Company EIN", { ns: "liquidity" }) }
                controls={ false }
                autoComplete="nope"
                prefix
              />
            ) : (
              <Input
                onChange={ () => handleKeyFieldChange(setFieldValue) }
                maxLength={ maxLength20 }
                name="tax_id"
                placeholder={ t("Tax ID", { ns: "liquidity" }) }
                suffix
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default InstitutionOwnerFields;
