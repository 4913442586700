export const LOGIN_FAIL_MESSAGE = "Sign-in failed";
export const WRONG_EMAIL_OR_PASSWORD_MESSAGE = "Invalid email or password";
export const LOGIN_SUCCESS_MESSAGE = "";
export const SET_PASSWORD_SUCCESS_MESSAGE = "";
export const FORGOT_PASSWORD_SUCCESS_MESSAGE = "";
export const INVALID_CODE_MESSAGE= "Invalid code";

export enum AuthenticationType {
  PHONE = "sms",
  EMAIL = "email",
}
