import { AssetType, LocationRegion } from "domains/liquidity/shared/constants";
import { TNonFundAsset } from "domains/liquidity/shared/types";

export const getAssetValuesByType = (values: Partial<TNonFundAsset>) => {
  switch (values.asset_type) {
    case AssetType.CoInvestment: {
      const { asset_type, sponsor, sponsor_description } = values;
      return { asset_type, sponsor, sponsor_description };
    }

    case AssetType.DirectRealEstateInvestment: {
      if (values.real_estate_location !== LocationRegion.NorthAmerica) {
        values.real_estate_location = values.other_region;
      }

      const { asset_type, real_estate_location, state, real_estate_property_type } = values;
      return { asset_type, real_estate_location, state, real_estate_property_type };
    }

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany: {
      const { asset_type, account_name, amount_invested, company_description } = values;
      return { asset_type, account_name, amount_invested, company_description };
    }

    case AssetType.DirectInterestsInNaturalResourcesAsset: {
      const { asset_type, asset_description } = values;
      return { asset_type, asset_description };
    }

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC: {
      const { asset_type, asset_name, asset_description, amount_invested } = values;
      return { asset_type, asset_name, asset_description, amount_invested };
    }

    default:
      return {};
  }
};
