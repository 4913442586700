import { ExcelFilled, JPEGFilled, PDFFilled, PowerPointFilled, WordFilled } from "common/lib/components";

export const getIconForFile = (fileName: string) => {
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);

  switch (extension.toLowerCase()) {
    case "csv":
    case "xls":
    case "xlsb":
    case "xlsm":
    case "xlsx":
      return <ExcelFilled width={ 14 } />;
    case "doc":
    case "docx":
      return <WordFilled width={ 14 } />;
    case "jpg":
    case "jpeg":
      return <JPEGFilled width={ 14 } />;
    case "ppt":
    case "pptx":
      return <PowerPointFilled width={ 14 } />;
    case "pdf":
      return <PDFFilled width={ 14 } />;
  }
};

export const truncateFileName = (fileName: string) => {
  const docType = fileName.substring(fileName.lastIndexOf("."));
  const docName = fileName.substring(0, fileName.lastIndexOf("."));

  return docName.substring(0, 128 - docType.length) + docType;
};
