import { FC } from "react";

import { Button } from "common/lib/components";

import "./ModalCard.scss";

interface IProps {
  title: string;
  text: string;
  buttonText: string;
  onClick: () => void;
}

const ModalCard: FC<IProps> = ({ title, text, buttonText, onClick }) => {
  return (
    <div className="ben-content-container">
      <h3 className="ben-text-center modal">{ title }</h3>
      <span className="ben-card-text ben-d-block ben-text-center text">{ text }</span>
      <Button type="primary" onClick={ onClick }>{ buttonText }</Button>
    </div>
  );
};

export default ModalCard;
