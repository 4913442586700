import { AssetType } from "domains/liquidity/shared/constants";

export const assetTypes = [
  {
    title: "Non-traded REIT",
    value: AssetType.NonTradedREIT,
  },
  {
    title: "Non-traded BDC",
    value: AssetType.NonTradedBDC,
  },
  {
    title: "Private Shares in a single privately held company",
    value: AssetType.PrivateSharesInSinglePrivatelyHeldCompany,
  },
  {
    title: "Direct Real Estate Investment",
    value: AssetType.DirectRealEstateInvestment,
  },
  {
    title: "Direct Interest in a Natural Resources Asset",
    value: AssetType.DirectInterestsInNaturalResourcesAsset,
  },
  {
    title: "Co-investment in company held by funds",
    value: AssetType.CoInvestment,
  },
];

export const LOCATION_IDS = {
  STATE: 0,
  OTHER_REGION: 1,
};
