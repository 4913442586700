import { FC } from "react";

import { useTranslation } from "react-i18next";
import { Table } from "antd";

import { DeleteOutlined, EditOutlined, Spinner } from "common/lib/components";
import { useScreen } from "common/shared-hooks";
import { IBeneficialTrust } from "domains/myProfile/shared/types";

import "./TrustOwnerSummaryTable.scss";

interface IProps {
  onDelete: (trustItem: IBeneficialTrust) => void;
  dataSource: IBeneficialTrust[];
  editBeneficialTrust: (index: number) => void;
  isFormDisabled: boolean;
}

const TrustOwnerSummaryTable: FC<IProps> = ({ onDelete, dataSource, editBeneficialTrust, isFormDisabled }) => {
  const { t } = useTranslation([ "myProfile" ]);
  const { mobile } = useScreen();

  const columns = [
    {
      title: t("Trust Name"),
      dataIndex: "trust_name",
      key: "trust_name",
      className: "ben-trust-owner-summary-table-trust-name",
    },
    {
      title: t("% Owned"),
      dataIndex: "trust_percent",
      key: "trust_percent",
      width: "90px",
    },
    {
      title: t("Trustee Name"),
      dataIndex: "trustee_name",
      key: "trustee_name",
      render: (value: string) => (
        <div key={ value } className="ben-pb-1">
          {value}
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "street_address1",
      key: "street_address1",
      render: (value: string) => (
        <div key={ value } className="ben-pb-1">
          {value}
        </div>
      ),
      className: "ben-trust-owner-summary-table-trustee-address",
    },
    {
      title: "",
      width: "60px",
      key: "actions",
      render: (value: IBeneficialTrust, record: any, index: number) => {
        return isFormDisabled ? null : (
          <div key={ value.trust_name } className="ben-pb-1">
            <Spinner spinning={ value.is_loading } fullWidth>
              <EditOutlined onClick={ () => editBeneficialTrust(index) } />
              <DeleteOutlined onClick={ () => onDelete(value) } />
            </Spinner>
          </div>
        );
      },
      className: "ben-trust-owner-summary-table-actions",
    },
  ];

  return (
    <>
      {mobile ? (
        <>
          {dataSource.map((value: IBeneficialTrust, index: number) => (
            <div key={ value.trust_name } className="ben-trust-owner-summary-info-wrapper ben-pb-2 ben-mb-3">
              <Spinner spinning={ value.is_loading }>
                <div className="ben-trust-owner-summary-info-title ben-pb-1 ben-mb-2">{t("Trust Name")}</div>
                <div className="ben-trust-owner-summary-info-trust-name ben-pb-2">{value.trust_name}</div>
                <div className="ben-trust-owner-summary-info-container">
                  <div className="ben-trust-owner-summary-info-subtitle">{t("% Owned")}</div>
                  <div>{value.trust_percent}</div>
                </div>
                <div key={ value.trustee_name } className="ben-trust-owner-summary-info-container">
                  <div className="ben-trust-owner-summary-info-subtitle">{t("Trustee Name")}</div>
                  <div>{value.trustee_name}</div>
                  <div>{value.street_address1}</div>
                  {!isFormDisabled && (
                    <div className="ben-trust-owner-summary-info-actions">
                      <EditOutlined onClick={ () => editBeneficialTrust(index) } />
                      <DeleteOutlined onClick={ () => onDelete(value) } />
                    </div>
                  )}
                </div>
                {!isFormDisabled && (
                  <div className="ben-trust-owner-summary-info-add" onClick={ () => editBeneficialTrust(index) }>
                    {t("+ Add another trustee")}
                  </div>
                )}
              </Spinner>
            </div>
          ))}
        </>
      ) : (
        <Table
          className="ben-trust-owner-summary-table ben-mb-3"
          pagination={ false }
          dataSource={ dataSource }
          columns={ columns }
        />
      )}
    </>
  );
};

export default TrustOwnerSummaryTable;
