export const mobileFundColumns = [
  {
    title: "Fund Manager & Fund Name",
    dataIndex: "fund",
    key: "fund",
    className: "ben-w-70",
  },
  {
    title: "",
    key: "action",
    dataIndex: "action",
    align: "right",
    className: "ben-w-30",
  } as const,
];

export const desktopFundColumns = [
  {
    title: "Fund Manager",
    dataIndex: "fundManager",
    key: "fundManager",
    className: "ben-w-30",
  },
  {
    title: "Fund Name",
    dataIndex: "fundName",
    key: "fundName",
    className: "ben-w-30",
  },
  {
    title: "NAV",
    dataIndex: "nav",
    key: "nav",
    className: "ben-w-20",
  },
  {
    title: "",
    key: "action",
    dataIndex: "action",
    align: "right",
    className: "ben-w-20",
  } as const,
];

export const altQuoteDesktopFundColumns = [
  {
    title: "Fund Manager",
    dataIndex: "fundManager",
    key: "fundManager",
    className: "ben-w-30",
  },
  {
    title: "Fund Name",
    dataIndex: "fundName",
    key: "fundName",
    className: "ben-w-35",
  },
  {
    title: "NAV",
    dataIndex: "nav",
    key: "nav",
    className: "ben-w-25",
  },
  {
    title: "Unfunded Commitments",
    dataIndex: "unfundedCommitments",
    key: "unfundedCommitments",
    className: "ben-w-25 ben-white-space-break-spaces",
  },
];
