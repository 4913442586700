import { IApiEnv } from "common/shared/interfaces/apiEnv";
import { WhiteLabelType } from "common/shared/types";

// change to "" when running tests locally
let host = window.location.hostname === "localhost" ? "https://baadev.bcpbenapp-dev.bankben.org" : "";

export class ApiEnvConstants {
  public auditAPI = `${ host }/api/audit`;
  public authAPI = `${ host }/api/auth`;
  public accountsAPI = `${ host }/api/accounts`;
  public holdingsAPI = `${ host }/api/holdings`;
  public identityAPI = `${ host }/api/identity`;
  public documentsAPI = `${ host }/api/documents`;
  public fundsAPI = `${ host }/api/funds`;

  public oAuthClientId = "";
  public whiteLabel = "";
  public verifyInvestorToken = "";
  public userPoolId = "";
  public isProductionEnv = false;
  public altQuoteLogin = "";
  public altQuotePassword = "";
  public benTrustLogin = "";
  public benTrustPassword = "";
  public featureEncryptPassword = "";
  public publicKey = "";
  public emailAddressInvestOps = "";
  public emailAddressOriginations = "";
  public emailAddressPrivacy = "";
  private resolve: any;
  private envs: Promise<IApiEnv>;

  constructor() {
    this.envs = new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  public setApiEnv(envAPIs: any) {
    envAPIs = envAPIs || {};

    this.oAuthClientId = envAPIs.OAuthClient_id || "";
    this.userPoolId = envAPIs.UserPoolId || "";
    this.whiteLabel = envAPIs.WhiteLabel || WhiteLabelType.Default;

    this.verifyInvestorToken = envAPIs.VerifyInvestor_token || "";
    this.isProductionEnv = envAPIs.IsProductionEnv || false;

    this.altQuoteLogin = envAPIs.AltQuoteLogin || "";
    this.altQuotePassword = envAPIs.AltQuotePassword || "";

    this.benTrustLogin = envAPIs.BenTrustLogin || "";
    this.benTrustPassword = envAPIs.BenTrustPassword || "";

    this.featureEncryptPassword = envAPIs.FeatureEncryptPassword || "Off";
    this.publicKey = envAPIs.PublicKey || "";

    this.emailAddressInvestOps = envAPIs.EmailAddressInvestOps || "";
    this.emailAddressOriginations = envAPIs.EmailAddressOriginations || "";
    this.emailAddressPrivacy = envAPIs.EmailAddressPrivacy || "";

    this.resolve(this);
  }

  public getApiEnv(): Promise<IApiEnv> {
    return this.envs;
  }
}

const apiEnvs = new ApiEnvConstants();
export default apiEnvs;
