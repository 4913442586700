import { Suspense, useMemo } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { defaultTheme } from "@ant-design/compatible";

import ErrorBoundary from "common/lib/components/ErrorBoundary";
import { router } from "common/shared";
import useWhiteLabel from "common/shared-hooks/useWhiteLabel";
import { WhiteLabelType } from "common/shared/types";
import "common/locales/config";

const App = () => {
  const { t } = useTranslation("common");
  const whiteLabelType = useWhiteLabel();
  const title = t(`Website Title.${ whiteLabelType }`, { defaultValue: "" });
  const tabIconSrc = useMemo(() => {
    if (!whiteLabelType) return "";

    const defaultIconPath = `${ process.env.PUBLIC_URL }/favicon-32x32-default.png`;
    const partnerIconPath = `${ process.env.PUBLIC_URL }/favicon-32x32-partner.png`;

    switch (whiteLabelType) {
      case WhiteLabelType.Default:
        return defaultIconPath;

      case WhiteLabelType.Trustben:
        return defaultIconPath;

      case WhiteLabelType.Partner:
        return partnerIconPath;

      default:
        return "";
    }
  }, [ whiteLabelType ]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={ tabIconSrc } sizes="32x32" />
      </Helmet>
      <ErrorBoundary>
        <ConfigProvider
          theme={ { ...defaultTheme, token: { ...defaultTheme.token, fontFamily: "IBM Plex sans Regular" } } }
        >
          <Suspense fallback={ null }>
            <RouterProvider router={ router } />
          </Suspense>
        </ConfigProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
