import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as CloseWindow } from "common/assets/images/icons/Close Window.svg";
import { ReactComponent as ClosePanel } from "common/assets/images/icons/Close Panel.svg";

const CloseWindowOutlined: FC<Partial<CustomIconComponentProps & { onClick: any, isPanel?: boolean }>> = (props) => {
  const { isPanel } = props;

  return (
    <Icon { ...props } className={ props?.className + " ben-icon-primary-color" } component={ isPanel ? ClosePanel : CloseWindow } />
  )
};

export default CloseWindowOutlined;
