export const AUTH = {
  SET_PASSWORD: "/set-password",
  SIGNIN: "/signin",
  SIGN_IN: "/sign-in",
  LOGIN_HELP: "/login-help",
  VERIFY_RESET_PASSWORD: "/verifyResetPassword",
  RESET_PASSWORD_EXPIRED: "/reset-expired",
  ERROR: "/public-error",
  ERROR_404: "/public-404",
  RESEND_WELCOME_EMAIL: "/resend-welcome-email",
  RESEND_SHARING_INVITATION_IN: "/resend-sharing-invitation-in",
  VERIFY_IDENTITY: "/verifyIdentity",
  VERIFY_SHARING: "/verifySharing",
  VERIFY_IDENTIFICATION_CODE: "/verify-identification-code",
  AUTHENTICATION: "/authentication",
  CODE_EXPIRED: "/code-expired",
  ACCOUNT_BLOCKED: "/account-blocked",
  INTERNAL_ALTQUOTE_FUNCTIONALITY: "/internal-altquote-functionality",
  TRUSTBEN: "/trustben",
  TRUSTBEN_ALTQUOTE: "/trustben-altquote",
  GP_FUND_MONETIZATION: "/gp_fund_monetization",
  NOT_FOUND: "*",
};

export const PROTECTED = {
  MY_CLIENTS: "/my-clients/",
  ALT_DATA: "/altdata",
  ALT_CUSTODY: "/altcustody",
  CLIENT_APPLICATIONS: "/client-applications",
  MY_CLIENTS_ASSETS: "/my-clients-assets",
  DASHBOARD: "/dashboard",
  LIQUIDITY: "/dashboard/liquidity/",
  MY_APPLICATIONS: "/my-applications/",
  MY_ASSETS: "/my-assets",
  MY_ALT_CUSTODY_ASSETS: "/my_assets",
  CONTACT_US: "/portal-help",
  ERROR_404: "/404",
  PRIVACY_NOTICE: "/privacy-notice",
  PREFERENCES: "/preferences",
};
