import { useCallback, useState, memo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "common/lib/components";
import { ApplyForLiquidityButtonModal } from "domains/dashboard/components";
import { URLs } from "common/lib/constants";
import { FeatureNames, useFeature } from "domains/features";

interface IProps {
  isAdvisor: boolean;
  className?: string;
}

const ApplyForLiquidityButton = memo(function ApplyForLiquidityButton({ className, isAdvisor }: IProps) {
  const { t } = useTranslation([ "dashboard" ]);
  const navigate = useNavigate();

  const { isFeatureActive } = useFeature(FeatureNames.AltCustody, []);

  const [ isModalVisible, setIsModalVisible ] = useState(false);

  const onClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleApplyForAltCustodyClick = useCallback(() => {
    isAdvisor
      ? navigate(URLs.PROTECTED.MY_CLIENTS_ASSETS)
      : navigate(URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS, { state: { showAltCustodyTutorial: true } });
  }, [ isAdvisor ]);

  return (
    <>
      <ApplyForLiquidityButtonModal
        isModalVisible={ isModalVisible }
        handleCancel={ handleCancel }
        isAdvisor={ isAdvisor }
      />
      <div style={ { display: "flex", flexDirection: "column", width: "210px" } }>
        {isFeatureActive && (
          <Button type="primary" style={ { marginBottom: 10 } } onClick={ handleApplyForAltCustodyClick }>
            {t("Apply for AltCustody", { ns: "dashboard" })} +
          </Button>
        )}

        <Button type="primary" className={ className } onClick={ onClick }>
          {t("Apply for Liquidity", { ns: "dashboard" })} +
        </Button>
      </div>
    </>
  );
});

export default ApplyForLiquidityButton;
