import { useSelector } from "react-redux";

import { TAppState } from "store/root.store";
import { UserType } from "domains/clients/shared/types";

export const useClientsSelector = () => {
  const clientsReducer = useSelector((state: TAppState) => state.clientsReducer);
  const isAdvisor =
    clientsReducer.user?.user_type === UserType.Advisor || clientsReducer.user?.user_type === UserType.BenOriginations;

  return { ...clientsReducer, isAdvisor };
};
