export interface IImpersonatePayload {
  encryptedPayload: string;
  initVector: string;
  userPayload?: string;
}

export class ImpersonationService {
  private impersonateData: IImpersonatePayload | null = null;

  public getImpersonationPayload(): IImpersonatePayload | null {
    return this.impersonateData ? { ...this.impersonateData } : null;
  }

  public setImpersonatePayload(impersonateData: IImpersonatePayload) {
    this.impersonateData = impersonateData;
  }
}

export default new ImpersonationService();
