import { Radio as AntdRadio, RadioProps as AntdRadioProps } from "antd";
import { useField } from "formik";

import RadioGroup from "common/lib/formik-antd/RadioGroup";

const Radio = ({ name, onChange: $onChange, ...restProps }: AntdRadioProps & { name: string }) => {
  const [ { value }, _meta, { setValue, setTouched } ] = useField(name);

  return (
    <AntdRadio
      id={ name }
      name={ name }
      checked={ value }
      onChange={ (event) => {
        setValue(event.target.checked);
        setTouched(true, false);
        $onChange?.(event);
      } }
      { ...restProps }
    />
  );
};

Radio.Group = RadioGroup;
Radio.Button = AntdRadio.Button;

export default Radio;
