import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FormHeader, Spinner } from "common/lib/components";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";
import { CustodyAccountIDForm } from "domains/liquidity/forms";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

const CustodyAccountIDPage = () => {
  const dispatch = useDispatch();
  const [ searchParams ] = useSearchParams();
  const { t } = useTranslation("liquidity");

  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const [ fetchDocumentsById, { data: documents } ] = documentsApiReducer.useLazyFetchDocumentsByIdQuery();
  const [ fetchAccountLevelDetailsById, { isFetching: isFetchingAccountLevelDetails } ] =
    clientsApiReducer.useLazyFetchAccountLevelDetailsByIdQuery();
  const [ _, { isLoading: isUpdatingAccountLevelDetails } ] = clientsApiReducer.useUpdateAccountLevelDetailsMutation({
    fixedCacheKey: "update-custody-account-id-form",
  });

  const custodyAccountId = searchParams.get("account_id") || null;

  useEffect(() => {
    if (!custodyAccountId) return;

    dispatch(
      setCurrentCustodyAccount({
        account_id: custodyAccountId,
      } as ICustodyAccount),
    );
    fetchAccountLevelDetailsById({ accountId: custodyAccountId, type: "custody" });
    fetchDocumentsById(custodyAccountId);
  }, [ custodyAccountId ]);

  useEffect(() => {
    if (!custodyAccountId) return;

    fetchAccountLevelDetailsById({
      accountId: custodyAccountId,
      type: "custody",
    });
  }, [ custodyAccountId ]);

  useEffect(() => {
    if (!currentCustodyAccount?.account_id || custodyAccountId) return;

    fetchAccountLevelDetailsById({ accountId: currentCustodyAccount.account_id, type: "custody" });
    fetchDocumentsById(currentCustodyAccount.account_id);
  }, [ currentCustodyAccount?.account_id, custodyAccountId ]);

  useEffect(() => {
    if (!currentCustodyAccount?.account_id || custodyAccountId) return;

    fetchAccountLevelDetailsById({
      accountId: currentCustodyAccount.account_id,
      type: "custody",
    });
  }, [ currentCustodyAccount?.account_id, custodyAccountId ]);

  return (
    <Spinner spinning={ isFetchingAccountLevelDetails || isUpdatingAccountLevelDetails }>
      <FormHeader subtitle={ t("Custody Account ID Page.Subtitle") } />
      <CustodyAccountIDForm documents={ documents ?? [] } />
    </Spinner>
  );
};

export default CustodyAccountIDPage;
