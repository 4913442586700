import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import featuresApiService from "domains/features/shared/features.api.service";
import { formatFeaturesForReducer } from "domains/features/shared/utils/apiFormatter";
import { IFeature } from "domains/features/shared/types";
import { apiService } from "common/shared";

export const featuresApiReducer = rootApiReducer.injectEndpoints({
  endpoints: (build) => ({
    fetchFeatures: build.query<IFeature[], void>({
      queryFn: async () => {
        try {
          const features = await featuresApiService.fetchFeatures();
          const data = formatFeaturesForReducer(features);

          return { data };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),
  }),
});
