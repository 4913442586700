import { FC } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { URLs } from "common/lib/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { LAPages } from "domains/liquidity";
import { FormFooter, FormHeader, Button } from "common/lib/components";
import { IConfirmationLabels } from "./types";

import "./ConfirmationPage.scss";

const ConfirmationPage: FC = () => {
  const { t } = useTranslation("liquidity");
  const { current, LRId } = useParams();
  const navigate = useNavigate();

  const { isLA } = useLiquiditySelector();

  const altQuoteConstants: IConfirmationLabels = {
    title: t("Confirmation.AltQuote.Title"),
    info: t("Confirmation.AltQuote.Info"),
    actionSectionTitle: t("Confirmation.AltQuote.Action Title"),
    actionSectionInfo: t("Confirmation.AltQuote.Action Info"),
    actionSectionButton: t("Confirmation.AltQuote.Action Button"),
    applicationStatusInfo: t("Confirmation.AltQuote.Application Status Info"),
    footerInfoTitle: t("Confirmation.AltQuote.Footer Title"),
    footerInfo: [
      t("Confirmation.AltQuote.Footer Info.0"),
      t("Confirmation.AltQuote.Footer Info.1"),
      t("Confirmation.AltQuote.Footer Info.2"),
    ],
  };

  const applicationConstants: IConfirmationLabels = {
    title: t("Confirmation.Application.Title"),
    info: t("Confirmation.Application.Info"),
    actionSectionTitle: t("Confirmation.Application.Action Title"),
    actionSectionInfo: t("Confirmation.Application.Action Info"),
    actionSectionButton: t("Confirmation.Application.Action Button"),
    applicationStatusInfo: t("Confirmation.Application.Application Status Info"),
    footerInfoTitle: t("Confirmation.Application.Footer Title"),
    footerInfo: t("Confirmation.Application.Footer Info"),
  };

  const documentConstants: IConfirmationLabels = {
    title: t("Confirmation.Document.Title"),
    info: t("Confirmation.Document.Info"),
    actionSectionTitle: t("Confirmation.Document.Action Title"),
    actionSectionInfo: t("Confirmation.Document.Action Info"),
    actionSectionButton: t("Confirmation.Document.Action Button"),
    applicationStatusInfo: t("Confirmation.Document.Application Status Info"),
    footerInfo: [ t("Confirmation.Document.Footer Info") ],
  };

  const labels = !isLA
    ? altQuoteConstants
    : current === LAPages.ConfirmApplication
      ? applicationConstants
      : documentConstants;

  const onButtonClick = () => {
    if (isLA) {
      if (current === LAPages.ConfirmApplication) {
        navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.AssetDocuments);
      } else {
        navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.APPLICATION_STATUS);
      }
    } else {
      navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.ALTQUOTE);
    }
  };

  return (
    <>
      <div className="ben-confirmation-wrapper">
        <FormHeader subtitle={ labels.title } hint={ labels.info } />
        <div className="ben-confirmation-upload-documents-section">
          {!(current === LAPages.ConfirmAssetDocuments) && (
            <>
              <h4>{labels.actionSectionTitle}</h4>
              <p>{labels.actionSectionInfo}</p>
              <Button type="primary" onClick={ onButtonClick }>
                {labels.actionSectionButton}
              </Button>
              <p>{labels.applicationStatusInfo}</p>
            </>
          )}
          <Button type="text" style={ { fontSize: "19px" } }>
            <Link
              to={
                URLs.PROTECTED.MY_APPLICATIONS +
                LRId +
                "/" +
                (isLA ? MyApplicationsPages.APPLICATION_STATUS : MyApplicationsPages.ALTQUOTE)
              }
            >
              {isLA ? t("View Application Status") : t("View AltQuote Status")}
            </Link>
          </Button>
        </div>
        {current === LAPages.ConfirmApplication && (
          <FormFooter>
            <p>
              <span>{labels.footerInfoTitle}</span> {labels.footerInfo}
            </p>
          </FormFooter>
        )}
      </div>
    </>
  );
};

export default ConfirmationPage;
