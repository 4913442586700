import { Checkbox as AntdCheckbox } from "antd";
import { useField } from "formik";
import { CheckboxGroupProps as AntdCheckboxGroupProps } from "antd/lib/checkbox";

const CheckboxGroup = ({
  name,
  // eslint-disable-next-line no-unused-vars
  value: $value,
  onChange: $onChange,
  ...restProps
}: AntdCheckboxGroupProps & { name: string }) => {
  const [ { value }, _meta, { setValue, setTouched } ] = useField(name);

  return (
    <AntdCheckbox.Group
      name={ name }
      value={ value }
      onChange={ (checkedValue) => {
        setValue(checkedValue);
        setTouched(true, false);

        $onChange?.(checkedValue);
      } }
      { ...restProps }
    />
  );
};

export default CheckboxGroup;
