export const nonTradedREITAssets = [
  "American Realty Capital (ARC)",
  "Benefit Street Partners Realty Trust Inc",
  "Black Creek Diversified Property Fund",
  "Blackstone Real Estate Investment Trust (BREIT)",
  "Cantor Fitzgerald Income Trust",
  "Carey Watermark",
  "Carter Validus Mission Critical REITs",
  "CIM Real Estate Finance Trust Inc",
  "CNL Financial Group",
  "CNL Healthcare Properties",
  "Cole Capital",
  "Corporate Property Associates (CPA)",
  "Dividend Capital",
  "Griffin Capital Corp",
  "Healthcare Trust",
  "Highlands REIT Inc",
  "Hines Real Estate",
  "Hospitality Investors Trust",
  "Inland Real Estate Group",
  "InvenTrust Properties Corp",
  "KBS Capital Markets",
  "Lightstone Group",
  "MacKenzie Realty Capital",
  "NetREIT",
  "New York City REIT",
  "NorthStar Asset Management",
  "Phillips Edison & Co",
  "Presidio Property Trust",
  "Resource Real Estate",
  "Smartstop Self Storage Inc",
  "Starwood Real Estate Income Trust",
  "Steadfast Apartment REIT",
  "Steadfast Income REIT",
  "Strategic Realty Trust",
  "Strategic Storage REIT",
  "Summit Healthcare",
  "Watermark Lodging Trust Inc",
  "Whitestone REIT",
  "Other",
];

export const nonTradedBDCAssets = [
  "AEI Capital Corp",
  "ATEL Capital Group",
  "Business Development Corp of America",
  "Corporate Capital Trust",
  "Cypress Leasing Corp",
  "Divall Properties",
  "Franklin BSP Lending Corp",
  "Franklin Square Capital Partners",
  "FS Investments",
  "ICON Investments",
  "LEAF Commercial Capital",
  "MSC Income Fund",
  "Redwood Mortgage Investors",
  "Reef Oil & Gas",
  "Secured Income",
  "Sedona Tree Farm",
  "United Development Funding",
  "Other",
];
