import { FC, useState, useMemo, useLayoutEffect, ChangeEvent, useEffect } from "react";

import { Modal, Table, Checkbox, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { maxLengthText } from "common/shared/constants/validationConstants";
import { formatCurrencyWithoutFractional, formatId } from "common/shared/helpers";
import { CircleX } from "common/lib/components/Icons";
import { LAPages } from "domains/liquidity";
import { URLs } from "common/lib/constants";
import { Button } from "common/lib/components";
import { AssetType } from "domains/liquidity/shared/constants";
import { TAssetType, TConfirmAssetToIncludeItem } from "domains/liquidity/shared/types";
import { setCurrentLiquidityRequestData, setIsLA } from "domains/liquidity/store/liquidity.reducer";
import { setIsAltQuoteTab } from "domains/altQuotes/store/altQuotes.reducer";
import { useClientsSelector } from "domains/clients";
import { RelationshipTypes } from "domains/clients/shared/types";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { useTrustbenSelector } from "domains/trustben";
import { useAppDispatch } from "store/store.hooks";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { altQuotesApiReducer } from "domains/altQuotes/store/altQuotes.api.reducer";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";

import "./RequestFormalProposalModal.scss";

interface IProps {
  isVisible: boolean;
  handleCancel: any;
}

const RequestFormalProposalModal: FC<IProps> = ({ isVisible, handleCancel }) => {
  const navigate = useNavigate();
  const { t } = useTranslation([ "altQuotes", "common" ]);
  const dispatch = useAppDispatch();
  const { LRId: id } = useParams();
  const LRId = sessionStorage.getItem("LIQUIDITY_REQUEST_ID") || id;

  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const { isAdvisor, currentClient, user } = useClientsSelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const { data: clients } = clientsApiReducer.useFetchUserClientsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const { data: assets = [], isFetching: isFetchingLRAssets } = liquidityApiReducer.useFetchLRAssetsQuery(
    LRId ?? skipToken,
  );
  const [
    convertAltQuoteToLiquidityApplication,
    { data: convertedAltQuoteResponse, isLoading: isConvertingAltQuote, isSuccess: isAltQuoteConverted, reset },
  ] = altQuotesApiReducer.useConvertAltQuoteToLiquidityApplicationMutation({
    fixedCacheKey: "convert-altquote-to-liquidity-application",
  });
  const [ logout ] = authApiReducer.useLogoutMutation();

  const [ isCheckboxChecked, setIsCheckboxChecked ] = useState(false);
  const [ inputValue, setInputValue ] = useState("");

  const liquidityRequest = liquidityRequests?.find((lr) => lr.id === LRId);
  const clientId = liquidityRequest?.accounts?.find(
    (account) => account.relationship_type === RelationshipTypes.Client,
  )?.account_id;
  const client =
    clients?.items?.find((client) => client.account_id === formatId("ACC-", clientId ?? "")) || currentClient;
  const activeCustodyAccounts = useMemo(
    () =>
      custodyAccounts?.filter(
        (custodyAccount) =>
          custodyAccount.account_status !== CustodyAccountStatus.Closed &&
          custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
          custodyAccount.account_status !== CustodyAccountStatus.Duplicate,
      ),
    [ custodyAccounts ],
  );
  const funds = useMemo(
    () =>
      assets?.filter(
        (asset) =>
          asset.asset_type !== undefined &&
          (asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ),
    [ assets?.length ],
  );
  const fundAssets = inputValue
    ? funds.filter((fund) => {
        return fund.asset_name.toLowerCase().includes(inputValue.toLowerCase());
      })
    : funds;
  const [ chosenAssets, setChosenAssets ] = useState<TAssetType[]>([]);
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<string[]>([]);

  useLayoutEffect(() => {
    setSelectedRowKeys(Object.keys(funds));
    setChosenAssets(Object.values(funds));
  }, [ funds ]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelect: (record: TConfirmAssetToIncludeItem) => {
      const selectedRowKeysCopy = [ ...selectedRowKeys ];
      if (selectedRowKeysCopy.indexOf(record.key) >= 0) {
        selectedRowKeysCopy.splice(selectedRowKeysCopy.indexOf(record.key), 1);
      } else {
        selectedRowKeysCopy.push(record.key);
      }

      const chosenAssets = fundAssets.filter((_, index) => selectedRowKeysCopy.some((key: string) => +key === index));
      setChosenAssets(chosenAssets);
      setSelectedRowKeys(selectedRowKeysCopy);
    },
    onSelectAll: (selected: boolean) => {
      if (selected) {
        setSelectedRowKeys(Object.keys(fundAssets));
        setChosenAssets(Object.values(fundAssets));
      } else {
        setSelectedRowKeys([]);
        setChosenAssets([]);
      }
    },
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleContinueModal = () => {
    if (!liquidityRequest) return;

    if (isTrustbenAltQuote) {
      logout();
      navigate(URLs.AUTH.SIGN_IN, { state: { LRId }, replace: true });
      return;
    }

    convertAltQuoteToLiquidityApplication({
      altQuote: liquidityRequest,
      assets: chosenAssets,
      client: client ?? undefined,
    });
  };

  const assetsToIncludeColumns = [
    {
      title: t("Request Formal Proposal Modal.Table Columns.0"),
      dataIndex: "assetName",
      key: "assetName",
      width: "64.5%",
      align: "left",
    } as const,
    {
      title: t("Request Formal Proposal Modal.Table Columns.1"),
      dataIndex: "valueInMyPortfolio",
      key: "valueInMyPortfolio",
      width: "30%",
      align: "right",
    } as const,
  ];

  const fundDataSource = fundAssets?.map((asset, i) => {
    const { asset_name, current_net_asset_value } = asset;
    return {
      key: String(i),
      assetName: asset_name,
      valueInMyPortfolio: formatCurrencyWithoutFractional(current_net_asset_value),
    };
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCleanInput = () => {
    setInputValue("");
  };

  useEffect(() => {
    if (!isAltQuoteConverted) return;

    const LRId = convertedAltQuoteResponse;

    // Fetch liquidity request and accounts data after successful conversion
    dispatch(liquidityApiReducer.endpoints.fetchLiquidityRequest.initiate(LRId!));
    dispatch(liquidityApiReducer.endpoints.fetchLRAccounts.initiate(LRId!));
    dispatch(setIsAltQuoteTab(false));
    dispatch(setIsLA(true));

    if (isCheckboxChecked) {
      navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.SelectAssets);
    } else {
      dispatch(setCurrentCustodyAccount(null));
      activeCustodyAccounts?.length
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccount)
        : navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
    }

    sessionStorage.removeItem("LIQUIDITY_REQUEST_ID");

    reset();
  }, [ isAltQuoteConverted ]);

  return (
    <Modal
      afterClose={ () => setInputValue("") }
      className="ben-modal-request-formal-proposal"
      open={ isConvertingAltQuote || isVisible }
      width={ 725 }
      onCancel={ handleCancel }
      onOk={ handleContinueModal }
      footer={ [
        <Button disabled={ isConvertingAltQuote } onClick={ handleCancel } key="back">
          {t("Cancel", { ns: "common" })}
        </Button>,
        <Button disabled={ isConvertingAltQuote } onClick={ handleContinueModal } type="primary" key="continue">
          {t("Continue", { ns: "common" })}
        </Button>,
      ] }
    >
      <div className="ben-modal-request-formal-proposal-table">
        <h1>
          {isAdvisor ? t("Request Formal Proposal Modal.Advisor Title") : t("Request Formal Proposal Modal.User Title")}
        </h1>
        {isAdvisor ? (
          <Input
            maxLength={ maxLengthText }
            value={ inputValue }
            addonBefore={ "Search" }
            name="client_name"
            className="ben-small ben-search ben-modal-request-formal-proposal-input"
            onChange={ handleChange }
            suffix={ inputValue ? <CircleX onClick={ handleCleanInput } /> : <span /> }
          />
        ) : (
          <p>
            {t("Request Formal Proposal Modal.Text.0")}
            <br />
            {t("Request Formal Proposal Modal.Text.1")}
          </p>
        )}
        <Table
          loading={ isConvertingAltQuote || isFetchingLRAssets }
          rowSelection={ { type: "checkbox", ...rowSelection } }
          columns={ assetsToIncludeColumns }
          dataSource={ isFetchingLRAssets ? [] : fundDataSource }
          pagination={ false }
          footer={ () => (
            <Checkbox className="ben-modal-request-formal-proposal-table-checkbox" onChange={ onCheckboxChange }>
              {t("Request Formal Proposal Modal.Checkbox Text")}
            </Checkbox>
          ) }
        >
        </Table>
      </div>
    </Modal>
  );
};

export default RequestFormalProposalModal;
