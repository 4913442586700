import { FC, MouseEvent } from "react";

import { Col, Row } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  DatePicker,
  InputOnlyDigits,
  PhoneNumberInput,
} from "common/lib/formik-antd";
import { filterStates } from "common/shared/helpers/filterStates";
import { Tooltip, Caret, NavigationButtons } from "common/lib/components";
import {
  maxBirthDate,
  maxLength100,
  maxLength20,
  maxLength80,
  maxLengthSocialSecurity,
  maxLengthText,
  maxLengthZipCode,
  minDate,
} from "common/shared/constants/validationConstants";
import { onStateSearch } from "common/shared/helpers/searchAmericanStates";
import { AccountType, countries, getStates } from "common/lib/constants";
import InstitutionOwnerFields from "./InstitutionOwnerFields";
import { socialSecurityFormatter, socialSecurityParser } from "domains/liquidity/shared/utils/inputValueFormatter";

const { Option } = Select;

interface IProps {
  handleKeyFieldChange: (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => void;
  handleCustodyAgreementOpen: (e: MouseEvent, isCustodyAgreementSigned: boolean) => void;
}

const RegularCustodyAccountForm: FC<IProps> = ({ handleKeyFieldChange, handleCustodyAgreementOpen }) => {
  const { t } = useTranslation([ "common", "liquidity", "authentication" ]);

  const { setFieldValue, values, isValid } = useFormikContext<any>();

  return (
    <Form className="ben-custody-agreement-form" layout="vertical">
      <div className="ben-custody-agreement-fields">
        <Form.Item name="owner_type">
          <p className="ben-text-center ben-secondary-color">Is the owner an institution?</p>
          <Row className="ben-mb-2" justify="center">
            <Radio.Group name="owner_type">
              <Radio name="owner_type" value={ AccountType.CustodialInstitution }>
                {t("Yes")}
              </Radio>
              <Radio name="owner_type" value={ AccountType.CustodialIndividual }>
                {t("No")}
              </Radio>
            </Radio.Group>
          </Row>
        </Form.Item>

        {values.owner_type === AccountType.CustodialInstitution && (
          <InstitutionOwnerFields handleKeyFieldChange={ () => handleKeyFieldChange(setFieldValue) } />
        )}

        <Row gutter={ 8 }>
          <Col xs={ 24 } md={ 12 }>
            <Form.Item name="first_name" label={ t("First Name", { ns: "liquidity" }) }>
              <Input
                onChange={ () => handleKeyFieldChange(setFieldValue) }
                maxLength={ maxLengthText }
                name="first_name"
                autoComplete="nope"
                suffix
              />
            </Form.Item>
          </Col>

          <Col xs={ 24 } md={ 12 }>
            <Form.Item name="last_name" label={ t("Last Name", { ns: "liquidity" }) }>
              <Input
                onChange={ () => handleKeyFieldChange(setFieldValue) }
                maxLength={ maxLengthText }
                name="last_name"
                autoComplete="nope"
                suffix
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="country" label={ t("Address Country", { ns: "liquidity" }) } className="ben-input">
          <Select
            showSearch
            optionFilterProp="children"
            suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
            name="country"
            placeholder={ t("Select Country", { ns: "liquidity" }) }
            // @ts-ignore
            autoComplete="nope"
          >
            {countries.map((country) => (
              <Option key={ country } value={ country }>
                {country}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="address_line_1" label={ t("Address Line 1", { ns: "liquidity" }) }>
          <Input maxLength={ maxLengthText } name="address_line_1" autoComplete="nope" suffix />
        </Form.Item>

        <Form.Item name="address_line_2" label={ t("Address Line 2", { ns: "liquidity" }) }>
          <Input maxLength={ maxLengthText } name="address_line_2" autoComplete="nope" suffix />
        </Form.Item>

        <Form.Item name="city" label={ t("City", { ns: "liquidity" }) }>
          <Input maxLength={ maxLengthText } name="city" autoComplete="nope" suffix />
        </Form.Item>

        <Row gutter={ 8 }>
          <Col xs={ 24 } md={ 12 }>
            <Form.Item
              name="state"
              label={
                values.country === "United States"
                  ? t("State", { ns: "liquidity" })
                  : t("State or Province", { ns: "liquidity" })
              }
            >
              {values.country === "United States" ? (
                <Select
                  showSearch
                  name="state"
                  optionFilterProp="label"
                  suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                  onSearch={ (value) => onStateSearch(value, setFieldValue) }
                  filterOption={ filterStates }
                  options={ getStates() }
                  // @ts-ignore
                  autoComplete="nope"
                />
              ) : (
                <Input maxLength={ maxLength80 } name="state" autoComplete="nope" suffix />
              )}
            </Form.Item>
          </Col>

          <Col xs={ 24 } md={ 12 }>
            <Form.Item name="zip_code" label={ values.country === "United States" ? t("Zip Code") : t("Postal Code") }>
              {values.country === "United States" ? (
                <InputOnlyDigits
                  maxLength={ values.country === "United States" ? maxLengthZipCode : maxLength100 }
                  name="zip_code"
                />
              ) : (
                <Input maxLength={ maxLength100 } name="zip_code" placeholder={ t("Postal Code") } suffix />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={ 8 }>
          <Col xs={ 24 } md={ 12 }>
            <Form.Item name="country_of_citizenship" label={ t("Country of Citizenship") }>
              <Select
                showSearch
                optionFilterProp="children"
                onChange={ () => handleKeyFieldChange(setFieldValue) }
                suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                name="country_of_citizenship"
                placeholder={ t("Select Country", { ns: "liquidity" }) }
                // @ts-ignore
                autoComplete="nope"
              >
                {countries.map((country) => (
                  <Option key={ country } value={ country }>
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={ 24 } md={ 12 }>
            {values.country_of_citizenship === "United States" ? (
              <Form.Item
                name="ssn"
                label={
                  <>
                    {t("Social Security Number", { ns: "liquidity" })}{" "}
                    <Tooltip content={ t("Tooltip Content", { ns: "liquidity" }) } dark />
                  </>
                }
              >
                <InputNumber
                  maxLength={ maxLengthSocialSecurity }
                  formatter={ socialSecurityFormatter }
                  parser={ socialSecurityParser }
                  onChange={ () => handleKeyFieldChange(setFieldValue) }
                  className="small"
                  name="ssn"
                  placeholder={ t("Social Security Number", { ns: "liquidity" }) }
                  controls={ false }
                  autoComplete="nope"
                  prefix
                />
              </Form.Item>
            ) : (
              <Form.Item name="ssn" label={ t("Government ID") }>
                <Input
                  onChange={ () => handleKeyFieldChange(setFieldValue) }
                  maxLength={ maxLength20 }
                  name="ssn"
                  placeholder={ t("Government ID") }
                  suffix
                />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Form.Item name="date_of_birth" label={ t("Date of Birth", { ns: "liquidity" }) }>
          <DatePicker
            name="date_of_birth"
            inputClassName="small"
            disabledDate={ (current) => {
              return current.toDate() > maxBirthDate || current.toDate() < minDate;
            } }
          />
        </Form.Item>

        <Form.Item name="telephone_number" label={ t("Telephone Number") }>
          <PhoneNumberInput className="small" name="telephone_number" />
        </Form.Item>

        <Form.Item name="email_address" label={ t("Email Address", { ns: "authentication" }) }>
          <Input maxLength={ maxLengthText } name="email_address" autoComplete="nope" suffix />
        </Form.Item>
      </div>

      <div className="ben-custody-agreement-section">
        <h1>{t("License and Custody Account Agreement", { ns: "liquidity" })}</h1>
        <p>{t("Custody Agreement Section Info Individual", { ns: "liquidity" })}</p>
        <Form.Item name="custody_agreement" className="ben-agreement-checkbox">
          <Checkbox
            disabled={ values.custody_agreement }
            name="custody_agreement"
            onClick={ (e) => handleCustodyAgreementOpen(e, values.custody_agreement) }
          />
          <p>
            {t("I agree to the", { ns: "authentication" })}
            <span
              className="ben-agreement-link"
              onClick={ (e) => handleCustodyAgreementOpen(e, values.custody_agreement) }
            >
              {t("License and Custody Account Agreement", { ns: "liquidity" })}
            </span>
          </p>
        </Form.Item>
      </div>

      <NavigationButtons disabled={ !isValid } />
    </Form>
  );
};

export default RegularCustodyAccountForm;
