import { Location } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity";
import { GPPages, TrustbenPages } from "./constants";
import { trustbenSessionService } from "./services";

export const getPageTitle = (route: string) => {
  let result = "";

  switch (true) {
    case route.includes(URLs.AUTH.SIGN_IN):
      result = "AltAccess – Sign In";
      break;

    case route.includes(URLs.AUTH.SIGNIN):
      result = "AltAccess – Sign In";
      break;

    case route.includes(URLs.AUTH.LOGIN_HELP):
      result = "AltAccess – Contact Us";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }`) &&
      route.includes(`${ LAPages.SelectAssets }?tab=1`):
      result = "AltQuote – Select Non-Fund Asset";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }`) && route.includes(LAPages.SelectAssets):
      result = "BAA AQ Step 1";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPPrimaryCommitment }${ GPPages.FundInformation }`):
      result = "Primary Commitment Fund";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPPrimaryCommitment }${ GPPages.ContactInformation }`):
      result = "Primary Commitment User";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPPrimaryCommitment }${ GPPages.ThankYou }`):
      result = "Primary Commitment Thank you";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPFundMonetization }${ GPPages.FundInformation }`):
      result = "Fund Monetization Fund";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPFundMonetization }${ GPPages.ContactInformation }`):
      result = "Fund Monetization User";
      break;

    case route.includes(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.GPFundMonetization }${ GPPages.ThankYou }`):
      result = "Fund Monetization Thank you";
      break;

    default:
      result = document.title;
  }

  return result;
};

export const getNewUrlWhenLRIsCreated = (location: Location, LRId: string) => {
  const { pathname } = location;
  const LAPagesUrls = Object.values(LAPages);
  const TrustbenPagesUrls = Object.values(TrustbenPages);
  const pages = [ ...LAPagesUrls, ...TrustbenPagesUrls ];

  if (!pathname.includes(URLs.AUTH.TRUSTBEN) || !pages.some((url) => pathname.includes(url))) return "";

  const currentLAPage = pathname.split("/").at(-1);
  const newPathname = `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LRId }/${ currentLAPage }`;
  const newLocation: Location = { ...location, pathname: newPathname };

  return newLocation;
};

export const sendGoogleAnalytics = (location: Location, pageTitle: string) => {
  if (!window.gtag) return;

  window.gtag("event", "page_view", {
    page_title: pageTitle,
    page_path: location.pathname + location.search,
  });
};

export const getIsTrustbenAPIRequest = () => {
  const isTrustbenAltQuote = trustbenSessionService.getIsTrustbenAltQuote();
  const trustbenSessionId = trustbenSessionService.getTrustbenSessionId();
  const isTrustbenAPIRequest = isTrustbenAltQuote && trustbenSessionId;

  return { isTrustbenAPIRequest, sessionId: trustbenSessionId };
};
