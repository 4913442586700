import axios from "axios";

import { apiEnvs, apiService } from "common/shared";
import { IAltQuoteQuestionnaireAPIResponse } from "domains/trustben/pages/AltQuoteQuestionnairePage/AltQuoteQuestionnaireForm/types";
import { IGPQuestionnaire, ILogs, IQuestionnaire } from "domains/trustben/shared/types";

export class TrustbenAPIService {
  private config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async sendLogs(logs: ILogs) {
    return axios.post(`${ apiEnvs.auditAPI }/v1/audit/logs`, logs, this.config);
  }

  async sendAltQuoteQuestionnaire(questionnaire: IQuestionnaire, LRId: string) {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/liquidity_questionnaire/${ LRId }`, questionnaire);
  }

  async fetchAltQuoteQuestionnaire(LRId: string, sessionId: string): Promise<IAltQuoteQuestionnaireAPIResponse[]> {
    return apiService.get(
      `${ apiEnvs.holdingsAPI }/v1/liquidity_questionnaire/${ LRId }?filter[0][field]=session_id&filter[0][operator]=equal&filter[0][value]=${ sessionId }`,
    );
  }

  async sendGPQuestionnaire(questionnaire: IGPQuestionnaire, sessionId: string) {
    return apiService.post(`${ apiEnvs.accountsAPI }/v1/accounts/questionnaire/${ sessionId }`, questionnaire);
  }
}

const trustbenAPIService = new TrustbenAPIService();

export default trustbenAPIService;
