import { FC, MouseEvent } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";

interface IProps {
  title: string;
  onClick: (e: MouseEvent) => void;
}

const PanelExtraButton: FC<IProps> = ({ title, onClick }) => {
  const { t } = useTranslation([ "dashboard" ]);

  return (
    <Button type="text" onClick={ onClick }>
      {t(`${ title }`)} +
    </Button>
  );
};

export default PanelExtraButton;
