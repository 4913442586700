import { FC, MouseEvent } from "react";

import { useTranslation } from "react-i18next";

import { NotificationFilled, Button } from "common/lib/components";

import "./CompactToDo.scss";

interface IProps {
  toDoText: string;
  buttonText: string | null;
  onClick: ((e: MouseEvent) => void) | null;
}

const CompactToDo: FC<IProps> = ({
  toDoText,
  buttonText,
  onClick,
}) => {
  const { t } = useTranslation([ "dashboard" ]);

  return (
    <div className="ben-compact-todo">
      <div className="ben-todo-info">
        <NotificationFilled />
        <div>
          <b>{ t("To Do") }</b>
          <p>
            { toDoText }
          </p>
        </div>
      </div>
      { buttonText && onClick && <Button className="ben-todo-action" onClick={ onClick }>{ buttonText }</Button> }
    </div>
  );
};

export default CompactToDo;
