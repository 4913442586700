import { AssetType } from "domains/liquidity/shared/constants";
import { nonTradedREITAssets, nonTradedBDCAssets } from "./constants";
import { INonTradedREITorBDC } from "domains/liquidity/shared/types";

const getNonTradedREITAssets = () => {
  return nonTradedREITAssets.map((asset) => ({
    label: asset,
    value: asset,
  }));
};

const getNonTradedBDCAssets = () => {
  return nonTradedBDCAssets.map((asset) => ({
    label: asset,
    value: asset,
  }));
};

export const getSelectOptions = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.NonTradedREIT:
      return getNonTradedREITAssets();

    case AssetType.NonTradedBDC:
      return getNonTradedBDCAssets();

    default:
      return [];
  }
};

export const getFormFieldLabel = (fieldName: keyof INonTradedREITorBDC, assetType: AssetType) => {
  switch (assetType) {
    case AssetType.NonTradedREIT:
      switch (fieldName) {
        case "asset_name":
          return "Non-traded REIT";

        case "asset_description":
          return "Other NTR";

        default:
          return "";
      }

    case AssetType.NonTradedBDC:
      switch (fieldName) {
        case "asset_name":
          return "Non-traded BDC";

        case "asset_description":
          return "Other BDC";

        default:
          return "";
      }

    default:
      return "";
  }
};
