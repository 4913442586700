import { FC } from "react";

import { CustodyAccountAgreementForm } from "domains/liquidity/forms";
import CustodyAccountAgreementHeader from "./CustodyAccountAgreementHeader";

import "./CustodyAccountAgreementPage.scss";

const CustodyAccountAgreementPage: FC = () => {
  return (
    <>
      <CustodyAccountAgreementHeader />
      <div className="ben-form-container">
        <CustodyAccountAgreementForm />
      </div>
    </>
  );
};

export default CustodyAccountAgreementPage;
