import { Divider } from "antd";
import { useTranslation } from "react-i18next";

import { useScreen } from "common/shared-hooks";

import "./DividerWithOR.scss";

const DividerWithOR = () => {
  const { t } = useTranslation("common");

  const { mobile } = useScreen();

  return (
    <div className="divider-with-or-container">
      <Divider className="divider-line" type={ mobile ? "horizontal" : "vertical" } />
      <span className="divider-text">{t("OR")}</span>
      <Divider className="divider-line" type={ mobile ? "horizontal" : "vertical" } />
    </div>
  );
};

export default DividerWithOR;
