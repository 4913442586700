import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import { ICustodyAccount, ICustodyAccountAPI } from "domains/custodyAccounts/shared/types";
import clientsService from "domains/clients/shared/clients.api.service";
import { apiService } from "common/shared";
import { TAppState } from "store/root.store";
import custodyAccountsApiService from "domains/custodyAccounts/shared/custodyAccounts.api.service";
import { setCurrentCustodyAccount } from "./custodyAccounts.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

export const custodyAccountsApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "custodyAccounts" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCustodyAccounts: build.query<ICustodyAccountAPI[], { accountId: string; userId: string }>({
        queryFn: async ({ accountId, userId }) => {
          try {
            const response = await clientsService.fetchUserLevelDetails(accountId, userId);

            return { data: response.account_users ?? [] };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: [ { type: "custodyAccounts", id: "LIST" } ],
      }),

      createCustodyAccount: build.mutation<
        ICustodyAccount,
        { accountDetails: ICustodyAccount; LRId: string | undefined }
      >({
        queryFn: async ({ accountDetails, LRId }, { getState, dispatch }) => {
          try {
            const userType = (getState() as TAppState).clientsReducer.user?.user_type!;

            const response = await custodyAccountsApiService.createCustodyAccount(
              accountDetails,
              userType,
              accountDetails.owner_type,
            );

            const data = { ...accountDetails, ...response } as ICustodyAccount & ICustodyAccountAPI;

            if (LRId) {
              await custodyAccountsApiService.connectCustodyAccountWithLR(userType, data, LRId);
              dispatch(liquidityApiReducer.util.invalidateTags([ { type: "lr-accounts", id: LRId } ]));
            }

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        onQueryStarted: async ({ accountDetails }, { dispatch, queryFulfilled }) => {
          try {
            dispatch(setCurrentCustodyAccount(accountDetails));

            const { data } = await queryFulfilled;

            dispatch(setCurrentCustodyAccount(data));
          } catch {
            dispatch(
              setCurrentCustodyAccount({
                ...accountDetails,
                custody_agreement: false,
              }),
            );
          }
        },
        invalidatesTags: [ { type: "custodyAccounts", id: "LIST" } ],
      }),

      connectCustodyAccountWithLR: build.mutation<
        null,
        { custodyAccount: ICustodyAccount | ICustodyAccountAPI; LRId: string }
      >({
        queryFn: async ({ custodyAccount, LRId }, { getState, dispatch }) => {
          try {
            const userType = (getState() as TAppState).clientsReducer.user?.user_type!;

            await custodyAccountsApiService.connectCustodyAccountWithLR(
              userType,
              custodyAccount as ICustodyAccount & ICustodyAccountAPI,
              LRId,
            );

            dispatch(liquidityApiReducer.util.invalidateTags([ { type: "lr-accounts", id: LRId } ]));

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
