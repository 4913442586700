import { useEffect, useState } from "react";

import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Menu as MenuIcon } from "common/lib/components/Icons";
import { Button, Spinner } from "common/lib/components";
import { useScreen } from "common/shared-hooks";
import { URLs } from "common/lib/constants";
import { trustbenLinksService } from "domains/trustben";
import { ITrustbenEnvLinks, ITrustbenLink } from "domains/trustben/shared/types";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";
import { TBAltQuotePages, TrustbenPages } from "domains/trustben/shared/constants";

import "./TrustbenMainMenu.scss";

const TrustbenMainMenu = () => {
  const { t } = useTranslation("trustben");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { LRId } = useParams();

  const [ logout ] = authApiReducer.useLogoutMutation();
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const { mobile } = useScreen();

  const [ open, setOpen ] = useState(false);
  const [ headerLinks, setHeaderLinks ] = useState<ITrustbenLink[] | null>(null);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleLoginClick = () => {
    const pages = [ MyApplicationsPages.ALTQUOTE, MyApplicationsPages.ALTQUOTE_ASSETS_INCLUDED ];

    const currentSection = pathname.split("/").at(-1) || "";
    const isAltQuoteSubmitted =
      TBAltQuotePages.LinkToQuote.slice(1) === currentSection && !!liquidityRequests?.[ 0 ]?.alt_quote_submission_email;
    const isAltQuoteResultsPage = pages.includes(currentSection);
    const navigationState = isAltQuoteResultsPage || isAltQuoteSubmitted ? { LRId } : null;

    logout();
    navigate(URLs.AUTH.SIGN_IN, { state: navigationState, replace: true });
  };

  const handleGetAQuoteClick = () => {
    navigate(`${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }${ TBAltQuotePages.Questionnaire }`, { replace: true });
  };

  const actionButtons = (
    <>
      <Button onClick={ handleGetAQuoteClick } className={ `trustben-nav-menu-button secondary ${ mobile ? "mobile" : "" }` }>
        {t("Get A Quote")}
      </Button>
      <Button onClick={ handleLoginClick } className={ `trustben-nav-menu-button primary ${ mobile ? "mobile" : "" }` }>
        {t("LOGIN")}
      </Button>
    </>
  );

  useEffect(() => {
    (async () => {
      const hostname = window.location.hostname;
      const hostKey =
        hostname === "localhost"
          ? "baadev.bcpbenapp-dev.bankben.org_links"
          : (`${ hostname }_links` as keyof ITrustbenEnvLinks);
      const defaultHostKey = "benaltaccess.beneficient.com_links";
      const trustbenAPILinks = await trustbenLinksService.getTrustbenLinks();

      const headerLinks = trustbenAPILinks
        ? (trustbenAPILinks[ hostKey ] || trustbenAPILinks[ defaultHostKey ])?.[ 0 ].header_links
        : null;

      setHeaderLinks(headerLinks);
    })();
  }, []);

  if (!headerLinks) {
    return <Spinner fullWidth />;
  }

  return (
    <>
      {mobile ? (
        <>
          <MenuIcon onClick={ showDrawer } />
          <Drawer zIndex={ 1001 } width={ 300 } placement="right" open={ open } onClose={ onClose }>
            {headerLinks.map((link) => (
              <a className="trustben-nav-link mobile" key={ link.key } href={ link.url } target="_self">
                {link.label}
              </a>
            ))}
            {actionButtons}
          </Drawer>
        </>
      ) : (
        <div className="ben-d-flex ben-align-items-center">
          {headerLinks.map((link) => (
            <a className="trustben-nav-link" key={ link.key } href={ link.url } target="_self">
              {link.label}
            </a>
          ))}
          {actionButtons}
        </div>
      )}
    </>
  );
};

export default TrustbenMainMenu;
