import { object, number } from "yup";

import { useValidationSchemas } from "common/shared/constants/validationSchemas";
import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";

export const useFundDetailsPageValidationSchema = () => {
  const { required } = useValidationErrorMessages();
  const { baseStringValidationSchema } = useValidationSchemas();

  const validationSchema = object().shape({
    vintage: baseStringValidationSchema,
    fund_strategy: baseStringValidationSchema,
    geographic_focus: number(),
  });

  const requiredValidationSchema = object().shape({
    vintage: baseStringValidationSchema.required(required),
    fund_strategy: baseStringValidationSchema.required(required),
    geographic_focus: number().required(required),
  });

  return { validationSchema, requiredValidationSchema };
};
