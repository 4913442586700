import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as Info } from "common/assets/images/icons/Info Lt.svg";
import { ReactComponent as InfoDark } from "common/assets/images/icons/InfoDk.svg";

const InfoFilled: FC<Partial<CustomIconComponentProps> & { dark?: boolean }> = ({
  dark = false,
  ...props
}) => (
  <Icon { ...props } className={ props?.className + " ben-icon-primary-color" } component={ dark ? InfoDark : Info } />
);

export default InfoFilled;
