import { useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setCurrentAssetData, setIsAddManually } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity";
import { AssetType } from "domains/liquidity/shared/constants";

export const useHandlers = () => {
  const { LRId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onNext = useCallback(
    (values: any) => {
      dispatch(setIsAddManually(true));
      dispatch(setCurrentAssetData({ ...values, asset_type: AssetType.Fund }));
      navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.FundDetails);
    },
    [ LRId ],
  );

  return { onNext };
};
