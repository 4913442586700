import { Form as AntdForm, FormItemProps } from "antd";
import { useField } from "formik";

const FormItem = ({ name, children, hasFeedback, ...restProps }: FormItemProps & { name: string }) => {
  const [ _field, { error, touched, initialError } ] = useField(name);

  const isError = touched && Boolean(error);
  const isInitialError = Boolean(initialError);
  const isValid = touched && !error;
  const showHelp = isError || (isInitialError && !touched);

  return (
    <AntdForm.Item
      htmlFor={ name }
      name={ name }
      id={ name }
      validateStatus={ isError || (isInitialError && !touched) ? "error" : "success" }
      hasFeedback={ isValid && hasFeedback }
      help={
        showHelp ? (
          <>
            {isError && <li>{error}</li>}
            {isInitialError && !touched && <li>{initialError}</li>}
          </>
        ) : null
      }
      { ...restProps }
    >
      <>{children}</>
    </AntdForm.Item>
  );
};

export default FormItem;
