import { useEffect, useLayoutEffect } from "react";

import { Formik } from "formik";
import { message } from "antd";
import { useSearchParams } from "react-router-dom";

import { Spinner } from "common/lib/components";
import { UserType } from "domains/clients/shared/types";
import { CustodyAgreementModal, CustodyAgreementPDFModal } from "domains/liquidity/modals";
import { useValidationSchema } from "./useValidationSchema";
import { useScrollToTop } from "common/shared-hooks";
import useHandlers from "./useHandlers";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { useDocumentSelector } from "domains/documents/store/documents.selector";
import { DocumentType } from "domains/documents/shared/constants";
import { useHelpers } from "./useHelpers";
import RegularCustodyAccountForm from "./RegularCustodyAccountForm";
import AdvisorCustodyAccountForm from "./AdvisorCustodyAccountForm";
import { AccountType } from "common/lib/constants";
import { getInitialValues } from "./helpers";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { IErrorResponse } from "common/shared/types";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

import "./CustodyAccountAgreementForm.scss";

const forms = {
  [ UserType.Principal ]: RegularCustodyAccountForm,
  [ UserType.Employee ]: RegularCustodyAccountForm,
  [ UserType.Advisor ]: AdvisorCustodyAccountForm,
  [ UserType.BenOriginations ]: null,
  [ UserType.GeneralPartner ]: null,
};

const CustodyAccountAgreementForm = () => {
  const [ searchParams ] = useSearchParams();

  const { uploadingDocuments } = useDocumentSelector();
  const { user, isAdvisor, currentClient } = useClientsSelector();
  const [ fetchDocumentsById, { data: documents = [], isFetching: isFetchingDocuments } ] =
    documentsApiReducer.useLazyFetchDocumentsByIdQuery();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const [
    _f1,
    {
      data: createdCustodyAccount,
      isLoading: isCreatingCustodyAccount,
      isSuccess: isCustodyAccountCreated,
      isError: isCustodyAccountCreationFailed,
      error,
      reset: resetCreateCustodyAccountMutation,
    },
  ] = custodyAccountsApiReducer.useCreateCustodyAccountMutation({
    fixedCacheKey: "create-custody-account-agreement-form",
  });
  const [ fetchUserLevelDetails, { isFetching: isFetchingUserLevelDetails } ] =
    clientsApiReducer.useLazyFetchUserLevelDetailsQuery();
  const [
    fetchAccountLevelDetailsById,
    {
      data: custodyAccountLevelDetails,
      isFetching: isFetchingAccountLevelDetails,
      isSuccess: isCustodyAccountLevelDetailsFetched,
    },
  ] = clientsApiReducer.useLazyFetchAccountLevelDetailsByIdQuery();
  const [
    _,
    {
      data: accountLevelChanges,
      isLoading: isUpdatingAccountLevelDetails,
      isSuccess: isAccountLevelDetailsUpdated,
      reset,
    },
  ] = clientsApiReducer.useUpdateAccountLevelDetailsMutation({
    fixedCacheKey: "update-custody-account-level-details-agreement-form",
  });
  const [ _f2, { isLoading: isUpdatingUserLevelDetails } ] = clientsApiReducer.useUpdateUserLevelDetailsMutation({
    fixedCacheKey: "update-custody-user-level-details-agreement-form",
  });

  const initialValues = getInitialValues(user, currentClient, currentCustodyAccount, documents);
  const validationSchema = useValidationSchema();
  const {
    formRef,
    isCustodyAgreementModalVisible,
    isCustodyAgreementPDFModalVisible,
    handleCustodyAgree,
    handleCustodyAgreementCancel,
    handleCustodyAgreementPDFCancel,
    handleKeyFieldChange,
    handleSubmit,
    handleCustodyAgreementOpen,
    getAgreementData,
    exportPDF,
  } = useHandlers(documents);
  const { showErrorMessageIfDuplicateAccount, createCustodyAccount } = useHelpers();

  const isUploading = !!uploadingDocuments.filter(
    (document) => document.document_type === DocumentType.CustodyAgreementRedacted,
  ).length;
  const custodyAccountId = searchParams.get("account_id") || null;
  const isAdvisorCustodyAccount =
    (currentClient?.is_anonymous ||
      currentCustodyAccount?.account_type === AccountType.CustodialAdvisor ||
      currentCustodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
    false;

  useScrollToTop("auto");

  useEffect(() => {
    if (!custodyAccountId) return;

    fetchAccountLevelDetailsById({ accountId: custodyAccountId, type: "custody" });
  }, [ custodyAccountId ]);

  useEffect(() => {
    if (!currentCustodyAccount?.account_id || custodyAccountId) return;

    fetchAccountLevelDetailsById({
      accountId: currentCustodyAccount.account_id,
      type: "custody",
    });
  }, [ currentCustodyAccount?.account_id, custodyAccountId ]);

  useEffect(() => {
    if (custodyAccountId) {
      fetchDocumentsById(custodyAccountId);
    }

    if (currentCustodyAccount?.account_id) {
      fetchDocumentsById(currentCustodyAccount.account_id);
    }
  }, [ custodyAccountId, currentCustodyAccount?.account_id ]);

  useEffect(() => {
    if (!isCustodyAccountLevelDetailsFetched || !custodyAccountLevelDetails) return;

    const accountId = custodyAccountLevelDetails.account_id;
    //@ts-ignore
    const userId = custodyAccountLevelDetails.principal_data.user_id;
    fetchUserLevelDetails({ accountId, userId, type: "custody" });
  }, [ isCustodyAccountLevelDetailsFetched ]);

  useLayoutEffect(() => {
    if (isCustodyAccountCreated && createdCustodyAccount) {
      exportPDF(createdCustodyAccount);
    }

    if (isCustodyAccountCreationFailed && error) {
      showErrorMessageIfDuplicateAccount(error as IErrorResponse);
    }

    resetCreateCustodyAccountMutation();
  }, [ isCustodyAccountCreated, isCustodyAccountCreationFailed ]);

  useEffect(() => {
    if (!isAccountLevelDetailsUpdated || !accountLevelChanges) return;

    if (
      "advisory_firm_tax_id" in accountLevelChanges ||
      (isAdvisorCustodyAccount && "advisory_firm_country_of_incorporation" in accountLevelChanges)
    ) {
      const formValues = JSON.parse(window.sessionStorage.getItem("formValues") || "{}");
      createCustodyAccount(formValues);
      window.sessionStorage.removeItem("formValues");
    }

    reset();
  }, [ isAccountLevelDetailsUpdated, createCustodyAccount, isAdvisor, isAdvisorCustodyAccount ]);

  useEffect(() => {
    return () => {
      message.destroy();
    };
  }, []);

  if (!initialValues || !user) {
    return <Spinner />;
  }

  return (
    <Spinner
      spinning={
        isCreatingCustodyAccount ||
        isUploading ||
        isFetchingAccountLevelDetails ||
        isUpdatingAccountLevelDetails ||
        isFetchingUserLevelDetails ||
        isUpdatingUserLevelDetails ||
        isFetchingDocuments
      }
    >
      <Formik
        initialValues={ initialValues }
        onSubmit={ handleSubmit }
        validationSchema={ validationSchema }
        initialTouched={ initialValues }
        validateOnMount
        validateOnChange
        enableReinitialize
        innerRef={ formRef }
      >
        {() => {
          const FormComponent = forms[ user.user_type ];

          return (
            FormComponent && (
              <FormComponent
                handleKeyFieldChange={ handleKeyFieldChange }
                handleCustodyAgreementOpen={ handleCustodyAgreementOpen }
              />
            )
          );
        }}
      </Formik>

      {isCustodyAgreementModalVisible && (
        <CustodyAgreementModal
          isVisible={ isCustodyAgreementModalVisible }
          formRef={ formRef.current }
          formValues={ getAgreementData() }
          handleAgree={ () => handleCustodyAgree(formRef.current!.setValues) }
          handleCancel={ () => handleCustodyAgreementCancel(formRef.current!.setValues) }
        />
      )}

      {isCustodyAgreementPDFModalVisible && (
        <CustodyAgreementPDFModal
          isVisible={ isCustodyAgreementPDFModalVisible }
          isFooterModal={ false }
          handleCancel={ handleCustodyAgreementPDFCancel }
        />
      )}
    </Spinner>
  );
};

export default CustodyAccountAgreementForm;
