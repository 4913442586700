import { FC, useCallback, useEffect, useRef, useState, UIEvent } from "react";

import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import { CustodyAgreementPDF } from "domains/liquidity/components";
import { IUserClient, IUserDetails } from "domains/clients/shared/types";
import { StatusMessage, Button } from "common/lib/components";
import CustodyAgreementContent from "./CustodyAgreementContent";
import { useClientsSelector } from "domains/clients";
import { CustodyAccountPDFTypes } from "domains/liquidity/shared/types";

import "./CustodyAgreementModal.scss";

interface IProps {
  isVisible: boolean;
  formRef?: any;
  formValues?: Partial<IUserDetails> & {
    isFullName: boolean;
  };
  isFooterModal?: boolean;
  handleAgree?: () => void;
  handleCancel?: () => void;
}

const CustodyAgreementModal: FC<IProps> = ({
  isVisible,
  formRef,
  handleAgree,
  handleCancel,
  isFooterModal,
  formValues,
}) => {
  const { t } = useTranslation([ "common", "liquidity" ]);

  const { user, currentClient } = useClientsSelector();

  const [ isAgreeDisabled, setIsAgreeDisabled ] = useState(true);

  const printRef = useRef(null);
  const isFormFilled = formRef
    ? Object.keys(formRef?.errors || [])[ 0 ] === "custody_agreement" || Object.keys(formRef?.errors).length === 0
    : false;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Custody Agreement",
    pageStyle: ".ben-terms-agreement-modal-body { height: initial !important; overflow-y: hidden !important; }",
  });

  const downloadPDFDocument = async (data: typeof formValues) => {
    const blob = await pdf(
      <CustodyAgreementPDF
        user={ user as IUserDetails }
        currentClient={ currentClient as IUserClient }
        formValues={ data }
        pdfType={ CustodyAccountPDFTypes.Raw }
      />,
    ).toBlob();
    saveAs(blob, "Custody Agreement");
  };

  const handleScroll = useCallback(
    (e: UIEvent<HTMLElement>) => {
      if (!isAgreeDisabled) {
        return;
      }

      const showBeforePx = 42;
      const isBottom =
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight - showBeforePx;
      if (!isBottom || !isFormFilled) {
        return;
      }
      setIsAgreeDisabled(false);
    },
    [ isAgreeDisabled, isFormFilled ],
  );

  useEffect(() => {
    if (!isFormFilled) {
      setIsAgreeDisabled(true);
    }
  }, [ isFormFilled ]);

  return (
    <Modal
      centered={ true }
      open={ isVisible }
      destroyOnClose
      afterClose={ () => setIsAgreeDisabled(true) }
      wrapClassName="ben-custody-agreement-modal"
      footer={ null }
      onCancel={ handleCancel }
    >
      <>
        <div ref={ printRef }>
          <h1 className="ben-text-center">{t("License and Custody Account Agreement", { ns: "liquidity" })}</h1>
          <div className="ben-terms-agreement-modal-body" onScroll={ handleScroll }>
            <CustodyAgreementContent formValues={ formValues! } />
          </div>
        </div>
        <>
          <div className="ben-custody-agreement-actions">
            {!isFooterModal && (
              <>
                <Button
                  onClick={ () => {
                    if (!handleCancel) return;

                    if (!isFormFilled) {
                      setIsAgreeDisabled(true);
                    }

                    handleCancel();
                  } }
                >
                  {t("Cancel", { ns: "common" })}
                </Button>
                <Button type="primary" disabled={ isAgreeDisabled } onClick={ handleAgree }>
                  {t("Agree")}
                </Button>
              </>
            )}

            <>
              <Button onClick={ handlePrint } type="primary">
                {t("Print")}
              </Button>
              <Button type="primary" onClick={ () => downloadPDFDocument(formValues) }>
                {t("Download")}
              </Button>
            </>
          </div>
          {!isFooterModal && !isFormFilled && (
            <div className="ben-d-flex ben-justify-content-center ben-text-center">
              <StatusMessage
                message={ t("Please fill out data on previous page before clicking 'Agree'", { ns: "liquidity" }) }
                error
              />
            </div>
          )}
        </>
      </>
    </Modal>
  );
};

export default CustodyAgreementModal;
