import { FC, useCallback, useEffect, useRef, useState } from "react";

import { Modal } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { maxLengthText } from "common/shared/constants/validationConstants";
import { Form, Input, Select } from "common/lib/formik-antd";
import { Caret, Button, Spinner } from "common/lib/components";
import { AccountType } from "common/lib/constants";
import { useAddAnonymousClientNameModalValidationSchema } from "./validationSchema";
import { RequestFormalProposalModal } from "domains/altQuotes/components";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { useAltQuotesSelector } from "domains/altQuotes";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { IClientProfile } from "domains/clients/shared/types";

import "./AddAnonymousClientNameModal.scss";

const { Option } = Select;

interface IProps {
  handleCancel: () => void;
  isVisible: boolean;
  isConvertingAdvisorAltQuoteWithoutClient?: boolean;
  openRequestFormalProposalModal?: (altQuote: ILiquidityRequest) => void;
}

const AddAnonymousClientNameModal: FC<IProps> = ({
  isVisible,
  handleCancel,
  isConvertingAdvisorAltQuoteWithoutClient = false,
  openRequestFormalProposalModal,
}) => {
  const { t } = useTranslation([ "dashboard", "common" ]);

  const { validationSchema } = useAddAnonymousClientNameModalValidationSchema();
  const { currentAltQuote } = useAltQuotesSelector();
  const [ addNewClient, { isLoading: isCreatingClient, isSuccess, reset } ] =
    clientsApiReducer.useAddNewClientMutation();

  const formRef = useRef<any>(null);

  const [ isRequestFormalProposalModalVisible, setIsRequestFormalProposalModalVisible ] = useState(false);

  const handleRequestFormalProposalModalCancel = useCallback(() => {
    setIsRequestFormalProposalModalVisible(false);
  }, []);

  const onContinueClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSubmit = (value: any) => {
    const isIndividual = value.investor_type === AccountType.Individual;
    const data = { company_name: value.anonymous_client_name, isAnonymous: true } as IClientProfile;
    addNewClient({
      data,
      isLRCreation: true,
      isIndividual,
      isCompleteFullClientCheckboxChecked: undefined,
      isLRSharing: undefined,
      isConvertingAdvisorAltQuoteWithoutClient,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;

    if (isConvertingAdvisorAltQuoteWithoutClient && openRequestFormalProposalModal && currentAltQuote) {
      openRequestFormalProposalModal(currentAltQuote);
      handleCancel();
    }

    reset();
  }, [ isSuccess ]);

  return (
    <Modal
      destroyOnClose
      footer={ null }
      width={ 650 }
      onCancel={ handleCancel }
      open={ isCreatingClient || isVisible }
    >
      <div className="ben-add-liquidity-name-modal-container">
        <div className="ben-add-liquidity-name-modal-title">
          <h1>{t("Anonymous client name")}</h1>
        </div>
        <p>
          {t(
            "Given that you want to keep your client confidential, for now, please provide an anonymous name below. A client with this name will be created. You will be able to update your anonymous client details in your My Clients section.",
          )}
        </p>
        <Formik
          validationSchema={ validationSchema }
          onSubmit={ handleSubmit }
          innerRef={ formRef }
          validateOnMount
          initialValues={ { anonymous_client_name: "", investor_type: "" } }
        >
          {({ isValid }) => (
            <Form disabled={ isCreatingClient } className="ben-add-anonymous-client-name-form">
              <Form.Item
                className="ben-add-anonymous-client-name-form-item"
                label={ t("Anonymous client name") }
                name="anonymous_client_name"
              >
                <Input
                  maxLength={ maxLengthText }
                  placeholder={ t("Anonymous client name") }
                  className="small"
                  type="text"
                  name="anonymous_client_name"
                  suffix
                />
              </Form.Item>

              <Form.Item
                className="ben-add-anonymous-client-name-form-item"
                name="investor_type"
                label={ t("Anonymous client type") }
              >
                <Select
                  name="investor_type"
                  placeholder={ t("Anonymous client type") }
                  suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                >
                  <Option value="individual">{t("Individual")}</Option>
                  <Option value="institution">{t("Institutional")}</Option>
                </Select>
              </Form.Item>

              {isCreatingClient ? (
                <Spinner fullWidth />
              ) : (
                <Form.Item name="buttons" className="ben-add-anonymous-client-name-form-buttons">
                  <Button onClick={ handleCancel } type="primary">
                    {t("Cancel", { ns: "common" })}
                  </Button>
                  <Button disabled={ !isValid } onClick={ onContinueClick } type="primary">
                    {t("Continue", { ns: "common" })}
                  </Button>
                </Form.Item>
              )}
            </Form>
          )}
        </Formik>
      </div>
      <RequestFormalProposalModal
        isVisible={ isRequestFormalProposalModalVisible }
        handleCancel={ handleRequestFormalProposalModalCancel }
      />
    </Modal>
  );
};

export default AddAnonymousClientNameModal;
