import { BenInfo } from "common/lib/constants";
import { apiEnvs, apiService } from "common/shared";
import {
  ICreateRegistrationResponse,
  ICreateRegistrationRequest,
  IGetSharingResponse,
  IGetSharingRequest,
  IIdentity,
} from "./types";
import { WhiteLabelType } from "common/shared/types";

export class IdentityApiService {
  async createRegistration(data: ICreateRegistrationRequest): Promise<ICreateRegistrationResponse> {
    const { identityAPI, whiteLabel } = await apiEnvs.getApiEnv();
    const preparedData = {
      ...data,
      white_label_instance: whiteLabel || WhiteLabelType.Default,
      accepted_terms: false,
    };
    return apiService.post(`${ identityAPI }/v1/identity/register`, preparedData);
  }

  async fetchIdentity(email: string): Promise<IIdentity> {
    const { identityAPI } = await apiEnvs.getApiEnv();
    return apiService.get(`${ identityAPI }/v1/identity/${ email }`);
  }

  async getSharing(data: IGetSharingRequest): Promise<IGetSharingResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${ data.token }`,
        [ BenInfo.CUSTOM_HTTP_HEADERS.shouldSaveSession ]: "yes",
      },
    };
    const { holdingsAPI } = await apiEnvs.getApiEnv();
    return apiService.post(
      `${ holdingsAPI }/v1/liquidity_requests/${ data.liquidity_request_id }/sharings/${ data.sharing_id }/accept`,
      null,
      config,
    );
  }
}

export default new IdentityApiService();
