import { useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { setAssetsPendingCreation, setCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity/shared/constants";
import { TAssetType, TNonFundAsset } from "domains/liquidity/shared/types";
import { getAssetValuesByType } from "./helpers";
import { useTrustbenSelector } from "domains/trustben";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useAppDispatch } from "store/store.hooks";
import idUtils from "common/shared/utils/id/id.utils";
import { TrustbenPages, TBAltQuotePages } from "domains/trustben/shared/constants";

export const useHandlers = (isAssetEditing: boolean = false) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { LRId } = useParams();

  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const [ createLRAsset ] = liquidityApiReducer.useCreateLRAssetMutation({
    fixedCacheKey: "create-lr-asset",
  });
  const [ updateLRAsset ] = liquidityApiReducer.useUpdateLRAssetMutation({
    fixedCacheKey: "update-lr-asset",
  });

  const handleSubmit = useCallback(
    (values: Partial<TNonFundAsset>) => {
      const assetValues = getAssetValuesByType(values);

      dispatch(setCurrentAssetData(assetValues));
      isAssetEditing
        ? LRId && updateLRAsset(LRId)
        : LRId && createLRAsset(LRId);
      navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ReviewAssets);
    },
    [ LRId, isAssetEditing ],
  );

  const handleTrustbenSubmit = useCallback(
    (values: Partial<TNonFundAsset>) => {
      const assetValues = getAssetValuesByType(values);

      dispatch(setCurrentAssetData(assetValues));
      dispatch(setAssetsPendingCreation({ ...values, asset_id: `pending_creation_${ idUtils.uuidv4() }` } as TAssetType));

      const navigateTo = LRId
        ? `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LRId }${ TBAltQuotePages.ReviewAssets }`
        : `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }${ TBAltQuotePages.ReviewAssets }`;
      navigate(navigateTo, { replace: true });
    },
    [ assets, LRId ],
  );

  const onSubmit = useCallback(
    (values: Partial<TNonFundAsset>) => {
      isTrustbenAltQuote ? handleTrustbenSubmit(values) : handleSubmit(values);
    },
    [ handleSubmit, handleTrustbenSubmit, isTrustbenAltQuote ],
  );

  const onSelect = useCallback((values: Partial<TNonFundAsset>, setFieldValue: any, setFieldTouched: any) => {
    Object.keys(values).forEach((key) => {
      if (key !== "asset_type") {
        setFieldValue(key, undefined);
        setFieldTouched(key, false);
      }
    });
  }, []);

  return { onSubmit, onSelect };
};
