import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { apiEnvs } from "common/shared";
import { ILoginParams } from "domains/authentication/shared/types";
import { getIsAuth } from "domains/authentication/store/auth.selectors";
import { URLs } from "common/lib/constants";
import { FeatureNames, useFeature } from "domains/features";
import { trustbenSessionService, useTrustbenSelector } from "domains/trustben";
import { setIsTrustbenAltQuote, setIsTrustbenUserAuthenticated } from "domains/trustben/store/trustben.reducer";
import { useBootstrapSelector } from "common/shared-store/bootstrap.selectors";
import { setNavigateToAfterLogin } from "common/shared-store/bootstrap.reducer";
import { useIdentitySelector } from "domains/identity";
import { useAppDispatch } from "store/store.hooks";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";

const useTrustbenAltQuoteAutoLogin = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAuth = useSelector(getIsAuth);
  const { identity } = useIdentitySelector();
  const { navigateToAfterLogin } = useBootstrapSelector();
  const { isFeatureActive } = useFeature(FeatureNames.TrustbenAltQuote, []);

  const [ login, { isLoading, isSuccess, isError, reset } ] = authApiReducer.useLoginMutation();
  const [ logout ] = authApiReducer.useLogoutMutation();
  const { isTrustbenAltQuote } = useTrustbenSelector();

  const [ loginDetails, setLoginDetails ] = useState<ILoginParams | null>(null);

  const handleLogin = useCallback(async () => {
    const isTrustbenAltQuote = trustbenSessionService.getIsTrustbenAltQuote();
    const { benTrustLogin } = await apiEnvs.getApiEnv();
    const isSignInPage = pathname === URLs.AUTH.SIGN_IN;

    if (isTrustbenAltQuote && loginDetails && loginDetails.user_name !== benTrustLogin) {
      trustbenSessionService.clearTrustbenSession();
      dispatch(setIsTrustbenAltQuote(false));
    }

    if (isTrustbenAltQuote && isSignInPage && loginDetails && loginDetails.user_name !== benTrustLogin) {
      if (!navigateToAfterLogin) {
        navigate(URLs.PROTECTED.DASHBOARD, { replace: true });
        return;
      }

      navigateToAfterLogin.search
        ? navigate(
            {
              pathname: navigateToAfterLogin.pathname,
              search: createSearchParams(navigateToAfterLogin.search).toString(),
            },
            { replace: true },
          )
        : navigate(navigateToAfterLogin, { state: navigateToAfterLogin.state, replace: true });
      dispatch(setNavigateToAfterLogin(null));
    }

    setLoginDetails(null);
  }, [ pathname, loginDetails ]);

  useEffect(() => {
    (async () => {
      const { benTrustLogin, benTrustPassword } = await apiEnvs.getApiEnv();

      if (!isAuth && pathname.includes(URLs.AUTH.TRUSTBEN) && !isLoading) {
        const loginDetails = {
          user_name: benTrustLogin,
          password: benTrustPassword,
        };
        trustbenSessionService.setIsTrustbenAltQuote(true);
        dispatch(setIsTrustbenAltQuote(true));
        setLoginDetails(loginDetails);
        login(loginDetails);
      }
    })();
  }, [ pathname, isLoading ]);

  useEffect(() => {
    (async () => {
      if (!isSuccess && !isError) return;

      if (isSuccess) {
        const { benTrustLogin } = await apiEnvs.getApiEnv();
        if (!loginDetails || loginDetails.user_name !== benTrustLogin) return;

        dispatch(setIsTrustbenUserAuthenticated(true));
      }

      handleLogin();
      reset();
    })();
  }, [ isSuccess, isError ]);

  useEffect(() => {
    if (!isTrustbenAltQuote || isFeatureActive === undefined) return;

    if (!isFeatureActive) {
      logout();
      trustbenSessionService.clearTrustbenSession();
      dispatch(setIsTrustbenAltQuote(false));
      navigate(URLs.AUTH.SIGN_IN, { replace: true });
    }
  }, [ isFeatureActive, isTrustbenAltQuote ]);

  useLayoutEffect(() => {
    (async () => {
      const { benTrustLogin } = await apiEnvs.getApiEnv();

      if (!isAuth || !identity?.email || identity?.email === benTrustLogin || !pathname.includes(URLs.AUTH.TRUSTBEN))
        return;

      logout();
    })();
  }, [ isAuth ]);

  return isTrustbenAltQuote;
};

export default useTrustbenAltQuoteAutoLogin;
