import { IUserDetails } from "domains/clients/shared/types";
import { AssetType } from "domains/liquidity/shared/constants";
import { TAssetType } from "domains/liquidity/shared/types";

export const formatInvestmentForRequest = (asset: Partial<TAssetType>, user: IUserDetails): any => {
  switch (asset.asset_type) {
    case AssetType.PreqinFund:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        sponsor: asset.fund_manager,
        is_active: true,
        asset_status: "locked_by_bids",
        amount_invested: asset.amount_invested,
        current_net_asset_value: asset.current_net_asset_value,
        capital_commitment: asset.capital_commitment,
        unfunded_commitment_currency: asset.unfunded_commitment_currency,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        fund_strategy: asset.fund_strategy,
        vintage_year: asset.vintage,
        geographic_focus: asset.geographic_focus,
        external_asset_id: asset.fund_id,
      };

    case AssetType.Fund:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        is_active: true,
        asset_status: "needs_review_by_bids",
        amount_invested: asset.amount_invested,
        current_net_asset_value: asset.current_net_asset_value,
        capital_commitment: asset.capital_commitment,
        unfunded_commitment_currency: asset.unfunded_commitment_currency,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        fund_strategy: asset.fund_strategy,
        vintage_year: asset.vintage,
        geographic_focus: asset.geographic_focus,
      };

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        is_active: true,
        amount_invested: asset.amount_invested,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        asset_description: asset.asset_description,
      };

    case AssetType.CoInvestment:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        is_active: true,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        asset_description: asset.asset_description,
        sponsor: asset.sponsor,
        sponsor_description: asset.sponsor_description,
      };

    case AssetType.DirectRealEstateInvestment:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        is_active: true,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        real_estate_property_type: asset.real_estate_property_type,
        real_estate_location: asset.real_estate_location,
        state_or_province: asset.state,
      };

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        is_active: true,
        asset_type: asset.asset_type,
        asset_name: asset.account_name,
        amount_invested: asset.amount_invested,
        asset_description: asset.company_description,
      };

    case AssetType.DirectInterestsInNaturalResourcesAsset:
      return {
        account_id: user.account_id,
        account_display_id: user.account_display_id,
        account_name: user.account_name,
        account_type: user.user_type,
        status: "in_portfolio",
        is_active: true,
        asset_type: asset.asset_type,
        asset_description: asset.asset_description,
      };
  }
};

export const formatInvestmentsForReducer = (investments: any): Partial<TAssetType>[] => {
  return investments.map((investment: any) => {
    switch (investment.asset_type) {
      case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
        return {
          ...investment,
          account_name: investment.asset_name,
          company_description: investment.asset_description,
        };

      default:
        return investment;
    }
  });
};
