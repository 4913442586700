import { AccountType } from "common/lib/constants";
import {
  UserType,
  IUserDetails,
} from "domains/clients/shared/types";
import { getAccountLevelPathToUpdate } from "./helpers";
import { TCustodyAccountType } from "domains/custodyAccounts/shared/constants";
import { IIndividualCustodyAccount, IInstitutionCustodyAccount } from "domains/custodyAccounts/shared/types";
import { IUpdateAPIObject } from "common/shared/types";

export const formatDummyClientDataForRequest = (value: any) => ({
  first_name: value.first_name,
  last_name: value.last_name,
  user_type: value.investor_type === AccountType.Individual ? UserType.Principal : UserType.Employee,
  addresses: [],
  email_addresses: [
    {
      email: value.email_address,
      validation: {
        validation_date: new Date(),
        validation_method: "test",
      },
    },
  ],
  phones: [
    {
      country_code: "1",
      is_validated: false,
      accepts_text_messages: false,
      phone_number: value.phone_number,
      is_default: true,
    },
  ],
  locale: "en-US",
  white_label_instance: "default",
});

const getPath = (key: string, custodyAccountType: TCustodyAccountType) => {
  switch (key) {
    case "address_line_1":
    case "address_line_2":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/street${ key.slice(-1) }`
        : `/institution_data/addresses/0/street${ key.slice(-1) }`;

    case "city":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/${ key }`
        : `/institution_data/addresses/0/${ key }`;

    case "state":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/region`
        : `/institution_data/addresses/0/region`;

    case "country":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/country`
        : `/institution_data/addresses/0/country`;

    case "country_of_incorporation":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/tax_id_country`
        : `/institution_data/tax_id_country`;

    case "country_of_citizenship":
      return `/tax_id_country`;

    case "zip_code":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/postal_code`
        : `/institution_data/addresses/0/postal_code`;

    case "telephone_number":
    case "phone_number":
      return `/phones/0/phone_number`;

    case "phone_country":
      return `/phones/0/phone_country`;

    case "country_code":
      return `/phones/0/country_code`;

    case "custody_agreement":
      return `custodial_agreement`;

    case "company_name":
      return `/account_name`;

    case "email_address":
      return `/email_addresses/0/email`;

    default:
      return `/${ key }`;
  }
};

export const formatCustodyAccountDataToUpdate = (
  formValues: IIndividualCustodyAccount | IInstitutionCustodyAccount,
  custodyAccountType: TCustodyAccountType,
) => {
  switch (custodyAccountType) {
    case AccountType.CustodialIndividual: {
      const formattedValues = Object.entries(formValues).reduce((acc: IUpdateAPIObject[], [ key, value ]) => {
        acc.push({
          op: "replace",
          path: getPath(key, custodyAccountType),
          value: value,
        });

        return acc;
      }, []);

      return formattedValues;
    }

    case AccountType.CustodialInstitution:
    case AccountType.CustodialAdvisor: {
      const formattedValues = Object.entries(formValues).reduce((acc: IUpdateAPIObject[], [ key, value ]) => {
        acc.push({
          op: "replace",
          path: getPath(key, custodyAccountType),
          value: value,
        });

        return acc;
      }, []);

      return formattedValues;
    }

    default:
      break;
  }
};

export const formatAccountDataToUpdate = (formValues: IUserDetails) => {
  const formattedValues = Object.entries(formValues).reduce((acc: IUpdateAPIObject[], [ key, value ]) => {
    acc.push({
      op: "replace",
      path: getAccountLevelPathToUpdate(key),
      value: value,
    });

    return acc;
  }, []);

  return formattedValues;
};
