import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";

import "./RequestFormalProposalButton.scss";

interface IProps {
  onRequestFormalProposal: (value: string) => void;
  LRId: string;
}

const RequestFormalProposalButton: FC<IProps> = ({ onRequestFormalProposal, LRId }) => {
  const { t } = useTranslation([ "altQuotes", "dashboard" ]);

  return (
    <div className="ben-apply-for-liquidity-button-container">
      <Button onClick={ () => onRequestFormalProposal(LRId) }>{t("Request Formal Proposal")}</Button>
      <span>{t("using this AltQuote")}</span>
    </div>
  );
};

export default RequestFormalProposalButton;
