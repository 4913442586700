export const usDocTypes = [
  {
    key: "drivers_license",
    value: "State Issued Driver’s License",
  },
  {
    key: "id_card",
    value: "U.S. State-Issued ID Card",
  },
  {
    key: "resident_card",
    value: "U.S. Issued Permanent Resident Card",
  },
  {
    key: "passport",
    value: "Passport",
  },
];

export const nonUSDocTypes = [
  {
    key: "passport",
    value: "Passport",
  },
  {
    key: "government_id",
    value: "Government Issued Photo Id",
  },
];
