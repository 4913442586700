import { IAssetGroup, IProposals, ISetOfferOptionAcceptedRequest } from "domains/proposals/shared/types";

export const findMinOffer = (proposal: IProposals) => {
  return proposal.asset_groups.reduce((min, assetGroup) => {
    const minGroupOffer = assetGroup.offer_options.reduce((min, option) => {
      return min < option.offer ? min : option.offer;
    }, Number.MAX_SAFE_INTEGER);

    return min < minGroupOffer ? min : minGroupOffer;
  }, Number.MAX_SAFE_INTEGER);
};

export const findMaxOffer = (proposal: IProposals) => {
  return proposal.asset_groups.reduce((max, assetGroup) => {
    const maxGroupOffer = assetGroup.offer_options.reduce((max, option) => {
      return max > option.offer ? max : option.offer;
    }, 0);

    return max > maxGroupOffer ? max : maxGroupOffer;
  }, 0);
};

export const findMostRecentProposal = (proposal: IProposals[]) => {
  return proposal.reduce((mostRecentProposal, proposal) => {
    return proposal.created_at > mostRecentProposal.created_at ? proposal : mostRecentProposal;
  });
};

export const checkIsPipeProposal = (assetGroups: IAssetGroup[]) => {
  return assetGroups.some((assetGroup) =>
    assetGroup.offer_options.some((option) => option.option_type.toLowerCase().includes("pipe")),
  );
};

export const checkIsRCPorREGProposal = (assetGroups: IAssetGroup[]) => {
  return assetGroups.some((assetGroup) =>
    assetGroup.offer_options.some(
      (option) =>
        option.option_type.toLowerCase().includes("rcp") ||
        option.option_type.toLowerCase().includes("reg a") ||
        option.option_type.toLowerCase().includes("resettable"),
    ),
  );
};

export const getOldOfferOptionValue = (assetGroups: IAssetGroup[], args: ISetOfferOptionAcceptedRequest) => {
  let newAssetGroups;

  if (assetGroups) {
    newAssetGroups = assetGroups.map((asset) => {
      const offerOption = asset.offer_options.find((option) => option.option_id === args.optionId);

      if (offerOption) {
        const newOfferOption = {
          ...offerOption,
          is_accepted: args.isAccepted,
        };

        return {
          ...asset,
          offer_options: [
            ...asset.offer_options.slice(0, asset.offer_options.indexOf(offerOption)),
            newOfferOption,
            ...asset.offer_options.slice(asset.offer_options.indexOf(offerOption) + 1),
          ],
        };
      }

      return asset;
    });

    return newAssetGroups;
  }

  return assetGroups;
};
