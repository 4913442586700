import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { Form, Input } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { Tooltip } from "common/lib/components";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { ICoInvestment } from "domains/liquidity/shared/types";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

const CoInvestmentForm = () => {
  const { t } = useTranslation([ "liquidity" ]);
  const { values } = useFormikContext<ICoInvestment>();

  const { sendLogs } = useTrustbenLogs();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { currentAsset } = useLiquiditySelector();

  return (
    <>
      <Form.Item
        name="sponsor"
        label={
          <>
            {t("Name of the professional manager/sponsor")} <Tooltip content={ t("Name of Sponsor Tooltip") } dark />
          </>
        }
      >
        <Input
          maxLength={ maxLengthText }
          name="sponsor"
          onBlur={ () => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "sponsor",
                details: `sponsor:${ values.sponsor }:${ currentAsset.audit_logs_id }`,
              });
          } }
          suffix
        />
      </Form.Item>
      <Form.Item
        name="sponsor_description"
        className="ben-description"
        required
        label={
          <>
            {t("Brief description of the co-investment asset")}{" "}
            <Tooltip content={ t("Co-investment Description Tooltip") } dark />
          </>
        }
      >
        <Input.TextArea
          name="sponsor_description"
          autoSize={ true }
          onBlur={ () => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "sponsor_description",
                details: `sponsor_description:${ values.sponsor_description }:${ currentAsset.audit_logs_id }`,
              });
          } }
        />
      </Form.Item>
    </>
  );
};

export default CoInvestmentForm;
