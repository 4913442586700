export const allowedMimeTypes = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "application/msword",
  "image/jpeg",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];

export enum DocumentType {
  Other = "other",
  Identification = "identification",
  CustodyAgreementRedacted = "custody_agreement_redacted",
  CustodyAgreementUnredacted = "custody_agreement_unredacted",
  AltDataReport = "alt_data_report",
  BenMacroLetterReport = "ben_macro_letter_report",
  AltCustodyReport = "alt_custody_report",
}
