import { useCallback, useEffect, useMemo } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { setCurrentLiquidityRequestData } from "domains/liquidity/store/liquidity.reducer";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { formatId, getChangedFormValues } from "common/shared/helpers";
import { useClientsSelector } from "domains/clients";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { AccountType, URLs } from "common/lib/constants";
import { RelationshipTypes } from "domains/clients/shared/types";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { LAPages } from "domains/liquidity/shared/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { isEmptyObject } from "common/shared/utils/object.utils";

export const useHandlers = (isLA: boolean) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { LRId } = useParams();

  const [ updateLiquidityRequest, { isSuccess, reset } ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });

  const { data: accounts } = liquidityApiReducer.useFetchLRAccountsQuery(LRId ?? skipToken);
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const { user, currentClient, isAdvisor } = useClientsSelector();
  const { data: custodyAccountsList } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const { currentLiquidityRequest } = useLiquiditySelector();

  const currentTask = liquidityRequests?.find((item) => item.id === currentLiquidityRequest?.id)?.currentTask;
  const initialValues = {
    reason_for_seeking_liquidity: currentLiquidityRequest?.reason_for_seeking_liquidity || "",
  };
  const custodyAccounts = useMemo(
    () => custodyAccountsList?.filter((custodyAccount) => custodyAccount.account_type !== AccountType.CustodialAdvisor),
    [ custodyAccountsList?.length ],
  );
  const isAnonymousClient = currentClient?.is_anonymous ?? false;
  const isActiveCustodyAccount = useMemo(() => {
    const custodyAccountLR = accounts?.find(
      (account) =>
        account.relationship_type === RelationshipTypes.Custodial ||
        account.relationship_type === RelationshipTypes.CustodialAdvisor,
    );
    const custodyAccountInTheList = custodyAccountsList?.find(
      (account) => account.account_id === formatId("ACC-", custodyAccountLR?.account_id || ""),
    );

    return custodyAccountInTheList ? custodyAccountInTheList.account_status !== CustodyAccountStatus.Closed : false;
  }, [ accounts?.length, custodyAccountsList?.length ]);

  const onNext = () => {
    if (!LRId) return;

    if (isLA && custodyAccounts && custodyAccounts.length > 0) {
      if (isAdvisor && isAnonymousClient && !isActiveCustodyAccount) {
        dispatch(setCurrentCustodyAccount(null));
        navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
        return;
      }

      isActiveCustodyAccount
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmApplication)
        : navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccount);
      return;
    }

    if (isLA && custodyAccounts?.length === 0) {
      dispatch(setCurrentCustodyAccount(null));
      isActiveCustodyAccount
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmApplication)
        : navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
      return;
    }

    if (!isLA) {
      navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.ALTQUOTE);
    }
  };

  const handleSelect = useCallback((value: string) => {
    dispatch(setCurrentLiquidityRequestData({ reason_for_seeking_liquidity: value }));
  }, []);

  const handleSubmit = useCallback(
    (formValues: typeof initialValues) => {
      if (!LRId) return;

      const changes = {
        ...getChangedFormValues(formValues, initialValues),
        ...(currentTask?.liquidity_request_status === LiquidityRequestStatus.InDocumentReview
          ? {}
          : {
              status: isLA ? LiquidityRequestStatus.PendingAssetStatements : LiquidityRequestStatus.AltQuoteSubmitted,
            }),
      };

      !isEmptyObject(changes) ? updateLiquidityRequest({ LRId, changes }) : onNext();
    },
    [ LRId, isLA ],
  );

  useEffect(() => {
    if (!isSuccess) return;

    onNext();
    reset();
  }, [ isSuccess ]);

  return { handleSelect, handleSubmit };
};
