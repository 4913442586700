import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useParams } from "react-router-dom";

import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { AssetType } from "domains/liquidity/shared/constants";
import { FormFooter, FormHeader, Spinner, Button } from "common/lib/components";
import { FundAssetsTable, NonFundAssetsTable } from "domains/liquidity/components";
import { useHandlers } from "./useHandlers";
import ReasonForSeekingLiquidityForm from "./ReasonForSeekingLiquidityForm";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./ReviewAssetsPage.scss";

const ReviewAssetsPage = () => {
  const { LRId } = useParams();

  const [ _, { isLoading: isUpdatingLR } ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });
  const { isLA } = useLiquiditySelector();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const { t } = useTranslation([ "liquidity", "common" ]);

  const { addFund, onDelete } = useHandlers();

  const fundAssets = useMemo(
    () =>
      assets.filter(
        (asset) =>
          asset.asset_type !== undefined &&
          (asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ),
    [ assets ],
  );

  const nonFundAssets = useMemo(
    () =>
      assets.filter(
        (asset) =>
          asset.asset_type !== undefined &&
          !(asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ),
    [ assets ],
  );

  return (
    <Spinner spinning={ isUpdatingLR } wrapperClassName="ben-review-assets-page-spinner">
      <FormHeader
        subtitle={ t("Confirm Assets for Liquidity") }
        title={ t("Assets to Exchange") }
        hint={ t("Review Assets Info") }
      />

      <div className="ben-form-container">
        {!assets.length && (
          <div className="ben-no-assets">
            <span>
              {t("Please, add assets to your")} {isLA ? t("Liquidity Request") : t("AltQuote")}
            </span>

            <Button onClick={ addFund }>+ {t("Add Asset")}</Button>
          </div>
        )}

        {!!fundAssets.length && (
          <div className="ben-form-table-container">
            <span>
              {t("Funds In This Application")}: {fundAssets.length}
            </span>
            <FundAssetsTable fundAssets={ fundAssets } onDelete={ onDelete } />
          </div>
        )}

        {!!nonFundAssets.length && (
          <div className="ben-form-table-container">
            <span>
              {t("Other Non-Fund Assets In This Application")}: {nonFundAssets.length}
            </span>
            <NonFundAssetsTable nonFundAssets={ nonFundAssets } onDelete={ onDelete } />
          </div>
        )}
        <br />
        <p>If you have one fund owned by multiple entities, please list the total value.</p>

        <ReasonForSeekingLiquidityForm isLA={ isLA } addFund={ addFund } />
      </div>

      {isLA && <FormFooter>{t("Review Assets Footer")}</FormFooter>}
    </Spinner>
  );
};

export default ReviewAssetsPage;
