/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import { trustbenLinksService } from "domains/trustben";
import { ITrustbenEnvLinks, ITrustbenLink } from "domains/trustben/shared/types";
import { LinkedIn, YouTube } from "domains/trustben/components/Icons";

import "./TrustbenFooter.scss";

const TrustbenFooter = () => {
  const { t } = useTranslation("trustben");

  const [ footerLinks, setFooterLinks ] = useState<ITrustbenLink[][] | null>(null);

  useEffect(() => {
    (async () => {
      const hostname = window.location.hostname;
      const hostKey =
        hostname === "localhost"
          ? "baadev.bcpbenapp-dev.bankben.org_links"
          : (`${ hostname }_links` as keyof ITrustbenEnvLinks);
      const defaultHostKey = "benaltaccess.beneficient.com_links";
      const trustbenAPILinks = await trustbenLinksService.getTrustbenLinks();

      const footerLinks = trustbenAPILinks
        ? (trustbenAPILinks[ hostKey ] || trustbenAPILinks[ defaultHostKey ])?.reduce((acc, cur) => {
            acc.push(
              cur.alt_access_links,
              cur.meet_ben_links,
              cur.insights_links,
              cur.resources_links,
              cur.shareholders_links,
              cur.financial_profiles_links,
            );
            return acc;
          }, [] as ITrustbenLink[][])
        : null;

      setFooterLinks(footerLinks);
    })();
  }, []);

  return (
    <footer className="trustben-footer">
      <div className="ben-container">
        <div className="trustben-footer-columns-section">
          {footerLinks?.map((column, index) => (
            <ul key={ index } className="trustben-footer-column">
              {column.map((link) => (
                <li key={ link.key } className="trustben-footer-column-item">
                  {link.url ? (
                    <a href={ link.url } target="_self" rel="noreferrer">
                      {link.label}
                    </a>
                  ) : (
                    link.label
                  )}
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="trustben-footer-support-section">
          <p>{t("Footer.Support Text")}</p>
          <div className="trustben-footer-social-media">
            <a href="https://www.linkedin.com/company/beneficient/" target="_blank" rel="nofollow noopener">
              <LinkedIn />
            </a>
            <a href="https://www.youtube.com/channel/UCtkQFfctPcamG9GHrrnrkWQ" target="_blank" rel="nofollow noopener">
              <YouTube />
            </a>
          </div>
        </div>
        <div className="trustben-footer-text-section">
          <div className="trustben-footer-text-section-left-block">
            <p>{t("Footer.Left Block Text.0", { currentYear: new Date().getFullYear() })}</p>
            <p>
              <Trans t={ t } i18nKey="Footer.Left Block Text.1">
                <a className="trustben-footer-link" href="https://www.finra.org" target="_self" rel="noreferrer"></a>
                <a className="trustben-footer-link" href="https://www.sipc.org/" target="_self" rel="noreferrer"></a>
              </Trans>
            </p>
            <p>{t("Footer.Left Block Text.2")}</p>
            <p>{t("Footer.Left Block Text.3")}</p>
            <p>{t("Footer.Left Block Text.4")}</p>
            <p>{t("Footer.Left Block Text.5")}</p>
            <p>{t("Footer.Left Block Text.6")}</p>
          </div>
          <div>
            <p>{t("Footer.Right Block Text.0")}</p>
            <p>{t("Footer.Right Block Text.1")}</p>
            <p>{t("Footer.Right Block Text.2")}</p>
            <p>{t("Footer.Right Block Text.3")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default TrustbenFooter;
