import { useTranslation } from "react-i18next";

import EmailForm from "./EmailForm";

import "./AltQuoteEmailSection.scss";

const AltQuoteEmailSection = () => {
  const { t } = useTranslation("trustben");

  return (
    <div className="altquote-email-section ben-mt-2">
      <h3 className="altquote-email-section-title">{t("AltQuote Email Section.Title")}</h3>
      <p className="altquote-email-section-text">{t("AltQuote Email Section.Text")}</p>
      <EmailForm />
    </div>
  );
};

export default AltQuoteEmailSection;
