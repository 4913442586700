import { FC } from "react";

import { Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { DocumentPasswordForm } from "domains/liquidity/components/AssetDocumentsTable/forms/DocumentPasswordForm";
import { AddFileFilled, CloseWindowOutlined } from "common/lib/components/Icons";
import { Spinner, DownloadDocumentIcon, DeleteDocumentIcon } from "common/lib/components";
import { useDocumentSelector } from "domains/documents/store/documents.selector";
import { apiService } from "common/shared";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { getIconForFile } from "domains/documents/shared/utils";
import { useCustodialAgreement } from "common/shared-hooks";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

import "./AssetDocumentsMobileList.scss";
interface IProps {
  draggerProps: any;
}

const AssetDocumentsMobileList: FC<IProps> = ({ draggerProps }) => {
  const { t } = useTranslation([ "liquidity" ]);
  const { LRId } = useParams();

  const { currentLiquidityRequest } = useLiquiditySelector();
  const { uploadingDocuments } = useDocumentSelector();
  const { data: documents = [] } = documentsApiReducer.useFetchLRDocumentsQuery(
    LRId && currentLiquidityRequest?.account_id
      ? {
          LRId,
          lrAccountId: currentLiquidityRequest.account_id,
        }
      : skipToken,
  );
  const allDocuments = [ ...documents, ...uploadingDocuments ];
  const { isCustodialAgreementActive, checkCustodialAgreement } = useCustodialAgreement();

  if (!currentLiquidityRequest) {
    return <Spinner />;
  }

  return (
    <div className="ben-asset-documents-mobile-list">
      <h4>Document</h4>
      <div onClick={ checkCustodialAgreement }>
        <Upload { ...draggerProps } openFileDialogOnClick={ isCustodialAgreementActive }>
          <div>
            <AddFileFilled className="ben-icon" width={ 30 } height={ 30 } />
            <p>{t("Add File")}</p>
          </div>
        </Upload>
      </div>
      {allDocuments?.map((document) => {
        return (
          <div className="ben-files-list-item" key={ document.file_name }>
            {getIconForFile(document.file_name)}
            <p>{document.file_name}</p>
            <p className="ben-pl-2">
              {document.percent
                ? `${ t("Uploading...") } ${ document.percent }%`
                : `${ getDateFromISO(document.date_provided) }`}
            </p>

            <DownloadDocumentIcon
              documentId={ document.id }
              accountId={ currentLiquidityRequest.account_id }
              spinnerClassName="ben-download-file-icon"
              iconClassName="ben-download-file-icon"
            />

            {document.cancelToken ? (
              <CloseWindowOutlined
                className="ben-delete-file-btn"
                onClick={ () => apiService.cancel(document.cancelToken) }
              />
            ) : (
              <DeleteDocumentIcon type="asset" documentId={ document.id } iconClassName="ben-delete-file-btn" />
            )}
          </div>
        );
      })}
      {allDocuments?.map((document) => {
        return (
          <div className="ben-files-list-item" key={ document.file_name }>
            {getIconForFile(document.file_name)}
            <p className="ben-w-45">{document.file_name}</p>
            <p className="ben-w-50">
              <DocumentPasswordForm key={ document.id } document={ document } />
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default AssetDocumentsMobileList;
