import { useSelector } from "react-redux";

import { TUserType } from "domains/clients/shared/types";
import { TFeatureNames } from "domains/features/shared/types";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import useWhiteLabel from "common/shared-hooks/useWhiteLabel";
import { featuresApiReducer } from "domains/features/store/features.api.reducer";
import { getIsAuth } from "domains/authentication/store/auth.selectors";

const useFeature = (featureName: TFeatureNames, permissions: TUserType | TUserType[]) => {
  const isAuth = useSelector(getIsAuth);
  const { user } = useClientsSelector();
  const whiteLabelType = useWhiteLabel();
  const { data: features } = featuresApiReducer.useFetchFeaturesQuery(undefined, { skip: !isAuth });
  const feature = features?.find(
    (feature) => feature.featureName === featureName && feature.whiteLabelInstance === whiteLabelType,
  );

  const userType = user?.user_type;
  const hasPermission = Array.isArray(permissions)
    ? permissions.some((permission) => permission === userType)
    : permissions === userType;
  const isFeatureActive = feature?.isActive;
  const isFeatureEnabled = isFeatureActive && hasPermission;

  return { isFeatureActive, isFeatureEnabled };
};

export default useFeature;
