export const maxLengthText = 50;
export const maxLengthAssetName = 100;
export const maxLengthIdentificationNumber = 20;
export const maxLength12 = 12;
export const maxLengthZipCode = 5;
export const maxLengthTinOrEin = 10;
export const maxLengthPhoneNumber = 17;
export const maxLengthSocialSecurity = 11;
export const maxLength2 = 2;
export const maxLength6 = 6;
export const maxLength9 = 9;
export const maxLength20 = 20;
export const maxLength30 = 30;
export const maxLength40 = 40;
export const maxLength80 = 80;
export const maxLength100 = 100;
export const maxLength250 = 250;
export const maxLength255 = 255;
export const maxLength11 = 11;
export const maxLengthTaxIDNumber = 9;
export const maxBirthDate = (function () {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 21);
  return date;
})();
export const maxIdExpirationDate = new Date(2100, 1, 1);
export const dateFormat = "MM/DD/YYYY";
export const minDate = new Date(1700, 1, 1);
export const maxDate = new Date(3000, 1, 1);
