import { JSEncrypt } from "jsencrypt";

import { generateRandomString } from "common/shared/helpers";

export const encryptValue = (value: string, publicKey: string) => {
  const jsEncrypt = new JSEncrypt();
  const salt = generateRandomString(12);
  jsEncrypt.setPublicKey(publicKey);
  const encryptedValue = jsEncrypt.encrypt(salt + value);

  return { encryptedValue, salt };
};
