import { FC } from "react";

import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { Form, Input, InputNumber, Select } from "common/lib/formik-antd";
import { AssetType } from "domains/liquidity/shared/constants";
import { getSelectOptions, getFormFieldLabel } from "./helpers";
import { currencyFormatter, currencyParser } from "domains/liquidity/shared/utils/inputValueFormatter";
import { TNonFundAsset } from "domains/liquidity/shared/types";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

interface IProps {
  assetType: AssetType;
}

const NonTradedREITorBDCForm: FC<IProps> = ({ assetType }) => {
  const { t } = useTranslation("liquidity");
  const { values } = useFormikContext<Partial<TNonFundAsset>>();

  const { currentAsset } = useLiquiditySelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { sendLogs } = useTrustbenLogs();

  return (
    <>
      <Form.Item name="asset_name" label={ getFormFieldLabel("asset_name", assetType) } required>
        <Select
          name="asset_name"
          options={ getSelectOptions(assetType) }
          placeholder={ t("Choose name from dropdown") }
          onChange={ (value) => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "asset_name",
                details: `asset_name:${ value }:${ currentAsset.audit_logs_id }`,
              });
          } }
        />
      </Form.Item>

      {values.asset_name === getSelectOptions(assetType).at(-1)?.value && (
        <Form.Item name="asset_description" label={ getFormFieldLabel("asset_description", assetType) } required>
          <Input
            className="small"
            name="asset_description"
            onBlur={ () => {
              isTrustbenAltQuote &&
                sendLogs({
                  what: "asset_description",
                  details: `asset_description:${ values.asset_description }:${ currentAsset.audit_logs_id }`,
                });
            } }
          />
        </Form.Item>
      )}

      <Form.Item name="amount_invested" label={ t("Amount Invested") }>
        <InputNumber
          name="amount_invested"
          className="small"
          formatter={ currencyFormatter }
          parser={ currencyParser }
          controls={ false }
          prefix
          onBlur={ () => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "amount_invested",
                details: `amount_invested:${ values.amount_invested }:${ currentAsset.audit_logs_id }`,
              });
          } }
        />
      </Form.Item>
    </>
  );
};

export default NonTradedREITorBDCForm;
