import { FC, MouseEvent } from "react";

import { useTranslation } from "react-i18next";

import MyClientsAltQuotesButton from "domains/dashboard/components/MyClientsAltQuotesButton";
import { ActionButtons } from "./constants";

interface IProps {
  buttonType: string;
  viewAltQuote: () => void;
  startAnApplication: () => void;
  requestReactivation: () => void;
}

const MyClientsAltQuotesPanelButton: FC<IProps> = ({
  buttonType,
  viewAltQuote,
  requestReactivation,
  startAnApplication,
}) => {
  const { t } = useTranslation([ "dashboard", "myApplications" ]);
  let onClick: ((e: MouseEvent) => void) | null = null;
  let buttonText: string | null = null;

  switch (buttonType) {
    case ActionButtons.ViewAltQuoteButton:
      buttonText = t("View AltQuote");
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        viewAltQuote();
      };
      break;
    case ActionButtons.StartAnApplicationButton:
      buttonText = t("Start an Application");
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        startAnApplication();
      };
      break;
    case ActionButtons.RequestReactivationButton:
      buttonText = t("Request Reactivation");
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        requestReactivation();
      };
      break;
  }

  return <MyClientsAltQuotesButton buttonText={ buttonText } onClick={ onClick } />;
};

export default MyClientsAltQuotesPanelButton;
