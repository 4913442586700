import { FC, MouseEvent } from "react";

import { useTranslation } from "react-i18next";
import { Collapse, CollapseProps } from "antd";

import { CollapseTitle } from "domains/dashboard/components/index";
import { Button } from "common/lib/components";
import { UserType, IUserDetails } from "domains/clients/shared/types";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { useScreen } from "common/shared-hooks";
import ApplicationsList from "domains/dashboard/components/ApplicationsList";

const { Panel } = Collapse;

interface IProps {
  isClientPage?: boolean;
  liquidityApplications: ILiquidityRequest[];
  user: IUserDetails | null;
  openModal: (e: MouseEvent) => void;
}


const MyApplicationsPanel: FC<CollapseProps & IProps> = ({
  openModal,
  isClientPage = false,
  liquidityApplications,
  user,
  ...props
}) => {
  const { t } = useTranslation("dashboard");
  const { mobile } = useScreen();

  const collapseTitle = isClientPage
    ? t("My Clients Liquidity Applications")
    : user?.user_type === UserType.Advisor
    ? t("Active Liquidity Applications")
    : t("My Liquidity Applications");

  return (
          <Panel
          { ...props }
          header={ 
            <CollapseTitle
              title={ collapseTitle }
              count={ liquidityApplications?.length }
              button={
                  <Button type="text" onClick={ openModal }>
                    { t("Apply for  Liquidity +") }
                  </Button>
              }
            />
          }
          key="My Applications"
          >
          <div className="ben-applications-content">
            
            {mobile && (
              <Button type="text" onClick={ openModal }>
                { t("Apply for  Liquidity +") }
              </Button>
            )}
            <ApplicationsList 
              isClientPage={ isClientPage } 
              liquidityApplications={ liquidityApplications } 
              hideAvailable
            />
          </div>
          </Panel>

  );
};

export default MyApplicationsPanel;
