export const getAccountLevelPathToUpdate = (key: string) => {
  switch (key) {
    case "advisory_firm_country_of_incorporation":
      return `/institution_data/tax_id_country`;

    case "advisory_firm_tax_id":
      return `/institution_data/tax_id`;

    default:
      return `/${ key }`;
  }
};
