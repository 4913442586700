import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { setCurrentAssetData, setIsAddManually } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { AssetType, LAPages } from "domains/liquidity/shared/constants";
import { getFundItemWithBIDSFundIdIfExists } from "domains/liquidity/shared/helpers/fundSearch";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { TrustbenPages, TBAltQuotePages } from "domains/trustben/shared/constants";

export const useHandlers = () => {
  const { LRId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ searchByFundName ] = liquidityApiReducer.useSearchByFundNameMutation({ fixedCacheKey: "search-by-fund-name" });
  const { isLA, currentAsset } = useLiquiditySelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { sendLogs } = useTrustbenLogs();

  const onSelect = useCallback(
    async (asset_name: string, fund_manager: string, key: string) => {
      const currentAssetData = await getFundItemWithBIDSFundIdIfExists(asset_name, fund_manager, key);

      dispatch(setIsAddManually(false));
      dispatch(setCurrentAssetData(currentAssetData));
      searchByFundName("");
      isLA
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.FundDetails)
        : navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.InvestmentDetails);
    },
    [ isLA, LRId ],
  );

  const onTrustbenSelect = useCallback(
    async (asset_name: string, fund_manager: string, key: string) => {
      const currentAssetData = await getFundItemWithBIDSFundIdIfExists(asset_name, fund_manager, key);

      sendLogs({
        what: "asset_name",
        details: `asset_name:${ asset_name }:${ currentAsset.audit_logs_id }`,
      });
      sendLogs({
        what: "asset_type",
        details: `asset_type:${ AssetType.PreqinFund }:${ currentAsset.audit_logs_id }`,
      });
      dispatch(setIsAddManually(false));
      dispatch(setCurrentAssetData(currentAssetData));
      searchByFundName("");

      const navigateTo = LRId
        ? `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }/${ LRId }${ TBAltQuotePages.InvestmentDetails }`
        : `${ URLs.AUTH.TRUSTBEN }${ TrustbenPages.AltQuote }${ TBAltQuotePages.InvestmentDetails }`;
      navigate(navigateTo, { replace: true });
    },
    [ LRId, currentAsset.audit_logs_id ],
  );

  return {
    onSelect: isTrustbenAltQuote ? onTrustbenSelect : onSelect,
  };
};
