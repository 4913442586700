import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { Form, Input, InputNumber } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { Tooltip } from "common/lib/components";
import { currencyFormatter, currencyParser } from "domains/liquidity/shared/utils/inputValueFormatter";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { IEquityInSinglePrivatelyHeldCompany } from "domains/liquidity/shared/types";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

const EquityInASinglePrivatelyHeldCompanyForm = () => {
  const { t } = useTranslation("liquidity");
  const { values } = useFormikContext<IEquityInSinglePrivatelyHeldCompany>();

  const { sendLogs } = useTrustbenLogs();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { currentAsset } = useLiquiditySelector();

  return (
    <>
      <Form.Item name="account_name" label={ t("Company Name") } required>
        <Input
          maxLength={ maxLengthText }
          name="account_name"
          onBlur={ () => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "account_name",
                details: `account_name:${ values.account_name }:${ currentAsset.audit_logs_id }`,
              });
          } }
          suffix
        />
      </Form.Item>

      <Form.Item name="amount_invested" label={ t("Amount Invested") }>
        <InputNumber
          name="amount_invested"
          className="small"
          formatter={ currencyFormatter }
          parser={ currencyParser }
          controls={ false }
          onBlur={ () => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "amount_invested",
                details: `amount_invested:${ values.amount_invested }:${ currentAsset.audit_logs_id }`,
              });
          } }
          prefix
        />
      </Form.Item>

      <Form.Item
        name="company_description"
        className="ben-description"
        label={
          <>
            {t("Description of Company")}
            <Tooltip content={ t("Tooltips.Description of Company") } dark />
          </>
        }
      >
        <Input.TextArea
          name="company_description"
          autoSize={ true }
          onBlur={ () => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "company_description",
                details: `company_description:${ values.company_description }:${ currentAsset.audit_logs_id }`,
              });
          } }
        />
      </Form.Item>
    </>
  );
};

export default EquityInASinglePrivatelyHeldCompanyForm;
