import { LocationRegion, RealEstatePropertyType } from "domains/liquidity/shared/constants";

interface IOptionData {
  label: string;
  value?: number;
}

export const realEstatePropertyTypeList: IOptionData[] = [
  { label: "Retail (such as a shopping center)", value: RealEstatePropertyType.Retail },
  { label: "Commercial (such as an office building)", value: RealEstatePropertyType.Commercial },
  { label: "Single Family residential", value: RealEstatePropertyType.SingleFamilyResidential },
  { label: "Multi-Family residential", value: RealEstatePropertyType.MultiFamilyResidential },
  { label: "Industrial (such as warehouse, factory)", value: RealEstatePropertyType.Industrial },
  { label: "Undeveloped land", value: RealEstatePropertyType.UndevelopedLand },
  { label: "Other", value: RealEstatePropertyType.Other },
];

export const locationList: IOptionData[] = [
  { label: "United States", value: LocationRegion.NorthAmerica },
  { label: "Outside of the U.S.", value: 1 },
];

export const outOfUSList: IOptionData[] = [
  { label: "North America (ex-Canada)", value: LocationRegion.NorthAmericaNonUS },
  { label: "Europe – West (U.K., France, Germany, etc.)", value: LocationRegion.EuropeWest },
  { label: "Europe – East (Poland, Russia, Greece, Turkey, etc.)", value: LocationRegion.EuropeEast },
  {
    label: "Asia – Developed (Japan, Australia, New Zealand, Hong Kong, Singapore)",
    value: LocationRegion.AsiaDeveloped,
  },
  { label: "Asia – Emerging (China, Taiwan, India, etc.)", value: LocationRegion.AsiaEmerging },
  { label: "Central & South America", value: LocationRegion.CentralAndSouthAmerica },
  { label: "General or unknown", value: LocationRegion.GeneralOrUnknown },
];
