import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as CaretIcon } from "common/assets/images/icons/Caret Lt.svg";

const Caret: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon { ...props } className={ props?.className + " ben-icon-input-color" } component={ CaretIcon } />
);

export default Caret;
