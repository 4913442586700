import { ReactNode } from "react";

import { FormProps, Form as AntdForm } from "antd";
import { Field, FieldProps } from "formik";

import FormItem from "common/lib/formik-antd/FormItem";

const Form = (props: FormProps & { children?: ReactNode }) => {
  return (
    <Field>
      {({ form: { handleSubmit, handleReset } }: FieldProps) => (
        <AntdForm onFinish={ handleSubmit } onReset={ handleReset } { ...props } />
      )}
    </Field>
  );
};

Form.Item = FormItem;

export default Form;
