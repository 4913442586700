export const responseAPIErrors = {
  sharedCustodyAccount: {
    message: "This account is shared and cannot be closed.",
    code: "1004",
  },
  duplicateCustodyAccount: {
    message: "There are 1 or more Accounts that are similar to the account you have provided.",
    code: "1002",
  },
};
