import { apiEnvs, apiService, idUtils } from "common/shared";
import { BenInfo } from "common/lib/constants";
import {
  IAcceptIdentityParams,
  IAcceptIdentityResponseData,
  IResetPasswordParams,
  IResetPasswordResponseData,
  IRememberLoginDeviceParams,
  IForgotPasswordParams,
  ILoginParams,
  IVerifyLoginPasswordParams,
  ILoginResponseData,
  IVerifyLoginPasswordWithMFAResponseData,
  IVerifyIdentificationCodeResponseDataSuccess,
  IVerifyLoginPasswordWithoutMFAResponseData,
  IRefreshAccessTokenParams,
  IRefreshAccessTokenResponseData,
  IResendWelcomeEmailParams,
  ISetMFAAuthenticationTypeRequestParams,
  ISetMFAAuthenticationTypeResponseData,
  ISetPasswordParams,
  IVerifyIdentificationCodeParams,
  ILoginUsingRememberedDeviceParams,
  ILoginUsingRememberedDeviceResponseData,
  IRememberLoginDeviceResponseData,
  IVerifyIdentificationCodeResponseDataFail,
} from "./types";
import {
  parseAcceptIdentityResponseData,
  parseAcceptResetPasswordResponseData,
  parseRefreshAccessTokenResponseData,
  prepareRefreshAccessTokenRequestBody,
} from "./auth.parser";

export class AuthService {
  async login(data: ILoginParams): Promise<ILoginResponseData> {
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const preparedData: any = {
      grant_type: "mfa",
      mfa_challenge_name: "user_srp_auth",
      user_name: data.user_name,
      srp_a: data.srp_a,
      client_id: oAuthClientId,
    };

    if (data.device_key && data.device_group_key && data.device_password) {
      preparedData.device_key = data.device_key;
      preparedData.device_group_key = data.device_group_key;
      preparedData.device_password = data.device_password;
    }

    return apiService.post(`${ authAPI }/v1/oauth2/token`, preparedData);
  }

  async verifyLoginPassword(
    data: IVerifyLoginPasswordParams,
  ): Promise<IVerifyLoginPasswordWithMFAResponseData | IVerifyLoginPasswordWithoutMFAResponseData> {
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const preparedData: any = {
      grant_type: "mfa",
      mfa_challenge_name: "password_verifier",
      password_claim_secret_block: data.password_claim_secret_block,
      password_claim_signature: data.password_claim_signature,
      user_name: data.user_name,
      timestamp: data.timestamp,
      client_id: oAuthClientId,
      mfa_session: data.mfa_session,
    };

    if (data.device_key && data.device_group_key) {
      preparedData.device_key = data.device_key;
      preparedData.device_group_key = data.device_group_key;
    }

    return apiService.post(`${ authAPI }/v1/oauth2/token`, preparedData);
  }

  async setMFAAuthenticationType(
    data: Omit<ISetMFAAuthenticationTypeRequestParams, "code_delivery_destination">,
  ): Promise<ISetMFAAuthenticationTypeResponseData> {
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const preparedData = {
      grant_type: "mfa",
      mfa_challenge_name: "email_or_sms",
      mfa_session: data.session_id,
      mfa_email_or_sms: data.authentication_type,
      user_name: data.user_name,
      client_id: oAuthClientId,
    };

    return apiService.post(`${ authAPI }/v1/oauth2/token`, preparedData);
  }

  async verifyIdentificationCode(
    data: IVerifyIdentificationCodeParams,
  ): Promise<IVerifyIdentificationCodeResponseDataSuccess | IVerifyIdentificationCodeResponseDataFail> {
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const preparedData = {
      grant_type: "mfa",
      mfa_challenge_name: "mfa_code",
      mfa_session: data.session_id,
      mfa_code: data.mfa_code,
      user_name: data.user_name,
      client_id: oAuthClientId,
    };

    return apiService.post(`${ authAPI }/v1/oauth2/token`, preparedData);
  }

  async rememberLoginDevice(data: IRememberLoginDeviceParams): Promise<IRememberLoginDeviceResponseData> {
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const preparedData = {
      grant_type: "mfa",
      mfa_challenge_name: "confirm_device_as_client",
      device_key: data.device_key,
      device_name: data.device_name,
      device_password: data.device_password,
      device_group_key: data.device_group_key,
      access_token: data.access_token,
      user_name: data.user_name,
      client_id: oAuthClientId,
    };

    return apiService.post(`${ authAPI }/v1/oauth2/token`, preparedData);
  }

  async loginUsingRememberedDevice(
    data: ILoginUsingRememberedDeviceParams,
  ): Promise<ILoginUsingRememberedDeviceResponseData> {
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const preparedData = {
      grant_type: "mfa",
      mfa_challenge_name: "device_srp_auth_as_client",
      device_key: data.device_key,
      device_password: data.device_password,
      device_group_key: data.device_group_key,
      user_name: data.user_name,
      client_id: oAuthClientId,
    };

    return apiService.post(`${ authAPI }/v1/oauth2/token`, preparedData);
  }

  async forgotPassword(data: IForgotPasswordParams) {
    const { identityAPI } = await apiEnvs.getApiEnv();

    const { resetMode, email, formatted_liquidity_request_id } = data;
    const passUrl = resetMode ? "forgot_password" : "send_password_notification";
    // request_id - any random guid for logging on Backend
    const request_id = idUtils.uuidv4();
    const postData = formatted_liquidity_request_id ? { request_id, formatted_liquidity_request_id } : { request_id };

    return apiService.post(`${ identityAPI }/v1/identity/${ email }/${ passUrl }`, postData);
  }

  async setPassword(data: ISetPasswordParams) {
    const { identityAPI, oAuthClientId } = await apiEnvs.getApiEnv();
    const {
      password,
      resetMode,
      resetToken,
      userEmail,
      preventSendingEmail,
      mfaPhoneNumber,
      salt,
      country_code,
      phone_country,
    } = data;
    const passUrl = resetMode ? "confirm_forgot_password" : "password";

    return apiService.post(
      `${ identityAPI }/v1/identity/${ userEmail }/${ passUrl }`,
      {
        password,
        client_id: oAuthClientId,
        mfa_phone_number: `+${ country_code }${ mfaPhoneNumber }`,
        phone_country,
        prevent_sending_email: preventSendingEmail,
        mfa_opt_in: true,
        salt: salt,
      },
      { headers: { Authorization: `Bearer ${ resetToken }` } },
    );
  }

  async acceptIdentity(data: IAcceptIdentityParams): Promise<IAcceptIdentityResponseData> {
    if (!data) return parseAcceptIdentityResponseData(null);
    const { identityAPI } = await apiEnvs.getApiEnv();
    const { email, token } = data;

    const customConfigs = {
      headers: {
        Authorization: `Bearer ${ token }`,
        [ BenInfo.CUSTOM_HTTP_HEADERS.shouldSaveSession ]: "yes",
      },
    };

    const response = await apiService.post(`${ identityAPI }/v1/identity/${ email }/accept`, null, customConfigs);

    return parseAcceptIdentityResponseData(response);
  }

  async acceptResetPassword(data: IResetPasswordParams): Promise<IResetPasswordResponseData> {
    if (!data) return parseAcceptResetPasswordResponseData(null);
    const { identityAPI } = await apiEnvs.getApiEnv();
    const { email, token } = data;
    const customConfigs = {
      headers: {
        Authorization: `Bearer ${ token }`,
        [ BenInfo.CUSTOM_HTTP_HEADERS.shouldNotRedirect ]: "yes",
      },
    };
    const response = await apiService.post(
      `${ identityAPI }/v1/identity/${ email }/accept_forgot_password`,
      null,
      customConfigs,
    );
    return parseAcceptResetPasswordResponseData(response);
  }

  refreshAccessToken = async (data: IRefreshAccessTokenParams): Promise<IRefreshAccessTokenResponseData> => {
    if (!data || !data.refreshToken) {
      return parseRefreshAccessTokenResponseData(null);
    }
    const { oAuthClientId, authAPI } = await apiEnvs.getApiEnv();
    const requestBody = prepareRefreshAccessTokenRequestBody(data, oAuthClientId);
    const response = await apiService.post(`${ authAPI }/v1/oauth2/token`, requestBody);
    return parseRefreshAccessTokenResponseData(response);
  };

  resendWelcomeEmail = async (data: IResendWelcomeEmailParams): Promise<boolean> => {
    if (!data) return false;
    const { identityAPI } = await apiEnvs.getApiEnv();

    const { email } = data;
    await apiService.post(`${ identityAPI }/v1/identity/${ email }/send_invitation`, {});
    return true;
  };

  resendSharingInvitation = async (token: string, liquidity_request_id: string, sharing_id: string) => {
    const { holdingsAPI } = await apiEnvs.getApiEnv();
    const config = {
      headers: {
        Authorization: `Bearer ${ token }`,
      },
    };

    return await apiService.post(
      `${ holdingsAPI }/v1/liquidity_requests/${ liquidity_request_id }/sharings/${ sharing_id }/send_invitation`,
      {},
      config,
    );
  };
}

const authService = new AuthService();
export default authService;
