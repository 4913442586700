import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IVerifyLoginPasswordParams } from "domains/authentication/shared/types";
import { AuthenticationType } from "domains/authentication/shared/constants";
import { authApiReducer } from "./auth.api.reducer";

interface IInitialState {
  isAuth: boolean;
  identificationCode: {
    code_delivery_destination: string | null;
    authentication_type: `${ AuthenticationType }` | null;
  };
  acceptedIdentityData: {
    email: string;
    isAcceptIdentityLinkWasUsed: boolean;
  };
  resendReqData: IVerifyLoginPasswordParams;
}

const initialState: IInitialState = {
  isAuth: false,
  identificationCode: {
    code_delivery_destination: null,
    authentication_type: null,
  },
  acceptedIdentityData: {
    email: "",
    isAcceptIdentityLinkWasUsed: false,
  },
  resendReqData: {
    password_claim_secret_block: "",
    password_claim_signature: "",
    user_name: "",
    password: "",
    timestamp: "",
    srp_b: "",
    salt: "",
    device_key: "",
    device_group_key: "",
  },
};

const authReducer = createSlice({
  name: "[Auth]",
  initialState,
  reducers: {
    checkIsAuth: (state) => state,

    setIsAuth: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAuth: action.payload,
    }),

    resendReqDataSave: (state, action: PayloadAction<IVerifyLoginPasswordParams>) => ({
      ...state,
      resendReqData: action.payload,
    }),

    setAcceptIdentityDataEmail: (state, action: PayloadAction<string>) => ({
      ...state,
      acceptedIdentityData: {
        ...state.acceptedIdentityData,
        email: action.payload,
      },
    }),

    setAcceptIdentityDataLinkWasUsed: (state, action: PayloadAction<boolean>) => ({
      ...state,
      acceptedIdentityData: {
        ...state.acceptedIdentityData,
        isAcceptIdentityLinkWasUsed: action.payload,
      },
    }),

    resetAuthReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApiReducer.endpoints.setMFAAuthenticationType.matchPending, (state, action) => ({
      ...state,
      identificationCode: {
        ...state.identificationCode,
        authentication_type: action.meta.arg.originalArgs.authentication_type,
        code_delivery_destination: action.meta.arg.originalArgs.code_delivery_destination,
      },
    }));
  },
});

export const {
  checkIsAuth,

  setIsAuth,

  resendReqDataSave,

  setAcceptIdentityDataEmail,
  setAcceptIdentityDataLinkWasUsed,

  resetAuthReducer,
} = authReducer.actions;

export default authReducer.reducer;
