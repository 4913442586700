import axios from "axios";

import { ITrustbenEnvLinks } from "domains/trustben/shared/types";
import { getNoCacheHeaders } from "common/shared/services/api.headers/api.headers";

export class TrustbenLinksService {
  static readonly TIMEOUT = 60000;
  private trustbenLinks: Promise<ITrustbenEnvLinks>;

  constructor() {
    this.trustbenLinks = new Promise(() => {});
    this.fetchTrustbenLinks().then(
      (links) => {
        this.setTrustbenLinks(links);
      },
      () => {
        this.setTrustbenLinks(null);
      },
    );
  }

  public getTrustbenLinks() {
    return this.trustbenLinks;
  }

  setTrustbenLinks(links: any) {
    this.trustbenLinks = links;
  }

  async fetchTrustbenLinks() {
    const host = window.location.hostname === "localhost" ? "https://baadev.bcpbenapp-dev.bankben.org" : "";
    const links: ITrustbenEnvLinks = await axios.get(`${ host }/trustben_links.json`, {
      headers: {
        ...getNoCacheHeaders(),
      },
    });

    return links;
  }
}

const trustbenLinksService = new TrustbenLinksService();
export default trustbenLinksService;
