import { useEffect } from "react";

import { useLocation } from "react-router-dom";

const useScrollToTop = (scrollBehavior: "auto" | "smooth" = "smooth") => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: scrollBehavior,
    });
  }, [ pathname ]);
};

export default useScrollToTop;
