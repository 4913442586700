import { ReactNode } from "react";

import { notification } from "antd";

export class MessageService {
  readonly duration = 15;

  public success(description?: string | ReactNode, message: string | ReactNode = "Success", duration = this.duration) {
    notification.success({ message, description, duration });
  }

  public info(description?: string | ReactNode, message: string | ReactNode = "Info", duration = this.duration) {
    notification.info({ message, description, duration });
  }

  public error(description?: string | ReactNode, message: string | ReactNode = "Error", duration = this.duration) {
    notification.error({ message, description, duration });
  }

  public warning(description?: string | ReactNode, message: string | ReactNode = "Warning", duration = this.duration) {
    notification.warning({ message, description, duration });
  }
}

export default new MessageService();
