import { Select as AntdSelect, SelectProps as AntdSelectProps } from "antd";
import { useField } from "formik";

const Select = ({
  name,
  // eslint-disable-next-line no-unused-vars
  value: $value,
  onChange: $onChange,
  onBlur: $onBlur,
  children,
  ...restProps
}: AntdSelectProps & { name: string }) => {
  const [ { value, onBlur }, _meta, { setValue } ] = useField(name);

  return (
    <AntdSelect
      id={ name }
      // setting undefined will show the placeholder
      value={ value === "" || value === null ? undefined : value }
      onChange={ (value, option) => {
        setValue(value);
        $onChange && $onChange(value, option);
      } }
      onBlur={ (event) => {
        onBlur(event);
        $onBlur && $onBlur(event);
      } }
      { ...restProps }
    >
      {children}
    </AntdSelect>
  );
};

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;
