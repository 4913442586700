import { FC, useEffect, useState } from "react";

import { Input, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./EditApplicationNamemodal.scss";

interface IProps {
  isVisible: boolean;
  handleCancel: () => void;
  LRName: string;
  LRId: string;
}

const EditApplicationNameModal: FC<IProps> = ({ isVisible, handleCancel, LRName, LRId }) => {
  const { t } = useTranslation([ "common", "liquidity" ]);
  const [ applicationName, setApplicationName ] = useState(LRName);

  const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });

  const onSaveClick = () => {
    if (applicationName !== LRName) {
      updateLiquidityRequest({ LRId, changes: { liquidity_request_name: applicationName } });
    }
    handleCancel();
  };

  useEffect(() => {
    setApplicationName(LRName);
  }, [ isVisible ]);

  return (
    <Modal centered={ true } open={ isVisible } footer={ null } onCancel={ handleCancel }>
      <h1 className="ben-text-center ben-edit-application-name-modal-title">
        {t("Edit Application Name", { ns: "liquidity" })}
      </h1>
      <div className="ben-text-center">
        <Input
          type="text"
          name="application_name"
          value={ applicationName }
          className="small ben-w-65 ben-mb-4"
          onChange={ (e) => setApplicationName(e.target.value) }
          suffix
        />
      </div>
      <div className="ben-text-center">
        <Button onClick={ handleCancel } className="ben-mr-2 ben-edit-application-name-modal-button">
          {t("Cancel", { ns: "common" })}
        </Button>
        <Button
          disabled={ !applicationName }
          className="ben-edit-application-name-modal-button"
          type="primary"
          onClick={ onSaveClick }
        >
          {t("Save", { ns: "common" })}
        </Button>
      </div>
    </Modal>
  );
};

export default EditApplicationNameModal;
