export const defaultDesktopColumns = [
  {
    title: "Fund Manager",
    dataIndex: "fundManager",
    key: "fundManager",
  },
  {
    title: "Fund Name",
    dataIndex: "fundName",
    key: "fundName",
    classname: "ben-w-40",
  },
];

export const defaultMobileColumns = [
  {
    title: "Fund Manager & Fund Name",
    dataIndex: "fund",
    key: "fund",
    classname: "ben-w-70",
  },
];
