import { FC, RefObject, useCallback, useState } from "react";

import { Formik, FormikProps } from "formik";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Spinner, StatusMessage, Button, FormikObserver, Caret, Tooltip } from "common/lib/components";
import { Form, Input, Select, PhoneNumberInput } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { useRequestAccessFormValidationSchema } from "./validationSchema";
import { initialValues } from "./constants";
import { AccountType } from "common/lib/constants";
import { initValues } from "domains/authentication/forms/SignInForm/constants";
import { FeatureNames, useFeature } from "domains/features";
import useWhiteLabel from "common/shared-hooks/useWhiteLabel";
import { WhiteLabelType } from "common/shared/types";
import { useTrustbenSelector } from "domains/trustben";
import { identityApiReducer } from "domains/identity/store/identity.api.reducer";
import { ICreateRegistrationRequest } from "domains/identity/shared/types";

import "./RequestAccessForm.scss";

const { Option } = Select;

interface IProps {
  signInFormRef: RefObject<FormikProps<typeof initValues>>;
  requestAccessFormRef: RefObject<FormikProps<typeof initialValues>>;
}

export const RequestAccessForm: FC<IProps> = ({ requestAccessFormRef, signInFormRef }) => {
  const { t } = useTranslation([ "common", "identity", "authentication", "trustben" ]);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const whiteLabelType = useWhiteLabel();

  const [ createRegistration, { isLoading, isSuccess, isError } ] = identityApiReducer.useCreateRegistrationMutation();

  const { validationSchema } = useRequestAccessFormValidationSchema();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { isFeatureActive } = useFeature(FeatureNames.GP, []);

  const [ isSubmitClicked, setIsSubmitClicked ] = useState(false);

  const LRId: string | null = state?.LRId ?? null;
  const accountTypes = Object.values(AccountType)
    .slice(0, 4)
    .filter((accountType) =>
      isFeatureActive && whiteLabelType === WhiteLabelType.Default ? true : accountType !== AccountType.GeneralPartner,
    );
  const accountLabels = [
    t("Individual Investor", { ns: "identity" }),
    t("Institutional Investor", { ns: "identity" }),
    t("Advisor", { ns: "identity" }),
    t("General Partner", { ns: "identity" }),
  ];
  const tooltipText = [
    t("User Types Tooltip Text.Individual Investor", { ns: "authentication" }),
    t("User Types Tooltip Text.Institutional Investor", { ns: "authentication" }),
    t("User Types Tooltip Text.Advisor", { ns: "authentication" }),
    t("User Types Tooltip Text.General Partner", { ns: "authentication" }),
  ];

  const handleSubmit = useCallback(
    (values: ICreateRegistrationRequest) => {
      const data = LRId ? { ...values, formatted_liquidity_request_id: LRId } : values;
      createRegistration(data);
    },
    [ dispatch ],
  );

  const onChange = useCallback(() => {
    const hasValues = Object.values(requestAccessFormRef?.current?.values || {})
      .slice(0, -1)
      .some((item) => item !== "");
    const hasTouched = !!Object.values(requestAccessFormRef?.current?.touched || {}).length;

    if (!hasValues && !hasTouched) return;

    signInFormRef?.current?.resetForm();
    setTimeout(() => {
      signInFormRef?.current?.validateForm();
    }, 50);
  }, []);

  return (
    <div className="ben-panel ben-w-100">
      <div className={ `ben-panel-header ${ isTrustbenAltQuote && "trustben-panel-header" }` }>
        <h3>{isTrustbenAltQuote ? t("New Accounts", { ns: "trustben" }) : t("Request Access", { ns: "identity" })}</h3>
      </div>
      {isLoading ? (
        <Spinner />
      ) : !isSuccess ? (
        <Formik
          initialValues={ initialValues }
          onSubmit={ handleSubmit }
          validationSchema={ validationSchema }
          innerRef={ requestAccessFormRef }
          validateOnChange={ isSubmitClicked }
          validateOnBlur={ false }
        >
          {({ values, errors, setErrors, submitCount }) => {
            if (submitCount === 1) {
              setIsSubmitClicked(true);
            }

            if (values.account_type === AccountType.Individual && errors.account_name) {
              delete errors.account_name;
              setErrors(errors);
            }

            return (
              <Form className="ben-request-access-form">
                <FormikObserver onChange={ onChange } />
                <div className="ben-border-bottom ben-mb-4">
                  <Form.Item name="account_type">
                    <Select
                      size="large"
                      name="account_type"
                      placeholder={ t("Select Account Type", { ns: "identity" }) }
                      suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                    >
                      {accountTypes.map((item, index) => (
                        <Option key={ item } value={ item }>
                          {accountLabels[ index ]}
                          <Tooltip placement="right" content={ tooltipText[ index ] } />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="first_name">
                    <Input
                      maxLength={ maxLengthText }
                      name="first_name"
                      placeholder={ t("First Name", { ns: "identity" }) }
                      suffix
                    />
                  </Form.Item>

                  <Form.Item name="last_name">
                    <Input
                      maxLength={ maxLengthText }
                      name="last_name"
                      placeholder={ t("Last Name", { ns: "identity" }) }
                      suffix
                    />
                  </Form.Item>

                  <Form.Item name="email">
                    <Input
                      maxLength={ maxLengthText }
                      name="email"
                      placeholder={ t("Email Address", { ns: "authentication" }) }
                      suffix
                    />
                  </Form.Item>

                  <Form.Item name="phone_number">
                    <PhoneNumberInput name="phone_number" />
                  </Form.Item>

                  {values.account_type && values.account_type !== AccountType.Individual && (
                    <Form.Item name="account_name">
                      <Input
                        maxLength={ maxLengthText }
                        name="account_name"
                        placeholder={ t("Company Name", { ns: "identity" }) }
                        suffix
                      />
                    </Form.Item>
                  )}

                  <StatusMessage message={ "" } error={ isError } />
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className={ isTrustbenAltQuote ? "request-access-trustben-button" : "ben-w-100" }
                >
                  {t(isTrustbenAltQuote ? "Register" : "Submit", { ns: "common" })}
                </Button>

                <p className="request-access-info ben-mt-3 ben-mb-0">{t("Request Access Info", { ns: "identity" })}</p>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <p>{t("Info", { ns: "identity" })}</p>
      )}
    </div>
  );
};
