import { FC, ReactElement } from "react";

import { Link, LinkProps } from "react-router-dom";

import "./Logo.scss";

interface IProps extends LinkProps {
  src: string;
  alt: string;
  to: string;
  className?: string;
}

const Logo: FC<IProps> = ({ className, src, alt, to, ...props }): ReactElement | null => {
  const classes = "ben-logo " + className;

  if (!src) {
    return null;
  }

  return (
    <Link className={ classes } to={ to } { ...props }>
      <img src={ src } alt={ alt } />
    </Link>
  );
};

export default Logo;
