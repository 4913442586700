import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { apiEnvs, sessionService } from "common/shared";
import { ILoginParams } from "domains/authentication/shared/types";
import { URLs } from "common/lib/constants";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";

const useInternalAltQuoteAutoLogin = () => {
  const { pathname } = useLocation();

  const [ login, { isLoading } ] = authApiReducer.useLoginMutation({
    fixedCacheKey: "internal-altquote-auto-login",
  });

  const isAuthenticated = sessionService.isAuthenticated();

  useEffect(() => {
    (async () => {
      const { altQuoteLogin, altQuotePassword } = await apiEnvs.getApiEnv();

      if (!isAuthenticated && pathname === URLs.AUTH.INTERNAL_ALTQUOTE_FUNCTIONALITY && !isLoading) {
        sessionStorage.setItem("IS_INTERNAL_ALTQUOTE_FUNCTIONALITY", "true");
        login({
          user_name: altQuoteLogin,
          password: altQuotePassword,
        } as ILoginParams);
      }
    })();
  }, [ isAuthenticated, pathname, isLoading ]);
};

export default useInternalAltQuoteAutoLogin;
