import { FC, useCallback, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { URLs } from "common/lib/constants";
import { FormHeader, NavigationButtons } from "common/lib/components";
import { UploadDocumentsForm } from "domains/liquidity/forms";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { LAPages } from "domains/liquidity/shared/constants";
import { useDocumentSelector } from "domains/documents/store/documents.selector";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./AssetsDocumentsPage.scss";

const AssetsDocumentsPage: FC = () => {
  const { t } = useTranslation([ "liquidity", "common" ]);
  const { LRId } = useParams();
  const navigate = useNavigate();

  const { currentLiquidityRequest } = useLiquiditySelector();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const { uploadingDocuments } = useDocumentSelector();
  const [ fetchLRDocuments ] = documentsApiReducer.useLazyFetchLRDocumentsQuery();

  const onSubmit = useCallback(() => {
    navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmAssetDocuments);
  }, [ assets ]);

  const onSkip = useCallback(() => {
    navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.APPLICATION_STATUS);
  }, []);

  useEffect(() => {
    if (!LRId || !currentLiquidityRequest?.account_id) return;

    fetchLRDocuments({ LRId, lrAccountId: currentLiquidityRequest.account_id });
  }, [ LRId, currentLiquidityRequest?.account_id ]);

  return (
    <>
      <FormHeader
        title={ t("Assets Documents Page.Step") }
        subtitle={ t("Assets Documents Page.Title") }
        hint={ t("Assets Documents Page.Info") }
      />
      <div className="ben-form-container ben-assets-documents-wrapper">
        <UploadDocumentsForm />
        <NavigationButtons
          disabled={ uploadingDocuments.length > 0 }
          onNext={ onSubmit }
          submitButtonText={ t("Submit", { ns: "common" }) }
          hideSkip={ false }
          onSkip={ onSkip }
        />
        <p className="assets-documents-bottom-info">{t("Assets Documents Page.Actions Info")}</p>
      </div>
    </>
  );
};

export default AssetsDocumentsPage;
