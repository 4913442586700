export enum AccountType {
  Individual = "individual",
  Institution = "institution",
  AdvisoryFirm = "advisory_firm",
  GeneralPartner = "gp",
  CustodialIndividual = "custodial_individual",
  CustodialInstitution = "custodial_institution",
  CustodialAdvisor = "custodial_advisor",
}

export type TAccountType = `${ AccountType }`;
