import { FC, ReactNode } from "react";

import "./FormFooter.scss";

interface IProps {
  children: ReactNode;
  className?: string;
}

const FormFooter: FC<IProps> = ({ children, className }) => {
  return (
    <div className={ `ben-form-footer ${ className }` }>
      <div className="ben-divider" />
      <span
        className="ben-form-footer-info ben-d-block ben-text-center"
      >
        { children }
      </span>
      <div className="ben-divider" />
    </div>
  );
};

export default FormFooter;
