import { FC } from "react";

import { Text } from "@react-pdf/renderer";

import { IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import { styles } from "domains/liquidity/components/CustodyAgreementPDF/CustodyAgreementPDF";
import { AccountType } from "common/lib/constants";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";

interface IProps {
  custodyAccount?: ICustodyAccount;
  user?: IUserDetails;
  currentClient?: IUserClient;
}

const DateAndName: FC<IProps> = ({ custodyAccount, user, currentClient }) => {
  const footerDate = `${ new Date().toLocaleString(undefined, { timeZoneName: "short" }) }`;
  const isAdvisor = user?.user_type === UserType.Advisor;

  if (isAdvisor) {
    const isAdvisorCustodyAccount =
      (currentClient?.is_anonymous ||
        custodyAccount?.account_type === AccountType.CustodialAdvisor ||
        custodyAccount?.owner_type === AccountType.CustodialAdvisor) ??
      false;
    const advisorName = `${ user?.first_name } ${ user?.last_name }`;
    const customerName = `${ custodyAccount?.first_name } ${ custodyAccount?.last_name }`;

    return (
      <>
        <Text style={ styles.marginTop }>{`Electronic Signature Accepted ${ footerDate } By:`}</Text>
        {isAdvisorCustodyAccount ? (
          <Text>{customerName}</Text>
        ) : (
          <Text>{`${ advisorName }, on behalf of and as advisor to ${ customerName }`}</Text>
        )}
        <Text style={ styles.marginBottom }>{custodyAccount?.email_address}</Text>
      </>
    );
  }

  return (
    <>
      <Text style={ styles.marginTop }>{`Electronic Signature Accepted ${ footerDate } By:`}</Text>
      <Text>{`${ custodyAccount?.first_name } ${ custodyAccount?.last_name }`}</Text>
      <Text style={ styles.marginBottom }>{custodyAccount?.email_address}</Text>
    </>
  );
};

export default DateAndName;
