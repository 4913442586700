import { FC } from "react";

import "./MyProfileFormHeader.scss";

interface IProps {
  title: string;
  subtitle?: string;
}

const MyProfileFormHeader: FC<IProps> = ({ title, subtitle }) => {
  return (
    <div className="ben-my-profile-form-header">
      <h1>{ title }</h1>
      { subtitle && (
        <h3>{ subtitle }</h3>
      ) }
    </div>
  );
};

export default MyProfileFormHeader;
