import { FC } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, Input } from "common/lib/formik-antd";
import { maxLengthAssetName } from "common/shared/constants/validationConstants";
import { FormFooter, FormHeader, NavigationButtons } from "common/lib/components";
import { useAddManuallyPageValidationSchema } from "domains/liquidity/pages/AddManuallyPage/validationSchema";
import { useHandlers } from "./useHandlers";

const AddManuallyPage: FC = () => {
  const { validationSchema } = useAddManuallyPageValidationSchema();
  const { t } = useTranslation([ "liquidity" ]);

  const { onNext } = useHandlers();

  return (
    <>
      <FormHeader
        title={ t("Assets to Exchange") }
        subtitle={ t("Add Manually Page.Desktop Title") }
        hint={ t("Add Manually Page.Text") }
      />

      <div className="ben-form-container">
        <Formik
          initialValues={ { asset_name: "" } }
          onSubmit={ onNext }
          validationSchema={ validationSchema }
          validateOnMount
        >
          {({ isValid }) => (
            <Form layout="vertical">
              <Form.Item name="asset_name" label={ t("Fund Name") }>
                <Input maxLength={ maxLengthAssetName } name="asset_name" placeholder={ t("Fund Name") } suffix />
              </Form.Item>
              <NavigationButtons disabled={ !isValid } />
            </Form>
          )}
        </Formik>
      </div>

      <FormFooter>
        <>
          <span>{t("Add Manually Page.Footer.0")}</span>
          <br />
          <span>{t("Add Manually Page.Footer.1")}</span>
        </>
      </FormFooter>
    </>
  );
};

export default AddManuallyPage;
