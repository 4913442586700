import ITokens from "common/shared/interfaces/ITokens";
import authService from "domains/authentication/shared/auth.api.service";
import { tokenDecodeUtils, router } from "common/shared";
import { BrowserSessionService } from "common/shared/services//browserSession/browserSession.service";
import { SessionExpirationService } from "common/shared/services/sessionExpiration/sessionExpiration.service";
import { IImpersonatePayload } from "common/shared/services/impersonation/impersonation.service";
import { URLs } from "common/lib/constants";
import { TWhiteLabel } from "common/shared/types";

export interface ISessionIds {
  userId: string;
  accountId: string;
}

export interface ISessionData {
  tokens: ITokens | null;
  userName: string;
  envClientId: string;
  sessionExpiredAt: string | null;
  sessionId: string | null;
}

export const storageEventKeys = {
  SESSION_USERNAME: "SESSION_USERNAME",
  CREDENTIALS_TOKEN: "CREDENTIALS_TOKEN",
  REQUESTING_SHARED_CREDENTIALS: "REQUESTING_SHARED_CREDENTIALS",
  CREDENTIALS_SHARING: "CREDENTIALS_SHARING",
  CREDENTIALS_FLUSH: "CREDENTIALS_FLUSH",
  SESSION_IS_APPIAN: "SESSION_IS_APPIAN",
  IMPERSONATE_PAYLOAD: "IMPERSONATE_PAYLOAD",
  SESSION_EXPIRED_AT: "SESSION_EXPIRED_AT",
  SESSION_ID: "SESSION_ID",
};

export class SessionService {
  private tokens: ITokens | null = null;
  private userName = "";
  private userId = "";
  private accountId = "";
  private whiteLabelInstance = "";
  private envClientId = "";
  private isAppian = false;
  private isMaintenance = false;
  private version = "";
  private timerForRefreshAccessToken: number | null = null;
  private expirationService: SessionExpirationService;
  private browserSessionService: BrowserSessionService;

  constructor(browserService: BrowserSessionService, expirationService: SessionExpirationService) {
    this.browserSessionService = browserService;
    this.expirationService = expirationService;
    this.isMaintenance = false;
    this.version = "";
  }

  public start() {
    this.tokens = this.getTokensFromStorage();
    this.userName = this.getUserNameFromStorage();
    this.isAppian = this.getIsAppianFromStorage();

    this.initRefreshAccessToken();
    return !!( this.tokens && this.userName );
  }

  public setTokens(tokens: ITokens, envClientId: string, browserSessionId: string) {
    this.tokens = tokens;
    this.tokens.created_at = this.getCurrentUnixTime();
    const decodedTokenData = tokenDecodeUtils.decodeAuthToken(tokens.access_token);
    const clientId = decodedTokenData?.clientId;
    this.isAppian = Boolean(envClientId && clientId !== envClientId);
    this.setEnvClientId(envClientId);
    this.browserSessionService.setSessionId(browserSessionId);

    window.sessionStorage.setItem(storageEventKeys.CREDENTIALS_TOKEN, JSON.stringify(tokens));
    // Runs after sign in process
    if (this.tokens) {
      this.initRefreshAccessToken();
    }
  }

  public getTokens() {
    if (!this.tokens) {
      this.tokens = this.getTokensFromStorage();
    }
    return this.tokens;
  }

  public setIsAppian(value: boolean) {
    this.isAppian = value;
    window.localStorage.setItem(
      storageEventKeys.SESSION_IS_APPIAN,
      ( value && value.toString() ) || "",
    );
  }

  public getIsAppian(): boolean {
    return this.isAppian;
  }

  public setIsMaintenance(value: boolean) {
    this.isMaintenance = value;
  }

  public getIsMaintenance() {
    return this.isMaintenance;
  }

  public setVersion(version: string) {
    this.version = version;
  }

  public getVersion(): string {
    return this.version;
  }

  public setUserName(userName: string) {
    this.userName = userName;
    window.sessionStorage.setItem(storageEventKeys.SESSION_USERNAME, userName);
  }

  public getUserEmail(): string {
    return this.userName;
  }

  public getUserId(): string {
    return this.userId;
  }

  public syncBrowserSessionId(): boolean {
    return this.browserSessionService.setOnWindowSessionId();
  }

  public getGeneratedBrowserSessionId(): string {
    return this.browserSessionService.getGeneratedSessionId();
  }

  public isSameUserSession(): boolean {
    return this.browserSessionService.isSameBrowserSession();
  }

  public setImpersonatePayloadToStorage(tokens: IImpersonatePayload) {
    window.sessionStorage.setItem(storageEventKeys.IMPERSONATE_PAYLOAD, JSON.stringify(tokens));
  }

  public getImpersonatePayloadFromStorage() {
    const stringifiedTokens = window.sessionStorage.getItem(storageEventKeys.IMPERSONATE_PAYLOAD);

    return this.stringifyTokens(stringifiedTokens);
  }

  public setSessionIds({ accountId, userId }: ISessionIds) {
    this.userId = userId;
    this.accountId = accountId;
  }

  public getSessionIds(): { userId: string; accountId: string } {
    return { accountId: this.accountId, userId: this.userId };
  }

  public setWhiteLabelInstance(whiteLabelInstance: TWhiteLabel) {
    this.whiteLabelInstance = whiteLabelInstance;
  }

  public getWhiteLabelInstance() {
    return this.whiteLabelInstance;
  }

  public clearSession() {
    this.tokens = null;
    this.userName = "";
    this.isAppian = false;
    this.setEnvClientId("");

    // Clear sessionStorage
    window.sessionStorage.removeItem(storageEventKeys.CREDENTIALS_TOKEN);
    window.sessionStorage.removeItem(storageEventKeys.SESSION_USERNAME);
    window.sessionStorage.removeItem(storageEventKeys.IMPERSONATE_PAYLOAD);
    // Clear localStorage
    window.localStorage.removeItem(storageEventKeys.SESSION_IS_APPIAN);

    this.browserSessionService.clearBrowserSession();
    this.expirationService.stopExpirationProcess();

    if (this.timerForRefreshAccessToken) {
      clearTimeout(this.timerForRefreshAccessToken);
    }
  }

  public isAuthenticated(): boolean {
    const tokens = this.getTokens();
    return !!(
      tokens &&
      tokens.access_token &&
      !tokenDecodeUtils.isTokenExpired(tokens.access_token)
    );
  }

  public getSessionData(): ISessionData | null {
    const tokens = this.getTokens();
    const envClientId = this.getEnvClientId();
    const userName = this.getUserEmail();
    const sessionExpiredAt = this.expirationService.getExpiredAt() || "";
    const sessionId = this.browserSessionService.getSessionId();

    if (!tokens || !userName) {
      return null;
    }

    return {
      tokens,
      userName,
      envClientId,
      sessionExpiredAt: String(sessionExpiredAt),
      sessionId,
    };
  }

  public setSessionData(sessionData: ISessionData): boolean {
    const { tokens, envClientId, userName, sessionExpiredAt, sessionId } = sessionData;

    if (!tokens || !userName || !sessionId) {
      return false;
    }

    this.setUserName(userName);
    this.setTokens(tokens, envClientId, sessionId);

    if (sessionExpiredAt) {
      this.expirationService.setExpiredAt(sessionExpiredAt);
    }

    return true;
  }

  public setSessionDataFromString(data: string): boolean {
    const sessionData = this.stringifyTokens(data);

    return this.setSessionData(sessionData);
  }

  public startExpirationProcess(remainingSeconds: number) {
    this.expirationService.startExpirationProcess(remainingSeconds);
  }

  public stopExpirationProcess(): void {
    this.expirationService.stopExpirationProcess();
  }

  public isSessionExpired(): boolean {
    return this.expirationService.isSessionExpired();
  }

  public getSessionExpiredIn(): number | null {
    return this.expirationService.getExpiredInSeconds();
  }

  public getRefreshToken(): string {
    if (!this.tokens || !this.tokens.refresh_token) {
      return "";
    }
    return this.tokens.refresh_token;
  }

  public updateAccessToken(accessToken: string) {
    if (!accessToken || !this.tokens) {
      this.clearSession();
      return;
    }

    this.tokens.access_token = accessToken;
    this.tokens.created_at = this.getCurrentUnixTime();
    window.sessionStorage.setItem(storageEventKeys.CREDENTIALS_TOKEN, JSON.stringify(this.tokens));
    this.initRefreshAccessToken();
  }

  private getEnvClientId(): string {
    return this.envClientId;
  }

  private setEnvClientId(envClientId: string): void {
    this.envClientId = envClientId;
  }

  private async runRefreshAccessToken() {
    if (!this.tokens || !this.tokens.refresh_token) {
      this.clearSession();
      return;
    }

    const payload = { refreshToken: this.tokens.refresh_token };
    let result;
    try {
      result = await authService.refreshAccessToken(payload);
    } catch (e) {
      this.clearSession();
      router.navigate(URLs.AUTH.SIGN_IN, { replace: true });
      return;
    }

    if (!result || !result.accessToken) {
      this.clearSession();
      router.navigate(URLs.AUTH.SIGN_IN, { replace: true });
      return;
    }
    this.updateAccessToken(result.accessToken);
  }

  /**
   * Set up a function to be executed after some time
   */
  private initRefreshAccessToken() {
    if (!this.tokens || !this.tokens.expires_in || !this.tokens.created_at) {
      this.clearSession();
      return;
    }
    const minSecondsToRefresh = this.tokens.expires_in * 0.9;
    const currentTime = this.getCurrentUnixTime();
    const secondsFromLastRefresh = currentTime - this.tokens.created_at;
    const nextUpdateIn = ( this.tokens.created_at + minSecondsToRefresh - currentTime ) * 1000;

    // case when page is being reloaded and token was not updated
    if (secondsFromLastRefresh > minSecondsToRefresh) {
      this.runRefreshAccessToken();
    }

    this.timerForRefreshAccessToken = window.setTimeout(
      () => this.runRefreshAccessToken(),
      nextUpdateIn,
    );
  }

  private getCurrentUnixTime() {
    return Math.round(new Date().getTime() / 1000);
  }

  private getUserNameFromStorage() {
    return window.sessionStorage.getItem(storageEventKeys.SESSION_USERNAME) || "";
  }

  private getIsAppianFromStorage() {
    return !!window.localStorage.getItem(storageEventKeys.SESSION_IS_APPIAN);
  }

  private getTokensFromStorage() {
    const stringifiedTokens = window.sessionStorage.getItem(storageEventKeys.CREDENTIALS_TOKEN);

    return this.stringifyTokens(stringifiedTokens);
  }

  private stringifyTokens(stringifiedTokens: string | null) {
    try {
      return stringifiedTokens && JSON.parse(stringifiedTokens);
    } catch {
      return null;
    }
  }
}

export const sessionService = new SessionService(
  new BrowserSessionService(storageEventKeys.SESSION_ID),
  new SessionExpirationService(storageEventKeys.SESSION_EXPIRED_AT),
);
