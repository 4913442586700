import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as Delete } from "common/assets/images/icons/Delete.svg";

interface IProps extends Partial<CustomIconComponentProps> {
  onClick: () => void;
}

const DeleteOutlined: FC<IProps> = (props) => (
  <Icon
    { ...props }
    className={ props?.className + " ben-icon-primary-color" }
    style={ { fontSize: "18px" } }
    component={ Delete }
  />
);

export default DeleteOutlined;
