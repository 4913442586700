import { Text, Image, View } from "@react-pdf/renderer";

import SignatureImg from "common/assets/images/signature.png";
import { styles } from "domains/liquidity/components/CustodyAgreementPDF/CustodyAgreementPDF";
import { getDateFromISO } from "common/shared/utils/date.utils";

const Signature = () => {
  return (
    <>
      <Text style={ styles.bold }>Beneficient Fiduciary Financial, L.L.C.</Text>
      <View style={ styles.section }>
        <Image style={ styles.image } src={ SignatureImg } />
        <View style={ styles.date }>
          <Text>Date:</Text>
          <Text style={ styles.border }> {getDateFromISO()}</Text>
        </View>
      </View>
      <Text>
        <Text style={ styles.bold }>Name:</Text> Derek L. Fletcher
      </Text>
      <Text>
        <Text style={ styles.bold }>Title:</Text> President & Chief Fiduciary Officer
      </Text>
    </>
  );
};

export default Signature;
