const trustbenSessionEventKeys = {
  IS_TRUSTBEN_ALTQUOTE: "IS_TRUSTBEN_ALTQUOTE",
  TRUSTBEN_SESSION_ID: "TRUSTBEN_SESSION_ID",
};

export class TrustbenSessionService {
  private isTrustbenAltQuote = false;
  private trustbenSessionId = "";

  constructor() {
    this.setTrustbenSessionId(this.getSessionIdFromStore() || this.generateSessionId());
    this.setIsTrustbenAltQuote(this.getIsTrustbenAltQuoteFromStore());
  }

  getTrustbenSessionId() {
    return this.trustbenSessionId;
  }

  setTrustbenSessionId(sessionId: string) {
    if (!sessionId) return;

    this.trustbenSessionId = sessionId;
    sessionStorage.setItem(trustbenSessionEventKeys.TRUSTBEN_SESSION_ID, sessionId);
  }

  generateSessionId() {
    return String(Date.now());
  }

  private getSessionIdFromStore() {
    return sessionStorage.getItem(trustbenSessionEventKeys.TRUSTBEN_SESSION_ID);
  }

  getIsTrustbenAltQuote() {
    return this.isTrustbenAltQuote;
  }

  setIsTrustbenAltQuote(isTrustbenAltQuote: boolean) {
    this.isTrustbenAltQuote = isTrustbenAltQuote;
    sessionStorage.setItem(trustbenSessionEventKeys.IS_TRUSTBEN_ALTQUOTE, `${ isTrustbenAltQuote }`);
  }

  private getIsTrustbenAltQuoteFromStore() {
    return JSON.parse(sessionStorage.getItem(trustbenSessionEventKeys.IS_TRUSTBEN_ALTQUOTE) || "false");
  }

  clearTrustbenSession() {
    this.isTrustbenAltQuote = false;
    sessionStorage.removeItem(trustbenSessionEventKeys.IS_TRUSTBEN_ALTQUOTE);
  }
}

const trustbenSessionService = new TrustbenSessionService();

export default trustbenSessionService;
