import { AccountType } from "common/lib/constants";
import { TCustodyAccountType } from "./constants";
import { sessionService } from "common/shared";
import {
  IIndividualCustodyAccount,
  IInstitutionCustodyAccount,
  ICustodyAccount,
  ICustodyAccountAPI,
} from "domains/custodyAccounts/shared/types";
import { RelationshipTypes, TUserType, UserType } from "domains/clients/shared/types";
import { IUpdateAPIObject } from "common/shared/types";

const getPath = (key: string, custodyAccountType: TCustodyAccountType) => {
  switch (key) {
    case "address_line_1":
    case "address_line_2":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/street${ key.slice(-1) }`
        : `/institution_data/addresses/0/street${ key.slice(-1) }`;

    case "city":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/${ key }`
        : `/institution_data/addresses/0/${ key }`;

    case "state":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/region`
        : `/institution_data/addresses/0/region`;

    case "country":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/country`
        : `/institution_data/addresses/0/country`;

    case "country_of_incorporation":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/tax_id_country`
        : `/institution_data/tax_id_country`;

    case "country_of_citizenship":
      return `/tax_id_country`;

    case "zip_code":
      return custodyAccountType === AccountType.CustodialIndividual
        ? `/addresses/0/postal_code`
        : `/institution_data/addresses/0/postal_code`;

    case "telephone_number":
    case "phone_number":
      return `/phones/0/phone_number`;

    case "phone_country":
      return `/phones/0/phone_country`;

    case "country_code":
      return `/phones/0/country_code`;

    case "custody_agreement":
      return `custodial_agreement`;

    case "company_name":
      return `/account_name`;

    case "email_address":
      return `/email_addresses/0/email`;

    default:
      return `/${ key }`;
  }
};

export const formatCustodyAccountDataToUpdate = (
  formValues: IIndividualCustodyAccount | IInstitutionCustodyAccount,
  custodyAccountType: TCustodyAccountType,
) => {
  switch (custodyAccountType) {
    case AccountType.CustodialIndividual: {
      const formattedValues = Object.entries(formValues).reduce((acc: IUpdateAPIObject[], [ key, value ]) => {
        acc.push({
          op: "replace",
          path: getPath(key, custodyAccountType),
          value: value,
        });

        return acc;
      }, []);

      return formattedValues;
    }

    case AccountType.CustodialInstitution:
    case AccountType.CustodialAdvisor: {
      const formattedValues = Object.entries(formValues).reduce((acc: IUpdateAPIObject[], [ key, value ]) => {
        acc.push({
          op: "replace",
          path: getPath(key, custodyAccountType),
          value: value,
        });

        return acc;
      }, []);

      return formattedValues;
    }

    default:
      break;
  }
};

export const formatCustodyAccountDataToCreateByUserType = (
  data: any,
  userType: TUserType,
  custodyAccountType: TCustodyAccountType,
) => {
  const { userId } = sessionService.getSessionIds();
  const white_label_instance = sessionService.getWhiteLabelInstance();

  const individualCustodyAccountBaseItem = {
    account_name: `${ data.first_name } ${ data.last_name }`,
    account_type: AccountType.CustodialIndividual,
    access_granted: false,
    relationship_type: RelationshipTypes.Custodial,
    status: "needs_id",
    user: {
      first_name: data.first_name,
      middle_name: "",
      last_name: data.last_name,
      nick_name: "",
      time_zone_abbreviation: null,
      suffix: null,
      phones: [
        {
          country_code: data.country_code,
          is_validated: false,
          accepts_text_messages: false,
          phone_number: String(data.telephone_number).startsWith("+")
            ? String(data.telephone_number).slice(String(data.country_code).length + 1)
            : data.telephone_number,
          phone_country: data.phone_country,
          is_default: true,
        },
      ],
      email_addresses: [
        {
          email: data.email_address,
          is_default: true,
        },
      ],
      addresses: [
        {
          street1: data.address_line_1,
          street2: data.address_line_2,
          city: data.city,
          region: data.state,
          country: data.country,
          postal_code: data.zip_code,
          is_default: true,
        },
      ],
      user_type: "contact",
      white_label_instance,
      ssn: data.ssn,
      tax_id_country: data.country_of_citizenship,
      date_of_birth: data.date_of_birth,
    },
    parent_user_id: userId,
    is_switch_account_for_liquidity_request: data.is_switch_account_for_liquidity_request,
  };

  const institutionCustodyAccountBaseItem = {
    account_name: data.account_name,
    account_type: AccountType.CustodialInstitution,
    access_granted: false,
    relationship_type: RelationshipTypes.Custodial,
    status: "needs_id",
    institution_data: {
      clientType: "Institution",
      addresses: [
        {
          street1: data.address_line_1,
          street2: data.address_line_2,
          city: data.city,
          region: data.state,
          country: data.country,
          postal_code: data.zip_code,
          is_default: true,
        },
      ],
      institution_type: "custody_account_firm",
      tax_id_country: data.country_of_incorporation,
      tax_id: data.tax_id,
    },
    user: {
      first_name: data.first_name,
      middle_name: "",
      last_name: data.last_name,
      nick_name: "",
      time_zone_abbreviation: null,
      suffix: null,
      phones: [
        {
          country_code: data.country_code,
          is_validated: false,
          accepts_text_messages: false,
          phone_number: String(data.telephone_number).startsWith("+")
            ? String(data.telephone_number).slice(String(data.country_code).length + 1)
            : data.telephone_number,
          phone_country: data.phone_country,
          is_default: true,
        },
      ],
      email_addresses: [
        {
          email: data.email_address,
          is_default: true,
        },
      ],
      user_type: "contact",
      white_label_instance,
      ssn: data.ssn,
      tax_id_country: data.country_of_citizenship,
      date_of_birth: data.date_of_birth,
    },
    parent_user_id: userId,
    is_switch_account_for_liquidity_request: data.is_switch_account_for_liquidity_request,
  };

  const advisorCustodyAccountBaseItem = {
    account_name: data.account_name,
    account_type: AccountType.CustodialAdvisor,
    access_granted: false,
    relationship_type: RelationshipTypes.CustodialAdvisor,
    status: "needs_id",
    institution_data: {
      addresses: [
        {
          street1: data.address_line_1,
          street2: data.address_line_2,
          city: data.city,
          region: data.state,
          country: data.country,
          postal_code: data.zip_code,
          is_default: true,
        },
      ],
      institution_type: "custody_account_firm",
      tax_id_country: data.country_of_incorporation,
      tax_id: data.tax_id,
    },
    user: {
      first_name: data.first_name,
      middle_name: "",
      last_name: data.last_name,
      nick_name: "",
      time_zone_abbreviation: null,
      suffix: null,
      phones: [
        {
          country_code: data.country_code,
          is_validated: false,
          accepts_text_messages: false,
          phone_number: String(data.telephone_number).startsWith("+")
            ? String(data.telephone_number).slice(String(data.country_code).length + 1)
            : data.telephone_number,
          phone_country: data.phone_country,
          is_default: true,
        },
      ],
      email_addresses: [
        {
          email: data.email_address,
          is_default: true,
        },
      ],
      user_type: "contact",
      white_label_instance,
      ssn: data.ssn,
      tax_id_country: data.country_of_citizenship,
      date_of_birth: data.date_of_birth,
    },
    parent_user_id: userId,
    is_switch_account_for_liquidity_request: data.is_switch_account_for_liquidity_request,
  };

  let custodyAccountData;

  switch (userType) {
    case UserType.Principal:
    case UserType.Employee:
      switch (custodyAccountType) {
        case AccountType.CustodialIndividual:
          custodyAccountData = individualCustodyAccountBaseItem;
          break;

        case AccountType.CustodialInstitution:
          custodyAccountData = institutionCustodyAccountBaseItem;
          break;
      }
      break;

    case UserType.Advisor:
      switch (custodyAccountType) {
        case AccountType.CustodialIndividual:
          custodyAccountData = {
            ...individualCustodyAccountBaseItem,
            relationship_type: RelationshipTypes.CustodialAdvisor,
          };
          break;

        case AccountType.CustodialInstitution:
          custodyAccountData = {
            ...institutionCustodyAccountBaseItem,
            relationship_type: RelationshipTypes.CustodialAdvisor,
          };
          break;

        case AccountType.CustodialAdvisor:
          custodyAccountData = advisorCustodyAccountBaseItem;
          break;
      }
      break;
  }

  data?.parent_account_id &&
    Object.defineProperty(custodyAccountData, "parent_account_id", {
      value: data.parent_account_id,
      writable: true,
      enumerable: true,
      configurable: true,
    });

  return custodyAccountData;
};

export const formatCustodyAccountToConnectWithLRByUserType = (
  userType: TUserType,
  data: ICustodyAccount & ICustodyAccountAPI,
  LRId: string,
) => {
  let requestBody;

  switch (userType) {
    case UserType.Principal:
    case UserType.Employee:
      requestBody = {
        liquidity_request_id: LRId,
        account_id: String(data.account_id).length === 10 ? parseInt(data.account_id.slice(4)) : data.account_id,
        account_name:
          data?.owner_type === AccountType.CustodialIndividual
            ? `${ data.first_name } ${ data.last_name }`
            : data.account_name,
        account_display_id: data.account_display_id || data.display_id,
        account_relationship_type: RelationshipTypes.Custodial,
        relationship_type: "5",
        kyc_status: "None",
        is_active: true,
        is_test_data: false,
      };
      break;

    case UserType.Advisor:
      requestBody = {
        liquidity_request_id: LRId,
        account_id: String(data.account_id).length === 10 ? parseInt(data.account_id.slice(4)) : data.account_id,
        account_name:
          data?.owner_type === AccountType.CustodialIndividual
            ? `${ data.first_name } ${ data.last_name }`
            : data.account_name,
        account_display_id: data.account_display_id || data.display_id,
        account_relationship_type: RelationshipTypes.CustodialAdvisor,
        relationship_type: "8",
        kyc_status: "None",
        is_active: true,
        is_test_data: false,
      };
      break;
  }

  data?.parent_account_id &&
    Object.defineProperty(requestBody, "parent_account_id", {
      value: data.parent_account_id,
      writable: true,
      enumerable: true,
      configurable: true,
    });

  return requestBody;
};
