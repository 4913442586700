import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as Edit } from "common/assets/images/icons/Edit.svg";

interface IProps extends Partial<CustomIconComponentProps> {
  onClick: (e:any) => void;
}

const EditOutlined: FC<IProps> = (props) => (
  <Icon { ...props } className={ props?.className + " ben-icon-primary-color" } component={ Edit } />
);

export default EditOutlined;
