import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import { IAltQuoteOffers, IAltQuoteOffersOptions } from "domains/altQuotes/shared/types";
import { trustbenSessionService } from "domains/trustben";
import altQuotesApiService from "domains/altQuotes/shared/altQuotes.api.service";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { formatAltQuoteOffersForReducer } from "domains/altQuotes/shared/utils/apiFormatter";
import { apiService } from "common/shared";
import { ILiquidityRequest, LiquidityRequestStatus, TAssetType } from "domains/liquidity/shared/types";
import { IUserClient } from "domains/clients/shared/types";
import { TAppState } from "store/root.store";
import {
  formatAssetBodyForRequest,
  formatDocuemtGridDataForRequest,
  formatLiquidityRequestBodyForRequest,
} from "domains/liquidity/shared/utils/apiFormatter";
import liquidityApiService from "domains/liquidity/shared/liquidity.api.service";
import { DocumentGridStatus } from "domains/documents/shared/types";
import documentsApiService from "domains/documents/shared/document.api.service";

export const altQuotesApiReducer = rootApiReducer.injectEndpoints({
  endpoints: (build) => ({
    fetchAltQuoteOffers: build.query<IAltQuoteOffers, string>({
      queryFn: async (LRId, { dispatch }) => {
        try {
          const isInternalAltQuoteFunctionality = JSON.parse(
            sessionStorage.getItem("IS_INTERNAL_ALTQUOTE_FUNCTIONALITY") ||
              `${ trustbenSessionService.getIsTrustbenAltQuote() }` ||
              "false",
          );

          const response: Partial<IAltQuoteOffersOptions[]> = await altQuotesApiService.getAltQuoteOffers(LRId);

          const sortedResponse = response
            .sort((a) => (a?.option_group === "total_range" ? -1 : 1))
            .sort((a, b) => a?.alt_quote_asset_id! - b?.alt_quote_asset_id!);
          const altQuoteOffers = formatAltQuoteOffersForReducer(sortedResponse);

          if (isInternalAltQuoteFunctionality) {
            dispatch(liquidityApiReducer.endpoints.updateLRCurrentTask.initiate(LRId));
          }

          return { data: altQuoteOffers };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    convertAltQuoteToLiquidityApplication: build.mutation<
      string,
      { altQuote: ILiquidityRequest; assets: TAssetType[]; client?: IUserClient }
    >({
      queryFn: async ({ altQuote, assets, client }, { getState, dispatch }) => {
        try {
          const user = (getState() as TAppState).clientsReducer.user!;
          const body = formatLiquidityRequestBodyForRequest("LiquidityRequest", user, client);
          const data = {
            ...body,
            status: LiquidityRequestStatus.PendingAssetStatements,
            reason_for_seeking_liquidity: altQuote?.reason_for_seeking_liquidity || "",
            alt_quote_id: altQuote.id,
          };

          const { id: LRId } = await liquidityApiService.createLiquidityRequest(data);

          const formattedAssetsToCreate = assets.map((asset: TAssetType) =>
            formatAssetBodyForRequest(asset, user, LRId.slice(-6)),
          );
          const response = await Promise.all(
            formattedAssetsToCreate.map((body: any) => liquidityApiService.createAsset(body)),
          );

          const formattedAssetsDocGrid = assets.map((asset: TAssetType, index: number) => {
            const assetWithId = { ...asset, asset_id: response[ index ].id };
            return formatDocuemtGridDataForRequest(assetWithId, user, LRId, DocumentGridStatus.NotReceived);
          });
          await Promise.all(
            formattedAssetsDocGrid.map((body: any) => documentsApiService.setDocumentGridData(LRId, body)),
          );

          dispatch(liquidityApiReducer.endpoints.updateLRListAfterCreation.initiate(LRId));

          return { data: LRId };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),
  }),
});
