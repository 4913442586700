import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Form, Select } from "common/lib/formik-antd";
import { filterStates } from "common/shared/helpers/filterStates";
import { getStates } from "common/lib/constants";
import { LOCATION_IDS } from "domains/liquidity/pages/SelectAssetsPage/SelectOtherAsset/constants";
import { IDirectRealEstateInvestment } from "domains/liquidity/shared/types";
import { locationList, outOfUSList, realEstatePropertyTypeList } from "./constants";
import { onStateSearch } from "common/shared/helpers/searchAmericanStates";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { LocationRegion } from "domains/liquidity/shared/constants";

interface IDirectRealEstateInvestmentForm {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const DirectRealInvestmentForm: FC<IDirectRealEstateInvestmentForm & Partial<IDirectRealEstateInvestment>> = ({
  real_estate_location,
  setFieldValue,
}) => {
  const { t } = useTranslation([ "liquidity", "common" ]);

  const { sendLogs } = useTrustbenLogs();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { currentAsset } = useLiquiditySelector();

  return (
    <>
      <Form.Item name="real_estate_property_type" label={ t("Real Estate Property Type") } required>
        <Select
          name="real_estate_property_type"
          options={ realEstatePropertyTypeList }
          placeholder={ t("Select One", { ns: "common" }) }
          onChange={ (value) => {
            isTrustbenAltQuote &&
              sendLogs({
                what: "real_estate_property_type",
                details: `real_estate_property_type:${ value }:${ currentAsset.audit_logs_id }`,
              });
          } }
        />
      </Form.Item>

      <Form.Item name="real_estate_location" label={ t("Location") } required>
        <Select
          name="real_estate_location"
          placeholder={ t("Select One", { ns: "common" }) }
          options={ locationList }
          onChange={ (value) => {
            isTrustbenAltQuote &&
              value === LocationRegion.NorthAmerica &&
              sendLogs({
                what: "real_estate_location",
                details: `real_estate_location:${ value }:${ currentAsset.audit_logs_id }`,
              });
          } }
        />
      </Form.Item>

      {real_estate_location === LOCATION_IDS.STATE && (
        <Form.Item name="state" label={ t("State") }>
          <Select
            showSearch
            name="state" /* state_or_province */
            optionFilterProp="label"
            filterOption={ filterStates }
            placeholder={ t("Select One", { ns: "common" }) }
            onSearch={ (value) => onStateSearch(value, setFieldValue) }
            onChange={ (value) => {
              isTrustbenAltQuote &&
                sendLogs({
                  what: "state",
                  details: `state:${ value }:${ currentAsset.audit_logs_id }`,
                });
            } }
            options={ getStates() }
          />
        </Form.Item>
      )}

      {!!real_estate_location && real_estate_location !== LOCATION_IDS.STATE && (
        <Form.Item name="other_region" label={ t("Chose from the following") } required>
          <Select
            name="other_region"
            placeholder={ t("Select One", { ns: "common" }) }
            options={ outOfUSList }
            onChange={ (value) => {
              isTrustbenAltQuote &&
                sendLogs({
                  what: "real_estate_location",
                  details: `real_estate_location:${ value }:${ currentAsset.audit_logs_id }`,
                });
            } }
          />
        </Form.Item>
      )}
    </>
  );
};

export default DirectRealInvestmentForm;
