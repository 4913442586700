import { useMemo, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { useClientsSelector } from "domains/clients";
import { LAPages } from "domains/liquidity";
import { URLs } from "common/lib/constants";
import { RelationshipTypes } from "domains/clients/shared/types";
import { formatId } from "common/shared/helpers";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

const useCustodialAgreement = () => {
  const navigate = useNavigate();
  const { LRId } = useParams();

  const { user } = useClientsSelector();
  const { data: accounts } = liquidityApiReducer.useFetchLRAccountsQuery(LRId ?? skipToken);
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );

  const activeCustodyAccounts = useMemo(
    () =>
      custodyAccounts?.filter(
        (custodyAccount) =>
          custodyAccount.account_status !== CustodyAccountStatus.Closed &&
          custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
          custodyAccount.account_status !== CustodyAccountStatus.Duplicate,
      ),
    [ custodyAccounts ],
  );
  const isActiveCustodyAccount = useMemo(() => {
    const custodyAccountLR = accounts?.find(
      (account) =>
        account.relationship_type === RelationshipTypes.Custodial ||
        account.relationship_type === RelationshipTypes.CustodialAdvisor,
    );
    const custodyAccountInTheList = custodyAccounts?.find(
      (account) => account.account_id === formatId("ACC-", custodyAccountLR?.account_id || ""),
    );

    return custodyAccountInTheList ? custodyAccountInTheList.account_status !== CustodyAccountStatus.Closed : false;
  }, [ accounts?.length, custodyAccounts?.length ]);

  const [ isCustodialAgreementActive, setIsCustodialAgreementActive ] = useState(false);

  const checkCustodialAgreement = () => {
    if (isActiveCustodyAccount) {
      setIsCustodialAgreementActive(true);
      return true;
    } else {
      setIsCustodialAgreementActive(false);
      activeCustodyAccounts?.length
        ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccount)
        : navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
      return false;
    }
  };

  return { isCustodialAgreementActive, checkCustodialAgreement };
};

export default useCustodialAgreement;
