import { memo, useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { ModalCard } from "domains/dashboard/components";
import { useScreen } from "common/shared-hooks";
import { URLs } from "common/lib/constants";
import { ICard } from "./types";
import { LAPages } from "domains/liquidity";
import { FormFooter } from "common/lib/components";
import ApplyForLiquidityModal from "domains/dashboard/components/ApplyForLiquidityModal";
import { useInternalAltQuoteFunctionality } from "domains/altQuotes";
import { FeatureNames, useFeature } from "domains/features";
import { UserType } from "domains/clients/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./ApplyForLiquidityButtonModal.scss";

interface IProps {
  isModalVisible: boolean;
  handleCancel: (e?: any) => void;
  isAdvisor: boolean;
}

const ApplyForLiquidityButtonModal = memo(function ApplyForLiquidityButtonModal({
  isModalVisible,
  handleCancel,
  isAdvisor,
}: IProps) {
  const navigate = useNavigate();
  const { mobile } = useScreen();
  const { t } = useTranslation([ "dashboard" ]);

  const [ createLiquidityRequest ] = liquidityApiReducer.useCreateLiquidityRequestMutation({
    fixedCacheKey: "create-liquidity-request",
  });
  const { isInternalAltQuoteFunctionality } = useInternalAltQuoteFunctionality();
  const { isFeatureEnabled } = useFeature(FeatureNames.EmbeddedAltQuote, Object.values(UserType));

  const [ isApplyForLiquidityModalVisible, setIsApplyForLiquidityModalVisible ] = useState(false);
  const [ isLA, setIsLA ] = useState<boolean>();

  const cards: ICard[] = [
    {
      title: t("AltQuote"),
      text: t("Apply For Liquidity.AltQuote.Text"),
      buttonText: t("Apply For Liquidity.AltQuote.Button Text"),
      handleClick: () => {
        if (isAdvisor) {
          setIsLA(false);
          sessionStorage.setItem("IS_LIQUIDITY_APPLICATION", "false");
          setIsApplyForLiquidityModalVisible(true);
          handleCancel();
          return;
        }
        createLiquidityRequest({ isLA: false });
        navigate(URLs.PROTECTED.LIQUIDITY + LAPages.SelectAssets);
      },
    },
    {
      title: t("Apply For Liquidity.LA.Title"),
      text: t("Apply For Liquidity.LA.Text"),
      buttonText: t("Apply For Liquidity.LA.Button Text"),
      handleClick: () => {
        if (isAdvisor) {
          setIsLA(true);
          sessionStorage.setItem("IS_LIQUIDITY_APPLICATION", "true");
          setIsApplyForLiquidityModalVisible(true);
          handleCancel();
          return;
        }
        createLiquidityRequest({ isLA: true });
        navigate(URLs.PROTECTED.LIQUIDITY + LAPages.SelectAssets);
      },
    },
  ];

  isInternalAltQuoteFunctionality && cards.pop();
  !isFeatureEnabled && !isInternalAltQuoteFunctionality && cards.shift();

  const handleApplyForLiquidityModalCancel = useCallback(() => {
    setIsApplyForLiquidityModalVisible(false);
  }, []);

  return (
    <>
      <Modal
        mask={ !isInternalAltQuoteFunctionality }
        closable={ !isInternalAltQuoteFunctionality }
        width={ 650 }
        className="gray-modal"
        centered={ true }
        open={ isModalVisible }
        onCancel={ handleCancel }
        footer={ null }
      >
        <div className="ben-modal-apply-for-liquidity">
          {mobile && (
            <div className="ben-modal-new-liquidity-request">
              <h2 className="modal ben-text-center ben-pb-1">{t("Apply For Liquidity.New Liquidity Request")}</h2>
              <span className="text ben-d-block ben-text-center">{t("Apply For Liquidity.Mobile Text")}</span>
            </div>
          )}
          <div className={ `${ cards.length === 1 ? "ben-card-container" : "ben-cards-container" }` }>
            {cards.map((card) => (
              <ModalCard
                key={ card.title }
                title={ card.title }
                text={ card.text }
                buttonText={ card.buttonText }
                onClick={ card.handleClick }
              />
            ))}
          </div>
          <FormFooter className="ben-modal-apply-for-liquidity-footer">
            <p>
              <span>{t("Apply For Liquidity.Footer Title")}</span> {t("Apply For Liquidity.Footer Info")}
            </p>
          </FormFooter>
        </div>
      </Modal>
      <ApplyForLiquidityModal
        isVisible={ isApplyForLiquidityModalVisible }
        handleCancel={ handleApplyForLiquidityModalCancel }
        isLA={ isLA! }
      />
    </>
  );
});

export default ApplyForLiquidityButtonModal;
