import { TWhiteLabel } from "common/shared/types";

export enum FeatureNames {
  CustodyAccount = "CustodyAccountVersion",
  Consent = "ConsentVersion",
  GP = "GPVersion",
  PLP = "PLPVersion",
  EmbeddedAltQuote = "EmbeddedAltQuoteVersion",
  TrustbenAltQuote = "TrustBenAltQuoteVersion",
  AltCustody = "AltCustodyVersion",
}

export type TFeatureNames =
  | "CustodyAccountVersion"
  | "ConsentVersion"
  | "GPVersion"
  | "PLPVersion"
  | "EmbeddedAltQuoteVersion"
  | "TrustBenAltQuoteVersion"
  | "AltCustodyVersion";

export interface IFeature {
  featureName: string;
  whiteLabelInstance: TWhiteLabel;
  isActive: boolean;
}
