import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as CheckMark } from "common/assets/images/icons/Checkmark.svg";

const CheckMarkFilled: FC<Partial<CustomIconComponentProps>> = (props) => (
  <Icon { ...props } component={ CheckMark } />
);

export default CheckMarkFilled;
