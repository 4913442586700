import { FC, useMemo } from "react";

import { ApplyForLiquidityButton } from "domains/dashboard/components";
import { useScreen } from "common/shared-hooks";
import "common/assets/styles/utilities/display.scss";
import "common/assets/styles/utilities/spacing.scss";
import defaultVideoTutorial from "common/assets/images/Dashboard_Video-default.png";
import partnerVideoTutorial from "common/assets/images/Dashboard_Video-partner.png";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import useWhiteLabel from "common/shared-hooks/useWhiteLabel";
import { WhiteLabelType } from "common/shared/types";
import { useFeature, FeatureNames } from "domains/features";

import "./ApplyForLiquidityTutorial.scss";

interface IProps {
  title: string;
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
}

const ApplyForLiquidityTutorial: FC<IProps> = ({ className, title, children }) => {
  const { isAdvisor } = useClientsSelector();
  const { mobile } = useScreen();
  const whiteLabelType = useWhiteLabel();
  const { isFeatureActive } = useFeature(FeatureNames.AltCustody, []);

  const imgSrc = useMemo(() => {
    if (!whiteLabelType) return "";

    switch (whiteLabelType) {
      case WhiteLabelType.Default:
        return defaultVideoTutorial;

      case WhiteLabelType.Trustben:
        return defaultVideoTutorial;

      case WhiteLabelType.Partner:
        return partnerVideoTutorial;

      default:
        return "";
    }
  }, [ whiteLabelType ]);

  return (
    <div className={ `ben-card content ${ className }` }>
      <div className="ben-card-content">
        {!mobile && <h3>{title}</h3>}
        <span className="ben-d-block ben-pb-2 text">{children}</span>
        {!mobile && !isFeatureActive && <ApplyForLiquidityButton isAdvisor={ isAdvisor } />}
      </div>

      <div className="ben-card-image">
        <img src={ imgSrc } />
      </div>

      {mobile && !isFeatureActive && <ApplyForLiquidityButton isAdvisor={ isAdvisor } />}
    </div>
  );
};

export default ApplyForLiquidityTutorial;
