import { FC } from "react";

import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

import { ReactComponent as YouTubeIcon } from "common/assets/images/icons/trustben-youtube-icon.svg";

const YouTube: FC<Partial<CustomIconComponentProps>> = (props) => <Icon { ...props } component={ YouTubeIcon } />;

export default YouTube;
