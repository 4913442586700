import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { Form, Input } from "common/lib/formik-antd";
import { Tooltip } from "common/lib/components";
import { IDirectInterestsInNaturalResourcesAsset } from "domains/liquidity/shared/types";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

const DirectInterestInANaturalResourcesAssetForm = () => {
  const { t } = useTranslation([ "liquidity" ]);
  const { values } = useFormikContext<IDirectInterestsInNaturalResourcesAsset>();

  const { sendLogs } = useTrustbenLogs();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { currentAsset } = useLiquiditySelector();

  return (
    <Form.Item
      name="asset_description"
      className="ben-description"
      required
      label={
        <>
          {t("Description of Asset")}
          <Tooltip content={ t("Description of Asset") } dark />
        </>
      }
    >
      <Input.TextArea
        name="asset_description"
        autoSize={ true }
        onBlur={ () => {
          isTrustbenAltQuote &&
            sendLogs({
              what: "asset_description",
              details: `asset_description:${ values.asset_description }:${ currentAsset.audit_logs_id }`,
            });
        } }
      />
    </Form.Item>
  );
};

export default DirectInterestInANaturalResourcesAssetForm;
