import { FC, useEffect } from "react";

import { Collapse, UploadProps, message } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { RcFile } from "antd/lib/upload";

import { useCustodialAgreement, useScreen } from "common/shared-hooks";
import AssetDocumentsMobileList from "domains/liquidity/components/AssetDocumentsMobilList";
import AssetDocumentsTable from "domains/liquidity/components/AssetDocumentsTable";
import { DocumentGridStatus, IDocument } from "domains/documents/shared/types";
import { setDocumentUploadProgress } from "domains/documents/store/documents.reducer";
import apiService from "common/shared/services/api.service/api.service";
import { DocumentType, allowedMimeTypes } from "domains/documents/shared/constants";
import { truncateFileName } from "domains/documents/shared/utils";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

import "./UploadDocumentsForm.scss";

const { Panel } = Collapse;

interface IProps {
  isCollapseActiveByDefault?: boolean;
}

const UploadDocumentsForm: FC<IProps> = ({ isCollapseActiveByDefault = false }) => {
  const { t } = useTranslation("liquidity");
  const dispatch = useDispatch();
  const { LRId } = useParams();
  const { mobile } = useScreen();

  const { currentLiquidityRequest } = useLiquiditySelector();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });
  const [ uploadDocument, { data: document, isSuccess: isDocumentUploaded } ] =
    documentsApiReducer.useUploadDocumentMutation();
  const [ setLRDocumentGridData, { isLoading: isSettingLRDocumentGridData, isSuccess: isLRDocumentGridDataSetted } ] =
    documentsApiReducer.useSetLRDocumentGridDataMutation();

  const { checkCustodialAgreement } = useCustodialAgreement();

  const documentsList = [
    t("Documents List.0"),
    t("Documents List.1"),
    t("Documents List.2"),
    t("Documents List.3"),
    t("Documents List.4"),
  ];

  const onUploadProgress = (id: string) => (progress: number) => {
    dispatch(setDocumentUploadProgress({ docId: id, progress }));
  };

  const getDraggerProps = (): UploadProps => ({
    accept: allowedMimeTypes.join(","),
    multiple: true,
    showUploadList: false,
    onDrop: (event) => {
      const invalid = Array.from(event.dataTransfer!.files).some((file) => !allowedMimeTypes.includes(file.type));
      if (invalid) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
      }
    },
    beforeUpload: (file): boolean => {
      if (!checkCustodialAgreement()) {
        return false;
      }

      if (!allowedMimeTypes.includes(file.type)) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
        return false;
      }

      if ((file.size ?? 0) > 500 * 1_000_000) {
        message.error(t("validation.fileSize", { ns: "common", value: 500 }));
        return false;
      }

      return true;
    },
    customRequest: (options) => {
      const file = options.file as RcFile;

      const doc = {
        asset_id: null,
        file_name: truncateFileName(file.name.replace(/[^- _a-zA-Z0-9!.'()]/g, "-")),
        document_name:
          file.name.substring(0, file.name.lastIndexOf(".")).substring(0, 123) || file.name.substring(0, 123),
        document_type: DocumentType.Other,
        reference_type: "liquidity_request",
        file_size: file.size,
        liquidity_request_id: LRId,
        investment_id: null,
        do_not_have_this_document: false,
        id: file.uid,
        uid: file.uid,
        cancelToken: apiService.getCancelToken(),
        mimeType: file.type,
      };

      currentLiquidityRequest?.account_id &&
        uploadDocument({
          accountId: currentLiquidityRequest.account_id,
          document: doc as IDocument,
          file,
          onUploadProgress: onUploadProgress(doc.id),
          LRId: currentLiquidityRequest.id,
        });
    },
  });

  useEffect(() => {
    if (!isDocumentUploaded || !document) return;

    const LRId = document.liquidity_request_id;
    const liquidityRequestStatus = liquidityRequests?.find((lr) => lr.id === LRId)?.currentTask
      .liquidity_request_status;
    const allDocsUploaded = assets.length > 0 && document;

    if (allDocsUploaded && liquidityRequestStatus === LiquidityRequestStatus.PendingAssetStatements && LRId) {
      updateLiquidityRequest({ LRId, changes: { status: LiquidityRequestStatus.InDocumentReview } });
    }
  }, [ isDocumentUploaded ]);

  useEffect(() => {
    if (!isDocumentUploaded || !document || isSettingLRDocumentGridData || isLRDocumentGridDataSetted) return;

    const LRId = document.liquidity_request_id as string;
    setLRDocumentGridData({ LRId, status: DocumentGridStatus.PendingReview });
  }, [ isDocumentUploaded ]);

  return (
    <div className="ben-upload-documents-form">
      <Collapse
        expandIcon={ ({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />) }
        defaultActiveKey={ isCollapseActiveByDefault ? 1 : undefined }
      >
        <Panel key="1" header={ t("Documents Required for Each Fund") }>
          <ul>
            {documentsList.map((item) => (
              <li key={ item }>{item}</li>
            ))}
          </ul>
        </Panel>
      </Collapse>
      {!mobile ? (
        <AssetDocumentsTable draggerProps={ getDraggerProps() } />
      ) : (
        <AssetDocumentsMobileList draggerProps={ getDraggerProps() } />
      )}
    </div>
  );
};

export default UploadDocumentsForm;
