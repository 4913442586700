import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import { IDocument } from "domains/documents/shared/types";
import { documentsApiReducer } from "./documents.api.reducer";

interface IInitialState {
  uploadingDocuments: IDocument[];
}

const initialState: IInitialState = {
  uploadingDocuments: [],
};

const documentsReducer = createSlice({
  name: "[Documents]",
  initialState,
  reducers: {
    setDocumentUploadProgress: (
      state,
      action: PayloadAction<{
        docId: string;
        progress: number;
      }>,
    ) => ({
      ...state,
      uploadingDocuments: state.uploadingDocuments.map((document) =>
        document.id === action.payload.docId
          ? {
              ...document,
              percent: action.payload.progress,
            }
          : document,
      ),
    }),

    resetDocumentsReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(documentsApiReducer.endpoints.uploadDocument.matchPending, (state, action) => ({
      ...state,
      uploadingDocuments: [ ...state.uploadingDocuments, action.meta.arg.originalArgs.document as IDocument ],
    })),
      builder.addMatcher(documentsApiReducer.endpoints.uploadDocument.matchFulfilled, (state, action) => ({
        ...state,
        uploadingDocuments: state.uploadingDocuments.filter((document) => document.uid !== action.payload.uid),
      })),
      builder.addMatcher(documentsApiReducer.endpoints.uploadDocument.matchRejected, (state, action) => {
        message.error("Files must be of type PDF, Word, Excel, Powerpoint, or JPEG");

        return {
          ...state,
          uploadingDocuments: state.uploadingDocuments.filter(
            (document) => document.uid !== (action.meta.arg.originalArgs.document as IDocument).uid,
          ),
        };
      });
  },
});

export const {
  setDocumentUploadProgress,

  resetDocumentsReducer,
} = documentsReducer.actions;

export default documentsReducer.reducer;
