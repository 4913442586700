import { FC, useEffect, useMemo } from "react";

import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Spinner, EmbeddedPDF } from "common/lib/components";
import { DocumentType } from "domains/documents/shared/constants";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

import "./CustodyAgreementPDFModal.scss";

interface IProps {
  isVisible: boolean;
  isFooterModal: boolean;
  handleCancel: () => void;
}

const CustodyAgreementPDFModal: FC<IProps> = ({ isVisible, handleCancel, isFooterModal }) => {
  const { t } = useTranslation("liquidity");

  const [ fetchDocumentUrlByDocID, { data: documentUrlData, isFetching: isFetchingDocumentUrlData } ] =
    documentsApiReducer.useLazyFetchDocumentUrlByDocIDQuery();
  const { currentCustodyAccount, currentFooterCustodyAccount } = useCustodyAccountsSelector();
  const { data: documents, isFetching: isFetchingDocuments } = documentsApiReducer.useFetchDocumentsByIdQuery(
    currentCustodyAccount && !isFooterModal ? currentCustodyAccount.account_id : skipToken,
  );
  const { data: footerDocuments, isFetching: isFetchingFooterDocuments } =
    documentsApiReducer.useFetchDocumentsByIdQuery(
      currentFooterCustodyAccount && isFooterModal ? currentFooterCustodyAccount.account_id : skipToken,
    );

  const pdfDocument = useMemo(() => {
    return isFooterModal
      ? footerDocuments?.find((document) => document.document_type === DocumentType.CustodyAgreementRedacted)
      : documents?.find((document) => document.document_type === DocumentType.CustodyAgreementRedacted);
  }, [ documents?.length, footerDocuments?.length, isFooterModal ]);

  useEffect(() => {
    if (!pdfDocument?.id || !isVisible || documentUrlData?.[ 0 ]?.url) return;

    let accountId = "";
    if (isFooterModal) {
      accountId = currentFooterCustodyAccount?.account_id || "";
    } else {
      accountId = currentCustodyAccount?.account_id || "";
    }

    accountId && fetchDocumentUrlByDocID({ accountId, documentIds: pdfDocument.id });
  }, [
    currentCustodyAccount?.account_id,
    currentFooterCustodyAccount?.account_id,
    pdfDocument?.id,
    isVisible,
    documentUrlData?.[ 0 ]?.url,
  ]);

  return (
    <Modal
      styles={ { body: { paddingTop: 50 } } }
      centered
      open={ isVisible }
      width={ 1000 }
      destroyOnClose
      footer={ null }
      onCancel={ handleCancel }
    >
      <Spinner spinning={ isFetchingDocumentUrlData || isFetchingDocuments || isFetchingFooterDocuments }>
        <div className="ben-embedded-pdf-container">
          {documentUrlData?.[ 0 ]?.url && <EmbeddedPDF url={ documentUrlData[ 0 ].url } />}
          {!documentUrlData?.[ 0 ]?.url &&
            !isFetchingDocumentUrlData &&
            !isFetchingDocuments &&
            !isFetchingFooterDocuments && <span className="ben-fallback-pdf-text">{t("FallbackCustodyPDFText")}</span>}
        </div>
      </Spinner>
    </Modal>
  );
};

export default CustodyAgreementPDFModal;
