export const OptionGroup = {
  TotalRange: "total_range",
  LiquidTrust: "liquid_trust",
  ExchangeBond: "exchange_bond",
  ExchangePreferred: "exchange_preferred",
  ExchangeCommon: "exchange_common",
  InterchangeBond: "interchange_bond",
  InterchangePreferred: "interchange_preferred",
  InterchangeCommon: "interchange_common",
};

export const OptionType = {
  None: "none",
  PreferredRange: "preferred_range",
  CashRange: "cash_range",
  BondRange: "bond_range",
  CommonRange: "common_range",
};

export const Note = {
  Annual: "5.0-6.0% annual",
  Return: "4.0-5.0% return",
};
