import { FC, useState } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";

import { Form, Select } from "common/lib/formik-antd";
import { NavigationButtons } from "common/lib/components";
import { reasonsForSeekingLiquidity } from "./constants";
import { useValidationSchema } from "./validationSchema";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { useHandlers } from "./useHandlers";
import { useTrustbenSelector } from "domains/trustben";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

interface IProps {
  isLA: boolean;
  addFund: () => void;
}

const ReasonForSeekingLiquidityForm: FC<IProps> = ({ isLA, addFund }) => {
  const { t } = useTranslation("liquidity");
  const { LRId } = useParams();

  const { currentLiquidityRequest, assetsPendingCreation } = useLiquiditySelector();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);

  const validationSchema = useValidationSchema();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { handleSelect, handleSubmit } = useHandlers(isLA);

  const [ initialValues ] = useState({
    reason_for_seeking_liquidity: currentLiquidityRequest?.reason_for_seeking_liquidity || "",
  });

  const isDisabledWhenTrustben = isTrustbenAltQuote && !!assetsPendingCreation.length;

  return (
    <Formik
      initialValues={ initialValues }
      validationSchema={ validationSchema }
      validateOnMount
      validateOnChange
      onSubmit={ handleSubmit }
    >
      {({ isValid }) => (
        <Form layout="vertical">
          <Form.Item
            className="ben-input"
            label={ t("Select Reason for Seeking Liquidity") }
            name="reason_for_seeking_liquidity"
          >
            <Select
              onSelect={ handleSelect }
              placeholder={ t("Select Reason for Seeking Liquidity") }
              name="reason_for_seeking_liquidity"
            >
              {reasonsForSeekingLiquidity.map((reason) => (
                <Select.Option key={ reason.label } value={ reason.label }>
                  {reason.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <NavigationButtons
            disabled={ !assets.length || !isValid || isDisabledWhenTrustben }
            submitButtonText={ isTrustbenAltQuote ? "Continue" : "Submit" }
            backButtonText={ `+ ${ t("Add Another Asset") }` }
            onBack={ addFund }
          />
        </Form>
      )}
    </Formik>
  );
};

export default ReasonForSeekingLiquidityForm;
