import { FC } from "react";

import { Table } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { desktopFundColumns, mobileFundColumns, altQuoteDesktopFundColumns } from "./constants";
import { useScreen } from "common/shared-hooks";
import { currencyFormatter } from "domains/liquidity/shared/utils/inputValueFormatter";
import { IFundAsset } from "domains/liquidity/shared/types";
import { Spinner, Button } from "common/lib/components";
import { useAltQuotesSelector } from "domains/altQuotes";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { URLs } from "common/lib/constants";

import "./FundAssetsTable.scss";

interface IProps {
  fundAssets: IFundAsset[];
  onDelete: (asset_id: string, LRId: string) => void;
}

const FundAssetsTable: FC<IProps> = ({ fundAssets, onDelete }) => {
  const { t } = useTranslation([ "liquidity", "common" ]);
  const { mobile } = useScreen();
  const { LRId } = useParams();
  const { pathname } = useLocation();

  const { isAltQuoteTab } = useAltQuotesSelector();
  const [ _, { isLoading: isDeletingLRAsset } ] = liquidityApiReducer.useDeleteLRAssetMutation({
    fixedCacheKey: "delete-lr-asset",
  });

  const isAltCustodyFlow = pathname.includes(URLs.PROTECTED.ALT_CUSTODY);
  const desktopColumns = isAltQuoteTab ? altQuoteDesktopFundColumns : desktopFundColumns;
  const mobileColumns = isAltQuoteTab
    ? mobileFundColumns.filter((column) => column.key !== "action")
    : mobileFundColumns;
  const columns = mobile ? mobileColumns : desktopColumns;

  const fundDataSource = fundAssets.map((asset, i) => ({
    key: i,
    fund: (
      <div>
        <b>
          <span>{asset.asset_name}</span>
        </b>
        <br />
        <span>{asset.fund_manager}</span>
        <br />
        <span className="ben-fund-assets-text">NAV: </span>
        {currencyFormatter(asset.current_net_asset_value)}
        {isAltQuoteTab && (
          <>
            <br />
            <span className="ben-fund-assets-text">Unfunded Commitments: </span>
            {currencyFormatter(asset.unfunded_commitment_currency)}
          </>
        )}
      </div>
    ),
    fundManager: asset.fund_manager || asset.sponsor,
    fundName: asset.asset_name,
    nav: currencyFormatter(asset.current_net_asset_value),
    unfundedCommitments: currencyFormatter(asset.unfunded_commitment_currency),
    action: (
      <div style={ { display: "inline-block" } }>
        <Spinner
          spinning={
            isDeletingLRAsset || asset.asset_id.startsWith("pending_creation") || asset.asset_id.startsWith("creating")
          }
        >
          <Button
            // @ts-ignore
            onClick={ () => (isAltCustodyFlow ? onDelete(asset.id) : onDelete(asset.asset_id, LRId!)) }
            type="primary"
          >
            {t("Delete", { ns: "common" })}
          </Button>
        </Spinner>
      </div>
    ),
  }));

  return <Table pagination={ false } columns={ columns } dataSource={ fundDataSource } className="ben-fund-assets-table" />;
};

export default FundAssetsTable;
