import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { sessionService } from "common/shared";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";

const idleTimeoutInMilliseconds = 3600000;

const IdleTimer = () => {
  const navigate = useNavigate();

  const [ logout ] = authApiReducer.useLogoutMutation();

  const onIdle = () => {
    sessionService.clearSession();
    logout();
    navigate(URLs.AUTH.SIGN_IN);
  };

  useIdleTimer({
    onIdle,
    timeout: idleTimeoutInMilliseconds,
  });

  return null;
};

export default IdleTimer;
