export const currencyFormatter = (value: any) => (value ? `$ ${ value }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value);
export const currencyParser = (value: any) => value.replace(/\$\s?|(,*)/g, "");
export const currencyWithDecimalFormatter = (value: any) => {
  const integer = parseInt(value);
  const decimal = value.split(".")[ 1 ];

  return value ? `${ `$ ${ integer }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }${ decimal ? `.${ decimal }` : "" }` : value;
};

export const percentageFormatter = (value: any) => (value ? `${ value }%` : value);
export const percentageParser = (value: any) => value.replace("%", "");
export const checkCurrency = (value: any) => {
  if (`${ value }`.includes(".")) {
    const arr = `${ value }`.split(".");
    return arr[ 1 ].length < 3 ? true : false;
  }

  return true;
};

export const phoneNumberFormatter = (value: any) => {
  let newValue = value.split("").filter((item: any) => item === "0" || +item).join("");

  if (value) {
    if (newValue[ 0 ] === "1") {
      newValue = newValue.slice(1);
    }

    return `+1 ${ newValue.slice(0, 3) ? "(" : "" }${ newValue.slice(0, 3) }${ 
      newValue.slice(0, 3) ? ")" : "" } ${ newValue.slice(3, 6) }${
      newValue.slice(6, 10) ? "-" : "" }${ newValue.slice(6, 10) }`;
  }

  return value;
};

export const phoneNumberParser = (value: any) => {
  const newValue = value.split("").filter((item: any) => item === "0" || +item).join("");

  return newValue[ 0 ] === "1" ? newValue.slice(1) : newValue;
};

export const socialSecurityFormatter = (value: any) => {
  if (value) {
    return `${ value.slice(0, 3) }${ value.slice(3, 5) ? "-" : "" }${
      value.slice(3, 5) }${ value.slice(5, 9) ? "-" : "" }${ value.slice(5, 9) }`;
  }

  return value;
};
export const socialSecurityParser = (value: any) => value.split("").filter((item: any) => item === "0" || +item).join("");

export const tinOrEinFormatter = (value: any) => {
  if (value) {
    return `${ value.slice(0, 2) }${ value.slice(2, 9) ? "-" : "" }${ value.slice(2, 9) }`;
  }

  return value;
}
export const tinOrEinParser = (value: any) => value.split("").filter((item: any) => item === "0" || +item).join("");
